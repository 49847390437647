import React, { useState } from 'react';
import queryKeys from 'config/queryKeys';
import styled from 'styled-components';
import theme from 'theme';
import { useSelector, shallowEqual } from 'react-redux';

import { useQuery, useMutation, useQueryCache } from 'react-query';

import * as remoteApi from 'api/remote';
import { Outcome, OutcomeRelationship } from 'types';
import { CustomTermKey, translate } from 'utils/customTermUtils';

import KoalaLoader from 'koala/components/Loader';
import RelatedOutcome from 'components/RelatedOutcome';
import KoalaIconButton from 'koala/components/IconButton';
import { useTranslation } from 'react-i18next';
import KoalaIcon from 'koala/components/Icons';

interface Props {
  outcome: Outcome;
  hasEditPermission: boolean;
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.x1};

  header {
    font-size: 1rem;
    text-transform: uppercase;
    font-weight: 700;
    margin-bottom: ${theme.spacing.x1};
    display: flex;
    align-items: center;
    gap: ${theme.spacing.half};
  }
`;

const OutcomesContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.x2};
`;

const Line = styled.div`
  display: flex;
  position: relative;
  gap: ${theme.spacing.x1};
  justify-content: space-between;

  .action {
    opacity: 0.2;
    transition: all ease 0.2s;
  }

  &:hover {
    .action {
      opacity: 1;
    }
  }
`;

function AlignmentAccordion(props: Props) {
  const { outcome, hasEditPermission } = props;

  const queryCache = useQueryCache();
  const { t } = useTranslation();
  const translationKey = 'panels.outcome';
  const currentWorkspace = useSelector((state: any) => state.session.currentWorkspace, shallowEqual);

  const [relationships_count, setRelationshipsCount] = useState(0);
  const [parents, setParent] = useState<OutcomeRelationship[]>([]);
  const [related, setRelated] = useState<OutcomeRelationship[]>([]);
  const [contributing, setContributing] = useState<OutcomeRelationship[]>([]);

  const queryKey = [queryKeys.outcomeRelationships, outcome?.id];
  const staleTime = 0;

  // Functions to destroy the relationship
  const [deleteOutcomeRelationshipMutation, { isLoading: isDeleting }]: any = useMutation(
    remoteApi.deleteOutcomeRelationship,
    {
      onSuccess: () => {
        queryCache.invalidateQueries(queryKeys.outcomeRelationships);
      },
    },
  );

  const handleDeleteOutcomeRelationship = (outcomeRelationshipId: string) => {
    deleteOutcomeRelationshipMutation(outcomeRelationshipId);
  };

  // Get the outcomes
  const { isFetching } = useQuery(queryKey, remoteApi.fetchOutcomeRelationships, {
    staleTime,
    onSuccess: (response: any) => {
      const outcomeRelationships: OutcomeRelationship[] = response.data;
      setRelationshipsCount(outcomeRelationships.length);
      const parents: OutcomeRelationship[] = [];
      const related: OutcomeRelationship[] = [];
      const contributing: OutcomeRelationship[] = [];
      outcomeRelationships.forEach((outcomeRelationship) => {
        if (
          outcomeRelationship.outcome_source_id === outcome.id &&
          outcomeRelationship.relationship_type === 'contributes_to'
        ) {
          parents.push(outcomeRelationship);
        }
        if (outcomeRelationship.relationship_type === 'relates_to') {
          related.push(outcomeRelationship);
        }
        if (
          outcomeRelationship.outcome_target_id === outcome.id &&
          outcomeRelationship.relationship_type === 'contributes_to'
        ) {
          contributing.push(outcomeRelationship);
        }
      });
      setParent(parents);
      setRelated(related);
      setContributing(contributing);
    },
  });

  if (isFetching) {
    return <KoalaLoader />;
  }

  if (relationships_count === 0) {
    return <p className="subtle">{t(`${translationKey}.noDependencies`)}</p>;
  }

  const DisplayOutcome = ({
    outcome,
    aligned = false,
    outcomeRelationshipId,
  }: {
    outcome: Outcome;
    aligned?: boolean;
    outcomeRelationshipId: string;
  }) => {
    return (
      <Line>
        <RelatedOutcome outcome={outcome} aligned={aligned} />
        {hasEditPermission && (
          <div className="action">
            {isDeleting && <KoalaLoader />}
            {!isDeleting && (
              <KoalaIconButton
                onClick={() => handleDeleteOutcomeRelationship(outcomeRelationshipId)}
                iconName="close"
                size="small"
              />
            )}
          </div>
        )}
      </Line>
    );
  };

  return (
    <Container>
      {parents?.length > 0 && (
        <div>
          <header>
            {t(`${translationKey}.parent`, {
              label: translate(currentWorkspace, CustomTermKey.OUTCOME, parents?.length),
            })}
          </header>
          <OutcomesContainer>
            {parents.map((outcomeRelationship) => {
              const outcome = outcomeRelationship.outcome_target;
              return <DisplayOutcome outcome={outcome} outcomeRelationshipId={outcomeRelationship.id} />;
            })}
          </OutcomesContainer>
        </div>
      )}
      {contributing?.length > 0 && (
        <div>
          <header>
            <KoalaIcon iconName="alignmentArrow" iconSize="xsmall" iconAppearance="custom" />
            {t(`${translationKey}.aligned`, {
              label: translate(currentWorkspace, CustomTermKey.OUTCOME, contributing?.length),
            })}
          </header>
          <OutcomesContainer>
            {contributing.map((outcomeRelationship) => {
              const outcome = outcomeRelationship.outcome_source;
              return <DisplayOutcome outcome={outcome} aligned={true} outcomeRelationshipId={outcomeRelationship.id} />;
            })}
          </OutcomesContainer>
        </div>
      )}
      {related?.length > 0 && (
        <div>
          <header>
            {t(`${translationKey}.related`, { label: translate(currentWorkspace, CustomTermKey.OUTCOME, 1) })}
          </header>
          <OutcomesContainer>
            {related.map((outcomeRelationship) => {
              let outcome;
              if (outcomeRelationship.outcome_source_id === props.outcome.id) {
                outcome = outcomeRelationship.outcome_target;
              } else {
                outcome = outcomeRelationship.outcome_source;
              }
              return <DisplayOutcome outcome={outcome} outcomeRelationshipId={outcomeRelationship.id} />;
            })}
          </OutcomesContainer>
        </div>
      )}
    </Container>
  );
}

export default AlignmentAccordion;
