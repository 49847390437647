/**
 * Outcome component
 */
import React, { useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
//import * as TabilityTypes from '../../../types';
import styled from 'styled-components';
import { useMutation, useQueryCache } from 'react-query';
import queryKeys from 'config/queryKeys';
import { useHistory } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';

import theme from 'theme';
// API
import * as remoteApi from 'api/remote';

// Actions
import {
  editorSetDisplayNewBlockForm,
  editorSelectBlock,
  editorDeleteOutcome,
  setLoadingOutcomeMetric,
} from 'state/actions/workspaceEditorActions';

// UI Components
import BlockGrid, { BlockMenu, BlockGutter, BlockContent, BlockMeta } from './BlockGrid';
import BlockLabel from './BlockLabel';
import { Droppable, Draggable } from 'react-beautiful-dnd';
import DropdownMenu from './DropdownMenu';

import InitiativeBlock from './InitiativeBlock';
import OutcomeBlockMeta from './OutcomeBlockMeta';
import OutcomeTitle from './OutcomeTitle';
import OutcomeOrInitiativeForm from './OutcomeOrInitiativeForm';
import IconDrag from './_assets/icon_drag.png';

import Loader from 'components/Loader';
import { CustomTermKey, translate } from 'utils/customTermUtils';
import { Workspace } from 'types';
import KoalaIconButton from 'koala/components/IconButton';
import { setGlobalModalContent } from 'state/actions/globalUIActions';
import KoalaButton from 'koala/components/Button';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';

interface Props {
  outcomeId: string;
  outcomeIndex: number;
  objectiveIndex: number;
}

const OutcomeWrapper = styled.div`
  background-color: #fff;
  border-radius: 4px;
  font-size: 1.8rem;

  border: ${(props: any) => (props.isDragging ? '2px solid rgba(245, 192, 43, 1)' : '0px solid #fff')};
  position: relative;

  .ai-button {
    background: ${theme.colors.V5};
    color: ${theme.colors.V70};

    &:hover {
      background: ${theme.colors.V10};
    }
  }

  .auto-detect-button {
    span {
      font-size: 22px;
    }
  }
`;

const InnerWrapper = styled.div`
  .add-menu {
    opacity: 0;
    transition: opacity ease 0.2s;
  }

  &:hover {
    .add-menu {
      opacity: 1;
    }
  }
`;

const AddInitiativeWrapper = styled.div`
  display: flex;
  gap: ${theme.spacing.x1};
`;

const removeMenuItems = (workspace: Workspace, t: TFunction) => {
  const items = [];
  const translationKey = 'workspacePlan.write';
  const label = translate(workspace, CustomTermKey.OUTCOME, 1).toLowerCase();
  items.push(<span data-action="delete-outcome">{t(`${translationKey}.delete`, { label: label })}</span>);
  items.push(<span data-action="archive-outcome">{t(`${translationKey}.archive`, { label: label })}</span>);
  return items;
};

function Outcome(props: Props) {
  const queryCache = useQueryCache();
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();
  const { outcomeId, objectiveIndex, outcomeIndex } = props;
  const currentWorkspace = useSelector((state: any) => state.session.currentWorkspace, shallowEqual);

  const [archiveOutcomeMutation, { isLoading: isArchiving }] = useMutation(remoteApi.archiveOutcome, {
    onSuccess: () => {
      queryCache.invalidateQueries(queryKeys.outcomes);
    },
  });

  const [adjustOutcomeMetricMutation, { isLoading: metricLoading }] = useMutation(remoteApi.updateOutcomeMetric, {
    onSuccess: () => {
      queryCache.invalidateQueries(queryKeys.outcomes);
      queryCache.invalidateQueries(queryKeys.currentPing);
      dispatch(setLoadingOutcomeMetric(outcomeId, false));
    },
    onError: () => {
      dispatch(setLoadingOutcomeMetric(outcomeId, false));
    },
  });

  const archiveOutcome = () => {
    const params = {
      archived: true,
    };
    const mutationParams = {
      outcomeId,
      outcome: params,
    };
    archiveOutcomeMutation(mutationParams);
  };

  const hasOutcome = useSelector((state: any) => state.editorEntities.outcomes[outcomeId]);

  const initiativeIds = useSelector((state: any) => state.editorEntities.outcomesToInitiativesMapping[outcomeId] || []);

  const blockId = `outcome:${outcomeId}`;
  const isSelected = useSelector((state: any) => state.editorUI.selectedBlockId === blockId);
  // Block form if (1) the block is selected AND (2) the displayBlockForm flag is true
  const displayForm = useSelector((state: any) => {
    return isSelected && state.editorUI.displayNewBlockForm;
  });

  const insightErrors = useSelector((state: any) => {
    if (!state.editorUI.scorecard) {
      return null;
    }

    return state.editorUI.scorecard.insights.blockInsights[blockId];
  });

  const [defaultFormEntityType, setDefaultFormEntityType] = useState('outcome');

  // Extract the review comments
  const reviewCommentsCount = useSelector((state: any) => {
    if (state.editorEntities.reviewCommentsByBlockId[blockId]) {
      const reviewComments = state.editorEntities.reviewCommentsByBlockId[blockId];
      let count = 0;
      reviewComments.forEach((reviewComment: any) => {
        count = count + 1 + reviewComment.review_replies_count; // count the initial comment and all replies;
      });
      return count;
    }
    return 0;
  });

  if (!hasOutcome) {
    return null;
  }

  const selectedClass = isSelected ? 'selected' : '';

  const handleClick = (e: any) => {
    dispatch(editorSelectBlock(blockId));
  };

  const outcomeLabel = translate(currentWorkspace, CustomTermKey.OUTCOME, 1).toLowerCase();
  const handleMenuSelection = (value: any) => {
    const action = value.props['data-action'];
    switch (action) {
      case 'archive-outcome':
        archiveOutcome();
        break;
      case 'delete-outcome':
        const confirmation =
          t('workspacePlan.write.deleteConfirm', { label: outcomeLabel }) ??
          `Are you sure that you want to delete this ${outcomeLabel}?`;

        if (window.confirm(confirmation)) {
          dispatch(editorDeleteOutcome(outcomeId));
        }
        break;
      case 'show-outcome-form':
        setDefaultFormEntityType('outcome');
        dispatch(editorSelectBlock(blockId));
        dispatch(editorSetDisplayNewBlockForm(true));
        break;
      case 'show-initiative-form':
        setDefaultFormEntityType('initiative');
        dispatch(editorSelectBlock(blockId));
        dispatch(editorSetDisplayNewBlockForm(true));
        break;
      case 'add-ai-initiatives':
        // dispatch(setGlobalModalContent(`outcome:${outcomeId}:populate.ai`));
        dispatch(setGlobalModalContent(`outcome:${outcomeId}:chat.ai.initiatives`));
        break;
      case 'add-objective':
        dispatch(editorSelectBlock(''));
        dispatch(editorSetDisplayNewBlockForm(true));
        break;
    }
  };

  const handleAddObjective = () => {
    dispatch(editorSelectBlock(''));
    dispatch(editorSetDisplayNewBlockForm(true));
  };

  const handleAddOutcome = () => {
    setDefaultFormEntityType('outcome');
    dispatch(editorSelectBlock(blockId));
    dispatch(editorSetDisplayNewBlockForm(true));
  };

  const handleAddInitiative = () => {
    setDefaultFormEntityType('initiative');
    dispatch(editorSelectBlock(blockId));
    dispatch(editorSetDisplayNewBlockForm(true));
  };

  const handleGenerateInitiatives = () => {
    // dispatch(setGlobalModalContent(`outcome:${outcomeId}:populate.ai`));
    dispatch(setGlobalModalContent(`outcome:${outcomeId}:chat.ai.initiatives`));
  };

  const handleEdit = () => {
    const hashPath = `#outcome:${hasOutcome.nano_slug}:edit`;
    history.push(hashPath);
  };

  const handleCreateComment = () => {
    const hashPath = `#editor:${blockId}:comment`;
    history.push(hashPath);
  };

  const handleAutoDetectMetric = () => {
    dispatch(setLoadingOutcomeMetric(outcomeId, true));
    adjustOutcomeMetricMutation({ outcomeId });
  };

  const commentedClass = reviewCommentsCount > 0 ? 'commented' : '';
  const joyrideSelector = `outcome-${objectiveIndex}-${outcomeIndex}`;
  return (
    <Draggable draggableId={blockId} index={props.outcomeIndex} key={blockId}>
      {(provided, snapshot) => {
        return (
          <OutcomeWrapper ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
            <InnerWrapper>
              <BlockGrid
                className={`editor-block ${selectedClass} ${insightErrors} ${commentedClass} ${joyrideSelector}`}
                onClick={handleClick}
              >
                <BlockMenu
                  className="option-menu"
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                >
                  <KoalaIconButton
                    edge="square"
                    onClick={handleAutoDetectMetric}
                    dataFor={`${blockId}-auto-detect-metric`}
                    className="ai-button auto-detect-button"
                    loading={metricLoading}
                  >
                    <span role="img">✨</span>
                  </KoalaIconButton>
                  <DropdownMenu
                    trigger={<KoalaIconButton edge="square" iconName="trash" dataFor={`${blockId}-remove-option`} />}
                    position="left"
                    onSelection={handleMenuSelection}
                    items={removeMenuItems(currentWorkspace, t)}
                  />
                  <KoalaIconButton
                    edge="square"
                    iconName="comment"
                    onClick={handleCreateComment}
                    dataFor={`${blockId}-create-comment`}
                  />
                  <KoalaIconButton edge="square" iconName="settings" onClick={handleEdit} dataFor={`${blockId}-edit`} />
                </BlockMenu>
                <ReactTooltip type="dark" id={`${blockId}-add-option`} className="tooltip" effect="solid">
                  {t('shared.add')}
                </ReactTooltip>
                <ReactTooltip type="dark" id={`${blockId}-remove-option`} className="tooltip" effect="solid">
                  {t('shared.remove')}
                </ReactTooltip>
                <ReactTooltip type="dark" id={`${blockId}-edit`} className="tooltip" effect="solid">
                  {t('shared.edit')}
                </ReactTooltip>
                <ReactTooltip type="dark" id={`${blockId}-create-comment`} className="tooltip" effect="solid">
                  {t('workspacePlan.write.addComment')}
                </ReactTooltip>
                <ReactTooltip type="dark" id={`${blockId}-auto-detect-metric`} className="tooltip" effect="solid">
                  {t('workspacePlan.write.autoDetectMetric')}
                </ReactTooltip>
                <BlockGutter>
                  {isArchiving && <Loader size="small" />}
                  <div className="icon-button">
                    <img src={IconDrag} alt="Drag" />
                  </div>
                  <BlockLabel className="outcome">#</BlockLabel>
                </BlockGutter>
                <BlockContent className="outcome">
                  <OutcomeTitle outcomeId={outcomeId} />
                </BlockContent>
                <BlockMeta className="meta">
                  <OutcomeBlockMeta
                    outcomeId={outcomeId}
                    reviewCommentsCount={reviewCommentsCount}
                    joyrideSelector={joyrideSelector}
                  />
                </BlockMeta>
              </BlockGrid>
              <Droppable droppableId={`outcome:${outcomeId}`} type="TASKS">
                {(provided, snapshot) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                    style={{
                      padding: '0.1rem 0',
                      backgroundColor: snapshot.isDraggingOver ? 'rgba(244, 205, 201, 0.4)' : '#fff',
                    }}
                  >
                    {initiativeIds.map((initiativeId: string, index: number) => {
                      return (
                        <InitiativeBlock
                          initiativeId={initiativeId}
                          initiativeIndex={index}
                          outcomeIndex={outcomeIndex}
                          objectiveIndex={objectiveIndex}
                          key={`initiative:${initiativeId}`}
                          outcomeId={outcomeId}
                        />
                      );
                    })}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
              {!displayForm && (
                <BlockGrid className="initiative compact add-menu">
                  <BlockGutter></BlockGutter>
                  <BlockContent className="initiative normal-font">
                    <AddInitiativeWrapper>
                      <KoalaButton size="small" appearance="secondary" onClick={handleAddObjective}>
                        + {translate(currentWorkspace, CustomTermKey.OBJECTIVE, 1).toLowerCase()}
                      </KoalaButton>
                      <KoalaButton size="small" appearance="secondary" onClick={handleAddOutcome}>
                        + {translate(currentWorkspace, CustomTermKey.OUTCOME, 1).toLowerCase()}
                      </KoalaButton>
                      <KoalaButton size="small" appearance="secondary" onClick={handleAddInitiative}>
                        + {translate(currentWorkspace, CustomTermKey.INITIATIVE, 1).toLowerCase()}
                      </KoalaButton>
                      {currentWorkspace.open_ai_enabled && (
                        <KoalaButton
                          size="small"
                          appearance="secondary"
                          className="ai-button"
                          onClick={handleGenerateInitiatives}
                        >
                          {t('workspacePlan.write.generateItem', {
                            label: translate(currentWorkspace, CustomTermKey.INITIATIVE, 2).toLowerCase(),
                          })}
                          <span role="img">✨</span>
                        </KoalaButton>
                      )}
                    </AddInitiativeWrapper>
                  </BlockContent>
                </BlockGrid>
              )}
            </InnerWrapper>
            {displayForm && (
              <OutcomeOrInitiativeForm defaultFormEntityType={defaultFormEntityType} selectedOutcomeId={outcomeId} />
            )}
          </OutcomeWrapper>
        );
      }}
    </Draggable>
  );
}

export default React.memo(Outcome);
