import { Link, useLocation } from 'react-router-dom';

import KoalaLoader from 'koala/components/Loader';
import React from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import ReactTooltip from 'react-tooltip';
import styled from 'styled-components';
import theme from 'theme';
import { Objective, Outcome } from 'types';
import { CustomTermKey, translate } from 'utils/customTermUtils';
import KoalaProgressBar from 'koala/components/ProgressBar';
import OutcomeExpandable from 'components/OutcomeExpandable';
import MetricExpandable, { MetricHeader } from 'components/MetricExpandable';
import KoalaTextBadge from 'koala/components/TextBadge';
import { useTranslation } from 'react-i18next';
import KoalaIcon from 'koala/components/Icons';

const ObjectiveBlock = styled.div`
  border-bottom: 1px solid ${theme.colors.blockBorder};
  background: ${theme.colors.N0};
  box-sizing: border-box;

  &:last-of-type {
    border-bottom: 0;
  }

  .subtle {
    padding: 1.2rem ${theme.spacing.x2};
  }
`;

/* Outcomes lines are a grid containing
  - Title (+ status)
  - Tag
  - Outcomes
  - Tasks
  - Owner (or ncs)
  - Progress
*/

const ObjectiveHeader = styled.div`
  display: grid;
  grid-template-columns: 1fr auto auto;
  grid-template-rows: auto;
  grid-template-areas: 'title ncs progress';
  background: ${theme.colors.N0};

  justify-content: center;
  align-items: center;
  padding: ${theme.spacing.x1} ${theme.spacing.x3};
  border-bottom: 1px solid ${theme.colors.blockBorder};
  gap: ${theme.spacing.x1};

  border-top-left-radius: 4px;
  border-top-right-radius: 4px;

  padding: ${theme.spacing.x2} ${theme.spacing.x3};
  margin: 0;

  @media ${theme.devices.tablet} {
    grid-template-columns: 1fr auto;
    grid-template-areas: 'title progress';
  }

  @media ${theme.devices.mobile} {
    grid-template-columns: 1fr;
    grid-template-areas: 'title';
  }

  &:hover {
    background: ${theme.colors.N3};
    cursor: pointer;
  }
  &.selected,
  &:active,
  &:focus {
    background: ${theme.colors.T10};
  }

  &.simplified {
    background: ${theme.colors.N0};
    padding: ${theme.spacing.x1} ${theme.spacing.x2};
    margin: 0;
    margin-top: ${theme.spacing.x2};

    border-bottom: 0;
    &:hover {
      background: ${theme.colors.N0};
      cursor: pointer;
    }
    &.selected,
    &:active,
    &:focus {
      background: ${theme.colors.N0};
    }
  }
`;

const ObjectiveTitle = styled.div`
  grid-area: title;
  font-weight: 800;
  font-size: 1.5rem;
  display: flex;
  gap: ${theme.spacing.x1};
  align-items: center;
`;

const ObjectiveNCS = styled.div`
  grid-area: ncs;
  display: flex;
  justify-content: flex-start;
  gap: ${theme.spacing.x1};
  @media ${theme.devices.laptop} {
    display: none;
  }
`;
const ProgressContainer = styled.div`
  width: 6rem;
`;
const ObjectiveProgress = styled.div`
  grid-area: progress;
  width: 100%;
  display: flex;
  justify-content: end;

  @media ${theme.devices.mobile} {
    display: none;
  }
`;

const OutcomeBlockContainer = styled.div`
  border-bottom: 1px solid ${theme.colors.N20};
  margin: 0 ${theme.spacing.x2};

  :last-of-type {
    .outcome-row {
      border-bottom-left-radius: 0.3rem;
      border-bottom-right-radius: 0.3rem;
    }
  }

  &:last-of-type {
    border-bottom: 0;
  }
  hr {
    color: ${theme.colors.N20};
    background-color: ${theme.colors.N20};
    border: none;
    height: 1px;
    margin: ${theme.spacing.half} 0;
  }
  &.no-border {
    border: 0;
  }
`;

const LoaderContainer = styled.div`
  padding: ${theme.spacing.x1};
`;

interface Props {
  objective: Objective;
  outcomes: Outcome[];
  isLoading: boolean;
  defaultExpandedState: boolean;
  hideClosedInitiatives: boolean;
  objectiveIndex: number;
  hasEditPermission: boolean;
  defaultChanged: boolean;
  setDefaultChanged: (value: boolean) => void;
  planType: string;
}

function PlanObjective(props: Props) {
  const { t } = useTranslation();
  const {
    objective,
    outcomes,
    isLoading,
    defaultExpandedState,
    hideClosedInitiatives,
    objectiveIndex,
    hasEditPermission,
    defaultChanged,
    setDefaultChanged,
    planType,
  } = props;
  const currentWorkspace = useSelector((state: any) => state.session.currentWorkspace, shallowEqual);
  const location = useLocation();

  const objectiveProgress = Math.round((objective.outcome_progress_prct || 0) * 100);

  const blockId = `objective:${objective.nano_slug}`;
  const hashPath = `#${blockId}:show`;

  // Set class to selected if the URL contains the block id.
  const selectedClass = location.hash.includes(blockId) ? 'selected' : '';

  const iconName = planType === 'simplified' ? 'metric' : 'flag';
  const planTypeClass = planType;

  return (
    <ObjectiveBlock key={objective.id}>
      <ObjectiveHeader className={`${selectedClass} ${planTypeClass}`}>
        <ObjectiveTitle>
          <KoalaIcon iconName={iconName} iconAppearance={theme.colors.T80} />
          <Link to={hashPath}>{objective.title}</Link>&nbsp;
          {isLoading && <KoalaLoader />}
        </ObjectiveTitle>
        {planType === 'classic' && (
          <>
            <ObjectiveNCS>
              <KoalaTextBadge
                edge="circle"
                size="small"
                style={{ border: 'none' }}
                maxWidth="10rem"
                backgroundColor="transparent"
                textColor={theme.colors.T70}
              >
                {objectiveProgress}%
              </KoalaTextBadge>
            </ObjectiveNCS>
            <ObjectiveProgress>
              <ProgressContainer>
                <KoalaProgressBar
                  value={objectiveProgress}
                  appearance="custom"
                  bgColor={theme.colors.T20}
                  barColor={theme.colors.T70}
                  dataFor={`obj-percentage-${objective.nano_slug}`}
                />
              </ProgressContainer>

              <ReactTooltip
                place="bottom"
                type="dark"
                className="tooltip"
                effect="solid"
                id={`obj-percentage-${objective.nano_slug}`}
              >
                {t('workspacePlan.objectiveProgress', {
                  progress: objectiveProgress,
                  outcomes: translate(currentWorkspace, CustomTermKey.OUTCOME, 2).toLowerCase(),
                })}
              </ReactTooltip>
            </ObjectiveProgress>
          </>
        )}
      </ObjectiveHeader>
      {isLoading && outcomes.length === 0 && (
        <LoaderContainer>
          <KoalaLoader size="large" />
        </LoaderContainer>
      )}
      {!isLoading && outcomes.length === 0 && (
        <div className="empty-objective subtle">
          {t('workspacePlan.objectiveEmpty', {
            objective: translate(currentWorkspace, CustomTermKey.OBJECTIVE, 1).toLowerCase(),
            outcomes: translate(currentWorkspace, CustomTermKey.OUTCOME, 2).toLowerCase(),
          })}
        </div>
      )}
      {planType === 'simplified' && (
        <OutcomeBlockContainer>
          <MetricHeader />
        </OutcomeBlockContainer>
      )}
      {outcomes.map((outcome, index) => {
        return (
          <OutcomeBlockContainer key={outcome.id}>
            {planType === 'simplified' && (
              <MetricExpandable
                outcome={outcome}
                hasEditPermission={hasEditPermission}
                key={outcome.id}
                outcomeIndex={`${objectiveIndex}-${index}`}
                defaultExpanded={defaultExpandedState}
                hideClosedInitiatives={hideClosedInitiatives}
                showParentsLabel={true}
                defaultChanged={defaultChanged}
                setDefaultChanged={setDefaultChanged}
              />
            )}
            {planType === 'classic' && (
              <OutcomeExpandable
                outcome={outcome}
                hasEditPermission={hasEditPermission}
                key={outcome.id}
                outcomeIndex={`${objectiveIndex}-${index}`}
                defaultExpanded={defaultExpandedState}
                hideClosedInitiatives={hideClosedInitiatives}
                showParentsLabel={true}
                defaultChanged={defaultChanged}
                setDefaultChanged={setDefaultChanged}
              />
            )}
          </OutcomeBlockContainer>
        );
      })}
    </ObjectiveBlock>
  );
}

export default PlanObjective;
