import queryKeys from 'config/queryKeys';
import React, { useEffect, useRef, useState } from 'react';
import { useQuery } from 'react-query';
import { shallowEqual, useSelector } from 'react-redux';
import styled from 'styled-components';
import * as remoteApi from 'api/remote';
import theme from 'theme';
import { CustomTermKey, translate } from 'utils/customTermUtils';
import { useTranslation } from 'react-i18next';
import { DragHandle, SourceTitle, WidgetContainer, WidgetContent, WidgetHeader } from '.';
import { Link } from 'react-router-dom';
import PlanIconLabel from 'components/PlanIconLabel';
import KoalaLoader from 'koala/components/Loader';
import { WORKSPACE_PLAN_TEMPLATE_ROUTE, WORKSPACE_PLAN_TRACK_ROUTE, WORKSPACE_PLAN_WRITE_ROUTE } from 'routes';
import { AxiosError, AxiosResponse } from 'axios';
import * as planUtils from 'utils/planUtils';
import { Domain, Plan } from 'types';
import Chart from 'components/CheckinChart';
import { format, getTime, isAfter, isBefore, parseISO, toDate } from 'date-fns';
import { VictoryArea, VictoryChart, VictoryGroup, VictoryLegend, VictoryVoronoiContainer } from 'victory';
import KoalaIcon from 'koala/components/Icons';

const Container = styled.div`
  display: flex;
  width: 100%;
  height: 90%;
  max-height: 100%;

  @media ${theme.devices.mobile} {
    max-height: 25rem;
  }
`;

interface ChartProps {
  targetData: any;
  outcomesData: any;
  initiativesData: any;
  isInsideTimeline: boolean;
  width: number;
  height: number;
}
function RenderChart(props: ChartProps) {
  const { targetData, outcomesData, initiativesData, isInsideTimeline, width, height } = props;
  const { t, i18n } = useTranslation();
  const currentWorkspace = useSelector((state: any) => state.session.currentWorkspace, shallowEqual);
  const label = width / 2;
  return (
    <VictoryChart
      width={width}
      height={height - 10}
      domainPadding={{ x: 15, y: 20 }}
      padding={{ top: 20, bottom: 20, left: 40, right: 10 }}
      domain={{ y: [0, 100] }}
      containerComponent={<VictoryVoronoiContainer />}
    >
      <VictoryLegend
        x={label - 150}
        y={0}
        centerTitle
        orientation="horizontal"
        gutter={20}
        style={{ labels: { fontSize: 12, fontFamily: theme.font.fontFamily, fill: theme.colors.N80 } }}
        data={[
          {
            name:
              t('workspacePlan.insights.outcomeProgress', {
                outcome: translate(currentWorkspace, CustomTermKey.OUTCOME, 2),
              }) ?? 'Outcome Progress',
            symbol: { fill: theme.colors.T50 },
          },
          {
            name:
              t('workspacePlan.insights.initiativeProgress', {
                initiative: translate(currentWorkspace, CustomTermKey.INITIATIVE, 2),
              }) ?? 'Initiative Progress',
            symbol: { fill: theme.colors.V50 },
          },
        ]}
      />
      {Chart.XAxis(i18n)}
      {Chart.YAxis()}
      <VictoryGroup scale="time">
        <VictoryArea
          style={{
            data: {
              fill: 'transparent',
              fillOpacity: 0.5,
              stroke: theme.colors.N10,
              strokeWidth: 2,
            },
          }}
          data={targetData}
        />
        <VictoryArea
          style={{
            data: {
              stroke: theme.colors.T50,
              fill: 'transparent',
              fillOpacity: 0.2,
              strokeWidth: 3,
              // strokeAreacap: 'round',
            },
          }}
          data={outcomesData}
          interpolation="monotoneX"
          labels={({ datum }: any) => [`${format(toDate(datum.x), 'd MMM yyyy')}`, `${datum.displayText}`]}
          labelComponent={Chart.LabelTooltip()}
        />
        <VictoryArea
          style={{
            data: {
              stroke: theme.colors.V50,
              strokeWidth: 3,
              fill: 'transparent',
              fillOpacity: 0.2,
              strokeLinecap: 'round',
            },
          }}
          data={initiativesData}
          interpolation="monotoneX"
          labels={({ datum }: any) => [`${format(toDate(datum.x), 'd MMM yyyy')}`, `${datum.displayText}`]}
          labelComponent={Chart.LabelTooltip()}
        />
      </VictoryGroup>
      {isInsideTimeline && Chart.TodayLine({ min: 0, max: 100 }, t)}
    </VictoryChart>
  );
}

interface Props {
  planId: string;
}

function OutcomeTaskGraphWidget(props: Props) {
  const { planId } = props;
  const { t } = useTranslation();
  const currentWorkspace = useSelector((state: any) => state.session.currentWorkspace, shallowEqual);
  const [error, setError] = useState('');
  const queryKey = [queryKeys.currentPlan, planId];
  const [trends, setTrends] = useState([]);
  const [plan, setPlan] = useState<Plan>();
  let yDomain: Domain = { min: 0, max: 0 };

  const chartRef = useRef<HTMLDivElement>(null);

  const [dimensions, setDimensions] = useState({ width: 200, height: 200 });

  useEffect(() => {
    const handleResize = (entries: any) => {
      for (let entry of entries) {
        if (entry.target === chartRef.current) {
          setDimensions({
            width: entry.contentRect.width,
            height: entry.contentRect.height,
          });
        }
      }
    };

    const resizeObserver = new ResizeObserver(handleResize);

    if (chartRef.current) {
      resizeObserver.observe(chartRef.current);
    }

    // Cleanup observer on unmount
    return () => {
      if (chartRef.current) {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        resizeObserver.unobserve(chartRef.current);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chartRef.current]);

  // Get the plan details
  const { isLoading } = useQuery(queryKey, remoteApi.fetchPlanDetails, {
    onSuccess: (response: AxiosResponse) => {
      const data = response.data;
      setPlan(data);
    },
    onError: (error: AxiosError) => {
      const errorText = error.response?.data.error;
      if (errorText.includes('not allowed') && error.response?.status === 401) {
        setError(
          t('workspaceDashboards.permissionError') ?? 'You do not have permission to view the content in this widget',
        );
      } else {
        setError(t('workspaceDashboards.widgetError') ?? 'Unable to fetch widget');
      }
    },
  });

  // Fetch trends data
  useQuery([queryKeys.currentPlan, plan?.id, 'trends'], remoteApi.fetchPlanTrends, {
    staleTime: 0,
    onSuccess: (response) => {
      setTrends(response.data);
    },
    enabled: !!plan,
  });

  if (isLoading) {
    return (
      <WidgetContainer>
        <KoalaLoader />
        <Container className="panel-checkins-chart" ref={chartRef} />
      </WidgetContainer>
    );
  }

  if (!plan) {
    return (
      <WidgetContainer>
        <WidgetHeader>
          <DragHandle className="drag-handle">
            <KoalaIcon iconName="grab" iconSize="small" />
          </DragHandle>
          <p className="widget-type">
            {t('workspaceDashboards.OutcomeTaskGraph', {
              outcomes: translate(currentWorkspace, CustomTermKey.OUTCOME, 2),
              tasks: translate(currentWorkspace, CustomTermKey.INITIATIVE, 2),
            })}
          </p>
        </WidgetHeader>
        <WidgetContent>
          <p>{error ? t('workspaceDashboards.widgetError') : 'Unable to load plan'}</p>
        </WidgetContent>
      </WidgetContainer>
    );
  }

  if (!plan.start_at || !plan.finish_at) {
    return (
      <WidgetContainer>
        <WidgetHeader>
          <DragHandle className="drag-handle">
            <KoalaIcon iconName="grab" iconSize="small" />
          </DragHandle>
          <p className="widget-type">
            {t('workspaceDashboards.OutcomeTaskGraph', {
              outcomes: translate(currentWorkspace, CustomTermKey.OUTCOME, 2),
              tasks: translate(currentWorkspace, CustomTermKey.INITIATIVE, 2),
            })}
          </p>
        </WidgetHeader>
        <WidgetContent>
          <p>Missing plan timeline</p>
        </WidgetContent>
      </WidgetContainer>
    );
  }

  const targetData: any[] = [];
  const outcomesData: any[] = [];
  const initiativesData: any[] = [];

  const startDate: Date = parseISO(plan.start_at);
  const finishDate: Date = parseISO(plan.finish_at);

  const firstPoint = {
    x: startDate,
    y: 0,
    timestamp: getTime(startDate),
    displayText: '0%',
  };

  const lastPoint = {
    x: finishDate,
    y: 100,
    timestamp: getTime(finishDate),
  };

  // By default we're going to use the first point from the target
  // We'll change that if we find a checkin prior to the first point.
  let useFirstPoint = true;

  targetData.push(firstPoint);
  targetData.push(lastPoint);

  trends.forEach((trend: any, index: number) => {
    const date = parseISO(trend.reference_date);
    // If the checkin was done prior to the first point we don't store
    // the first point in the list of current points
    if (isBefore(date, firstPoint.x)) {
      useFirstPoint = false;
    }

    // Create the point using the checkin data
    const outcomesPoint: any = {
      x: date,
      y: trend.outcome_progress_prct * 100,
      timestamp: getTime(date),
      displayText: `${Math.round(trend.outcome_progress_prct * 100)}%`,
      trend,
    };
    outcomesData.push(outcomesPoint);

    // Create the point using the checkin data
    const initiativesPoint: any = {
      x: date,
      y: trend.initiative_progress_prct * 100,
      timestamp: getTime(date),
      displayText: `${Math.round(trend.initiative_progress_prct * 100)}%`,
      trend,
    };
    initiativesData.push(initiativesPoint);

    if (isAfter(date, lastPoint.x)) {
      targetData.push({
        x: date,
        y: 100,
        timestamp: getTime(date),
      });
    }
  });

  if (useFirstPoint) {
    outcomesData.push(firstPoint);
    initiativesData.push(firstPoint);
  }

  const isInsideTimeline = planUtils.isInPlanTimeline(startDate, finishDate, new Date());
  if (yDomain.max === 0) {
    yDomain.max = 10;
  }

  const stateToRouteMapping: { [key: string]: string } = {
    draft: WORKSPACE_PLAN_WRITE_ROUTE,
    published: WORKSPACE_PLAN_TRACK_ROUTE,
    template: WORKSPACE_PLAN_TEMPLATE_ROUTE,
  };
  let routeMappingState = plan.state || 'draft';
  const routeTarget = stateToRouteMapping[routeMappingState];
  const planRoute = routeTarget.replace(':workspaceSlug', currentWorkspace.slug).replace(':planId', plan.nano_slug);

  return (
    <WidgetContainer>
      <WidgetHeader>
        <DragHandle className="drag-handle">
          <KoalaIcon iconName="grab" iconSize="small" />
        </DragHandle>
        <p className="widget-type">
          {t('workspaceDashboards.OutcomeTaskGraph', {
            outcomes: translate(currentWorkspace, CustomTermKey.OUTCOME, 2),
            tasks: translate(currentWorkspace, CustomTermKey.INITIATIVE, 2),
          })}
        </p>
      </WidgetHeader>
      <WidgetContent>
        <SourceTitle>
          <Link to={planRoute}>
            <PlanIconLabel plan={plan} size="small" />
          </Link>
        </SourceTitle>
        <Container className="panel-checkins-chart" ref={chartRef}>
          <RenderChart
            width={dimensions.width}
            height={dimensions.height}
            targetData={targetData}
            outcomesData={outcomesData}
            initiativesData={initiativesData}
            isInsideTimeline={isInsideTimeline}
          />
        </Container>
      </WidgetContent>
    </WidgetContainer>
  );
}

export default OutcomeTaskGraphWidget;
