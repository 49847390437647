/**
 * Outcome component
 */
import React from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
//import * as TabilityTypes from '../../../types';
import styled from 'styled-components';
import { useMutation, useQueryCache } from 'react-query';
import queryKeys from 'config/queryKeys';
import { useHistory } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';

// API
import * as remoteApi from 'api/remote';

// Actions
import {
  editorSetDisplayNewBlockForm,
  editorSelectBlock,
  editorDeleteInitiative,
} from 'state/actions/workspaceEditorActions';

// UI Components
import BlockGrid, { BlockMenu, BlockGutter, BlockContent, BlockMeta } from './BlockGrid';
import { Draggable } from 'react-beautiful-dnd';
import DropdownMenu from './DropdownMenu';
import InitiativeBlockMeta from './InitiativeBlockMeta';
import InitiativeLabel from './InitiativeLabel';
import InitiativeTitle from './InitiativeTitle';
import InitiativeForm from './InitiativeForm';
import IconDrag from './_assets/icon_drag.png';
import IconJiraPng from 'components/_assets/IconJira.png';
import IconClickupPng from 'components/_assets/IconClickup.png';
import IconAsanaPng from 'components/_assets/IconAsana.png';

import Loader from 'components/Loader';
import { CustomTermKey, translate } from 'utils/customTermUtils';
import { Workspace } from 'types';

import KoalaIconButton from 'koala/components/IconButton';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';

interface Props {
  initiativeId: string;
  initiativeIndex: number;
  outcomeIndex: number;
  objectiveIndex: number;
  outcomeId: string;
}

const IntegrationIcon = styled.img`
  height: 2rem;
  margin-right: 0.8rem;
  position: absolute;
  left: 0;
  top: 2px;
`;

const IntegrationTitle = styled.div`
  font-size: 1.4rem;
  line-height: 2rem;
  color: #6a6a6a;
  position: relative;
  span {
    margin-left: 3.2rem;
  }
`;

const InitiativeWrapper = styled.div`
  background-color: #fff;
  border-radius: 4px;
  font-size: 1.8rem;

  border: ${(props: any) => (props.isDragging ? '2px solid rgba(244, 205, 201, 1)' : '0px solid #fff')};
  position: relative;
`;

const InitiativeContentWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  flex: 1;

  .block-title {
    flex: 1;
  }
`;

const removeMenuItems = (workspace: Workspace, t: TFunction) => {
  const items = [];
  const initLabel = translate(workspace, CustomTermKey.INITIATIVE, 1).toLowerCase();
  items.push(<span data-action="delete-initiative">{t(`workspacePlan.write.delete`, { label: initLabel })}</span>);
  items.push(<span data-action="archive-initiative">{t(`workspacePlan.write.archive`, { label: initLabel })}</span>);
  return items;
};

function Initiative(props: Props) {
  const queryCache = useQueryCache();
  const dispatch = useDispatch();
  const history = useHistory();
  const { initiativeId, objectiveIndex, outcomeIndex, initiativeIndex, outcomeId } = props;
  const { t } = useTranslation();
  // Get the data needed to build the component
  const initiative = useSelector((state: any) => state.editorEntities.initiatives[initiativeId]);
  const currentWorkspace = useSelector((state: any) => state.session.currentWorkspace, shallowEqual);

  const [archiveInitiativeMutation, { isLoading: isArchiving }]: [any, any] = useMutation(remoteApi.archiveInitiative, {
    onSuccess: () => {
      queryCache.invalidateQueries(queryKeys.initiatives);
    },
  });

  const archiveInitiative = () => {
    const params = {
      archived: true,
    };
    const mutationParams = {
      initiativeId,
      initiative: params,
    };
    archiveInitiativeMutation(mutationParams);
  };

  // Get the data needed to build the component
  const hasInitiative = useSelector((state: any) => {
    return state.editorEntities.initiatives[initiativeId];
  });

  const blockId = `initiative:${initiativeId}`;
  const isSelected = useSelector((state: any) => state.editorUI.selectedBlockId === blockId);
  // Block form if (1) the block is selected AND (2) the displayBlockForm flag is true
  const displayForm = useSelector((state: any) => {
    return isSelected && state.editorUI.displayNewBlockForm;
  });

  // Extract the review comments
  const reviewCommentsCount = useSelector((state: any) => {
    if (state.editorEntities.reviewCommentsByBlockId[blockId]) {
      const reviewComments = state.editorEntities.reviewCommentsByBlockId[blockId];
      let count = 0;
      reviewComments.forEach((reviewComment: any) => {
        count = count + 1 + reviewComment.review_replies_count; // count the initial comment and all replies;
      });
      return count;
    }
    return 0;
  });

  if (!hasInitiative || !initiative) {
    return null;
  }

  // Creates the block ID

  const selectedClass = isSelected ? 'selected' : '';

  const handleClick = (e: any) => {
    dispatch(editorSelectBlock(blockId));
  };

  const handleEdit = () => {
    const hashPath = `#initiative:${initiative.nano_slug}:edit`;
    history.push(hashPath);
  };

  const handleCreateComment = () => {
    const hashPath = `#editor:${blockId}:comment`;
    history.push(hashPath);
  };

  const handleMenuSelection = (value: any) => {
    const action = value.props['data-action'];
    switch (action) {
      case 'archive-initiative':
        archiveInitiative();
        break;
      case 'delete-initiative':
        const initLabel = translate(currentWorkspace, CustomTermKey.INITIATIVE, 1).toLowerCase();
        const deletePlaceholder =
          t('workspacePlan.write.deleteConfirm', {
            label: initLabel,
          }) ?? `Are you sure that you want to delete this ${initLabel}?`;
        if (window.confirm(deletePlaceholder)) {
          dispatch(editorDeleteInitiative(initiativeId));
        }
        break;
      case 'show-initiative-form':
        dispatch(editorSelectBlock(blockId));
        dispatch(editorSetDisplayNewBlockForm(true));
        break;
    }
  };

  const commentedClass = reviewCommentsCount > 0 ? 'commented' : '';

  const isJiraIssue = initiative.integration_type === 'jira_cloud' && initiative.integration_remote_id;
  const isClickupTask =
    initiative.integration_type === 'clickup' && initiative.integration_remote_id && initiative.integration_meta.status;
  const isAsanaTask = initiative.integration_type === 'asana' && initiative.integration_remote_id;

  const joyrideSelector = `initiative-${objectiveIndex}-${outcomeIndex}-${initiativeIndex}`;

  return (
    <Draggable draggableId={blockId} index={props.initiativeIndex} key={blockId}>
      {(provided, snapshot) => {
        return (
          <InitiativeWrapper ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
            <BlockGrid
              className={`initiative editor-block  ${selectedClass} ${commentedClass} ${joyrideSelector}`}
              onClick={handleClick}
            >
              <BlockMenu
                className="option-menu"
                onClick={(e) => {
                  e.stopPropagation();
                }}
              >
                <DropdownMenu
                  trigger={<KoalaIconButton edge="square" iconName="trash" dataFor={`${blockId}-remove-option`} />}
                  position="left"
                  onSelection={handleMenuSelection}
                  items={removeMenuItems(currentWorkspace, t)}
                />

                <KoalaIconButton
                  edge="square"
                  iconName="comment"
                  onClick={handleCreateComment}
                  dataFor={`${blockId}-create-comment`}
                />
                <KoalaIconButton edge="square" iconName="settings" onClick={handleEdit} dataFor={`${blockId}-edit`} />
              </BlockMenu>
              <ReactTooltip type="dark" id={`${blockId}-add-option`} className="tooltip" effect="solid">
                {t('shared.add')}
              </ReactTooltip>
              <ReactTooltip type="dark" id={`${blockId}-remove-option`} className="tooltip" effect="solid">
                {t('shared.remove')}
              </ReactTooltip>
              <ReactTooltip type="dark" id={`${blockId}-edit`} className="tooltip" effect="solid">
                {t('shared.edit')}
              </ReactTooltip>
              <ReactTooltip type="dark" id={`${blockId}-create-comment`} className="tooltip" effect="solid">
                {t('workspacePlan.write.addComment')}
              </ReactTooltip>

              <BlockGutter>
                {isArchiving && <Loader size="small" />}
                <div className="icon-button">
                  <img src={IconDrag} alt="Drag" />
                </div>
              </BlockGutter>
              <BlockContent className="initiative">
                <InitiativeContentWrapper>
                  <InitiativeLabel>&#10003;</InitiativeLabel>
                  {isJiraIssue && (
                    <IntegrationTitle>
                      <IntegrationIcon src={IconJiraPng} />
                      <span>{initiative.integration_title}</span>
                    </IntegrationTitle>
                  )}
                  {isClickupTask && (
                    <IntegrationTitle>
                      <IntegrationIcon src={IconClickupPng} />
                      <span>{initiative.integration_title}</span>
                    </IntegrationTitle>
                  )}
                  {isAsanaTask && (
                    <IntegrationTitle>
                      <IntegrationIcon src={IconAsanaPng} />
                      <span>{initiative.integration_title}</span>
                    </IntegrationTitle>
                  )}
                  {!isJiraIssue && !isClickupTask && !isAsanaTask && <InitiativeTitle initiativeId={initiativeId} />}
                </InitiativeContentWrapper>
              </BlockContent>
              <BlockMeta>
                <InitiativeBlockMeta initiativeId={initiativeId} reviewCommentsCount={reviewCommentsCount} />
              </BlockMeta>
            </BlockGrid>
            {displayForm && <InitiativeForm selectedInitiativeId={initiativeId} outcomeId={outcomeId} />}
          </InitiativeWrapper>
        );
      }}
    </Draggable>
  );
}

const MemoInitiative = React.memo(Initiative);

export default MemoInitiative;
