import queryKeys from 'config/queryKeys';
import React, { useCallback, useEffect, useState } from 'react';
import { useMutation, useQuery, useQueryCache } from 'react-query';
import styled from 'styled-components';
import { Objective, ObjectiveSectionMeta, Retrospective, RetrospectiveSection } from 'types';
import * as remoteApi from 'api/remote';
import { AxiosResponse } from 'axios';
import KoalaLoader from 'koala/components/Loader';
import update from 'immutability-helper';
import { midString } from 'state/reducers/utils';
import theme from 'theme';
import Section from './Section';
import KoalaTextBadge from 'koala/components/TextBadge';
import KoalaInlineTextInput from 'koala/components/InlineTextInput';
import { useTranslation } from 'react-i18next';
import ElementBlock from './ElementBlock';
import { DropTargetMonitor, useDrop } from 'react-dnd';
import { CustomTermKey, translate } from 'utils/customTermUtils';
import { shallowEqual, useSelector } from 'react-redux';

const Container = styled.div`
  display: grid;
  grid-template-areas: 'page elements';
  grid-template-columns: 1fr auto;
  width: 100%;
  height: 100%;
`;

const Title = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${theme.spacing.x1};
  gap: ${theme.spacing.x2};
`;
const ContentContainer = styled.div`
  grid-area: page;
  position: relative;
  height: 100%;
  overflow-y: scroll;
  width: 100%;
  background-color: ${theme.colors.N5};
  padding: ${theme.spacing.x3};
`;
const PageContainer = styled.div`
  width: 100%;
  background-color: ${theme.colors.N0};
  box-shadow: 0px 0px 8px 0px rgba(21, 21, 21, 0.15);
  padding: ${theme.spacing.x3} ${theme.spacing.x10};
`;
const PageInner = styled.div`
  max-width: 75rem;
  margin: ${theme.spacing.x3} auto;
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.x1};
`;

const EmptyRetroPlaceholder = styled.div<{ isOver: boolean }>`
  display: flex;
  padding: ${theme.spacing.x2};
  border: 2px dotted ${theme.colors.N20};
  border-radius: 8px;
  height: 8rem;
  align-items: center;
  justify-content: center;
  background-color: ${({ isOver }) => (isOver ? theme.colors.G5 : theme.colors.N0)};
  color: ${theme.colors.N60};
`;

const ElementContainer = styled.div`
  grid-area: elements;
  width: 30rem;
  border-left: 1px solid ${theme.colors.N20};
  background: ${theme.colors.N0};
  padding: ${theme.spacing.x3};
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.x3};

  h4 {
    font-size: 16px;
    font-weight: 600;
  }
  label {
    font-size: 10px;
    font-weight: 800;
    text-transform: uppercase;
  }
`;
const PlaceholderSection = styled.div`
  width: 100%;
  height: 8rem;
  background-color: ${theme.colors.G5};
  border-radius: 8px;
`;
const BlockHeader = styled.div`
  display: flex;
  gap: ${theme.spacing.x1};
`;
const ElementSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.x1};
`;

const BadgeContainer = styled.div`
  display: flex;
`;

interface Props {
  retrospective: Retrospective;
}

function EditRetrospectiveContent(props: Props) {
  const { retrospective } = props;
  const currentWorkspace = useSelector((state: any) => state.session.currentWorkspace, shallowEqual);
  const [sections, setSections] = useState<RetrospectiveSection[]>([]);
  const queryCache = useQueryCache();
  const [editSectionId, setEditSectionId] = useState('');
  const [title, setTitle] = useState(retrospective.title);
  const [order, setOrder] = useState<string[]>([]);
  const [objectives, setObjectives] = useState<Objective[]>([]);
  const { t } = useTranslation();
  const translationKey = 'workspaceRetrospective';

  useEffect(() => {
    setOrder(sections.map((section) => section.id));
  }, [sections]);

  // Get the retrospective sections
  const sectionQueryKey = [queryKeys.retrospectiveSections, retrospective.id];
  const staleTime = 0;
  const { isLoading: isSectionsLoading } = useQuery(sectionQueryKey, remoteApi.fetchRetrospectiveSections, {
    staleTime,
    onSuccess: (response: AxiosResponse<RetrospectiveSection[]>) => {
      const sections = response.data;
      setSections(sections);
    },
  });

  // get the plan objectives
  const { isLoading: objectivesAreLoading } = useQuery(
    [queryKeys.objectives, retrospective.plan_id],
    remoteApi.fetchPlanObjectives,
    {
      staleTime,
      onSuccess: (response: AxiosResponse<Objective[]>) => {
        const objectives = response.data;
        setObjectives(objectives);
      },
    },
  );

  const [createSectionMutation, { isLoading: isCreating }] = useMutation(remoteApi.createRetrospectiveSection, {
    onSuccess: (response) => {
      queryCache.invalidateQueries(sectionQueryKey);
      setEditSectionId(response.data.id);
    },
  });

  const [updateSectionMutation] = useMutation(remoteApi.updateRetrospectiveSection, {
    onSuccess: () => {
      queryCache.invalidateQueries(sectionQueryKey);
    },
  });

  const [updateRetrospectiveMutation, { isLoading: isUpdatingRetrospective }] = useMutation(
    remoteApi.updateRetrospective,
    {
      onSuccess: (response: AxiosResponse<Retrospective>) => {
        const retrospective = response.data;
        setTitle(retrospective.title);
        queryCache.invalidateQueries([queryKeys.currentRetrospective, retrospective.nano_slug]);
      },
    },
  );

  const moveSection = useCallback(
    (dragIndex: number, hoverIndex: number, itemId: string) => {
      // if dragging in new item for the first time
      if (itemId.includes('new') && !order.find((orderItem) => orderItem === itemId)) {
        setOrder((prevOrder) =>
          update(prevOrder, {
            $splice: [
              [hoverIndex, 0, itemId], // Insert the newItem at hoverIndex
            ],
          }),
        );
      } else {
        setOrder((prevOrder) =>
          update(prevOrder, {
            $splice: [
              [dragIndex, 1],
              [hoverIndex, 0, prevOrder[dragIndex]],
            ],
          }),
        );
      }
    },
    [order],
  );

  const addTextSection = (rank: string) => {
    createSectionMutation({
      retrospectiveId: retrospective.id,
      retrospectiveSection: { body: '', section_type: 'text', rank },
    });
  };
  const addObjectiveSection = (rank: string, selectedObjective: Objective) => {
    const meta: ObjectiveSectionMeta = {
      objective_id: selectedObjective.id,
      objective_grade_color: '',
      objective_grade_label: '',
      objective_green_outcomes_count: selectedObjective.green_outcomes_count,
      objective_grey_outcomes_count: selectedObjective.grey_outcomes_count,
      objective_red_outcomes_count: selectedObjective.red_outcomes_count,
      objective_yellow_outcomes_count: selectedObjective.yellow_outcomes_count,
      objective_ncs: selectedObjective.ncs,
      objective_outcome_progress_prct: selectedObjective.outcome_progress_prct,
      objective_initiative_progress_prct: selectedObjective.initiative_progress_prct,
      objective_title: selectedObjective.title,
    };
    createSectionMutation({
      retrospectiveId: retrospective.id,
      retrospectiveSection: { body: '', section_type: 'objective', rank, section_meta: JSON.stringify(meta) },
    });
  };

  const saveDrag = (id: string, index: number, sectionObject?: Objective) => {
    const prevSectionId = index - 1 >= 0 ? order[index - 1] : undefined;
    const prevSection = prevSectionId ? sections.find((s) => s.id === prevSectionId) : undefined;
    const prevRank = prevSection ? prevSection.rank : '';

    const nextSectionId = order[index + 1];
    const nextSection = nextSectionId ? sections.find((s) => s.id === nextSectionId) : undefined;
    const nextRank = nextSection ? nextSection.rank : '';

    const rank = midString(prevRank, nextRank);
    const newData = id.split(':');
    // if dragging existing sections, update section with new rank
    if (!newData || newData[0] !== 'new') {
      updateSectionMutation({ retrospectiveSectionId: id, retrospectiveSection: { rank } });
    } else if (newData[1] === 'text') {
      addTextSection(rank);
    } else if (newData[1] === 'objective' && sectionObject) {
      addObjectiveSection(rank, sectionObject);
    }
  };

  const [, dropList] = useDrop(() => ({
    accept: 'new-section',
    hover: (item: { id: string }) => {
      // if new section is dragged over element list, remove from order to cancel
      setOrder((prevOrder) => prevOrder.filter((orderItem) => orderItem !== item.id));
    },
  }));

  const [{ isOver }, dropPlaceholder] = useDrop(() => ({
    accept: 'new-section',
    hover: (item: { id: string }) => {
      // if new section is dragged over element list, remove from order to cancel
      setOrder([item.id]);
    },
    collect: (monitor: DropTargetMonitor) => ({ isOver: monitor.isOver() }),
  }));

  if (isSectionsLoading) {
    return (
      <div>
        <KoalaLoader />
      </div>
    );
  }
  const updatePlanTitle = (value: string) => {
    const mutationParams = {
      retrospectiveId: retrospective.id,
      retrospective: { title: value },
    };
    updateRetrospectiveMutation(mutationParams);
  };

  const sectionLength = sections.length;
  return (
    <Container>
      <ContentContainer>
        <PageContainer>
          <PageInner>
            <Title>
              <BadgeContainer>
                {retrospective.published && (
                  <KoalaTextBadge variant="blue-light" isLowercase>
                    {t(`shared.status.published`)}
                  </KoalaTextBadge>
                )}
                {!retrospective.published && (
                  <KoalaTextBadge variant="yellow-light" isLowercase>
                    {t(`shared.status.draft`)}
                  </KoalaTextBadge>
                )}
              </BadgeContainer>
              <KoalaInlineTextInput
                handleChangeText={updatePlanTitle}
                placeholder={t(`${translationKey}.retrospectivePlaceholder`) ?? 'Enter retrospective title'}
                text={title}
                size="large"
                hideOverflow={true}
                isUpdating={isUpdatingRetrospective}
              />
            </Title>
            {order.map((sectionId, i) => {
              const section = sections.find((s) => s.id === sectionId);
              if (section) {
                return (
                  <Section
                    key={section.id}
                    index={i}
                    id={section.id}
                    section={section}
                    isEditing={section.id === editSectionId}
                    setEditSectionId={setEditSectionId}
                    moveSection={moveSection}
                    saveDrag={saveDrag}
                  />
                );
              } else if (sectionId.includes('new')) {
                return <PlaceholderSection />;
              } else {
                return <div>{t(`${translationKey}.sectionNotFound`)}</div>;
              }
            })}
            {order.length === 0 && (
              <EmptyRetroPlaceholder ref={dropPlaceholder} isOver={isOver}>
                <p>{t(`${translationKey}.dragPlaceholder`)}</p>
              </EmptyRetroPlaceholder>
            )}
          </PageInner>
        </PageContainer>
      </ContentContainer>
      <ElementContainer ref={dropList}>
        <ElementSection>
          <BlockHeader>
            <h4>{t(`${translationKey}.add`)}</h4>
            {isCreating && <KoalaLoader />}
          </BlockHeader>
          <p className="subtle">{t(`${translationKey}.addInfo`)}</p>
        </ElementSection>
        <ElementSection>
          <label>{t(`${translationKey}.basic`)}</label>
          <ElementBlock id="new:text" index={sectionLength + 1} saveDrag={saveDrag} order={order}>
            {t(`${translationKey}.text`)}
          </ElementBlock>
        </ElementSection>
        <ElementSection>
          <BlockHeader>
            <label>{translate(currentWorkspace, CustomTermKey.OBJECTIVE, 2)}</label>
            {objectivesAreLoading && <KoalaLoader />}
          </BlockHeader>
          {objectives.map((objective) => (
            <ElementBlock
              id="new:objective"
              index={sectionLength + 2}
              saveDrag={saveDrag}
              sectionObject={objective}
              order={order}
            >
              {objective.title}
            </ElementBlock>
          ))}
        </ElementSection>
      </ElementContainer>
    </Container>
  );
}

export default EditRetrospectiveContent;
