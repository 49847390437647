import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import theme from 'theme';
import KoalaButton from 'koala/components/Button';
import PlanSelect from './PlanSelect';
import OutcomeSelect from './OutcomeSelect';
import { useTranslation } from 'react-i18next';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: ${theme.spacing.x2} 0;

  .plan-select {
    width: 100%;
  }
`;

interface Props {
  handleAddWidget: (type: string, id: string, source: string) => void;
  isLoading: boolean;
}

function OutcomeSummaryWidgetDetails(props: Props) {
  const { handleAddWidget, isLoading } = props;
  const { t } = useTranslation();
  const { workspaceSlug } = useParams<{
    workspaceSlug: string;
  }>();
  const [planId, setPlanId] = useState<string | null>(null);
  const [outcomeId, setOutcomeId] = useState<string | null>(null);

  const handleSave = (e: React.FormEvent) => {
    e.preventDefault();
    if (!outcomeId) {
      return;
    }
    handleAddWidget('outcome-summary', outcomeId, 'outcome');
  };

  const changePlan = (value: string) => {
    setPlanId(value);
    setOutcomeId(null);
  };

  return (
    <Container>
      <PlanSelect workspaceSlug={workspaceSlug} setPlanId={changePlan} />
      {planId && <OutcomeSelect workspaceSlug={workspaceSlug} setOutcomeId={setOutcomeId} planId={planId} />}
      <div>
        <KoalaButton onClick={handleSave} loading={isLoading} disabled={!outcomeId}>
          {t('shared.add')}
        </KoalaButton>
      </div>
    </Container>
  );
}

export default OutcomeSummaryWidgetDetails;
