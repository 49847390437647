import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useInfiniteQuery, useQueryCache } from 'react-query';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import parse from 'parse-link-header';
import { AxiosResponse } from 'axios';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';

import { ModalContent, ModalFooter, ModalGrid, ModalHeader } from 'components/GlobalModal';
import queryKeys from 'config/queryKeys';
import KoalaIconButton from 'koala/components/IconButton';
import { setGlobalModalContent } from 'state/actions/globalUIActions';
import * as remoteApi from 'api/remote';
import KoalaLoader from 'koala/components/Loader';
import { Outcome, Workspace } from 'types';
import BulkCheckinBlock from './BulkCheckinBlock';
import theme from 'theme';
import { hasExceededCheckinsLimit } from 'utils/workspaceUtils';
import KoalaButton from 'koala/components/Button';
import KoalaTextButton from 'koala/components/TextButton';

const LoadMoreContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Warning = styled.div`
  border-radius: 8px;
  margin-top: ${theme.spacing.x3};
  background-color: ${theme.colors.warningBg};
  padding: ${theme.spacing.x2};
  h3 {
    margin-bottom: ${theme.spacing.x2};
  }
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.x2};
`;

interface Props {}

function BulkCheckin(props: Props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const queryCache = useQueryCache();
  const [errorCheckinsLimitExceeded, setErrorCheckinsLimitExceeded] = useState(false);

  const currentPing = useSelector((state: any) => state.session.currentPing, shallowEqual);
  const currentWorkspace: Workspace = useSelector((state: any) => state.session.currentWorkspace, shallowEqual);
  const currentMembership = useSelector((state: any) => state.session.currentMembership, shallowEqual);

  const getNextPage = (response: any) => {
    if (response && response.headers && response.headers.link) {
      const links = response.headers.link;
      const parsed = parse(links);
      if (parsed && parsed.next) {
        return parsed.next.page;
      }
    }
    return null;
  };

  const translationKey = 'modals.checkin';

  const pendingCheckinsQueryKey = [queryKeys.checkins, currentMembership.id, false, { limit: 5 }];
  const {
    isLoading,
    isFetchingMore,
    data: outcomesResponse,
    fetchMore,
    canFetchMore,
  } = useInfiniteQuery(pendingCheckinsQueryKey, remoteApi.fetchMembershipPendingCheckins, {
    getFetchMore: (lastGroup, allGroups) => {
      return getNextPage(lastGroup);
    },
  });

  let pendingCheckins: AxiosResponse<Outcome[]>[] = [];

  if (outcomesResponse) {
    pendingCheckins = outcomesResponse;
  }

  const handleCancel = (e: any) => {
    e.preventDefault();
    dispatch(setGlobalModalContent(''));
    queryCache.invalidateQueries(queryKeys.outcomes);
    queryCache.invalidateQueries(queryKeys.checkins);
    queryCache.invalidateQueries(queryKeys.currentPlan);
    queryCache.invalidateQueries([queryKeys.currentPing]);
  };

  const handleStopPropagation = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
  };

  // Handle check-ins quota exceeded
  const handleUpgradeV2 = (e: any) => {
    e.preventDefault();
    const route = `/${currentWorkspace.slug}/settings/billing/plans`;
    history.push(route);
    dispatch(setGlobalModalContent(''));
  };

  const isOwner = currentMembership.role === 'owner';

  let pending_checkins_count = 0;

  if (currentPing && currentPing.inbox) {
    pending_checkins_count = currentPing.inbox.pending_checkins;
  }

  if (
    currentWorkspace.pricing_version >= 2 &&
    (hasExceededCheckinsLimit(currentWorkspace) || errorCheckinsLimitExceeded)
  ) {
    return (
      <ModalGrid onClick={handleStopPropagation}>
        <ModalHeader>
          <h2>{t(`modals.bulkCheckin.title`)}</h2>
          <KoalaIconButton iconName="close" onClick={handleCancel} />
        </ModalHeader>
        <ModalContent className="grey-bg">
          <Warning>
            <h3>
              <span role="img" aria-label="yikes!">
                😬
              </span>{' '}
              {t(`${translationKey}.quota`)}
            </h3>
            <p>{t(`${translationKey}.quotaLine1`)}</p>
            <p>{t(`${translationKey}.quotaLine2`)}</p>
            {isOwner && (
              <p>
                <KoalaButton onClick={handleUpgradeV2} appearance="subtle">
                  {t('shared.upgrade.upgradeWorkspace')}
                </KoalaButton>
              </p>
            )}
          </Warning>
        </ModalContent>
        <ModalFooter>
          <KoalaTextButton onClick={handleCancel}>{t('shared.cancel')}</KoalaTextButton>
        </ModalFooter>
      </ModalGrid>
    );
  }

  return (
    <ModalGrid>
      <ModalHeader>
        <h2>{t(`modals.bulkCheckin.noCheckins`, { count: pending_checkins_count })}</h2>
        <KoalaIconButton onClick={handleCancel} iconName="close" />
      </ModalHeader>
      <ModalContent className="grey-bg">
        <ContentContainer>
          {isLoading && <KoalaLoader />}
          {pendingCheckins.map((group) =>
            group.data.map((outcome) => (
              <BulkCheckinBlock outcome={outcome} setErrorCheckinsLimitExceeded={setErrorCheckinsLimitExceeded} />
            )),
          )}
          {canFetchMore && (
            <LoadMoreContainer>
              <KoalaButton onClick={fetchMore} loading={!!isFetchingMore} appearance="secondary">
                {t(`shared.loadMore`)}
              </KoalaButton>
            </LoadMoreContainer>
          )}
        </ContentContainer>
      </ModalContent>
      <ModalFooter>
        <KoalaButton onClick={handleCancel} appearance="secondary">
          {t('shared.close')}
        </KoalaButton>
      </ModalFooter>
    </ModalGrid>
  );
}

export default BulkCheckin;
