import React, { useEffect, useState } from 'react';
import { useParams, useLocation, Redirect, Switch, Route, useHistory } from 'react-router-dom';
import { useQuery } from 'react-query';
import queryKeys from 'config/queryKeys';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { Base64 } from 'js-base64';
import { useHotkeys, HotkeysProvider } from 'react-hotkeys-hook';
import { parseISO, getUnixTime } from 'date-fns';

import QuickstartSidebar from 'components/QuickstartSidebar';

// Role controls
import RequireAdminStatus from './RequireAdminStatus';
import RequireOwnerStatus from './RequireOwnerStatus';
import RedirectIfReadOnly from './RedirectIfReadOnly';

// Onboarding
import WorkspaceQuickstart from 'pages/WorkspaceQuickstart';
import WorkspaceAccelerator from 'pages/WorkspaceAccelerator';

// Workspace pages
import WorkspaceOnboardingRole from 'pages/WorkspaceOnboardingRole';
import WorkspaceOnboardingRollout from 'pages/WorkspaceOnboardingRollout';
import WorkspaceOnboardingAbout from 'pages/WorkspaceOnboardingQuestions';
import WorkspaceOnboardingWorkflow from 'pages/WorkspaceOnboardingWorkflow';
import WorkspaceOnboardingTeam from 'pages/WorkspaceOnboardingTeam';
import WorkspaceOnboardingTemplate from 'pages/WorkspaceOnboardingTemplate';
import WorkspaceOnboardingDone from 'pages/WorkspaceOnboardingDone';
import WorkspaceOnboardingReferral from 'pages/WorkspaceOnboardingReferral';

import WorkspaceJiraOutcomes from 'pages/WorkspaceJiraOutcomes';
import WorkspaceEmbedOutcome from 'pages/WorkspaceEmbedOutcome';

import WorkspaceInbox from 'pages/WorkspaceInbox';
import WorkspaceInsights from 'pages/WorkspaceInsights';
import WorkspacePlans from 'pages/WorkspacePlans';
// import WorkspaceMap from 'pages/WorkspaceMap';
import WorkspaceOutcomes from 'pages/WorkspaceOutcomes';
import WorkspacePlanWrite from 'pages/WorkspacePlanWrite';
import WorkspacePlanTrack from 'pages/WorkspacePlanTrack';
import WorkspacePlanTemplate from 'pages/WorkspacePlanTemplate';
import WorkspacePlanInsights from 'pages/WorkspacePlanInsights';
import WorkspacePlanMap from 'pages/WorkspacePlanMap';
import WorkspacePlanWork from 'pages/WorkspacePlanWork';
import WorkspacePlanNotes from 'pages/WorkspacePlanNotes';
import WorkspacePlanActivity from 'pages/WorkspacePlanActivity';
import WorkspacePlanSettings from 'pages/WorkspacePlanSettings';
import WorkspacePlanSettingsNotifications from 'pages/WorkspacePlanSettingsNotifications';
import WorkspacePlanSettingsSharing from 'pages/WorkspacePlanSettingsSharing';

import WorkspacePeople from 'pages/WorkspacePeople';
import WorkspaceProfile from 'pages/WorkspaceProfile';

import WorkspaceTeams from 'pages/WorkspaceTeams';
import WorkspaceTeamDetails from 'pages/WorkspaceTeamDetails';

import WorkspaceStandups from 'pages/WorkspaceStandups';

import WorkspacePlanPrezTrack from 'pages/WorkspacePlanPrezTrack';
import WorkspacePlanPrezDashboard from 'pages/WorkspacePlanPrezDashboard';

import WorkspaceSettingsBilling from 'pages/WorkspaceSettingsBilling';
import WorkspaceSettingsBillingPlans from 'pages/WorkspaceSettingsBillingPlans';
import WorkspaceSettingsDetails from 'pages/WorkspaceSettingsDetails';
import WorkspaceSettingsUsage from 'pages/WorkspaceSettingsUsage';
import WorkspaceSettingsAudit from 'pages/WorkspaceSettingsAudit';
import WorkspaceSettingsDelete from 'pages/WorkspaceSettingsDelete';
import WorkspaceSettingsIntegrations from 'pages/WorkspaceSettingsIntegrations';
import WorkspaceSettingsSecurity from 'pages/WorkspaceSettingsSecurity';
import WorkspaceSettingsWhitelisting from 'pages/WorkspaceSettingsWhitelisting';
import WorkspaceSettingsSSO from 'pages/WorkspaceSettingsSSO';
import WorkspaceSettingsWorkflow from 'pages/WorkspaceSettingsWorkflow';
import WorkspaceSettingsMemberships from 'pages/WorkspaceSettingsMemberships';
import WorkspaceSettingsMembershipDetails from 'pages/WorkspaceSettingsMembershipDetails';

import WorkspaceMembershipNotifications from 'pages/WorkspaceMembershipNotifications';
import WorkspaceMembershipStandups from 'pages/WorkspaceMembershipStandups';
import WorkspaceMembershipIntegrations from 'pages/WorkspaceMembershipIntegrations';
import WorkspaceMembershipDelete from 'pages/WorkspaceMembershipDelete';
import WorkspacePlanRetrospectives from 'pages/WorkspacePlanRetrospectives';
import WorkspaceRetrospective from 'pages/WorkspaceRetrospective/View';
import WorkspaceRetrospectiveEdit from 'pages/WorkspaceRetrospective/Edit';

// Components
import Suspended from 'components/Suspended';
import TrialExpired from 'components/TrialExpired';
import LoadingScreen from 'components/LoadingScreen';
import NotFound from 'components/NotFound';
import GlobalModal from 'components/GlobalModal';
import GlobalPanel from 'components/GlobalPanel';
import { ChromeContent, ChromeWrapper } from 'components/Chrome';

// Mobile-ready nav components

// The components listed below are copies of the existing Chrome and nav components. They're used to allow
// developers to iterate on the mobile-ready sidebar without breaking the existing app.
// Search in the code for `use_mobile_chrome` to see when and how they're used.

// The plan is to replace the current components (non-mobile ready) with these ones, once we are done.
import MobileReadyAppSidebar from 'components/MobileReadyAppSidebar'; // Temporary sidebar for mobile-readiness development
import MobileNav from 'components/MobileNav';
import {
  MobileReadyChromeWrapperWithSidebar,
  MobileReadyChromeMobileNav,
  MobileReadyChromeSidebar,
  MobileReadyChromeQuickstart,
} from 'components/MobileReadyChrome';

import HashHandler from './HashHandler';
import MembershipStats from './MembershipStats';
import JoinWorkspaceByDomain from './JoinWorkspaceByDomain';
import JoinWorkspaceBySSO from './JoinWorkspaceBySSO';
import WorkspaceAccessDenied from 'components/WorkspaceAccessDenied';

import * as routes from 'routes';

// API
import {
  fetchWorkspaceDetails,
  fetchCurrentUserMemberships,
  fetchIntegrations,
  fetchWorkspaceMemberships,
  fetchWorkspaceSettings,
} from 'api/remote';

// Actions
import {
  setCurrentMembership,
  setCurrentWorkspace,
  setCurrentIntegrations,
  setWorkspaceMemberships,
  setCurrentWorkspaceSettings,
} from 'state/actions/sessionActions';
import WorkspaceSettingsTerms from 'pages/WorkspaceSettingsTerms';
import WorkspaceInitiatives from 'pages/WorkspaceInitiatives';
import WorkspaceObjectives from 'pages/WorkspaceObjectives';
import WorkspaceSettingsTheme from 'pages/WorkspaceSettingsTheme';
import { Workspace, WorkspaceSettings } from 'types';
import { LAST_VISITED_FILTER } from 'config/constants';
import WorkspaceSettingsPrivacy from 'pages/WorkspaceSettingsPrivacy';
import WorkspaceMapV2 from 'pages/WorkspaceMapV2';
import { setGlobalModalContent } from 'state/actions/globalUIActions';
import { hasV4Trial } from 'utils/workspaceUtils';
import WorkspaceSettingsTags from 'pages/WorkspaceSettingsTags';
import WorkspaceDashboards from 'pages/WorkspaceDashboards';
import WorkspaceDashboardEdit from 'pages/WorkspaceDashboard/Edit';
import WorkspaceDashboardView from 'pages/WorkspaceDashboard/View';

function WorkspaceRoutes() {
  const dispatch = useDispatch();
  const location = useLocation();
  const { workspaceSlug } = useParams<{ workspaceSlug: string }>();
  const queryKey = [queryKeys.currentWorkspace, workspaceSlug];
  const [currentUserMemberships, setCurrentUserMemberships]: any = useState([]);
  const [showMobileSidebar, setShowMobileSidebar] = useState(false); // used to display panel
  const history = useHistory();
  // Global hotkeys
  useHotkeys(`shift+?`, () => dispatch(setGlobalModalContent('workspace::shortcuts')), { scopes: ['*'] });
  useHotkeys(`mod+k`, () => dispatch(setGlobalModalContent('workspace::command.center')), {
    scopes: ['*'],
    preventDefault: true,
  });
  useHotkeys(
    'g+h',
    () => {
      history.push(routes.WORKSPACE_INBOX_ROUTE.replace(':workspaceSlug', workspaceSlug));
    },
    { scopes: ['*'] },
  );
  useHotkeys(
    'g+m',
    () => {
      history.push(routes.WORKSPACE_MAP_ROUTE.replace(':workspaceSlug', workspaceSlug));
    },
    { scopes: ['*'] },
  );
  useHotkeys(
    'g+f',
    () => {
      history.push(routes.WORKSPACE_FILTERS_ROUTE.replace(':workspaceSlug', workspaceSlug));
    },
    { scopes: ['*'] },
  );
  useHotkeys(
    'g+u',
    () => {
      history.push(routes.WORKSPACE_PEOPLE_ROUTE.replace(':workspaceSlug', workspaceSlug));
    },
    { scopes: ['*'] },
  );
  useHotkeys(
    'g+p',
    () => {
      history.push(routes.WORKSPACE_PLANS_ROUTE.replace(':workspaceSlug', workspaceSlug));
    },
    { scopes: ['*'] },
  );
  useHotkeys(
    'g+t',
    () => {
      history.push(routes.WORKSPACE_TEAMS_ROUTE.replace(':workspaceSlug', workspaceSlug));
    },
    { scopes: ['*'] },
  );
  useHotkeys(
    'g+s',
    () => {
      history.push(routes.WORKSPACE_STANDUPS_ROUTE.replace(':workspaceSlug', workspaceSlug));
    },
    { scopes: ['*'] },
  );

  const {
    isFetching,
    isError,
    error,
    data: workspacesResponse,
  }: any = useQuery(queryKey, fetchWorkspaceDetails, {
    staleTime: 0,
    onSuccess: (response) => {
      const workspace = response.data;
      dispatch(setCurrentWorkspace(workspace));
    },
  });

  const settingsQueryKey = [queryKeys.currentWorkspaceSettings, workspaceSlug];
  const { data: workspacesSettingsResponse } = useQuery(settingsQueryKey, fetchWorkspaceSettings, {
    staleTime: 0,
    onSuccess: (response) => {
      dispatch(setCurrentWorkspaceSettings(response.data));
    },
  });

  const membershipsQueryKey = queryKeys.currentUserMemberships;
  useQuery(membershipsQueryKey, fetchCurrentUserMemberships, {
    staleTime: 0,
    onSuccess: (response: any) => {
      setCurrentUserMemberships(response.data);
    },
  });

  const queryKeyIntegration = [queryKeys.integrations, workspaceSlug];
  useQuery(queryKeyIntegration, fetchIntegrations, {
    staleTime: 0,
    onSuccess: (response: any) => {
      dispatch(setCurrentIntegrations(response.data));
    },
  });

  // preload workspace users for user picker
  const queryKeyWorkspaceUsers = [
    queryKeys.memberships,
    workspaceSlug,
    {
      per_page: 5,
    },
  ];
  useQuery(queryKeyWorkspaceUsers, fetchWorkspaceMemberships, {
    onSuccess: (response) => {
      dispatch(setWorkspaceMemberships(response.data));
    },
  });

  useEffect(() => {
    const currentMembership = currentUserMemberships.filter((membership: any) => {
      return membership.workspace.slug === workspaceSlug;
    })[0];
    dispatch(setCurrentMembership(currentMembership));
  }, [workspaceSlug, currentUserMemberships, dispatch]);

  const currentUser = useSelector((state: any) => state.session.currentUser, shallowEqual);
  const currentMembership = useSelector((state: any) => state.session.currentMembership, shallowEqual);
  const currentWorkspace = useSelector((state: any) => state.session.currentWorkspace, shallowEqual);

  useEffect(() => {
    if (currentUser && currentWorkspace) {
      const createdAtUnix = getUnixTime(parseISO(currentWorkspace.created_at));
      const is_trial = hasV4Trial(currentWorkspace) ? true : false;
      let seatsFilledPercentage = 0;
      let seats_left_count = -1;
      if (currentWorkspace.billing_user_limit > 0) {
        seatsFilledPercentage = Math.round(
          (currentWorkspace.memberships_count / currentWorkspace.billing_user_limit) * 100,
        );
        seats_left_count = Math.max(0, currentWorkspace.billing_user_limit - currentWorkspace.memberships_count);
      }
      window.Intercom('update', {
        app_id: process.env.REACT_APP_INTERCOM_APP_ID,
        user_id: currentUser.id,
        user_hash: currentUser.intercom_hash,
        company: {
          id: currentWorkspace.slug,
          name: currentWorkspace.name,
          created_at: createdAtUnix,
          billing_subscription_plan: currentWorkspace.billing_subscription_plan,
          billing_subscription_created_at: currentWorkspace.billing_subscription_plan,
          pricing_version: currentWorkspace.pricing_version,
          stripe_customer_id: currentWorkspace.stripe_customer_id,
          billing_checkins_limit: currentWorkspace.billing_checkins_limit,
          billing_user_limit: currentWorkspace.billing_user_limit,
          seats_left_count,
          seats_filled_percentage: seatsFilledPercentage,
          is_trial: is_trial,
          time_zone: currentWorkspace.time_zone,
          trial_period_finish_at: currentWorkspace.trial_period_finish_at,
          trial_period_extended: currentWorkspace.trial_period_extended,
          memberships_count: currentWorkspace.memberships_count,
          plans_count: currentWorkspace.plans_count,
          draft_plans_count: currentWorkspace.draft_plans_count,
          published_plans_count: currentWorkspace.published_plans_count,
          outcomes_count: currentWorkspace.outcomes_count,
          initiatives_count: currentWorkspace.initiatives_count,
          checkins_count: currentWorkspace.checkins_count,
          integrations_count: currentWorkspace.integrations_count,
          creator_team_rollout: currentWorkspace.creator_team_rollout,
          creator_team_size: currentWorkspace.creator_team_size,
          creator_team_role: currentWorkspace.creator_team_role,
          creator_team_demo: currentWorkspace.creator_team_demo,
        },
      });
    }
  }, [currentUser, currentWorkspace]);

  if (isError) {
    const errorResponse = error.response;
    if (errorResponse.status === 404) {
      return <NotFound />;
    }
    if (errorResponse.status === 401 && errorResponse.data.sso_required) {
      return <JoinWorkspaceBySSO workspaceSlug={workspaceSlug} />;
    }
    if (errorResponse.status === 401) {
      return <JoinWorkspaceByDomain workspaceSlug={workspaceSlug} />;
    }
    if (errorResponse.status === 403) {
      return <WorkspaceAccessDenied />;
    }
  }

  // Now we can display the app with the Chrome
  if (!currentUser || !currentMembership || isFetching) {
    return <LoadingScreen />;
  }

  const workspace: Workspace = workspacesResponse.data;
  const workspaceSettings: WorkspaceSettings = workspacesSettingsResponse ? workspacesSettingsResponse.data : null;

  // Check if the workspace is suspended
  const isSuspended = workspace.is_suspended;

  const isTrialExpired = workspace.trial_period_exceeded;

  // Display suspended message if workspace is suspended
  if (
    isSuspended &&
    !location.pathname.includes('/onboarding') && // Don't redirect in onboarding
    !location.pathname.includes('/account') && // Don't redirect on /account pages
    !location.pathname.includes('/settings') // Don't redirect on settings pages
  ) {
    return <Suspended membership={currentMembership} />;
  }

  // Display trial expired message if trial is expired
  if (
    isTrialExpired &&
    !location.pathname.includes('/onboarding') && // Don't redirect in onboarding
    !location.pathname.includes('/account') && // Don't redirect on /account pages
    !location.pathname.includes('/settings') // Don't redirect on settings pages
  ) {
    return <TrialExpired membership={currentMembership} />;
  }

  // Check if we are in the write mode of the plan
  // I'm going to regret this when we change the URL
  const splittedPath = location.pathname.split('/');
  const isPlanWriteMode = splittedPath[2] === 'plans' && splittedPath[4] === 'write';
  const isRetrospective = splittedPath[4] === 'retrospectives';
  const hideSidebarForRetrospectives = isRetrospective && splittedPath.length > 6; // Hide sidebar if in edit or public mode

  let showGettingStarted = false;
  const isAdmin = ['admin', 'owner'].includes(currentMembership.role);
  if (isAdmin && workspace.pricing_version === 4 && workspace.billing_has_subscription === false) {
    showGettingStarted = true;
  }

  // Collapse the nav if showGettingStarted is active, or if we're in map, outcomes or tasks listings
  const isCollapsed = ['map', 'outcomes', 'tasks', 'objectives', 'standups', 'dashboards'].includes(splittedPath[2]);
  const collapsedClass = isCollapsed ? 'collapsed' : '';

  const isPresentMode = splittedPath[4] === 'present';
  const isOnboarding = splittedPath[2] === 'onboarding';
  const isJiraIntegration = splittedPath[2] === 'jira';
  const isEmbedOutcome = splittedPath[2] === 'embed' && splittedPath[3] === 'outcome';
  const isSetting = splittedPath[2] === 'settings';
  if (isJiraIntegration) {
    return <WorkspaceJiraOutcomes />;
  }

  if (isEmbedOutcome) {
    return <WorkspaceEmbedOutcome />;
  }

  if (isPresentMode) {
    return (
      <ChromeWrapper>
        <HashHandler />
        <GlobalPanel />
        <GlobalModal />

        <Switch>
          <Route path={routes.WORKSPACE_PLAN_PREZ_DASHBOARD_ROUTE} exact={true}>
            <WorkspacePlanPrezDashboard />
          </Route>
          <Route path={routes.WORKSPACE_PLAN_PREZ_ROOT_ROUTE}>
            <WorkspacePlanPrezTrack />
          </Route>
        </Switch>
      </ChromeWrapper>
    );
  }

  if (isOnboarding) {
    return (
      <ChromeWrapper>
        <ChromeContent>
          <GlobalModal />

          <Switch>
            <Route path={routes.WORKSPACE_ONBOARDING_REFERRAL_ROUTE} exact={true}>
              <WorkspaceOnboardingReferral workspace={workspace} />
            </Route>
            <Route path={routes.WORKSPACE_ONBOARDING_ABOUT_ROUTE} exact={true}>
              <WorkspaceOnboardingAbout workspace={workspace} />
            </Route>
            <Route path={routes.WORKSPACE_ONBOARDING_ROLE_ROUTE} exact={true}>
              <WorkspaceOnboardingRole workspace={workspace} />
            </Route>
            <Route path={routes.WORKSPACE_ONBOARDING_ROLLOUT_ROUTE} exact={true}>
              <WorkspaceOnboardingRollout workspace={workspace} />
            </Route>
            <Route path={routes.WORKSPACE_ONBOARDING_WORKFLOW_ROUTE} exact={true}>
              <WorkspaceOnboardingWorkflow workspace={workspace} />
            </Route>
            <Route path={routes.WORKSPACE_ONBOARDING_TEAM_ROUTE} exact={true}>
              <WorkspaceOnboardingTeam workspace={workspace} />
            </Route>
            <Route path={routes.WORKSPACE_ONBOARDING_TEMPLATE_ROUTE} exact={true}>
              <WorkspaceOnboardingTemplate workspace={workspace} />
            </Route>
            <Route path={routes.WORKSPACE_ONBOARDING_DONE_ROUTE} exact={true}>
              <WorkspaceOnboardingDone workspace={workspace} />
            </Route>
          </Switch>
        </ChromeContent>
      </ChromeWrapper>
    );
  }

  return (
    <HotkeysProvider initiallyActiveScopes={['*']}>
      <MobileReadyChromeWrapperWithSidebar className={collapsedClass}>
        <MembershipStats currentWorkspace={workspace} />
        <HashHandler />
        <GlobalPanel />
        <GlobalModal />
        <MobileReadyChromeMobileNav>
          <MobileNav setShowMobileSidebar={setShowMobileSidebar} isSetting={isSetting} />
        </MobileReadyChromeMobileNav>
        {!isPlanWriteMode && !isSuspended && !hideSidebarForRetrospectives && (
          <MobileReadyChromeSidebar
            color={workspace.theme_bg ?? ''}
            className={showMobileSidebar ? 'mobile-sidebar' : ''}
          >
            <MobileReadyAppSidebar setShowMobileSidebar={setShowMobileSidebar} isCollapsed={isCollapsed} />
          </MobileReadyChromeSidebar>
        )}
        {!isPlanWriteMode && !isSuspended && !isRetrospective && showGettingStarted && !isSetting && (
          <MobileReadyChromeQuickstart>
            <QuickstartSidebar />
          </MobileReadyChromeQuickstart>
        )}
        <Switch>
          <Route path={routes.WORKSPACE_MEMBERSHIP_NOTIFICATIONS_ROUTE} exact={true}>
            <WorkspaceMembershipNotifications />
          </Route>
          <Route path={routes.WORKSPACE_MEMBERSHIP_STANDUPS_ROUTE} exact={true}>
            <WorkspaceMembershipStandups />
          </Route>
          <Route path={routes.WORKSPACE_MEMBERSHIP_INTEGRATIONS_ROUTE} exact={true}>
            <WorkspaceMembershipIntegrations workspace={workspace} />
          </Route>
          <Route path={routes.WORKSPACE_MEMBERSHIP_DELETE_ROUTE} exact={true}>
            <WorkspaceMembershipDelete />
          </Route>
          <Route
            path={routes.WORKSPACE_ROOT_ROUTE}
            exact={true}
            render={() => <Redirect to={routes.WORKSPACE_INBOX_ROUTE.replace(':workspaceSlug', workspace.slug)} />}
          />
          <Route path={routes.WORKSPACE_TEAMS_ROUTE} exact={true}>
            <RedirectIfReadOnly />
            <WorkspaceTeams workspace={workspace} />
          </Route>
          <Route path={routes.WORKSPACE_TEAM_DETAILS_ROUTE} exact={true}>
            <RedirectIfReadOnly />
            <WorkspaceTeamDetails />
          </Route>
          <Route path={routes.WORKSPACE_STANDUPS_ROUTE} exact={true}>
            <RedirectIfReadOnly />
            <WorkspaceStandups workspace={workspace} />
          </Route>
          <Route path={routes.WORKSPACE_PEOPLE_ROUTE} exact={true}>
            <RedirectIfReadOnly />
            <WorkspacePeople workspace={workspace} />
          </Route>
          <Route path={routes.WORKSPACE_PROFILE_ROUTE} exact={true}>
            <RedirectIfReadOnly />
            <WorkspaceProfile />
          </Route>
          <Route path={routes.WORKSPACE_INBOX_ROUTE} exact={true}>
            <WorkspaceInbox />
          </Route>
          <Route path={routes.WORKSPACE_INSIGHTS_ROUTE} exact={true}>
            <WorkspaceInsights />
          </Route>
          <Route path={routes.WORKSPACE_ACCELERATOR_ROUTE} exact={true}>
            <WorkspaceAccelerator />
          </Route>
          <Route path={routes.WORKSPACE_ACCELERATOR_SECTION_ROUTE} exact={true}>
            <WorkspaceAccelerator />
          </Route>
          <Route path={routes.WORKSPACE_QUICKSTART_ROUTE} exact={true}>
            <WorkspaceQuickstart />
          </Route>
          <Route path={routes.WORKSPACE_PLANS_ROUTE} exact={true}>
            <WorkspacePlans workspace={workspace} />
          </Route>
          <Route path={routes.WORKSPACE_MAP_ROUTE}>
            <WorkspaceMapV2 workspace={workspace} />
          </Route>
          <Route path={routes.WORKSPACE_DASHBOARDS_ROUTE} exact={true}>
            <WorkspaceDashboards workspace={workspace} />
          </Route>
          <Route path={routes.WORKSPACE_DASHBOARD_ROUTE} exact={true}>
            <WorkspaceDashboardView workspace={workspace} />
          </Route>
          <Route path={routes.WORKSPACE_DASHBOARD_EDIT_ROUTE} exact={true}>
            <RedirectIfReadOnly />
            <WorkspaceDashboardEdit workspace={workspace} />
          </Route>
          <Route path={routes.WORKSPACE_MAP_V2_ROUTE} exact={true}>
            <WorkspaceMapV2 workspace={workspace} />
          </Route>
          <Route path={routes.WORKSPACE_MAP_V2_ROUTE_WITH_FILTERS} exact={true}>
            <WorkspaceMapV2 workspace={workspace} />
          </Route>
          <Route path={routes.WORKSPACE_MAP_V2_SEGMENT_ROUTE_WITH_FILTERS} exact={true}>
            <WorkspaceMapV2 workspace={workspace} />
          </Route>
          <Route
            path={routes.WORKSPACE_FILTERS_ROUTE}
            exact={true}
            render={(data) => {
              // get the encoded default filters
              const objectiveDefaultFilters = Base64.encode(
                JSON.stringify({
                  plan_status: 'is_in_progress',
                  sorting: 'plan',
                }),
              );

              const outcomeDefaultFilters = Base64.encode(
                JSON.stringify({
                  plan_status: 'is_in_progress',
                  sorting: 'plan',
                }),
              );
              const initiativeDefaultFilters = Base64.encode(
                JSON.stringify({
                  plan_status: 'is_in_progress',
                  sorting: 'plan',
                }),
              );
              const { workspaceSlug } = data.match.params;
              const filtersMap = {
                objectives: routes.WORKSPACE_OBJECTIVES_ROUTE_WITH_FILTERS.replace(
                  ':workspaceSlug',
                  workspaceSlug,
                ).replace(':filter', objectiveDefaultFilters),
                outcomes: routes.WORKSPACE_OUTCOMES_ROUTE_WITH_FILTERS.replace(':workspaceSlug', workspaceSlug).replace(
                  ':filter',
                  outcomeDefaultFilters,
                ),
                initiatives: routes.WORKSPACE_INITIATIVE_ROUTE_WITH_FILTERS.replace(
                  ':workspaceSlug',
                  workspaceSlug,
                ).replace(':filter', initiativeDefaultFilters),
              };
              const lastTab = localStorage.getItem(LAST_VISITED_FILTER);
              let redirectRoute;
              if (lastTab) {
                redirectRoute = filtersMap[lastTab as keyof typeof filtersMap];
              } else {
                redirectRoute = routes.WORKSPACE_OBJECTIVES_ROUTE;
              }
              return <Redirect to={redirectRoute.replace(':workspaceSlug', workspaceSlug)} />;
            }}
          />
          <Route path={routes.WORKSPACE_OUTCOMES_ROUTE} exact={true}>
            <WorkspaceOutcomes workspace={workspace} />
          </Route>
          <Route path={routes.WORKSPACE_OUTCOMES_ROUTE_WITH_FILTERS} exact={true}>
            <WorkspaceOutcomes workspace={workspace} />
          </Route>
          <Route path={routes.WORKSPACE_OUTCOMES_SEGMENT_ROUTE_WITH_FILTERS} exact={true}>
            <WorkspaceOutcomes workspace={workspace} />
          </Route>
          <Route path={routes.WORKSPACE_INITIATIVE_ROUTE} exact={true}>
            <WorkspaceInitiatives workspace={workspace} />
          </Route>
          <Route path={routes.WORKSPACE_INITIATIVE_ROUTE_WITH_FILTERS} exact={true}>
            <WorkspaceInitiatives workspace={workspace} />
          </Route>
          <Route path={routes.WORKSPACE_INITIATIVE_SEGMENT_ROUTE_WITH_FILTERS} exact={true}>
            <WorkspaceInitiatives workspace={workspace} />
          </Route>
          <Route path={routes.WORKSPACE_OBJECTIVES_ROUTE} exact={true}>
            <WorkspaceObjectives workspace={workspace} />
          </Route>
          <Route path={routes.WORKSPACE_OBJECTIVES_ROUTE_WITH_FILTERS} exact={true}>
            <WorkspaceObjectives workspace={workspace} />
          </Route>
          <Route path={routes.WORKSPACE_OBJECTIVES_SEGMENT_ROUTE_WITH_FILTERS} exact={true}>
            <WorkspaceObjectives workspace={workspace} />
          </Route>
          <Route
            path={routes.WORKSPACE_PLAN_ROOT_ROUTE}
            exact={true}
            render={(data: any) => {
              const { workspaceSlug, planId } = data.match.params;
              const redirectRoute = routes.WORKSPACE_PLAN_WRITE_ROUTE.replace(':workspaceSlug', workspaceSlug).replace(
                ':planId',
                planId,
              );
              return <Redirect to={redirectRoute} />;
            }}
          />
          <Route path={routes.WORKSPACE_PLAN_WRITE_ROUTE} exact={true}>
            <RedirectIfReadOnly />
            <WorkspacePlanWrite />
          </Route>
          <Route path={routes.WORKSPACE_PLAN_TEMPLATE_ROUTE} exact={true}>
            <RedirectIfReadOnly />
            <WorkspacePlanTemplate />
          </Route>
          <Route path={routes.WORKSPACE_PLAN_TRACK_ROUTE} exact={true}>
            <WorkspacePlanTrack />
          </Route>
          <Route path={routes.WORKSPACE_PLAN_INSIGHTS_ROUTE} exact={true}>
            <RedirectIfReadOnly />
            <WorkspacePlanInsights />
          </Route>
          <Route path={routes.WORKSPACE_PLAN_RETROSPECTIVES_ROUTE} exact={true}>
            <WorkspacePlanRetrospectives />
          </Route>
          <Route path={routes.WORKSPACE_RETROSPECTIVE_ROUTE} exact={true}>
            <WorkspaceRetrospective />
          </Route>
          <Route path={routes.WORKSPACE_RETROSPECTIVE_EDIT_ROUTE} exact={true}>
            <RedirectIfReadOnly />
            <WorkspaceRetrospectiveEdit />
          </Route>
          <Route path={routes.WORKSPACE_PLAN_MAP_ROUTE} exact={true}>
            <WorkspacePlanMap />
          </Route>
          <Route path={routes.WORKSPACE_PLAN_NOTES_ROUTE} exact={true}>
            <WorkspacePlanNotes />
          </Route>
          <Route path={routes.WORKSPACE_PLAN_ACTIVITY_ROUTE} exact={true}>
            <WorkspacePlanActivity />
          </Route>
          <Route path={routes.WORKSPACE_PLAN_WORK_ROUTE} exact={true}>
            <RedirectIfReadOnly />
            <WorkspacePlanWork />
          </Route>
          <Route path={routes.WORKSPACE_PLAN_WORK_WEEK_ROUTE} exact={true}>
            <RedirectIfReadOnly />
            <WorkspacePlanWork />
          </Route>
          <Route path={routes.WORKSPACE_PLAN_SETTINGS_ROUTE} exact={true}>
            <RedirectIfReadOnly />
            <WorkspacePlanSettings />
          </Route>
          <Route path={routes.WORKSPACE_PLAN_SETTINGS_NOTIFICATIONS_ROUTE} exact={true}>
            <RedirectIfReadOnly />
            <WorkspacePlanSettingsNotifications />
          </Route>
          <Route path={routes.WORKSPACE_PLAN_SETTINGS_SHARING_ROUTE} exact={true}>
            <RedirectIfReadOnly />
            <WorkspacePlanSettingsSharing />
          </Route>
          <Route path={routes.WORKSPACE_SETTINGS_ROUTE} exact={true}>
            <RequireAdminStatus />
            <WorkspaceSettingsDetails workspace={workspace} />
          </Route>
          <Route path={routes.WORKSPACE_SETTINGS_USAGE_ROUTE} exact={true}>
            <RequireAdminStatus />
            <WorkspaceSettingsUsage workspace={workspace} />
          </Route>
          <Route path={routes.WORKSPACE_SETTINGS_AUDIT_ROUTE} exact={true}>
            <RequireAdminStatus />
            <WorkspaceSettingsAudit workspace={workspace} />
          </Route>
          <Route path={routes.WORKSPACE_SETTINGS_BILLING_ROUTE} exact={true}>
            <RequireOwnerStatus />
            <WorkspaceSettingsBilling workspace={workspace} />
          </Route>
          <Route path={routes.WORKSPACE_SETTINGS_BILLING_PLANS_ROUTE} exact={true}>
            <RequireOwnerStatus />
            <WorkspaceSettingsBillingPlans workspace={workspace} />
          </Route>
          <Route path={routes.WORKSPACE_SETTINGS_INTEGRATIONS_ROUTE} exact={true}>
            <RequireAdminStatus />
            <WorkspaceSettingsIntegrations workspace={workspace} />
          </Route>
          <Route path={routes.WORKSPACE_SETTINGS_SECURITY_ROUTE} exact={true}>
            <RequireAdminStatus />
            <WorkspaceSettingsSecurity workspace={workspace} />
          </Route>
          <Route path={routes.WORKSPACE_SETTINGS_WHITELISTING_ROUTE} exact={true}>
            <RequireAdminStatus />
            <WorkspaceSettingsWhitelisting workspace={workspace} />
          </Route>
          <Route path={routes.WORKSPACE_SETTINGS_SSO_ROUTE} exact={true}>
            <RequireAdminStatus />
            <WorkspaceSettingsSSO workspace={workspace} />
          </Route>
          <Route path={routes.WORKSPACE_SETTINGS_PRIVACY_ROUTE} exact={true}>
            <RequireAdminStatus />
            <WorkspaceSettingsPrivacy workspace={workspace} />
          </Route>
          <Route path={routes.WORKSPACE_SETTINGS_DELETE_ROUTE} exact={true}>
            <RequireOwnerStatus />
            <WorkspaceSettingsDelete workspace={workspace} />
          </Route>
          <Route path={routes.WORKSPACE_SETTINGS_TERMS_ROUTE} exact={true}>
            <RequireAdminStatus />
            <WorkspaceSettingsTerms workspace={workspace} workspaceSettings={workspaceSettings} />
          </Route>
          <Route path={routes.WORKSPACE_SETTINGS_TAGS_ROUTE} exact={true}>
            <RequireAdminStatus />
            <WorkspaceSettingsTags workspace={workspace} />
          </Route>
          <Route path={routes.WORKSPACE_SETTINGS_THEME_ROUTE} exact={true}>
            <RequireAdminStatus />
            <WorkspaceSettingsTheme workspace={workspace} />
          </Route>
          <Route path={routes.WORKSPACE_SETTINGS_WORKFLOW_ROUTE} exact={true}>
            <RequireAdminStatus />
            <WorkspaceSettingsWorkflow workspace={workspace} />
          </Route>
          <Route path={routes.WORKSPACE_SETTINGS_MEMBERSHIPS_ROUTE} exact={true}>
            <RequireAdminStatus />
            <WorkspaceSettingsMemberships workspace={workspace} />
          </Route>
          <Route path={routes.WORKSPACE_SETTINGS_MEMBERSHIP_DETAILS_ROUTE} exact={true}>
            <RequireAdminStatus />
            <WorkspaceSettingsMembershipDetails />
          </Route>
          <Route>
            <NotFound />
          </Route>
        </Switch>
      </MobileReadyChromeWrapperWithSidebar>
    </HotkeysProvider>
  );
}

export default React.memo(WorkspaceRoutes);
