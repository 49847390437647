/**
  This is the playground page. It's used to try out the editor and the different templates.
  State is saved in local storage, but you can only do that for one plan.

  The editor piece should be portable and be able to plug itself onto local storage OR
  onto the remote API (DB) once it'll be plugged into the app.
 */

import React from 'react';
import styled from 'styled-components';
import theme from 'theme';
import { useSelector, shallowEqual } from 'react-redux';

// Components
import { Link, useParams } from 'react-router-dom';
import TabilityIconBlack from 'components/_assets/TabilityIconBlack';
import KoalaAvatar from 'koala/components/Avatar';

const Header = styled.div`
  padding: ${theme.spacing.x2} ${theme.spacing.x3};
  background: ${theme.colors.N5};
  border-bottom: 1px solid ${theme.colors.N20};
`;

const HeaderWrapper = styled.div`
  max-width: 100rem;
  width: 100rem;
  margin: 0 auto;
  padding: 0 ${theme.spacing.x3};
  display: flex;
  align-items: center;
  justify-content: space-between;

  > div {
    display: flex;
    align-items: center;
    gap: ${theme.spacing.x2};

    a {
      &.link {
        display: flex;
        align-items: center;
        span {
          display: inline-block;
          margin-left: ${theme.spacing.x1};
        }
        svg {
          width: 2.4rem;
        }
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }

  @media ${theme.devices.laptop} {
    width: auto;
    padding: 0 ${theme.spacing.x2};
    flex-direction: column;
  }
`;

function TemplateHeader() {
  const currentUser = useSelector((state: any) => state.session.currentUser, shallowEqual);

  const { planId } = useParams<{ planId: string }>();
  const utmCampaign = planId || 'home';

  // Displays the list of templates
  return (
    <Header>
      <HeaderWrapper>
        <div>
          <Link to="/templates" className="link">
            <TabilityIconBlack />
            <span>Templates</span>
          </Link>
          <a
            href={`https://www.tability.io?utm_source=tability&utm_content=templates&utm_campaign=${utmCampaign}`}
            target="_blank"
            className="link"
            rel="noopener noreferrer"
          >
            <span>Product</span>
          </a>
          <a
            href={`https://www.tability.io/pricing?utm_source=tability&utm_content=templates&utm_campaign=${utmCampaign}`}
            target="_blank"
            className="link"
            rel="noopener noreferrer"
          >
            <span>Pricing</span>
          </a>
        </div>
        {!currentUser && (
          <div>
            <Link to="/login" className="">
              Login
            </Link>
            <Link to="/signup" className="button primary">
              Signup
            </Link>
          </div>
        )}
        {currentUser && (
          <div>
            <Link to="/" className="button">
              Return to Tability
            </Link>
            <KoalaAvatar user={currentUser} />
          </div>
        )}
      </HeaderWrapper>
    </Header>
  );
}

export default TemplateHeader;
