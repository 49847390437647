import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import theme from 'theme';
import FormField from 'components/FormField';
import KoalaSelect from 'koala/components/Select';
import * as remoteApi from 'api/remote';
import request from 'state/utils/request';

import _ from 'lodash';
import { useParams } from 'react-router-dom';
import KoalaButton from 'koala/components/Button';
import { useTranslation } from 'react-i18next';
import { CustomTermKey, translate } from 'utils/customTermUtils';
import { shallowEqual, useSelector } from 'react-redux';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: ${theme.spacing.x2} 0;
`;

interface Props {
  handleAddWidget: (type: string, id: string, source: string) => void;
  isLoading: boolean;
}

function ObjectiveListWidgetDetails(props: Props) {
  const { handleAddWidget, isLoading } = props;
  const { t } = useTranslation();
  const { workspaceSlug } = useParams<{
    workspaceSlug: string;
  }>();
  const workspace = useSelector((state: any) => state.session.currentWorkspace, shallowEqual);

  const [selectedSegment, setSelectedSegment] = useState<{ value: string; label: string }>();

  const handleSelectSegment = (option: any) => {
    setSelectedSegment(option);
  };

  const loadOptions = (inputValue: string, callback: any) => {
    const url = remoteApi.SEGMENTS_API.replace(':workspaceSlug', workspaceSlug).replace(
      ':segmentable_type',
      'objective',
    );

    const params = inputValue
      ? {
          filter: {
            title: inputValue,
            archived: false,
          },
          per_page: 10,
        }
      : {
          filter: {
            archived: false,
          },
          per_page: 10,
        };
    request
      .get(url, { params })
      .then((response) => {
        const plans = response.data;
        if (plans && plans.length > 0) {
          const options = plans.map((plan: any) => {
            return {
              value: plan.id,
              label: plan.title,
            };
          });
          return options;
        } else {
          return [];
        }
      })
      .then(callback);
  };

  const debouncedLoadOptions: any = useRef(
    _.debounce((inputValue: string, callback: any) => loadOptions(inputValue, callback), 200, {
      maxWait: 2000,
    }),
  );

  const handleSave = (e: React.FormEvent) => {
    e.preventDefault();
    if (selectedSegment) {
      handleAddWidget('objective-list', selectedSegment.value, 'segment');
    }
  };

  return (
    <Container>
      <FormField className="objective-segment-select">
        <label>
          {t('workspaceDashboards.objectiveSegment', { objective: translate(workspace, CustomTermKey.OBJECTIVE, 1) })}
        </label>
        <KoalaSelect
          handleChange={handleSelectSegment}
          placeholder={
            t('workspaceDashboards.objectiveSegmentPlaceholder', {
              objective: translate(workspace, CustomTermKey.OBJECTIVE, 1).toLowerCase(),
            }) ?? 'objective'
          }
          selectedOption={selectedSegment}
          isClearable
          loadOptions={debouncedLoadOptions.current}
          size="small"
          id="segment"
        />
      </FormField>
      <div>
        <KoalaButton onClick={handleSave} loading={isLoading} disabled={!selectedSegment}>
          {t('shared.add')}
        </KoalaButton>
      </div>
    </Container>
  );
}

export default ObjectiveListWidgetDetails;
