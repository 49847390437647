import React, { useState } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import queryKeys from 'config/queryKeys';
import { useMutation, useQuery } from 'react-query';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import theme from 'theme';
import { REFERENCE_TEMPLATE_STORAGE_KEY } from 'config/constants';

// API
import * as remoteApi from 'api/remote';
import * as workspaceUtils from 'utils/workspaceUtils';

import { setGlobalModalContent } from 'state/actions/globalUIActions';
import { setReferenceTemplate, setIsEmptyWorkspace } from 'state/actions/sessionActions';

// Routes
import * as routes from 'routes';

// Components
import FormField from 'components/FormField';
import RadioField from 'components/RadioField';
import { ModalGrid, ModalHeader, ModalContent, ModalFooter } from 'components/GlobalModal';
import 'theme/DatePicker.css';
import DatePicker from 'react-datepicker';
import { addMonths, startOfQuarter, endOfQuarter, addDays } from 'date-fns';
import KoalaButton from 'koala/components/Button';
import KoalaTextButton from 'koala/components/TextButton';
import KoalaIconButton from 'koala/components/IconButton';
import { Workspace } from 'types';
import { useTranslation } from 'react-i18next';
import { CustomTermKey, translate } from 'utils/customTermUtils';
import * as planUtils from 'utils/planUtils';

import FlagIcon from 'pages/WorkspaceOnboardingWorkflow/_assets/Flag.png';
import MetricIcon from 'pages/WorkspaceOnboardingWorkflow/_assets/Metric.png';
import TasksIcon from 'pages/WorkspaceOnboardingWorkflow/_assets/Tasks.png';
import PersonIcon from 'pages/WorkspaceOnboardingWorkflow/_assets/Person.png';
import KoalaTextBadge from 'koala/components/TextBadge';
import ReactTooltip from 'react-tooltip';

const Form = styled.form`
  padding: 0 ${theme.spacing.x3};
  label {
    color: #151515;
    font-size: 1.2rem;
    font-weight: 800;
    color: ${theme.colors.subtleText};
    text-transform: uppercase;
  }
  > div {
    margin-bottom: ${theme.spacing.x3};
  }
  small {
    display: block;
    margin-top: ${theme.spacing.x1};
  }

  @media ${theme.devices.mobile} {
    .radio-group {
      flex-wrap: wrap;
      overflow: unset;
      max-height: unset;
      height: unset;
      label {
        margin-bottom: 0;
        height: 3.8rem;
        flex-basis: 100%;
      }
      label + input[type='radio'] + label {
        border-left: unset;
      }
      .current_quarter {
        border-top-left-radius: 3px;
        border-top-right-radius: 3px;
        border-bottom: solid 1px ${theme.colors.inputBorder};
      }
      .next_quarter {
        border-bottom: solid 1px ${theme.colors.inputBorder};
      }
      .custom {
        border-bottom-left-radius: 3px;
        border-bottom-right-radius: 3px;
      }
    }
  }
`;

const InputBlock = styled.div`
  margin-right: 3.2rem;
`;

const InputLine = styled.div`
  display: flex;
  gap: ${theme.spacing.half};
  align-items: center;

  span {
  }

  @media ${theme.devices.mobile} {
    flex-wrap: wrap;
  }
`;

const TemplateLabel = styled.div`
  background: #ebedfb;
  display: inline-block;
  padding: 0.4rem 0.8rem;
  letter-spacing: 1px;
  text-transform: uppercase;
  font-size: 1.2rem;
  font-weight: 800;
  color: #324dd3;
  border-radius: 4px;
`;

const PermissionRow = styled.div`
  display: flex;
  gap: ${theme.spacing.x2};
  align-items: center;
`;

const CustomRadioField = styled.div`
  display: flex;
  flex-direction: column;

  label {
    color: ${theme.colors.N70};
    margin-bottom: ${theme.spacing.x1};
    text-transform: uppercase;
    font-weight: 700;
    font-size: 1.1rem;
    display: block;
  }

  .form-label {
    text-transform: uppercase;
    font-size: 1.4rem;
    font-weight: 600;
    color: ${theme.colors.subtleText};
    margin-bottom: ${theme.spacing.x1};
  }

  .radio-group {
    display: flex;
    gap: ${theme.spacing.x1};
    position: relative;
    // hide radio button and put it behind label so not clickable
    input[type='radio'] {
      position: absolute;
      opacity: 0;
      z-index: -1;
    }

    input[type='radio']:focus + label {
      border: 1px solid ${theme.colors.blue};
    }

    label {
      color: ${theme.colors.black};
      text-transform: initial;
      font-weight: initial;
      font-size: 1.4rem;
      background-color: #fff;
      cursor: pointer;
      flex: 1;
      padding: ${theme.spacing.x2};
      transition: all 0.2s ease;
      border: solid 1px ${theme.colors.blockBorder};
      border-radius: 4px;
      &:hover {
        background: ${theme.colors.N3};
        cursor: pointer;
      }

      span.label {
        display: inline-block;
        padding: ${theme.spacing.half} ${theme.spacing.x1};
        border-radius: 4px;
        background: ${theme.colors.N5};
        font-size: 1.1rem;
        font-weight: 500;
        color: ${theme.colors.N70};
        text-transform: uppercase;
        font-weight: 700;
        font-size: 1.1rem;
      }

      &.disabled {
        cursor: not-allowed;
        filter: grayscale(0.7);
        opacity: 0.7;
      }

      svg {
        width: 1.8rem;
        height: 1.8rem;
        g,
        path,
        circle {
          stroke: ${theme.colors.subtleText};
        }
      }
    }

    input[type='radio']:checked + label {
      color: #fff;
      background: ${theme.colors.B5};
      border: 1px solid ${theme.colors.B30};
      color: ${theme.colors.N100};

      span {
        background: ${theme.colors.B50};
        font-size: 1.1rem;
        font-weight: 500;
        color: ${theme.colors.N0};
      }
    }

    &.compact {
      label {
        font-weight: 800;
        font-size: 1rem;
        padding: ${theme.spacing.x1} ${theme.spacing.x2};
      }

      input[type='radio']:checked + label {
        color: ${theme.colors.B50};
      }
    }
  }

  &.icons-only {
    .radio-group {
      display: flex;
      align-items: center;
    }
    label {
      display: flex;
      align-items: center;
    }
  }
`;

const TermContainer = styled.div`
  padding-top: ${theme.spacing.x2};
  margin-top: ${theme.spacing.x2};
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.x2};
  border-top: 1px solid ${theme.colors.N10};
`;

const TermRow = styled.div`
  display: grid;
  gap: ${theme.spacing.x1};
  grid-template-columns: 2.4rem 1fr 2.4rem;
  align-items: center;

  &.flag {
    grid-template-columns: 2rem 1fr auto;
    grid-template-rows: 2.2rem;
  }
  &.metric {
    margin-left: ${theme.spacing.x4};
  }
  &.task {
    margin-left: ${theme.spacing.x8};
  }
  img {
    width: 100%;
  }
`;

const InlineHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

interface TemplateProps {
  templateId: string;
  setTemplate: void;
}

function ReferenceTemplate(props: TemplateProps) {
  const { templateId, setTemplate } = props;

  // Construct the query key using the plan Id
  const queryKeyTemplate = [queryKeys.publicTemplates, templateId];
  const staleTime = 0;

  // Get the template2 details
  useQuery(queryKeyTemplate, remoteApi.fetchPublicTemplateDetails, {
    staleTime,
    // @ts-ignore
    onSuccess: (response) => setTemplate(response.data),
  });

  return null;
}

interface Props {
  planId: string;
  isTemplate?: boolean;
}

function CreatePlan(props: Props) {
  const dispatch = useDispatch();
  const history = useHistory();
  const { planId, isTemplate } = props;
  const { t } = useTranslation();
  const translationKey = 'modals.createPlan';

  const currentWorkspace: Workspace = useSelector((state: any) => state.session.currentWorkspace, shallowEqual);
  const referenceTemplate = useSelector((state: any) => state.session.referenceTemplate);
  const isEmptyWorkspace = useSelector((state: any) => state.session.isEmptyWorkspace);

  const BetaKPIWorkspaces = process.env.REACT_APP_BETA_KPI_WORKSPACES
    ? process.env.REACT_APP_BETA_KPI_WORKSPACES.split(',')
    : [''];

  const isBetaKPIWorkspace =
    BetaKPIWorkspaces.includes(currentWorkspace.slug) || currentWorkspace.created_at > '2024-07-02';
  const defaultPlanType = isBetaKPIWorkspace ? '' : 'classic';

  const [title, setTitle] = useState('');
  const [plan_type, setPlanType] = useState(defaultPlanType);
  const [reminderConfig, setReminderConfig] = useState('weekly');
  const [timeline, setTimeline] = useState('current_quarter');
  const [customStartAt, setCustomStartAt] = useState(new Date());
  const [customFinishAt, setCustomFinishAt] = useState(new Date());
  const [template, setTemplate] = useState(null);
  const [globalPermissionType, setGlobalPermissionType] = useState('edit');

  const hasV4PremiumSubscription = workspaceUtils.hasV4PremiumSubscription(currentWorkspace);
  const hasV4Trial = workspaceUtils.hasV4Trial(currentWorkspace);

  // Set upgrade required to true
  let upgradeRequired = true;

  // Only Premium workspaces should have access to insights
  if (hasV4PremiumSubscription || hasV4Trial) {
    upgradeRequired = false;
  }

  const upgradeClass = upgradeRequired ? 'disabled' : '';

  // Mutation that will create the workspace in the backend
  const [createPlanMutation, { isLoading }]: [any, any] = useMutation(remoteApi.createPlan, {
    onSuccess: (response: any) => {
      const createdPlan = response.data;
      // If there's a template, we populate the content
      if (template) {
        const params = {
          planId: createdPlan.nano_slug,
          // @ts-ignore
          template: template.nano_slug,
        };
        populatePlanMutation(params);
      } else {
        // Otherwise, redirect to plan!
        const planRoute = routes.WORKSPACE_PLAN_WRITE_ROUTE.replace(':workspaceSlug', currentWorkspace.slug).replace(
          ':planId',
          createdPlan.nano_slug,
        );
        history.push(planRoute);

        // Clean up all existing template references once a plan is created
        localStorage.removeItem(REFERENCE_TEMPLATE_STORAGE_KEY);
        // @ts-ignore
        dispatch(setReferenceTemplate(null));

        // Close the modal
        dispatch(setGlobalModalContent(''));

        // Unflag the workspace as empty if it was
        if (isEmptyWorkspace) {
          dispatch(setIsEmptyWorkspace(false));
        }
      }
    },
  });

  // Functions to update the outcome
  const [populatePlanMutation, { isLoading: isPopulating }]: [any, any] = useMutation(remoteApi.populatePlan, {
    onSuccess: (_, params) => {
      const { planId } = params;
      const planRoute = routes.WORKSPACE_PLAN_WRITE_ROUTE.replace(':workspaceSlug', currentWorkspace.slug).replace(
        ':planId',
        planId,
      );
      history.push(planRoute);
      localStorage.removeItem(REFERENCE_TEMPLATE_STORAGE_KEY);
      // Clean up all existing template references once a plan is created
      // @ts-ignore
      dispatch(setReferenceTemplate(null));

      // Close the modal
      dispatch(setGlobalModalContent(''));

      // Unflag the workspace as empty if it was
      if (isEmptyWorkspace) {
        dispatch(setIsEmptyWorkspace(false));
      }
    },
  });

  // Get the starting date from the plan start_at and reminderConfig setting
  const getRemindersStartAt = (start_at: Date, reminderConfig: string) => {
    if (!start_at) {
      return null;
    }

    // Clone the starting date
    const date = new Date(start_at.getTime());

    // If we have a monthly frequency, return the first of the month
    if (['monthly', 'quarterly'].includes(reminderConfig)) {
      return addDays(
        new Date(date.getFullYear(), date.getMonth(), 1),
        currentWorkspace.monthly_reminder_day_difference,
      );
    }

    // If we're here, it's not either a weekly or fortnightly frequency

    // The date offset is the default weekly_reminder_day set at the workspace level.
    const dateOffset = currentWorkspace.weekly_reminder_day;

    // Calculate difference between current date and default reminder day.
    const daysUntilOffset = (dateOffset - date.getDay()) % 7;

    // Return the first "weekly_reminder_day" after the start_at date.
    return new Date(date.getFullYear(), date.getMonth(), date.getDate() + daysUntilOffset + 7);
  };

  const handleCreatePlan = (e: React.SyntheticEvent) => {
    e.preventDefault();
    let start_at = customStartAt;
    let finish_at = customFinishAt;
    let state = 'draft';

    if (timeline === 'current_quarter') {
      const refDate = Date.now();
      // @ts-ignore
      start_at = startOfQuarter(refDate);
      // @ts-ignore
      finish_at = endOfQuarter(refDate);
    }

    if (timeline === 'next_quarter') {
      const refDate = addMonths(Date.now(), 3); // Add 3 months to get the next quarter
      // @ts-ignore
      start_at = startOfQuarter(refDate);
      // @ts-ignore
      finish_at = endOfQuarter(refDate);
    }

    const reminders_start_at = getRemindersStartAt(start_at, reminderConfig);
    let reminders_period, reminders_interval;

    switch (reminderConfig) {
      case 'weekly':
        reminders_period = 'week';
        reminders_interval = 1;
        break;
      case 'fortnightly':
        reminders_period = 'week';
        reminders_interval = 2;
        break;
      case 'monthly':
        reminders_period = 'month';
        reminders_interval = 1;
        break;
      case 'quarterly':
        reminders_period = 'month';
        reminders_interval = 3;
        break;
    }

    let mutationParams: any = {
      workspaceSlug: currentWorkspace.slug,
      plan: {
        title,
        start_at,
        finish_at,
        parent_id: planId,
        reminders_interval,
        reminders_period,
        reminders_hour: currentWorkspace.weekly_reminder_hour,
        reminders_start_at,
        global_permission_type: globalPermissionType,
        plan_type,
        state,
      },
    };

    if (isTemplate) {
      mutationParams = {
        workspaceSlug: currentWorkspace.slug,
        plan: {
          title,
          state: 'template',
        },
      };
    }
    createPlanMutation(mutationParams);
  };

  const handleCancel = (e: React.SyntheticEvent) => {
    e.preventDefault();
    dispatch(setGlobalModalContent(''));
  };

  const handleChange = (e: any) => {
    const inputTarget = e.target;
    const value = inputTarget.type === 'checkbox' ? inputTarget.checked : inputTarget.value;
    const name = inputTarget.name;

    switch (name) {
      case 'title':
        setTitle(value);
        break;
      case 'plan_type':
        setPlanType(value);
        break;
      case 'timeline':
        setTimeline(value);
        break;
    }
  };

  const handleChangeStart = (date: any) => {
    setCustomStartAt(date);
  };
  const handleChangeFinish = (date: any) => {
    setCustomFinishAt(date);
  };

  const handleGlobalPermissionChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const global_permission_type = e.target.value;
    setGlobalPermissionType(global_permission_type);
  };

  const isDisabled = isPopulating || isLoading || !title;
  let placeholder = t(`${translationKey}.placeholder`, { workspace: currentWorkspace.name });
  if (plan_type === 'simplified') {
    placeholder = t(`${translationKey}.placeholderKPIs`, { workspace: currentWorkspace.name });
  }

  // Use the template layout if we're creating a template.
  if (isTemplate) {
    return (
      <ModalGrid>
        <ModalHeader>
          <h2>{t(`${translationKey}.templateTitle`)}</h2>
          <KoalaIconButton onClick={handleCancel} iconName="close" />
        </ModalHeader>
        <ModalContent>
          <Form onSubmit={handleCreatePlan}>
            <FormField>
              <label>{t(`${translationKey}.templateName`)}</label>
              <input
                type="text"
                required={true}
                autoComplete="off"
                onChange={handleChange}
                value={title}
                name="title"
                placeholder={placeholder}
              />
            </FormField>
          </Form>
        </ModalContent>
        <ModalFooter>
          <KoalaButton loading={isLoading || isPopulating} disabled={isDisabled} onClick={handleCreatePlan}>
            {t('shared.create')}
          </KoalaButton>
          &nbsp;
          <KoalaTextButton onClick={handleCancel}> {t('shared.cancel')}</KoalaTextButton>
        </ModalFooter>
      </ModalGrid>
    );
  }

  if (!plan_type) {
    const objectiveLabel = translate(currentWorkspace, CustomTermKey.OBJECTIVE, 1);
    const outcomeLabel = translate(currentWorkspace, CustomTermKey.OUTCOME, 1);
    const initiativeLabel = translate(currentWorkspace, CustomTermKey.INITIATIVE, 1);
    return (
      <ModalGrid>
        <ModalHeader>
          {planId && <h2>{t(`${translationKey}.subPlanTitle`)}</h2>}
          {!planId && <h2>{t(`${translationKey}.planTitle`)}</h2>}
          <KoalaIconButton onClick={handleCancel} iconName="close" />
        </ModalHeader>
        <ModalContent>
          <CustomRadioField>
            <label>{t('modals.createPlan.planTypeLabel')}</label>
            <div className="radio-group">
              <>
                <input
                  type="radio"
                  id="classic"
                  required
                  checked={plan_type === 'classic'}
                  name="plan_type"
                  value={'classic'}
                  onChange={handleChange}
                />
                <label htmlFor="classic">
                  <InlineHeader>
                    <span className="label">{t('shared.planTypes.classic')}</span>
                  </InlineHeader>
                  <br />
                  {t('shared.planTypes.classicInfo')}
                  <TermContainer>
                    <TermRow className="flag">
                      <img src={FlagIcon} alt="Flag" />
                      <b>{objectiveLabel}</b>
                    </TermRow>
                    <TermRow className="metric">
                      <img src={MetricIcon} alt="Metric" />
                      <p>{outcomeLabel}</p>
                      <img src={PersonIcon} alt="Person" />
                    </TermRow>
                    <TermRow className="task">
                      <img src={TasksIcon} alt="Task" />
                      <p>{initiativeLabel}</p>
                      <img src={PersonIcon} alt="Person" />
                    </TermRow>
                    <TermRow className="task">
                      <img src={TasksIcon} alt="Task" />
                      <p>{initiativeLabel}</p>
                      <img src={PersonIcon} alt="Person" />
                    </TermRow>
                  </TermContainer>
                </label>
              </>
              <>
                <input
                  type="radio"
                  id="simplified"
                  checked={plan_type === 'simplified'}
                  name="plan_type"
                  value={'simplified'}
                  onChange={handleChange}
                  disabled={upgradeRequired}
                />
                <label htmlFor="simplified" className={upgradeClass} data-tip data-for="simplified-metrics-upgrade">
                  <InlineHeader>
                    <span className="label">{t('shared.planTypes.simplified')}</span>
                    {hasV4Trial && <KoalaTextBadge variant="violet">Premium</KoalaTextBadge>}
                  </InlineHeader>

                  <br />
                  {t('shared.planTypes.simplifiedInfo')}
                  <TermContainer>
                    <TermRow className="flag">
                      <img src={FlagIcon} alt="Flag" />
                      <b>{t('shared.terms.section')}</b>
                    </TermRow>
                    <TermRow className="metric">
                      <img src={MetricIcon} alt="Metric" />
                      <p>{t('shared.terms.metric')}</p>
                      <img src={PersonIcon} alt="Person" />
                    </TermRow>
                    <TermRow className="metric">
                      <img src={MetricIcon} alt="Task" />
                      <p>{t('shared.terms.metric')}</p>
                      <img src={PersonIcon} alt="Person" />
                    </TermRow>
                    <TermRow className="metric">
                      <img src={MetricIcon} alt="Task" />
                      <p>{t('shared.terms.metric')}</p>
                      <img src={PersonIcon} alt="Person" />
                    </TermRow>
                  </TermContainer>
                </label>
              </>
            </div>
          </CustomRadioField>
          {upgradeRequired && (
            <ReactTooltip
              place="bottom"
              type="dark"
              className="tooltip"
              effect="solid"
              id="simplified-metrics-upgrade"
              delayHide={500}
            >
              {t('modals.upgradeRequired.title')}
            </ReactTooltip>
          )}
        </ModalContent>
        <ModalFooter>
          <KoalaButton loading={isLoading || isPopulating} disabled={isDisabled} onClick={handleCreatePlan}>
            {t('shared.create')}
          </KoalaButton>
          &nbsp;
          <KoalaTextButton onClick={handleCancel}> {t('shared.cancel')}</KoalaTextButton>
        </ModalFooter>
      </ModalGrid>
    );
  }

  // Normal plan creation layout.
  return (
    <ModalGrid>
      {referenceTemplate && (
        // @ts-ignore
        <ReferenceTemplate templateId={referenceTemplate} setTemplate={setTemplate} />
      )}
      <ModalHeader>
        {planId && <h2>{t(`${translationKey}.subPlanTitle`)}</h2>}
        {!planId && <h2>{t(`${translationKey}.planTitle`)}</h2>}
        <KoalaIconButton onClick={handleCancel} iconName="close" />
      </ModalHeader>
      <ModalContent>
        <Form onSubmit={handleCreatePlan}>
          {isBetaKPIWorkspace && (
            <FormField>
              <label>{t('modals.createPlan.planTypeLabel')}</label>
              <InputLine>
                {planUtils.planTypeToLabel(plan_type, t)}
                <KoalaButton onClick={() => setPlanType('')} size="small" appearance="secondary">
                  {t('shared.change')}
                </KoalaButton>
              </InputLine>
            </FormField>
          )}
          <FormField>
            {planId && <label>{t(`${translationKey}.subPlanName`)}</label>}
            {!planId && <label>{t(`${translationKey}.planName`)}</label>}

            <input
              type="text"
              required={true}
              autoComplete="off"
              onChange={handleChange}
              value={title}
              name="title"
              placeholder={placeholder}
            />
          </FormField>
          {template && (
            <FormField>
              <label>{t(`${translationKey}.selectedTemplate`)}</label>
              <TemplateLabel>
                {
                  // @ts-ignore
                  template.title
                }
              </TemplateLabel>
              <small className="subtle">{t(`${translationKey}.editLater`)}</small>
            </FormField>
          )}
          <FormField>
            <label>{t(`${translationKey}.checkinReminders`)}</label>
            <select value={reminderConfig} onChange={(e) => setReminderConfig(e.currentTarget.value)}>
              <option value="weekly">{t(`${translationKey}.weekly`)}</option>
              <option value="fortnightly">{t(`${translationKey}.fortnightly`)}</option>
              <option value="monthly">{t(`${translationKey}.monthly`)}</option>
              <option value="quarterly">{t(`${translationKey}.quarterly`)}</option>
            </select>
          </FormField>
          <FormField>
            <label>{t(`${translationKey}.plan`)}</label>
            <RadioField>
              <div className="radio-group">
                <input
                  type="radio"
                  id="current_quarter"
                  checked={timeline === 'current_quarter'}
                  name="timeline"
                  value={'current_quarter'}
                  onChange={handleChange}
                />
                <label htmlFor="current_quarter" className="current_quarter">
                  {t(`shared.timelineThisQuarter`)}
                </label>
                <input
                  type="radio"
                  id="next_quarter"
                  checked={timeline === 'next_quarter'}
                  name="timeline"
                  value={'next_quarter'}
                  onChange={handleChange}
                />
                <label htmlFor="next_quarter" className="next_quarter">
                  {t(`shared.timelineNextQuarter`)}
                </label>
                <input
                  type="radio"
                  id="custom"
                  checked={timeline === 'custom'}
                  name="timeline"
                  value={'custom'}
                  onChange={handleChange}
                />
                <label htmlFor="custom" className="custom">
                  {t(`shared.timelineCustom`)}
                </label>
              </div>
            </RadioField>
          </FormField>
          {timeline === 'custom' && (
            <FormField>
              <InputLine>
                <InputBlock>
                  <label>{t(`shared.planStart`)}</label>
                  <DatePicker
                    selected={customStartAt}
                    onChange={handleChangeStart}
                    popperPlacement="top"
                    dateFormat="d MMM yyyy"
                  />
                </InputBlock>
                <InputBlock>
                  <label>{t(`shared.planFinish`)}</label>
                  <DatePicker
                    selected={customFinishAt}
                    onChange={handleChangeFinish}
                    popperPlacement="top"
                    dateFormat="d MMM yyyy"
                  />
                </InputBlock>
              </InputLine>
            </FormField>
          )}
          <FormField>
            <InputLine>
              <InputBlock>
                <label>{t('modals.editPlanPermissions.permissions')}</label>
                <PermissionRow>
                  <select
                    value={globalPermissionType}
                    onChange={handleGlobalPermissionChange}
                    data-tip
                    data-for="globalPermissionSelector"
                  >
                    <option value="edit">{t(`${translationKey}.everyoneCanEdit`)}</option>
                    <option value="view">{t(`${translationKey}.everyoneCanView`)}</option>
                    <option value="none">{t(`${translationKey}.everyoneNoAccess`)}</option>
                  </select>
                </PermissionRow>
                {globalPermissionType === 'view' && (
                  <small className="subtle">{t(`${translationKey}.permissionViewInfo`)}</small>
                )}
                {globalPermissionType === 'none' && (
                  <small className="subtle">{t(`${translationKey}.permissionNoneInfo`)}</small>
                )}
              </InputBlock>
            </InputLine>
          </FormField>
        </Form>
      </ModalContent>
      <ModalFooter>
        <KoalaButton loading={isLoading || isPopulating} disabled={isDisabled} onClick={handleCreatePlan}>
          {t('shared.create')}
        </KoalaButton>
        <KoalaTextButton onClick={handleCancel}> {t('shared.cancel')}</KoalaTextButton>
      </ModalFooter>
    </ModalGrid>
  );
}

export default CreatePlan;
