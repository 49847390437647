import React, { useEffect } from 'react';
import styled from 'styled-components';
import * as TabilityTypes from 'types';
import { useInfiniteQuery } from 'react-query';
import queryKeys from 'config/queryKeys';
import { useHistory, useLocation } from 'react-router-dom';
import parse from 'parse-link-header';
import { useTranslation } from 'react-i18next';
import { AxiosResponse } from 'axios';
import { shallowEqual, useSelector } from 'react-redux';

// API
import * as remoteApi from 'api/remote';

// Components
import theme from 'theme';
import { CustomTermKey, translate } from 'utils/customTermUtils';
import InitiativeRow from 'components/InitiativeRow';
import KoalaLoader from 'koala/components/Loader';
import KoalaButton from 'koala/components/Button';
import { Header } from 'components/WorkspaceHeader/_Notifications/NotificationLayout';

export const BlockContent = styled.div`
  font-weight: 700;
  grid-area: content;
  background: transparent;
  margin: 0;
  height: 100%;

  display: flex;
  align-items: center;
`;

export const BlockMeta = styled.div`
  font-weight: 700;
  grid-area: meta;
  display: flex;
  align-items: center;
  height: 100%;
`;

const InitiativeList = styled.div`
  .item {
    border-bottom: 1px solid ${theme.colors.N20};
  }
  .item:last-of-type {
    border: none;
  }

  .initiative-owners {
    display: none;
  }
  .initiative-due-date {
    display: flex;
  }
`;

const LoadContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1.2rem ${theme.spacing.x2};
`;

const EmptyState = styled.div`
  padding: 1.2rem;
`;

const getNextPage = (response: any) => {
  if (response && response.headers && response.headers.link) {
    const links = response.headers.link;
    const parsed = parse(links);
    if (parsed && parsed.next) {
      return parsed.next.page;
    }
  }
  return null;
};

interface Props {
  currentMembership: TabilityTypes.Membership;
  setInitiativesLoading: Function;
  showContributingInitiatives: boolean;
}

function ActiveInitiativesList(props: Props) {
  // Query that fetches all the initiatives with pending checkins
  const location = useLocation();
  const { t } = useTranslation();
  const history = useHistory();

  const workspace: TabilityTypes.Workspace = useSelector((state: any) => state.session.currentWorkspace, shallowEqual);
  const { currentMembership, showContributingInitiatives, setInitiativesLoading } = props;
  const activeInitiativesQueryKey = [queryKeys.initiatives, currentMembership.id, showContributingInitiatives];

  const {
    isLoading,
    data: initiativeResponse,
    canFetchMore,
    fetchMore,
  } = useInfiniteQuery(activeInitiativesQueryKey, remoteApi.fetchMembershipActiveInitiatives, {
    getFetchMore: (lastGroup, allGroups) => {
      return getNextPage(lastGroup);
    },
  });

  useEffect(() => {
    setInitiativesLoading(isLoading);
  }, [setInitiativesLoading, isLoading]);

  if (isLoading) {
    return <KoalaLoader />;
  }

  const isBlockSelected = (blockId: string) => {
    return location.hash.includes(blockId);
  };

  // Display empty state if you're at inbox zero
  const intiativesLabel = translate(workspace, CustomTermKey.INITIATIVE, 2);

  const hasInitiative =
    !initiativeResponse || initiativeResponse.length === 0 || initiativeResponse[0].data.length === 0;

  if (hasInitiative && showContributingInitiatives) {
    return <></>;
  }

  if (hasInitiative) {
    if (showContributingInitiatives) {
      return (
        <EmptyState>
          <p className="subtle">
            {t('workspaceInbox.initiatives.emptyContributor', {
              initiatives: intiativesLabel,
            })}
          </p>
        </EmptyState>
      );
    }
    return (
      <EmptyState>
        <p className="subtle">{t('workspaceInbox.initiatives.emptyOwner', { initiatives: intiativesLabel })}</p>
      </EmptyState>
    );
  }

  return (
    <InitiativeList className="item-list">
      {showContributingInitiatives && (
        <Header>
          <h4>{t('workspaceInbox.contributing')}</h4>
        </Header>
      )}
      {initiativeResponse.map((group: AxiosResponse<TabilityTypes.Initiative[]>) =>
        group.data.map((initiative) => {
          const blockId = `initiative:${initiative.nano_slug}`;
          const isSelected = isBlockSelected(blockId);

          const showHashRoute = `#${blockId}:show`;
          const handleShowInitiative = (e: any) => {
            e.preventDefault();
            e.stopPropagation();
            history.push(showHashRoute);
          };
          return (
            <InitiativeRow
              key={initiative.id}
              initiative={initiative}
              isSelected={isSelected}
              handleClick={handleShowInitiative}
              showMeta
            />
          );
        }),
      )}
      {canFetchMore && (
        <LoadContainer>
          <KoalaButton appearance="secondary" onClick={() => fetchMore()} loading={isLoading} disabled={isLoading}>
            {t(`shared.loadMore`)}
          </KoalaButton>
        </LoadContainer>
      )}
    </InitiativeList>
  );
}

export default ActiveInitiativesList;
