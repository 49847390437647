import React from 'react';
import styled from 'styled-components';
import theme from 'theme';
import KoalaButton from 'koala/components/Button';
import UserSearchableDropdown from 'components/UserSearchableDropdown';
import { useTranslation } from 'react-i18next';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: ${theme.spacing.x2} 0;
`;

interface Props {
  handleAddWidget: (type: string, id: string, source: string) => void;
  isLoading: boolean;
}

function UserProfileWidgetDetails(props: Props) {
  const { handleAddWidget, isLoading } = props;
  const { t } = useTranslation();

  const handleSelectMembership = (value: string) => {
    handleAddWidget('user-profile', value, 'membership');
  };

  return (
    <Container>
      <UserSearchableDropdown
        trigger={
          <KoalaButton loading={isLoading} prefixIcon="personPlus">
            <span className="owner-option-name">{t('workspaceDashboards.selectUser')}</span>
          </KoalaButton>
        }
        callback={handleSelectMembership}
        position="left"
      />
    </Container>
  );
}

export default UserProfileWidgetDetails;
