import React, { Fragment, useState } from 'react';
import styled from 'styled-components';
import * as TabilityTypes from 'types';
import { useQuery, useMutation, useQueryCache } from 'react-query';
import queryKeys from 'config/queryKeys';
import theme from 'theme';
import { Prompt } from 'react-router-dom';
import { Helmet } from 'react-helmet';

// Utils
import * as workspaceUtils from 'utils/workspaceUtils';

// API
import * as remoteApi from 'api/remote';

// Components
import { ChromeContent, ChromeSideNav } from 'components/Chrome';
import SettingsNarrowWrapper from 'components/SettingsNarrowWrapper';
import ContentTitle from 'components/ContentTitle';
import CopyTextInput from 'components/CopyTextInput';
import FormField from 'components/FormField';
import Loader from 'components/Loader';
import WorkspaceSettingsNav from 'components/WorkspaceSettingsNav';

// API
import { fetchWorkspaceAdminDetails } from 'api/remote';
import KoalaButton from 'koala/components/Button';
import { useTranslation } from 'react-i18next';

const Form = styled.form`
  display: flex;
  flex-direction: column;

  .session_duration {
    width: 10rem;
    margin-right: ${theme.spacing.x1};
  }
`;

const ErrorList = styled.ul`
  margin-bottom: ${theme.spacing.x2};
  color: #d0402e;
`;

const Actions = styled.div`
  display: flex;
  align-items: center;
  button {
    margin-right: ${theme.spacing.x2};
  }
`;

interface Props {
  workspace: TabilityTypes.Workspace;
}

const staleTime = 0;

function WorkspaceSettingsDetails(props: Props) {
  const queryCache = useQueryCache();
  const { workspace } = props;
  const queryKey = [queryKeys.currentWorkspace, workspace.slug, 'admin'];
  const { t } = useTranslation();
  const [accepted_domains, setAcceptedDomains] = useState('');
  const [invites_restricted, setInvitesRestricted] = useState('');
  const [discovery_enabled, setDiscoveryEnabled] = useState('');
  const [invite_link_token, setInviteLinkToken] = useState('');
  const [invite_link_token_enabled, setInviteLinkTokenEnabled] = useState('');
  const [session_timeout_enabled, setSessionTimeoutEnabled] = useState('');
  const [session_duration, setSessionDuration] = useState(1);
  const [isFormSaved, setIsFormSaved] = useState(false);
  const [isFormDirty, setIsFormDirty] = useState(false);

  const { isFetching: isInitialFetch }: any = useQuery(queryKey, fetchWorkspaceAdminDetails, {
    staleTime,
    onSuccess: (response) => {
      const workspace = response.data;
      setAcceptedDomains(workspace.accepted_domains.toString());
      setInviteLinkToken(workspace.invite_link_token);
      setInviteLinkTokenEnabled(workspace.invite_link_token_enabled.toString());
      setDiscoveryEnabled(workspace.discovery_enabled.toString());
      setInvitesRestricted(workspace.invites_restricted.toString());
      setSessionDuration(workspace.session_duration);
      setSessionTimeoutEnabled(workspace.session_timeout_enabled.toString());
      setSessionDuration(workspace.session_duration);
    },
  });

  // Workspace mutations
  const [updateWorkspaceMutation, { isError, isLoading, error }]: [any, any] = useMutation(remoteApi.updateWorkspace, {
    onMutate: () => {
      setIsFormSaved(false);
    },
    onSuccess: () => {
      setIsFormSaved(true);
      setIsFormDirty(false);
    },
  });

  // Workspace mutations
  const [resetWorkspaceInviteLinkMutation, { isLoading: resetIsLoading }]: [any, any] = useMutation(
    remoteApi.resetWorkspaceInviteLink,
    {
      onSuccess: () => {
        queryCache.invalidateQueries(queryKey);
      },
    },
  );

  let errors: any = {}; // UI Errors

  // Errors for the workspace creation are returned as a hash
  if (isError) {
    if (error.response.status === 500) {
      errors = {
        server: [t('error.serverError')],
      };
    } else {
      errors = error.response ? error.response.data : {};
    }
  }

  const handleChange = (e: any) => {
    const inputTarget = e.target;
    const value = inputTarget.type === 'checkbox' ? inputTarget.checked : inputTarget.value;
    const name = inputTarget.name;
    setIsFormDirty(true);
    setIsFormSaved(false);

    switch (name) {
      case 'accepted_domains':
        setAcceptedDomains(value);
        break;
      case 'invites_restricted':
        setInvitesRestricted(value);
        break;
      case 'invite_link_token_enabled':
        setInviteLinkTokenEnabled(value);
        break;
      case 'discovery_enabled':
        setDiscoveryEnabled(value);
        break;
      case 'session_timeout_enabled':
        setSessionTimeoutEnabled(value);
        break;
      case 'session_duration':
        setSessionDuration(value);
        break;
    }
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    const new_accepted_domains = accepted_domains ? accepted_domains.replace(' ', '').split(',') : [];
    const timeout_enabled = session_timeout_enabled === 'true';
    const params = {
      accepted_domains: new_accepted_domains,
      discovery_enabled,
      invite_link_token_enabled,
      invites_restricted,
      session_duration,
      session_timeout_enabled: timeout_enabled,
    };
    updateWorkspaceMutation({
      workspaceId: workspace.id,
      workspace: params,
    });
  };

  const handleResetInviteLink = (e: any) => {
    e.preventDefault();
    resetWorkspaceInviteLinkMutation(workspace.slug);
  };

  // This function is used to turn the error hash keys into proper strings.
  const errorKeyToString = (errorKey: string): string => {
    switch (errorKey) {
      case 'accepted_domains':
        return t('errors.acceptedDomains');
      case 'restricted_domains':
        return t('errors.restrictedDomains');
      case 'restricted_ips':
        return t('errors.restrictedIPs');
      case 'invite_link_token_enabled':
        return t('errors.invitationLink');
      case 'discovery_enabled':
        return t('errors.workspaceDiscovery');
      case 'invites_restricted':
        return t('errors.invitations');
      default:
        return '';
    }
  };

  // Error classnames used to highlight input fields that have errors
  const acceptedDomainClassname = errors['accepted_domains'] ? 'error' : '';
  const discoveryEnabledClassname = errors['discovery_enabled'] ? 'error' : '';
  const inviteLinkTokenEnabledClassname = errors['invite_link_token_enabled'] ? 'error' : '';
  const sessionTimeoutEnabledClassname = errors['session_timeout_enabled'] ? 'error' : '';
  const sessionDurationClassname = errors['session_duration'] ? 'error' : '';
  const invitesRestrictedClassname = errors['invites_restricted'] ? 'error' : '';

  const inviteLink = `https://${process.env.REACT_APP_DOMAIN}/invite/${invite_link_token}`;

  const resetText = resetIsLoading ? <Loader size="small" /> : t('workspaceSettingsSecurity.resetInviteLink');

  const showSessionTimeout = workspace.pricing_version === 3 || workspaceUtils.hasV4PremiumSubscription(workspace);
  return (
    <Fragment>
      <Helmet>
        <title>
          {workspace.name} | {t('workspaceSettingsSecurity.title')} | Tability
        </title>
      </Helmet>
      <Prompt
        message={t('shared.confirmLeave') ?? 'Your changes are not saved. Are you sure you want to leave?'}
        when={isFormDirty}
      />
      <ChromeSideNav>
        <WorkspaceSettingsNav />
      </ChromeSideNav>
      <ChromeContent>
        <SettingsNarrowWrapper>
          <ContentTitle>
            <h1>
              {t('workspaceSettingsSecurity.title')}&nbsp;
              {isInitialFetch && <Loader size="small" />}
            </h1>
          </ContentTitle>
          <Form onSubmit={handleSubmit}>
            <FormField>
              <label>{t('workspaceSettingsSecurity.domains')}</label>
              <small className="light">{t('workspaceSettingsSecurity.domainsInfo')}</small>
              <input
                type="text"
                name="accepted_domains"
                className={`${acceptedDomainClassname} small`}
                autoComplete="off"
                placeholder={t('workspaceSettingsSecurity.domains') ?? 'Accepted domains'}
                value={accepted_domains}
                required={false}
                onChange={handleChange}
              />
            </FormField>
            {workspace.billing_has_subscription && (
              <FormField>
                <label>{t('workspaceSettingsSecurity.discovery')}</label>
                <small className="light">{t('workspaceSettingsSecurity.discoveryInfo')}</small>
                <select
                  value={discovery_enabled}
                  onChange={handleChange}
                  className={`${discoveryEnabledClassname}`}
                  name="discovery_enabled"
                >
                  <option value={'true'}>{t('shared.enabled')}</option>
                  <option value={'false'}>{t('shared.disabled')}</option>
                </select>
              </FormField>
            )}

            <FormField>
              <label>{t('workspaceSettingsSecurity.invitesRestricted')}</label>
              <select
                value={invites_restricted}
                onChange={handleChange}
                className={`${invitesRestrictedClassname}`}
                name="invites_restricted"
              >
                <option value={'true'}>{t('shared.true')}</option>
                <option value={'false'}>{t('shared.false')}</option>
              </select>
            </FormField>
            <FormField>
              <label>{t('workspaceSettingsSecurity.inviteLink')}</label>
              <small className="light">{t('workspaceSettingsSecurity.inviteLinkInfo')}</small>
              <select
                value={invite_link_token_enabled}
                onChange={handleChange}
                className={`${inviteLinkTokenEnabledClassname}`}
                name="invite_link_token_enabled"
              >
                <option value={'true'}>{t('shared.enabled')}</option>
                <option value={'false'}>{t('shared.disabled')}</option>
              </select>
            </FormField>
            {invite_link_token_enabled === 'true' && (
              <Fragment>
                <FormField>
                  <label>{t('workspaceSettingsSecurity.inviteUrl')}</label>
                  <small className="light">{t('workspaceSettingsSecurity.inviteUrlInfo')}</small>
                  <CopyTextInput text={inviteLink} />
                  <KoalaButton onClick={handleResetInviteLink} disabled={resetIsLoading} appearance="subtle">
                    {resetText}
                  </KoalaButton>
                </FormField>
              </Fragment>
            )}
            {showSessionTimeout && (
              <>
                <h3>{t('workspaceSettingsSecurity.sessionTimeout')}</h3>
                <FormField>
                  <small className="light">{t('workspaceSettingsSecurity.sessionTimeoutInfo')}</small>
                  <select
                    value={session_timeout_enabled}
                    onChange={handleChange}
                    className={`${sessionTimeoutEnabledClassname}`}
                    name="session_timeout_enabled"
                  >
                    <option value={'true'}>{t('shared.enabled')}</option>
                    <option value={'false'}>{t('shared.disabled')}</option>
                  </select>
                </FormField>
                <FormField>
                  <label>{t('workspaceSettingsSecurity.sessionDuration')}</label>
                  <small className="light">{t('workspaceSettingsSecurity.sessionDurationInfo')}</small>
                  <input
                    type="number"
                    name="session_duration"
                    className={`${sessionDurationClassname} small session_duration`}
                    value={session_duration}
                    required={false}
                    onChange={handleChange}
                    min={1}
                    disabled={session_timeout_enabled === 'false'}
                  />
                  {t('shared.time.minute', { count: 2 })}
                </FormField>
              </>
            )}
            {isError && (
              <ErrorList>
                {Object.keys(errors).map((errorKey: string) => {
                  // Get the first error for the key
                  // NOTE: there might be more! I'm just keeping the UI simpler right now.
                  const error: string = errors[errorKey][0];
                  return (
                    <li>
                      {errorKeyToString(errorKey)} {error.toLowerCase()}
                    </li>
                  );
                })}
              </ErrorList>
            )}
            <Actions>
              <KoalaButton submit disabled={isLoading} loading={isLoading}>
                {t('shared.save')}
              </KoalaButton>
              {isFormSaved && <div>{t('shared.saved')}</div>}
            </Actions>
          </Form>
        </SettingsNarrowWrapper>
      </ChromeContent>
    </Fragment>
  );
}

export default WorkspaceSettingsDetails;
