import React from 'react';
import styled from 'styled-components';
import { useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';
import { NavLink, useHistory } from 'react-router-dom';

// API
import * as remoteApi from 'api/remote';

// Components
import theme from 'theme';
import queryKeys from 'config/queryKeys';
import WorkspaceAvatar from 'components/WorkspaceAvatar';
import { DASHBOARD_WORKSPACES_NEW_ROUTE } from 'routes';
import KoalaIcon from 'koala/components/Icons';
import { Membership } from 'types';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  width: 100%;
  height: 100%;
  max-width: 95rem;
  position: relative;
  padding: ${theme.spacing.x4} ${theme.spacing.x2};
  gap: ${theme.spacing.x2};
`;

const WorkspaceList = styled.div`
  display: flex;
  flex-direction: column;
  overflow: auto;
  border: 1px solid ${theme.colors.N20};
  border-radius: 8px;
  height: 100%;
`;

const Footer = styled.div`
  margin: ${theme.spacing.x1} 0;
  button {
    display: flex;
    align-items: center;
    border: 0;
    background: transparent;
    opacity: 0.7;
    transition: 0s;
    svg {
      width: 1.6rem;

      g {
        stroke: #60aeb9;
      }
      margin-right: ${theme.spacing.x1};
    }

    &:hover {
      opacity: 1;
      background: transparent;
      text-decoration: underline;
    }
  }
`;

const SidebarButton = styled(NavLink)`
  background: transparent;
  color: currentColor;
  padding: 8px;
  display: flex;
  font-weight: 400;
  font-size: 1.4rem;
  align-items: center;
  display: flex;
  gap: ${theme.spacing.x2};

  &:hover,
  &.active {
    text-decoration: none;
  }
`;

interface Props {}

function WorkspaceDeleted(props: Props) {
  const { t } = useTranslation();
  const { data: membershipsResponse }: any = useQuery(
    queryKeys.currentUserMemberships,
    remoteApi.fetchCurrentUserMemberships,
    {
      staleTime: queryKeys.staleTime,
    },
  );
  const history = useHistory();

  const handleAddWorkspace = (e: any) => {
    e.preventDefault();
    history.push(DASHBOARD_WORKSPACES_NEW_ROUTE);
  };

  let memberships: Membership[] = [];

  if (membershipsResponse && membershipsResponse.data) {
    memberships = membershipsResponse.data;
  }

  return (
    <Container>
      <h3>{t('workspaceDeleted.title')}</h3>
      <p>{t('workspaceDeleted.info')}</p>
      <WorkspaceList>
        {memberships.map((membership) => {
          const { workspace } = membership;
          if (!workspace) {
            return <></>;
          }
          return (
            <SidebarButton className="workspace-link" to={`/${workspace.slug}`} key={workspace.id}>
              <WorkspaceAvatar workspace={workspace} />
              <span>{workspace.name}</span>
            </SidebarButton>
          );
        })}
        <Footer>
          <button onClick={handleAddWorkspace}>
            <KoalaIcon iconName="plus" iconAppearance="currentColor" />
            {t('sidebar.newWorkspace')}
          </button>
        </Footer>
      </WorkspaceList>
    </Container>
  );
}

export default WorkspaceDeleted;
