import React, { ChangeEvent, useState } from 'react';
import * as TabilityTypes from 'types';

import { useQuery, useMutation, useQueryCache } from 'react-query';
import queryKeys from 'config/queryKeys';

// API
import * as remoteApi from 'api/remote';

import FormField from 'components/FormField';
import KoalaButton from 'koala/components/Button';
import KoalaLoader from 'koala/components/Loader';
import { useTranslation } from 'react-i18next';
import { toNumber } from 'lodash';
import KoalaInputValidation from 'koala/components/InputValidation';
import KoalaSelect, { KoalaSelectOption } from 'koala/components/Select';
import styled from 'styled-components';
import { shallowEqual, useSelector } from 'react-redux';

const ErrorContainer = styled.small`
  color: #d0402e;
`;
interface CurrentValueProps {
  outcome: TabilityTypes.Outcome;
}

// Component that loads the current value of from the data source
function CurrentValue(props: CurrentValueProps) {
  const { outcome } = props;
  const { t } = useTranslation();
  // Query keys and query params
  const outcomeBigQuery = outcome.data_source_meta?.sql || '';
  const queryKey = [
    queryKeys.currentOutcome,
    outcome.id,
    `data_source:big_query:sql${encodeURIComponent(outcomeBigQuery)}`,
    'current',
  ];
  const staleTime = 300;

  const { data: dataResponse, isLoading } = useQuery(queryKey, remoteApi.fetchOutcomeDataSourceCurrentValue, {
    staleTime,
  });

  if (isLoading) {
    return (
      <FormField>
        <KoalaLoader />
      </FormField>
    );
  }

  const currentValue = dataResponse?.data.result !== null ? toNumber(dataResponse?.data.result) : null;

  if (currentValue === null || currentValue === undefined) {
    return <FormField>{t(`panels.editOutcome.errorFetching`)}</FormField>;
  }

  return (
    <FormField>
      <label>{t('modals.dataConnectors.preview')}</label>
      <p>{currentValue}</p>
    </FormField>
  );
}

interface Props {
  outcome: TabilityTypes.Outcome;
  integration: any;
  workspaceSlug: string;
}

function BigQuerySource(props: Props) {
  const { outcome, workspaceSlug } = props;
  const { t } = useTranslation();
  const [projectOptions, setProjectOptions] = useState<KoalaSelectOption[]>([]);
  const [projectError, setProjectErrors] = useState('');
  const [query, setQuery] = useState((outcome.data_source_meta && outcome.data_source_meta.sql) ?? '');
  const [project, setProject] = useState<string>(outcome.data_source_meta ? outcome.data_source_meta.project_id : '');
  const [error, setError] = useState('');
  const outcomeSQL = outcome.data_source_meta?.sql || '';
  const currentMembership: TabilityTypes.Membership = useSelector(
    (state: any) => state.session.currentMembership,
    shallowEqual,
  );

  const queryCache = useQueryCache();

  // Functions to update the outcome
  const [updateOutcomeDataSourceMutation, { isLoading: isUpdating }] = useMutation(remoteApi.updateOutcomeDataSource, {
    onSuccess: () => {
      queryCache.invalidateQueries(queryKeys.currentOutcome);
    },
  });

  const staleTime = 0;
  const queryKeyBigQuery = ['big_query_projects', workspaceSlug];
  const { isLoading } = useQuery(queryKeyBigQuery, remoteApi.listBigQueryProjects, {
    staleTime,
    onSuccess: (response) => {
      if (!response.data?.projects) {
        setProjectOptions([]);
        return;
      }
      const options: KoalaSelectOption[] = response.data.projects.map((project: any) => ({
        label: `${project.friendlyName} (${project.id})`,
        value: project.id,
      }));
      setProjectOptions(options);
    },
    onError: (error: any) => {
      const errorString = error.response.data.error;
      setProjectErrors(errorString);
    },
  });

  const handleUpdate = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    // Prevent saving if query doesn't have a select
    const selectPattern = /^SELECT\b/i; // Check for 'SELECT' at the start
    const semicolonPattern = /;/; // Check for the presence of a semicolon

    if (!selectPattern.test(query)) {
      setError('SQL must be a SELECT query');
      return;
    }
    if (semicolonPattern.test(query)) {
      setError('SQL cannot contain a ;');
      return;
    }
    let body = {
      data_source_origin: 'big_query',
      data_source_type: 'sql',
      data_source_meta: { project_id: project, sql: query, membership_id: currentMembership.id },
    };

    const mutationParams = {
      outcomeId: outcome.nano_slug,
      body,
    };
    updateOutcomeDataSourceMutation(mutationParams);
  };

  const handleUpdateQuery = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setQuery(value);
    setError('');
  };

  const onProjectChange = (option: any) => {
    const value = option ? option.value : null;
    setProject(value);
  };

  const selectedOption = projectOptions.find((option) => option.value === project);

  const mySQLKey = `modals.dataConnectors.SQLData`;
  const placeholder = t(`${mySQLKey}.placeholder`) ?? 'Write your SQL here';

  const projectPlaceholder = t(`${mySQLKey}.projectPlaceholder`) ?? 'Select project';

  return (
    <>
      <FormField>
        <FormField>
          <label>
            Select Project
            {isLoading && <KoalaLoader />}
          </label>
          {!isLoading && (
            <div style={{ width: '50%' }}>
              <KoalaSelect
                handleChange={(options) => onProjectChange(options)}
                placeholder={projectPlaceholder}
                selectedOption={selectedOption}
                options={projectOptions}
                className="small"
              />
              {projectError && <ErrorContainer>{projectError}</ErrorContainer>}
            </div>
          )}
        </FormField>

        <label>{t(`${mySQLKey}.query`)}</label>
        <input value={query} onChange={handleUpdateQuery} placeholder={placeholder} />
        <small>{t(`${mySQLKey}.queryInfo`)}</small>
        <small>{t(`${mySQLKey}.bigQueryWarning`)}</small>
        {error && <KoalaInputValidation state="error" text={error} />}
      </FormField>
      <FormField>
        <KoalaButton
          onClick={handleUpdate}
          loading={isUpdating}
          disabled={!query || isUpdating || !!error}
          appearance="subtle"
          type="button"
        >
          {t(`panels.editOutcome.savePreview`)}
        </KoalaButton>
      </FormField>
      {outcome.data_source_origin === 'big_query' && outcomeSQL === query && <CurrentValue outcome={outcome} />}
    </>
  );
}

export default React.memo(BigQuerySource);
