import FormField from 'components/FormField';
import React, { useEffect, useState } from 'react';
import * as remoteApi from 'api/remote';
import KoalaSelect, { KoalaSelectOption } from 'koala/components/Select';
import { CustomTermKey, translate } from 'utils/customTermUtils';
import { shallowEqual, useSelector } from 'react-redux';
import { useQuery } from 'react-query';
import queryKeys from 'config/queryKeys';
import { Objective } from 'types';
import KoalaLoader from 'koala/components/Loader';
import { useTranslation } from 'react-i18next';

interface Props {
  planId: string;
  setObjectiveId: (value: string) => void;
}

function ObjectiveSelect(props: Props) {
  const { setObjectiveId, planId } = props;
  const { t } = useTranslation();
  const [selectedObjectiveOption, setSelectedObjectiveOption] = useState(null);
  const currentWorkspace = useSelector((state: any) => state.session.currentWorkspace, shallowEqual);
  const handleSelection = (selected: any) => {
    setSelectedObjectiveOption(selected);
    setObjectiveId(selected.value);
  };

  useEffect(() => {
    setSelectedObjectiveOption(null);
  }, [planId]);

  // Get the objectives
  const { isLoading: objectivesAreLoading, data: objectivesResponse } = useQuery(
    [queryKeys.objectives, planId],
    remoteApi.fetchPlanObjectives,
    {
      staleTime: 0,
    },
  );

  const objectiveOptions: KoalaSelectOption[] = objectivesResponse?.data.map((obj: Objective) => ({
    label: obj.title,
    value: obj.id,
  }));

  if (objectivesAreLoading) {
    return (
      <FormField>
        <label>{translate(currentWorkspace, CustomTermKey.OBJECTIVE, 1)}</label>
        <KoalaLoader />
      </FormField>
    );
  }

  const label = translate(currentWorkspace, CustomTermKey.OBJECTIVE, 1).toLowerCase();
  const placeholder = t(`workspaceDashboards.placeholder`, { item: label });

  return (
    <FormField>
      <label>{translate(currentWorkspace, CustomTermKey.OBJECTIVE, 1)}</label>
      <KoalaSelect
        handleChange={handleSelection}
        placeholder={placeholder ?? `Select ${label} or type to search`}
        selectedOption={selectedObjectiveOption}
        isClearable
        options={objectiveOptions}
        size="small"
        id="objective"
      />
    </FormField>
  );
}
export default ObjectiveSelect;
