import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import theme from 'theme';
import { Team, Workspace } from 'types';

const Container = styled(Link)`
  border: 1px solid ${theme.colors.N20};
  background: ${theme.colors.N0};
  border-radius: 4px;
  width: 23rem;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  :hover {
    filter: brightness(0.95);
  }
`;

const TitleContainer = styled.div<{ backgroundColor: string; textColor: string }>`
  padding: 1.2rem ${theme.spacing.x2};
  background: ${(props) => props.backgroundColor};
  color: ${(props) => props.textColor};

  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
`;

const DetailsContainer = styled.div`
  display: flex;
  padding: 1.2rem ${theme.spacing.x2};
  font-weight: 500;
  font-size: 11px;
  line-height: 15px;
  gap: ${theme.spacing.x1};

  .divider {
    color: ${theme.colors.N20};
  }
`;

interface Props {
  workspace: Workspace;
  team: Team;
}

function TeamCard(props: Props) {
  const { team, workspace } = props;
  const { t } = useTranslation();
  const path = `/${workspace.slug}/teams/${team.nano_slug}`;

  return (
    <Container to={path}>
      <TitleContainer
        className="title"
        backgroundColor={team.label_bg_color ?? theme.colors.N0}
        textColor={team.label_text_color ?? theme.colors.N100}
      >
        {team.name}
      </TitleContainer>
      <DetailsContainer>
        <div>{t('workspaceTeams.members', { count: team.team_members_count })}</div>
      </DetailsContainer>
    </Container>
  );
}

export default React.memo(TeamCard);
