import { ChromeContent } from 'components/Chrome';
import queryKeys from 'config/queryKeys';
import React, { Fragment, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useMutation, useQuery, useQueryCache } from 'react-query';
import { shallowEqual, useSelector } from 'react-redux';
import { useHistory, useParams, Link } from 'react-router-dom';
import styled from 'styled-components';
import theme from 'theme';
import * as remoteApi from 'api/remote';
import { Retrospective } from 'types';
import KoalaButton from 'koala/components/Button';
import { AxiosResponse } from 'axios';
import KoalaLoader from 'koala/components/Loader';
import KoalaIconButton from 'koala/components/IconButton';
import * as routes from 'routes';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import EditRetrospectiveContent from './EditRetrospectiveContent';
import { useTranslation } from 'react-i18next';
import DropdownMenu from 'components/DropdownMenu';
import PlanStatus from 'components/PlanStatus';
import PlanIconLabel from 'components/PlanIconLabel';

const GridLayout = styled.div`
  position: relative;
  display: grid;
  grid-template-areas: 'header' 'content';
  height: 100%;
  grid-template-rows: auto 1fr;
  width: 100%;
  height: 100%;

  @media ${theme.devices.laptop} {
    grid-template-columns: 25rem 1fr;
  }
  @media ${theme.devices.tablet} {
    grid-template-columns: 20rem 1fr;
  }
  @media ${theme.devices.mobile} {
    grid-template-columns: 10rem 1fr;
  }
`;

const Header = styled.div`
  grid-area: header;
  background-color: ${theme.colors.N0};
  border-bottom: 1px solid ${theme.colors.N20};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: ${theme.spacing.x1};
  padding: 1.2rem ${theme.spacing.x2};

  h4 {
    font-size: 16px;
    font-weight: 600;
  }
`;
const LeftHeader = styled.div`
  display: flex;
  align-items: center;
  gap: ${theme.spacing.x2};
  flex: 1;
`;
const LeftHeaderButton = styled.div`
  border-right: 1px solid ${theme.colors.N20};
  padding-right: ${theme.spacing.x1};
`;
const RightHeader = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: ${theme.spacing.x1};
  flex: 1;
`;
const CenterHeader = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
`;
const ContentContainer = styled.div`
  overflow-y: hidden;
  grid-area: content;
  width: 100%;
`;

const EmptyState = styled.div`
  background-color: ${theme.colors.N0};
`;

function WorkspaceRetrospectiveEdit() {
  const currentWorkspace = useSelector((state: any) => state.session.currentWorkspace, shallowEqual);
  const history = useHistory();
  const queryCache = useQueryCache();
  const [retrospective, setRetrospective] = useState<Retrospective | null>(null);
  const { t } = useTranslation();
  const translationKey = 'workspaceRetrospective';
  const {
    workspaceSlug,
    planId,
    retrospectiveId: retrospectiveSlug,
  } = useParams<{
    workspaceSlug: string;
    planId: string;
    retrospectiveId: string;
  }>();

  // Get the retrospective details
  const queryKey = [queryKeys.currentRetrospective, retrospectiveSlug];
  const staleTime = 0;
  const { isLoading } = useQuery(queryKey, remoteApi.fetchRetrospectiveDetails, {
    staleTime,
    onSuccess: (response: AxiosResponse<Retrospective>) => {
      const retrospective = response.data;
      setRetrospective(retrospective);
    },
  });

  const [deleteRetrospectiveMutation, { isLoading: isDeleting }] = useMutation(remoteApi.deleteRetrospective, {
    onSuccess: () => {
      queryCache.invalidateQueries([queryKeys.retrospectives, planId]);
      const path = routes.WORKSPACE_PLAN_RETROSPECTIVES_ROUTE.replace(':workspaceSlug', workspaceSlug).replace(
        ':planId',
        planId,
      );
      history.push(path);
    },
  });
  const [updateRetrospectiveMutation, { isLoading: isUpdatingRetrospective }] = useMutation(
    remoteApi.updateRetrospective,
    {
      onSuccess: (_, params) => {
        queryCache.invalidateQueries([queryKeys.currentRetrospective, retrospectiveSlug]);

        // Redirect to view mode if we just published a retro
        if (params.retrospective && params.retrospective.published) {
          const path = routes.WORKSPACE_RETROSPECTIVE_ROUTE.replace(':workspaceSlug', workspaceSlug)
            .replace(':planId', planId)
            .replace(':retrospectiveId', retrospectiveSlug);
          history.push(path);
        }
      },
    },
  );

  if (isLoading || isDeleting || isUpdatingRetrospective) {
    return (
      <EmptyState>
        <KoalaLoader />
      </EmptyState>
    );
  }

  const plan = retrospective?.plan;
  if (!retrospective || !plan) {
    return <EmptyState>{t(`${translationKey}.retrospectiveNotFound`)}</EmptyState>;
  }

  const handleBack = () => {
    history.goBack();
  };
  const handleView = () => {
    const path = routes.WORKSPACE_RETROSPECTIVE_ROUTE.replace(':workspaceSlug', workspaceSlug)
      .replace(':planId', planId)
      .replace(':retrospectiveId', retrospectiveSlug);
    history.push(path);
  };
  const handlePublish = () => {
    const mutationParams = {
      retrospectiveId: retrospective.id,
      retrospective: { published: true, published_date: new Date() },
    };
    updateRetrospectiveMutation(mutationParams);
  };
  const handleDelete = () => {
    if (window.confirm('Delete this retrospective?')) {
      setRetrospective(null);
      deleteRetrospectiveMutation(retrospective.id);
    }
  };
  const handleMenuSelection = (value: any) => {
    const action = value.props['data-action'];
    switch (action) {
      case 'delete':
        handleDelete();
        break;
    }
  };
  const getRetrospectiveOptions = () => {
    const options = [<span data-action="delete">{t(`shared.delete`)}</span>];
    return options;
  };

  const retrospectivesPath = routes.WORKSPACE_PLAN_RETROSPECTIVES_ROUTE.replace(
    ':workspaceSlug',
    workspaceSlug,
  ).replace(':planId', plan.nano_slug);

  return (
    <Fragment>
      <Helmet>
        <title>
          {currentWorkspace.name} | {retrospective.title} | Tability
        </title>
      </Helmet>
      <ChromeContent>
        <GridLayout>
          <Header>
            <LeftHeader>
              <LeftHeaderButton>
                <KoalaIconButton iconName="leftChevron" onClick={handleBack} />
              </LeftHeaderButton>
              <Link to={retrospectivesPath}>
                <PlanIconLabel plan={plan} />
              </Link>
              <PlanStatus plan={plan} />
            </LeftHeader>
            <CenterHeader>
              <h3>{t('workspaceRetrospective.editRetrospective')}</h3>
            </CenterHeader>
            <RightHeader>
              <KoalaButton appearance="secondary" onClick={handleView}>
                {t('shared.close')}
              </KoalaButton>
              {!retrospective.published && (
                <KoalaButton onClick={handlePublish}>{t('workspaceRetrospective.publish')}</KoalaButton>
              )}
              <DropdownMenu
                trigger={<KoalaIconButton iconName="ellipsis" />}
                onSelection={handleMenuSelection}
                items={getRetrospectiveOptions()}
              />
            </RightHeader>
          </Header>
          <DndProvider backend={HTML5Backend}>
            <ContentContainer>
              <EditRetrospectiveContent retrospective={retrospective} />
            </ContentContainer>
          </DndProvider>
        </GridLayout>
      </ChromeContent>
    </Fragment>
  );
}

export default WorkspaceRetrospectiveEdit;
