import queryKeys from 'config/queryKeys';
import React, { useState } from 'react';
import { useQuery } from 'react-query';
import styled from 'styled-components';
import * as remoteApi from 'api/remote';
import { Initiative } from 'types';
import { DragHandle, PathSection, WidgetContainer, WidgetHeader } from '.';
import { useTranslation } from 'react-i18next';
import KoalaLoader from 'koala/components/Loader';
import theme from 'theme';
import InitiativeRow from 'components/InitiativeRow';
import { AxiosError } from 'axios';
import KoalaIcon from 'koala/components/Icons';
import PlanIconLabel from 'components/PlanIconLabel';
import { WORKSPACE_PLAN_TEMPLATE_ROUTE, WORKSPACE_PLAN_TRACK_ROUTE, WORKSPACE_PLAN_WRITE_ROUTE } from 'routes';
import { shallowEqual, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { CustomTermKey, translate } from 'utils/customTermUtils';

const WidgetContent = styled.div`
  display: flex;
  width: 100%;

  .initiative-row {
    padding: ${theme.spacing.x2};
    width: 100%;
  }
`;

const InitiativePathSection = styled.div`
  padding: 0px ${theme.spacing.x1} ${theme.spacing.x1};
`;

interface Props {
  initiativeId: string;
}

function InitiativeSummaryWidget(props: Props) {
  const { initiativeId } = props;
  const { t } = useTranslation();
  const history = useHistory();
  const currentWorkspace = useSelector((state: any) => state.session.currentWorkspace, shallowEqual);
  // Query keys and query params
  const queryKey = [queryKeys.currentInitiative, initiativeId];
  const staleTime = 0;
  const [initiative, setInitiative] = useState<Initiative | null>(null);
  const [error, setError] = useState('');
  // Get the initiative details
  const { isLoading } = useQuery(queryKey, remoteApi.fetchInitiativeDetails, {
    staleTime,
    onSuccess: (response) => {
      setInitiative(response.data);
    },
    onError: (error: AxiosError) => {
      const errorText = error.response?.data.error;
      if (errorText.includes('not allowed') && error.response?.status === 401) {
        setError(
          t('workspaceDashboards.permissionError') ?? 'You do not have permission to view the content in this widget',
        );
      } else {
        setError(t('workspaceDashboards.widgetError') ?? 'Unable to fetch widget');
      }
    },
  });

  if (isLoading) {
    return (
      <WidgetContainer>
        <KoalaLoader />
      </WidgetContainer>
    );
  }

  if (!initiative) {
    return (
      <WidgetContainer>
        <WidgetHeader>
          <DragHandle className="drag-handle">
            <KoalaIcon iconName="grab" iconSize="small" />
          </DragHandle>
          <p className="widget-type">
            {t('workspaceDashboards.initiativeSummary', {
              initiative: translate(currentWorkspace, CustomTermKey.INITIATIVE, 1),
            })}
          </p>
        </WidgetHeader>
        <WidgetContent>
          <p>{t('workspaceDashboards.widgetError')}</p>
        </WidgetContent>
      </WidgetContainer>
    );
  }

  const handleClickObjective = () => {
    history.push(`#objective:${initiative.outcome.objective.nano_slug}:show`);
  };

  const handleClickOutcome = () => {
    history.push(`#outcome:${initiative.outcome.nano_slug}:show`);
  };

  const stateToRouteMapping: { [key: string]: string } = {
    draft: WORKSPACE_PLAN_WRITE_ROUTE,
    published: WORKSPACE_PLAN_TRACK_ROUTE,
    template: WORKSPACE_PLAN_TEMPLATE_ROUTE,
  };
  let routeMappingState = initiative.plan.state || 'draft';
  const routeTarget = stateToRouteMapping[routeMappingState];
  const planRoute = routeTarget
    .replace(':workspaceSlug', currentWorkspace.slug)
    .replace(':planId', initiative.plan.nano_slug);

  return (
    <WidgetContainer>
      <WidgetHeader>
        <DragHandle className="drag-handle">
          <KoalaIcon iconName="grab" iconSize="small" />
        </DragHandle>
        <p className="widget-type">
          {t('workspaceDashboards.initiativeSummary', {
            initiative: translate(currentWorkspace, CustomTermKey.INITIATIVE, 1),
          })}
        </p>
      </WidgetHeader>
      <WidgetContent>
        <p>{error}</p>
        {initiative && (
          <InitiativeRow
            initiative={initiative}
            allowLink={true}
            isReadOnly={true}
            showHeirarchy={false}
            showMeta={true}
          />
        )}
      </WidgetContent>
      <InitiativePathSection>
        <PathSection>
          <Link to={planRoute}>
            <PlanIconLabel plan={initiative.plan} />
          </Link>
          <span>/</span>
          <span onClick={handleClickObjective} className="widget-source">
            {initiative.outcome.objective.title}
          </span>
          <span>/</span>
          <span onClick={handleClickOutcome} className="widget-source">
            {initiative.outcome.title}
          </span>
        </PathSection>
      </InitiativePathSection>
    </WidgetContainer>
  );
}

export default InitiativeSummaryWidget;
