import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useMutation, useQueryCache } from 'react-query';
import queryKeys from 'config/queryKeys';
import styled from 'styled-components';
import theme from 'theme';

// API
import * as remoteApi from 'api/remote';

import { setGlobalModalContent } from 'state/actions/globalUIActions';

// Routes

// Components
import { ModalGrid, ModalHeader, ModalContent, ModalFooter } from 'components/GlobalModal';
import KoalaButton from 'koala/components/Button';
import KoalaTextButton from 'koala/components/TextButton';
import KoalaIconButton from 'koala/components/IconButton';
import { useTranslation } from 'react-i18next';
import { SELECTED_PLANS_KEY } from 'config/constants';

const Form = styled.form``;

const FormField = styled.div`
  display: flex;
  flex-direction: column;
  .form-label {
    text-transform: uppercase;
    font-size: 1.4rem;
    font-weight: 600;
    color: ${theme.colors.subtleText};
    margin-bottom: ${theme.spacing.x1};
  }

  margin: ${theme.spacing.x3} 0;
`;

const PlanSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.x1};
  padding: ${theme.spacing.x2};
`;

interface Props {}

function DeletePlans(props: Props) {
  const dispatch = useDispatch();
  const queryCache = useQueryCache();
  const { t } = useTranslation();
  const translationKey = 'modals.deletePlans';
  const storedSelectedPlansValue = localStorage.getItem(SELECTED_PLANS_KEY);
  const selectedPlans: { id: string; title: string }[] = storedSelectedPlansValue
    ? JSON.parse(storedSelectedPlansValue)
    : [];
  // Form values
  const [confirmText, setConfirmText] = useState('');

  const handleCancel = (e: any) => {
    e.preventDefault();
    dispatch(setGlobalModalContent(''));
  };

  const handleChange = (e: any) => {
    setConfirmText(e.target.value);
  };

  //api
  const deletePlanAsync = async (plan: { id: string; title: string }) => {
    try {
      const response = await remoteApi.deletePlan(plan.id);
      queryCache.invalidateQueries([queryKeys.currentPlan, plan.id]);
      return response.data;
    } catch (error: any) {
      // If an error occurs during deletion, return an object with the error information
      return {
        error: true,
        statusText: error.response?.statusText || 'Failed to delete',
        ...plan,
      };
    }
  };

  // mutation
  const [deletePlansMutation, { isLoading }] = useMutation(
    async (plans: any[]) => {
      const deletePromises = plans.map((plan) => deletePlanAsync(plan));
      return Promise.all(deletePromises);
    },
    {
      onSuccess: (results) => {
        // Filter out the plans that failed to delete
        const failedPlans = results.filter((result) => result.error);

        if (failedPlans.length > 0) {
          // Some plans failed to delete
          const failedPlansMessage = failedPlans
            .map((failedPlan) => `${failedPlan.title} (${failedPlan.statusText})`)
            .join('\n');
          const failedMessage = `${t(`${translationKey}.failed`, {
            count: failedPlans.length,
            plans: failedPlansMessage,
          })}`;
          window.alert(failedMessage);
        }

        if (results.length !== failedPlans.length) {
          // if there's at least one success, clear cache
          queryCache.invalidateQueries(queryKeys.plans);
          queryCache.invalidateQueries([queryKeys.currentWorkspace]);
        }

        localStorage.removeItem(SELECTED_PLANS_KEY);
        dispatch(setGlobalModalContent(''));
      },
    },
  );

  const handleDeletePlans = (e: React.FormEvent) => {
    e.preventDefault();
    deletePlansMutation(selectedPlans);
  };

  const isDisabled = confirmText !== 'BULK DELETE' || isLoading;
  const planCount = selectedPlans.length;
  return (
    <ModalGrid>
      <ModalHeader>
        <h2>{t(`${translationKey}.title`)}</h2>
        <KoalaIconButton onClick={handleCancel} iconName="close" />
      </ModalHeader>
      <ModalContent>
        <Form onSubmit={handleDeletePlans}>
          <p>{t(`${translationKey}.label`, { count: planCount })}</p>
          <PlanSection>
            {selectedPlans.map((plan, i) => (
              <b key={i}>{plan.title}</b>
            ))}
          </PlanSection>
          <p>{t(`${translationKey}.label2`)}</p>
          <FormField>
            <div className="form-label">{t(`${translationKey}.confirm`)}</div>
            <input
              onChange={handleChange}
              value={confirmText}
              className="small"
              placeholder={t(`${translationKey}.typeDelete`) ?? 'type BULK DELETE to confirm'}
            />
          </FormField>
        </Form>
      </ModalContent>
      <ModalFooter>
        <KoalaButton disabled={isDisabled} loading={isLoading} onClick={handleDeletePlans}>
          {t('shared.delete')}
        </KoalaButton>
        <KoalaTextButton onClick={handleCancel}>{t('shared.cancel')}</KoalaTextButton>
      </ModalFooter>
    </ModalGrid>
  );
}

export default DeletePlans;
