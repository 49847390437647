import React from 'react';
import PlanSummaryWidget from './PlanSummaryWidget';
import { DashboardWidget } from 'types';
import ObjectiveSummaryWidget from './ObjectiveSummaryWidget';
import OutcomeSummaryWidget from './OutcomeSummary';
import InitiativeSummaryWidget from './InitiativeSummaryWidget';
import UserProfileWidget from './UserProfileWidget';
import styled from 'styled-components';
import theme from 'theme';
import { useTranslation } from 'react-i18next';
import ObjectiveListWidget from './ObjectiveListWidget';
import OutcomeListWidget from './OutcomeListWidget';
import InitiativeListWidget from './InitiativeListWidget';
import OutcomeTaskGraphWidget from './OutcomeTaskGraphWidget';
import PlanConfidenceGraphWidget from './PlanConfidenceGraphWidget';
import PlanNCSGraphWidget from './PlanNCSGraphWidget';
import OutcomeGraphWidget from './OutcomeGraphWidget';

export const WidgetContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;
export const WidgetHeader = styled.span`
  display: flex;
  background-color: ${theme.colors.N5};
  border-bottom: 1px solid ${theme.colors.N20};
  height: 4.4rem;
  gap: ${theme.spacing.x1};
  border-top-left-radius: 7px;
  border-top-right-radius: 7px;

  .widget-type {
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 1.2rem 0px;
  }
`;

export const ListContainer = styled.div`
  border: 1px solid ${theme.colors.N20};
  border-radius: 4px;

  .item:first-of-type {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }
  .outcome-expanded:last-of-type,
  .outcome-row:last-of-type {
    border-bottom: 0px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
  }
`;

export const WidgetContent = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${theme.spacing.x2};
  gap: ${theme.spacing.x1};
  height: 100%;
  overflow: scroll;
`;

export const SourceTitle = styled.div`
  display: flex;
  gap: ${theme.spacing.x1};
  font-size: 16px;
  font-weight: 600;
  vertical-align: top;
  line-height: 18px;
  cursor: pointer;
  :active {
    text-decoration: underline;
  }
`;

export const DragHandle = styled.div`
  width: ${theme.spacing.x2};
  display: flex;
  justify-content: center;
  cursor: grab;
  padding: 1.2rem ${theme.spacing.x2};
`;

export const ContextHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-items: center;
  gap: ${theme.spacing.x1};
  font-size: 14px;
  font-weight: 400;
  margin-bottom: ${theme.spacing.x1};
`;

export const PathSection = styled.div`
  display: flex;
  align-items: center;
  column-gap: ${theme.spacing.x1};
  row-gap: ${theme.spacing.half};
  flex-wrap: wrap;

  .icon--custom-size {
    width: 1.6rem;
    height: 1.6rem;
  }
  span {
    height: 1.6rem;
    font-size: 11px;
    font-weight: 500;
    color: ${theme.colors.N60};
  }
  .widget-source {
    cursor: pointer;
  }
  a {
    font-size: 11px;
    font-weight: 500;
    color: ${theme.colors.N60};
  }
`;

interface Props {
  item: DashboardWidget;
}

function DashboardWidgetBlock(props: Props) {
  const { item } = props;
  const { t } = useTranslation();

  const sourceId = item.source_id;

  if (!sourceId) {
    return <div>{t('workspaceDashboards.widgetError')}</div>;
  }

  switch (item.widget_type) {
    case 'plan-summary':
      return <PlanSummaryWidget planId={sourceId} />;
    case 'objective-summary':
      return <ObjectiveSummaryWidget objectiveId={sourceId} />;
    case 'outcome-summary':
      return <OutcomeSummaryWidget outcomeId={sourceId} />;
    case 'initiative-summary':
      return <InitiativeSummaryWidget initiativeId={sourceId} />;
    case 'user-profile':
      return <UserProfileWidget membershipId={sourceId} />;
    case 'objective-list':
      return <ObjectiveListWidget segmentId={sourceId} />;
    case 'outcome-list':
      return <OutcomeListWidget segmentId={sourceId} />;
    case 'initiative-list':
      return <InitiativeListWidget segmentId={sourceId} />;
    case 'outcome-task-graph':
      return <OutcomeTaskGraphWidget planId={sourceId} />;
    case 'plan-confidence-graph':
      return <PlanConfidenceGraphWidget planId={sourceId} />;
    case 'plan-ncs-graph':
      return <PlanNCSGraphWidget planId={sourceId} />;
    case 'outcome-graph':
      return <OutcomeGraphWidget outcomeId={sourceId} />;
    default:
      return <PlanSummaryWidget planId={sourceId} />;
  }
}

export default DashboardWidgetBlock;
