import React from 'react';
import * as TabilityTypes from 'types';
import { useSelector, shallowEqual } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Base64 } from 'js-base64';
import * as checkinUtils from 'utils/checkinUtils';

import * as routes from 'routes';

// Translations
import { CustomTermKey, translate } from 'utils/customTermUtils';
import { Trans, useTranslation } from 'react-i18next';

import { OverviewCard, OverviewLine } from './';
import KoalaColorBlock from 'koala/components/ColorBlock';
import KoalaIcon from 'koala/components/Icons';
import ReactTooltip from 'react-tooltip';

interface Props {
  outcomesStats: any;
}

function Component(props: Props) {
  const { outcomesStats } = props;
  const history = useHistory();
  const { t } = useTranslation();
  const currentWorkspace: TabilityTypes.Workspace = useSelector(
    (state: any) => state.session.currentWorkspace,
    shallowEqual,
  );

  const totalOutcomesWarnings =
    outcomesStats.default_target_outcomes_count +
    outcomesStats.without_owner_outcomes_count +
    outcomesStats.pnone_outcomes_count;

  const prctOutcomes = (outcomesCount: number) => {
    if (outcomesStats.total_outcomes_count === 0) {
      return <span>0%</span>;
    }

    const prct = (outcomesCount / outcomesStats.total_outcomes_count) * 100;
    return <span>{Math.round(prct)}%</span>;
  };

  const goToOutcomesFilter = (filter: any) => {
    const encodedHash = Base64.encode(
      JSON.stringify({
        ...filter,
        plan_status: 'is_in_progress',
        sorting: 'plan',
      }),
    );
    history.push(
      routes.WORKSPACE_OUTCOMES_ROUTE_WITH_FILTERS.replace(':workspaceSlug', currentWorkspace.slug).replace(
        ':filter',
        encodedHash,
      ),
    );
  };

  return (
    <>
      <h3>
        {t('workspaceInsights.outcomesOverviewHeader', {
          label: translate(currentWorkspace, CustomTermKey.OUTCOME, 2),
        })}
      </h3>
      <OverviewCard>
        <OverviewLine className="header">
          <span>
            {t('workspaceInsights.outcomesOverview.totalActiveOutcomes', {
              label: translate(currentWorkspace, CustomTermKey.OUTCOME, 2).toLowerCase(),
            })}
          </span>
          <div>
            <span>{outcomesStats.total_outcomes_count}</span>
          </div>
        </OverviewLine>
        <OverviewLine
          role="button"
          tabIndex={0}
          className="clickable"
          onClick={() =>
            goToOutcomesFilter({
              confidence: 'red',
            })
          }
        >
          <div className="badge_container">
            <KoalaColorBlock color={checkinUtils.confidenceToColor('red')} size="small" />
            {t('shared.confidence.offTrack')}
          </div>
          <span className={outcomesStats.red_outcomes_count > 0 ? 'red' : ''}>{outcomesStats.red_outcomes_count}</span>
          {prctOutcomes(outcomesStats.red_outcomes_count)}
        </OverviewLine>
        <OverviewLine
          role="button"
          tabIndex={0}
          className="clickable"
          onClick={() =>
            goToOutcomesFilter({
              confidence: 'yellow',
            })
          }
        >
          <div className="badge_container">
            <KoalaColorBlock color={checkinUtils.confidenceToColor('yellow')} size="small" />
            {t('shared.confidence.atRisk')}
          </div>
          <span className={outcomesStats.yellow_outcomes_count > 0 ? 'yellow' : ''}>
            {outcomesStats.yellow_outcomes_count}
          </span>
          {prctOutcomes(outcomesStats.yellow_outcomes_count)}
        </OverviewLine>
        <OverviewLine
          role="button"
          tabIndex={0}
          className="clickable"
          onClick={() =>
            goToOutcomesFilter({
              confidence: 'green',
            })
          }
        >
          <div className="badge_container">
            <KoalaColorBlock color={checkinUtils.confidenceToColor('green')} size="small" />
            {t('shared.confidence.onTrack')}
          </div>
          <span>{outcomesStats.green_outcomes_count}</span>
          {prctOutcomes(outcomesStats.green_outcomes_count)}
        </OverviewLine>
        <OverviewLine
          role="button"
          tabIndex={0}
          className="clickable"
          onClick={() =>
            goToOutcomesFilter({
              confidence: 'grey',
            })
          }
        >
          <div className="badge_container">
            <KoalaColorBlock color={checkinUtils.confidenceToColor('grey')} size="small" />
            {t('shared.confidence.pending')}
          </div>
          <span>{outcomesStats.grey_outcomes_count}</span>
          {prctOutcomes(outcomesStats.grey_outcomes_count)}
        </OverviewLine>
        <OverviewLine className="header">
          <span>{t('workspaceInsights.goalSettingWarnings')}</span>
          <span>{totalOutcomesWarnings}</span>
        </OverviewLine>
        <OverviewLine
          role="button"
          tabIndex={0}
          className="clickable"
          onClick={() =>
            goToOutcomesFilter({
              insights: 'without_target',
            })
          }
        >
          <span>{t('workspaceFilters.withoutTargets')}</span>
          <span>{outcomesStats.pnone_outcomes_count}</span>
          {prctOutcomes(outcomesStats.pnone_outcomes_count)}
        </OverviewLine>
        <OverviewLine
          role="button"
          tabIndex={0}
          className="clickable"
          onClick={() =>
            goToOutcomesFilter({
              insights: 'with_default_target',
            })
          }
        >
          <span>
            {t('workspaceFilters.defaultTargets')}{' '}
            <KoalaIcon iconName="tooltip" iconSize="small" data-tip data-for="explain-default-target" />
          </span>
          <span>{outcomesStats.default_target_outcomes_count}</span>
          <ReactTooltip
            place="bottom"
            type="dark"
            className="tooltip"
            effect="solid"
            id="explain-default-target"
            delayHide={500}
            clickable={true}
          >
            <Trans i18nKey="shared.defaultTarget.tooltip" components={{ br: <br /> }} />{' '}
            <a
              href="https://guides.tability.app/docs/about-tability/best-practices#use-real-targets"
              target="_blank"
              rel="noopener noreferrer"
              onClick={(e) => e.stopPropagation()}
            >
              {t('shared.learnMore')}
            </a>
          </ReactTooltip>
          {prctOutcomes(outcomesStats.default_target_outcomes_count)}
        </OverviewLine>
        <OverviewLine
          role="button"
          tabIndex={0}
          className="clickable"
          onClick={() =>
            goToOutcomesFilter({
              insights: 'without_owner',
            })
          }
        >
          <span>{t('workspaceFilters.withoutOwner')}</span>
          <span>{outcomesStats.without_owner_outcomes_count}</span>
          {prctOutcomes(outcomesStats.without_owner_outcomes_count)}
        </OverviewLine>
      </OverviewCard>
    </>
  );
}

export default Component;
