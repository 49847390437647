import { ChromeContent } from 'components/Chrome';
import { Base64 } from 'js-base64';
import Loader from 'components/Loader';
import { format } from 'date-fns';
import KoalaButton from 'koala/components/Button';
import React, { Fragment, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useLocation, useParams } from 'react-router-dom';
import styled from 'styled-components';
import theme from 'theme';
import { Segment, Workspace } from 'types';
import * as workspaceUtils from 'utils/workspaceUtils';
import { useInfiniteQuery, useMutation, useQuery } from 'react-query';
import * as remoteApi from 'api/remote';
import { setGlobalModalContent } from 'state/actions/globalUIActions';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import queryKeys from 'config/queryKeys';
import Filters from './Filters';
import parse from 'parse-link-header';
import UpgradeIcon from 'components/_assets/UpgradeIcon';
import KoalaTextButton from 'koala/components/TextButton';
import ObjectiveBlockCompact from './ObjectiveBlockCompact';
import download from 'downloadjs';
import { CustomTermKey, translate } from 'utils/customTermUtils';
import PlanIconLabel from 'components/PlanIconLabel';
import * as routes from 'routes';
import { useHistory } from 'react-router-dom';
import * as membershipUtils from 'utils/membershipUtils';

import PresetFilters from './PresetFilters';
import SegmentBlock from 'components/SegmentBlock';

import DropdownMenu from 'components/DropdownMenu';
import KoalaIconButton from 'koala/components/IconButton';
import ListingNav from 'components/ListingNav';

// Header component
import WorkspaceHeader from 'components/WorkspaceHeader';
import { MobileReadyChromeHeader } from 'components/MobileReadyChrome';
import { LAST_VISITED_FILTER } from 'config/constants';
import SearchStats from './SearchStats';
import KoalaSelect, { KoalaSelectOption } from 'koala/components/Select';
import { ValueType } from 'react-select';
import { useTranslation } from 'react-i18next';
import * as filterUtils from 'utils/filterUtils';
import ShareButton from 'components/ShareButton';

const ContainerGrid = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  min-height: 100%;
`;

const Wrapper = styled.div`
  margin: 0 auto ${theme.spacing.x4} auto;
  max-width: 200rem;
  width: 100%;
  padding: 1.2rem ${theme.spacing.x2};

  h3 {
    padding: ${theme.spacing.x2} 0;
  }

  .outcome-header {
    display: flex;
    flex-wrap: wrap;
  }
`;

const ObjectiveContainer = styled.div`
  border: 1px solid ${theme.colors.blockBorder};
  box-sizing: border-box;
  border-radius: 4px;
  background: #fff;

  .item:first-of-type {
    border-radius: 3px 3px 0 0;
  }
  .item:last-of-type {
    border-radius: 0 0 3px 3px;
    border: none;
  }
`;
const ColumnHeader = styled.div`
  padding: 0 ${theme.spacing.x2} ${theme.spacing.x1} ${theme.spacing.x2};
  display: grid;
  color: ${theme.colors.N80};
  grid-template-areas: 'objective plan meta';
  grid-template-columns: 1fr 40rem 10rem;
  gap: ${theme.spacing.x2};

  > div {
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    text-transform: uppercase;
  }
  .objective {
    grid-area: objective;
  }
  .plan {
    grid-area: plan;
  }
  .meta {
    grid-area: meta;
  }

  @media ${theme.devices.laptop} {
    column-gap: ${theme.spacing.x1};
    grid-template-columns: 1fr 20rem;
    grid-template-areas: 'objective plan';
    .outcome,
    .meta {
      display: none;
    }
  }

  @media ${theme.devices.tablet} {
    display: none;
  }
`;

const LoadMore = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: ${theme.spacing.x1} 0;
`;

const NoResults = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${theme.spacing.x3};
`;
const UpgradeBanner = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: ${theme.spacing.x3};
  background: rgba(197, 165, 239, 0.25);
  border-radius: 4px;

  max-width: 60rem;
  margin: ${theme.spacing.x1} auto;

  p {
    display: flex;
    align-items: center;

    svg {
      margin-right: ${theme.spacing.x1};
    }
  }
`;

const ResultsHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  column-gap: ${theme.spacing.x1};

  @media ${theme.devices.tablet} {
    flex-direction: column;
    align-items: start;
    margin-bottom: ${theme.spacing.x1};
  }
`;
const ResultsHeaderLeft = styled.div`
  display: flex;
  align-items: center;
  gap: ${theme.spacing.x1};
`;

const SortFilters = styled.div`
  display: flex;
  align-items: center;
  gap: ${theme.spacing.x1};
`;

const GroupSeparator = styled.div`
  padding: ${theme.spacing.x1} ${theme.spacing.x2};
  background: ${theme.colors.N5};
  font-weight: 900;
  font-size: 1rem;
  text-transform: uppercase;
  border-bottom: 1px solid ${theme.colors.blockBorder};
  display: flex;
  align-items: center;
  gap: ${theme.spacing.x1};
`;

const FiltersContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  gap: ${theme.spacing.x2};

  .create-segment-button {
    display: flex;
    align-items: center;
    margin-top: 2.6rem;
  }
  @media ${theme.devices.mobile} {
    .create-segment-button {
      display: none;
    }
  }
`;

const ActionsNav = styled.div`
  display: flex;
  align-items: center;
  gap: ${theme.spacing.x1};
`;

export interface ObjectiveStatsResult {
  low_confidence: number;
  mid_confidence: number;
  high_confidence: number;
  avg_ncs: number;
}
interface Props {
  workspace: Workspace;
}

function WorkspaceObjectives(props: Props) {
  const { workspace } = props;
  const { t } = useTranslation();
  const location = useLocation();
  const { workspaceSlug, filter, segmentId } = useParams<{
    workspaceSlug: string;
    filter: string;
    segmentId: string;
  }>();
  const dispatch = useDispatch();

  const isSegment = segmentId ? true : false;
  const { data: segmentResponse } = useQuery([queryKeys.segments, segmentId], remoteApi.fetchSegmentDetails, {
    enabled: isSegment,
  });
  const segment: Segment = segmentResponse ? segmentResponse.data : null;
  const [objectiveStats, setObjectivesStats] = useState<ObjectiveStatsResult | null>(null);

  useEffect(() => {
    localStorage.setItem(LAST_VISITED_FILTER, 'objectives');
  }, []);

  const [objectiveGroups, setObjectiveGroups]: any = useState([]);
  const [resultsCount, setResultsCount]: any = useState(0);
  let filterPath = segmentId
    ? routes.WORKSPACE_OBJECTIVES_SEGMENT_ROUTE_WITH_FILTERS.replace(':segmentId', segmentId)
    : routes.WORKSPACE_OBJECTIVES_ROUTE_WITH_FILTERS;
  const history = useHistory();

  const updateURL = (filterHash: { [key: string]: any }) => {
    const encodedHash = Base64.encode(JSON.stringify(filterHash));
    history.push(filterPath.replace(':workspaceSlug', workspaceSlug).replace(':filter', encodedHash));
  };

  const currentMembership = useSelector((state: any) => state.session.currentMembership, shallowEqual);
  const isReadOnly = membershipUtils.isReadOnly(currentMembership);

  const customFilterHash = filter ? JSON.parse(Base64.decode(filter)) : {};

  const filterParams = filterUtils.getFilterHash(customFilterHash, currentMembership);

  // Construct the query key
  const queryKey = [
    queryKeys.objectives,
    'search',
    {
      workspaceSlug,
      filter: filterParams,
    },
  ];

  const getNextPage = (response: any) => {
    if (response && response.headers && response.headers.link) {
      const links = response.headers.link;
      const parsed = parse(links);
      if (parsed && parsed.next) {
        return parsed.next.page;
      }
    }
    return null;
  };

  // Get the task details
  const { isLoading, isFetchingMore, fetchMore, canFetchMore }: any = useInfiniteQuery(
    queryKey,
    remoteApi.searchObjectives,
    {
      getFetchMore: (lastGroup, allGroups) => {
        return getNextPage(lastGroup);
      },
      onSuccess: (data: any) => {
        const resultsCount = parseInt(data[0].headers['x-total']);
        setResultsCount(resultsCount);
        setObjectiveGroups(data);
      },
    },
  );

  const queryKeyStats = [
    queryKeys.objectives,
    'stats',
    {
      workspaceSlug,
      filter: filterParams,
    },
  ];

  const { isLoading: isLoadingStats }: any = useQuery(queryKeyStats, remoteApi.fetchObjectivesStats, {
    onSuccess: (response: any) => {
      setObjectivesStats(response.data);
    },
  });

  // Mutation that will export the objectives
  const [exportObjectivesMutation]: [any, any] = useMutation(remoteApi.exportObjectives, {
    onSuccess: (response: any) => {
      const content = response.headers['content-type'];
      const fileName = `objectives-${format(new Date(), 'Y-MM-dd')}.csv`;
      download(response.data, fileName, content);
    },
  });
  const handleExportObjectives = () => {
    const mutationParams = {
      workspaceSlug: workspaceSlug,
      filter: filterParams,
    };
    exportObjectivesMutation(mutationParams);
  };
  const handleUpgradeExport = () => {
    const upgradeModalHash = 'workspace:tability20-essentials:upgrade';
    dispatch(setGlobalModalContent(upgradeModalHash));
  };

  const handleUpdateSorting = (option: ValueType<KoalaSelectOption, boolean>) => {
    const filterHash = { ...customFilterHash };
    const optionValue = option as KoalaSelectOption;
    if (optionValue) {
      filterHash['sorting'] = optionValue.value;
      updateURL(filterHash);
    }
  };

  const hasItems = objectiveGroups && objectiveGroups.length > 0 ? objectiveGroups[0].data.length > 0 : false;
  const hasEssentialsSubscription =
    workspaceUtils.hasEssentialsSubscription(workspace) || workspace.pricing_version >= 2;

  // Limiting results is used to limit how many results are available on a free plan
  let limitResultsForUpgrade = false;
  if (workspace.pricing_version === 4) {
    limitResultsForUpgrade = false;
  } else {
    limitResultsForUpgrade = !hasEssentialsSubscription;
  }

  let indexCount = 0;
  let freeLimit = 2;
  let currentGroup: any = null;
  const sortToGroupMapping: any = {
    plan: 'plan',
    owner: 'membership',
    state: 'state',
  };

  const unseenResults = resultsCount - freeLimit;

  const showCreateSegment = () => {
    dispatch(setGlobalModalContent(`segment:${filter}:create.objective`));
  };

  const handleMenuSelection = (value: any) => {
    const action = value.props['data-action'];
    switch (action) {
      case 'export':
        if (hasEssentialsSubscription) {
          handleExportObjectives();
        } else {
          handleUpgradeExport();
        }
        break;
    }
  };

  const menuItems = () => {
    const items = [];
    items.push(<span data-action="export">{t('shared.export')}</span>);
    return items;
  };

  const handleShare = () => {
    dispatch(setGlobalModalContent('workspace:team:share'));
  };

  const link = `https://${process.env.REACT_APP_DOMAIN}${location.pathname}`;

  const sortOptions: KoalaSelectOption[] = [
    { label: t('workspaceFilters.sortTitle'), value: 'title' },
    { label: t('workspaceFilters.plan'), value: 'plan' },
    { label: t('workspaceFilters.mostCompleted'), value: '-completion' },
    { label: t('workspaceFilters.leastCompleted'), value: 'completion' },
    { label: t('workspaceFilters.highestConfidence'), value: '-ncs' },
    { label: t('workspaceFilters.lowestConfidence'), value: 'ncs' },
    { label: t('workspaceFilters.newest'), value: '-created_at' },
    { label: t('workspaceFilters.oldest'), value: 'created_at' },
  ];
  const selectedSortOption: any = sortOptions.find((option) => option.value === customFilterHash.sorting);

  return (
    <>
      <Helmet>
        <title>
          {workspace.name} | {translate(workspace, CustomTermKey.OBJECTIVE, 2)} | Tability
        </title>
      </Helmet>

      <MobileReadyChromeHeader className="no_border">
        <WorkspaceHeader workspace={workspace} useGreyBackground={true} title={<h1>Filters</h1>} />
      </MobileReadyChromeHeader>
      <ChromeContent isGreyBackground>
        <ListingNav />
        <ContainerGrid>
          <PresetFilters />
          <Wrapper>
            {segment && (
              <SegmentBlock
                segmentableType="objective"
                segment={segment}
                filterHash={filter}
                workspaceSlug={workspaceSlug}
              />
            )}
            <FiltersContainer>
              <Filters customFilterHash={customFilterHash} updateURL={updateURL} />
              {!isReadOnly && (
                <ActionsNav>
                  {!segment && (
                    <KoalaTextButton
                      appearance="subtle"
                      className="segment-save-button"
                      onClick={() => showCreateSegment()}
                    >
                      <span>{t('workspaceFilters.segments.save')}</span>
                    </KoalaTextButton>
                  )}
                  <ShareButton id="objective-filters" link={link} shareCallback={handleShare} />

                  <DropdownMenu
                    trigger={<KoalaIconButton iconName="ellipsis" />}
                    onSelection={handleMenuSelection}
                    items={menuItems()}
                  />
                </ActionsNav>
              )}
            </FiltersContainer>
            {isLoading && <Loader size="medium" />}
            {!isLoading && (
              <Fragment>
                <ResultsHeader>
                  <ResultsHeaderLeft>
                    <h3>
                      {t('workspaceFilters.resultHeader', {
                        count: resultsCount,
                        type: translate(workspace, CustomTermKey.OBJECTIVE, resultsCount).toLowerCase(),
                      })}
                    </h3>
                  </ResultsHeaderLeft>
                  <SortFilters>
                    <span>{t('workspaceFilters.sortBy')}</span>
                    <KoalaSelect
                      handleChange={handleUpdateSorting}
                      placeholder={t('workspaceFilters.placeholder')}
                      selectedOption={selectedSortOption}
                      options={sortOptions}
                      isMultiSelect={false}
                      size="small"
                    />
                  </SortFilters>
                </ResultsHeader>
                {isLoadingStats && <Loader size="medium" />}
                <SearchStats
                  objectiveStats={objectiveStats}
                  customFilterHash={customFilterHash}
                  updateURL={updateURL}
                />
                <ColumnHeader>
                  <div className="objective">{translate(workspace, CustomTermKey.OBJECTIVE, 1)}</div>
                  <div className="plan">{t('workspaceFilters.plan')}</div>
                  <div className="meta">{t('workspaceFilters.progress')}</div>
                </ColumnHeader>
                <ObjectiveContainer>
                  {!hasItems && (
                    <NoResults>
                      {t('workspaceFilters.emptyState', {
                        type: translate(workspace, CustomTermKey.OBJECTIVE, 2).toLowerCase(),
                      })}
                    </NoResults>
                  )}
                  {objectiveGroups.map((group: any, i: number) => {
                    return (
                      <Fragment key={i}>
                        {group.data.map((objective: any, index: number) => {
                          indexCount++;

                          let displayCurrentGroup = false;
                          let comparedGroup = null;
                          // Get the grouping key
                          const groupKey = sortToGroupMapping[customFilterHash.sorting];
                          if (groupKey) {
                            comparedGroup = objective[groupKey];
                            const currentGroupId = currentGroup ? currentGroup.id : null;
                            const comparedGroupId = comparedGroup ? comparedGroup.id : null;

                            if (currentGroupId !== comparedGroupId) {
                              currentGroup = comparedGroup;
                              displayCurrentGroup = true;
                            }
                          }

                          if (limitResultsForUpgrade && indexCount > freeLimit) {
                            return null;
                          }
                          return (
                            <Fragment key={objective.id}>
                              {displayCurrentGroup && customFilterHash.sorting === 'plan' && (
                                <GroupSeparator className="item">
                                  <PlanIconLabel size="xsmall" plan={objective.plan} />
                                </GroupSeparator>
                              )}
                              <ObjectiveBlockCompact objective={objective} />
                            </Fragment>
                          );
                        })}
                      </Fragment>
                    );
                  })}
                  {canFetchMore && !limitResultsForUpgrade && (
                    <LoadMore>
                      <KoalaButton loading={isFetchingMore} onClick={() => fetchMore()} appearance="secondary">
                        {t('shared.loadMore')}
                      </KoalaButton>
                    </LoadMore>
                  )}
                  {limitResultsForUpgrade && resultsCount > freeLimit && (
                    <>
                      {workspace.pricing_version < 4 && (
                        <UpgradeBanner>
                          <p>
                            <UpgradeIcon />{' '}
                            <b> {t('workspaceFilters.upgrade.moreResults', { count: unseenResults })}</b>
                          </p>
                          <p>
                            {t('workspaceFilters.upgrade.information', {
                              count: unseenResults,
                              type: translate(workspace, CustomTermKey.OUTCOME, unseenResults).toLowerCase(),
                            })}
                          </p>
                          {currentMembership && ['owner'].includes(currentMembership.role) && (
                            <p>
                              <KoalaButton isLink to={`/${workspaceSlug}/settings/billing`}>
                                {t('shared.upgrade.startTrial')}
                              </KoalaButton>
                            </p>
                          )}
                          {currentMembership && !['owner'].includes(currentMembership.role) && (
                            <p>
                              <KoalaTextButton href="https://tability.io/pricing" target="_blank" rel="noreferrer">
                                {t('shared.upgrade.learnMore')}
                              </KoalaTextButton>
                            </p>
                          )}
                        </UpgradeBanner>
                      )}
                      {workspace.pricing_version === 4 && (
                        <UpgradeBanner>
                          <p>
                            <UpgradeIcon />{' '}
                            <b> {t('workspaceFilters.upgradeV4.moreResults', { count: unseenResults })}</b>
                          </p>
                          <p>
                            {t('workspaceFilters.upgradeV4.information', {
                              count: unseenResults,
                              type: translate(workspace, CustomTermKey.OUTCOME, unseenResults).toLowerCase(),
                            })}
                          </p>
                          {currentMembership && ['owner'].includes(currentMembership.role) && (
                            <p>
                              <KoalaButton isLink to={`/${workspaceSlug}/settings/billing`}>
                                {t('workspaceFilters.upgradeV4.upgrade')}
                              </KoalaButton>
                            </p>
                          )}
                          {currentMembership && !['owner'].includes(currentMembership.role) && (
                            <p>
                              <KoalaTextButton href="https://tability.io/pricing" target="_blank" rel="noreferrer">
                                {t('shared.learnMore')}
                              </KoalaTextButton>
                            </p>
                          )}
                        </UpgradeBanner>
                      )}
                    </>
                  )}
                </ObjectiveContainer>
              </Fragment>
            )}
          </Wrapper>
        </ContainerGrid>
      </ChromeContent>
    </>
  );
}

export default WorkspaceObjectives;
