/**
 * Objective component
 */
import React, { useState, MouseEvent } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
//import * as TabilityTypes from '../../../types';
import styled from 'styled-components';
import { useMutation, useQueryCache } from 'react-query';
import queryKeys from 'config/queryKeys';
import { useHistory } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import theme from 'theme';

// API
import * as remoteApi from 'api/remote';

// Actions
import {
  editorSetDisplayNewBlockForm,
  editorSelectBlock,
  editorDeleteObjective,
} from 'state/actions/workspaceEditorActions';

// UI Components
import BlockGrid, { BlockMenu, BlockGutter, BlockContent, BlockMeta } from './BlockGrid';
import BlockLabel from './BlockLabel';
import { Droppable, Draggable } from 'react-beautiful-dnd';
import DropdownMenu from './DropdownMenu';
import ObjectiveOrOutcomeForm from './ObjectiveOrOutcomeForm';
import ObjectiveTitle from './ObjectiveTitle';
import ObjectiveBlockMeta from './ObjectiveBlockMeta';
import OutcomeBlock from './OutcomeBlock';
//import NewObjectiveOrOutcomeForm from './NewObjectiveOrOutcomeForm';
import IconDrag from './_assets/icon_drag.png';

import Loader from 'components/Loader';
import { CustomTermKey, translate } from 'utils/customTermUtils';
import { Workspace } from 'types';
import KoalaIconButton from 'koala/components/IconButton';
import KoalaButton from 'koala/components/Button';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';

interface Props {
  objectiveId: string;
  objectiveIndex: number;
}

const BlockWrapper = styled.div<{ isDragging: boolean }>`
  background-color: #fff;
  border-radius: 4px;
  font-size: 1.8rem;

  border: ${(props) => (props.isDragging ? '2px solid rgba(164, 220, 229, 1)' : '0px solid #fff')};
  position: relative;
`;

const InnerWrapper = styled.div`
  .add-menu {
    opacity: 0;
    transition: opacity ease 0.2s;
  }

  &:hover {
    .add-menu {
      opacity: 1;
    }
  }
`;

const AddInitiativeWrapper = styled.div`
  display: flex;
  gap: ${theme.spacing.x1};
`;

const removeMenuItems = (workspace: Workspace, t: TFunction) => {
  const items = [];
  items.push(
    <span data-action="delete-objective">
      {t('workspacePlan.write.delete', {
        label: translate(workspace, CustomTermKey.OBJECTIVE, 1).toLowerCase(),
      })}
    </span>,
  );
  items.push(
    <span data-action="archive-objective">
      {t('workspacePlan.write.archive', {
        label: translate(workspace, CustomTermKey.OBJECTIVE, 1).toLowerCase(),
      })}
    </span>,
  );
  return items;
};

function ObjectiveBlock(props: Props) {
  const queryCache = useQueryCache();
  const dispatch = useDispatch();
  const history = useHistory();
  const { objectiveId, objectiveIndex } = props;
  const blockId = `objective:${objectiveId}`;
  const currentWorkspace = useSelector((state: any) => state.session.currentWorkspace, shallowEqual);
  const { t } = useTranslation();

  const [archiveObjectiveMutation, { isLoading: isArchiving }]: [any, any] = useMutation(remoteApi.archiveObjective, {
    onSuccess: () => {
      queryCache.invalidateQueries(queryKeys.objectives);
    },
  });

  const archiveObjective = () => {
    const params = {
      archived: true,
    };
    const mutationParams = {
      objectiveId,
      objective: params,
    };
    archiveObjectiveMutation(mutationParams);
  };

  // This state param will be use to decide which form we should be displaying (objective or outcome)
  const [defaultFormEntityType, setDefaultFormEntityType] = useState('objective');

  // Check if we can find an objective
  const hasObjective = useSelector((state: any) => {
    return state.editorEntities.objectives[objectiveId];
  });
  const insightErrors = useSelector((state: any) => {
    if (!state.editorUI.scorecard) {
      return null;
    }

    return state.editorUI.scorecard.insights.blockInsights[blockId];
  });

  const isSelected = useSelector((state: any) => state.editorUI.selectedBlockId === blockId);

  // Block form if (1) the block is selected AND (2) the displayBlockForm flag is true
  const displayForm = useSelector((state: any) => {
    return isSelected && state.editorUI.displayNewBlockForm;
  });

  const outcomeIds = useSelector((state: any) => state.editorEntities.objectivesToOutcomesMapping[objectiveId] || []);

  // Extract the review comments
  const reviewCommentsCount = useSelector((state: any) => {
    if (state.editorEntities.reviewCommentsByBlockId[blockId]) {
      const reviewComments = state.editorEntities.reviewCommentsByBlockId[blockId];
      let count = 0;
      reviewComments.forEach((reviewComment: any) => {
        count = count + 1 + reviewComment.review_replies_count; // count the initial comment and all replies;
      });
      return count;
    }
    return 0;
  });

  if (!hasObjective) {
    return null;
  }

  const selectedClass = isSelected ? 'selected' : '';

  const handleClick = (e: React.MouseEvent) => {
    dispatch(editorSelectBlock(blockId));
  };

  const handleCreateComment = () => {
    const hashPath = `#editor:${blockId}:comment`;
    history.push(hashPath);
  };

  const handleEdit = () => {
    const hashPath = `#${blockId}:edit`;
    history.push(hashPath);
  };

  const handleAddObjective = () => {
    setDefaultFormEntityType('objective');
    dispatch(editorSelectBlock(blockId));
    dispatch(editorSetDisplayNewBlockForm(true));
  };

  const handleAddOutcome = () => {
    setDefaultFormEntityType('outcome');
    dispatch(editorSelectBlock(blockId));
    dispatch(editorSetDisplayNewBlockForm(true));
  };

  const handleMenuSelection = (value: any) => {
    const action = value.props['data-action'];
    switch (action) {
      case 'archive-objective':
        archiveObjective();
        break;
      case 'delete-objective':
        const deletePlaceholder =
          t('workspacePlan.write.deleteConfirm', {
            label: translate(currentWorkspace, CustomTermKey.OBJECTIVE, 1).toLowerCase(),
          }) ?? 'Are you sure that you want to delete this objective?';
        if (window.confirm(deletePlaceholder)) {
          dispatch(editorDeleteObjective(objectiveId));
        }
        break;
      case 'show-objective-form':
        setDefaultFormEntityType('objective');
        dispatch(editorSelectBlock(blockId));
        dispatch(editorSetDisplayNewBlockForm(true));
        break;
      case 'show-outcome-form':
        setDefaultFormEntityType('outcome');
        dispatch(editorSelectBlock(blockId));
        dispatch(editorSetDisplayNewBlockForm(true));
        break;
    }
  };

  const commentedClass = reviewCommentsCount > 0 ? 'commented' : '';
  const joyrideSelector = `objective-${objectiveIndex}`;

  const handleMenuClick = (e: MouseEvent) => {
    e.stopPropagation();
    ReactTooltip.hide();
  };

  return (
    <Draggable draggableId={blockId} index={props.objectiveIndex} key={blockId}>
      {(provided, snapshot) => {
        return (
          <BlockWrapper
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            isDragging={snapshot.isDragging}
            className={`${joyrideSelector}-drag`}
          >
            <InnerWrapper>
              <BlockGrid
                onClick={handleClick}
                className={`editor-block ${selectedClass} ${insightErrors} ${commentedClass} ${joyrideSelector}`}
              >
                <BlockMenu className="option-menu" onClick={handleMenuClick}>
                  <DropdownMenu
                    trigger={<KoalaIconButton edge="square" iconName="trash" dataFor={`${blockId}-remove-option`} />}
                    position="left"
                    onSelection={handleMenuSelection}
                    items={removeMenuItems(currentWorkspace, t)}
                  />
                  <KoalaIconButton
                    edge="square"
                    iconName="comment"
                    onClick={handleCreateComment}
                    dataFor={`${blockId}-create-comment`}
                  />
                  <KoalaIconButton edge="square" iconName="settings" onClick={handleEdit} dataFor={`${blockId}-edit`} />
                </BlockMenu>
                <ReactTooltip type="dark" id={`${blockId}-add-option`} className="tooltip" effect="solid">
                  {t('shared.add')}
                </ReactTooltip>
                <ReactTooltip type="dark" id={`${blockId}-remove-option`} className="tooltip" effect="solid">
                  {t('shared.remove')}
                </ReactTooltip>
                <ReactTooltip type="dark" id={`${blockId}-create-comment`} className="tooltip" effect="solid">
                  {t('workspacePlan.write.addComment')}
                </ReactTooltip>
                <BlockGutter>
                  {isArchiving && <Loader size="small" />}
                  <div className="icon-button">
                    <img src={IconDrag} alt="Drag" />
                  </div>
                  <BlockLabel className="objective">
                    {translate(currentWorkspace, CustomTermKey.OBJECTIVE, 1)}
                  </BlockLabel>
                </BlockGutter>
                <BlockContent className="objective title">
                  <ObjectiveTitle objectiveId={objectiveId} />
                </BlockContent>
                <BlockMeta>
                  <ObjectiveBlockMeta objectiveId={objectiveId} reviewCommentsCount={reviewCommentsCount} />
                </BlockMeta>
              </BlockGrid>
              {!displayForm && outcomeIds.length === 0 && (
                <BlockGrid className="initiative compact add-menu">
                  <BlockGutter></BlockGutter>
                  <BlockContent className="initiative normal-font">
                    <AddInitiativeWrapper>
                      <KoalaButton size="small" appearance="secondary" onClick={handleAddObjective}>
                        + {translate(currentWorkspace, CustomTermKey.OBJECTIVE, 1).toLowerCase()}
                      </KoalaButton>
                      <KoalaButton size="small" appearance="secondary" onClick={handleAddOutcome}>
                        + {translate(currentWorkspace, CustomTermKey.OUTCOME, 1).toLowerCase()}
                      </KoalaButton>
                    </AddInitiativeWrapper>
                  </BlockContent>
                </BlockGrid>
              )}
            </InnerWrapper>

            <Droppable droppableId={`objective:${objectiveId}`} type="OUTCOMES">
              {(provided, snapshot) => (
                <div
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                  style={{
                    padding: '0.1rem 0',
                    backgroundColor: snapshot.isDraggingOver ? 'rgba(245, 192, 43, 0.4)' : '#fff',
                  }}
                >
                  {outcomeIds.map((outcomeId: string, index: number) => {
                    return (
                      <OutcomeBlock
                        outcomeId={outcomeId}
                        objectiveIndex={objectiveIndex}
                        outcomeIndex={index}
                        key={`outcome:${outcomeId}`}
                      />
                    );
                  })}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
            {displayForm && (
              <ObjectiveOrOutcomeForm defaultFormEntityType={defaultFormEntityType} selectedObjectiveId={objectiveId} />
            )}
          </BlockWrapper>
        );
      }}
    </Draggable>
  );
}

export default React.memo(ObjectiveBlock);
