import React from 'react';
import * as TabilityTypes from 'types';
import { useSelector, shallowEqual } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { getWorkState, getWorkStateColor } from 'utils/initiativeUtils';
import { Base64 } from 'js-base64';

import * as routes from 'routes';

// Translations
import { CustomTermKey, translate } from 'utils/customTermUtils';
import { useTranslation } from 'react-i18next';

import { OverviewCard, OverviewLine } from './';
import KoalaTextBadge from 'koala/components/TextBadge';

interface Props {
  initiativesStats: any;
}

function ActivityGraphs(props: Props) {
  const { initiativesStats } = props;
  const history = useHistory();
  const { t } = useTranslation();
  const currentWorkspace: TabilityTypes.Workspace = useSelector(
    (state: any) => state.session.currentWorkspace,
    shallowEqual,
  );

  const totalInitiativesWarning = initiativesStats.initiatives_overdue_count;

  const prctInitiatives = (initiativesCount: number) => {
    if (initiativesStats.total_initiatives_count === 0) {
      return <span>0%</span>;
    }

    const prct = (initiativesCount / initiativesStats.total_initiatives_count) * 100;
    return <span>{Math.round(prct)}%</span>;
  };

  const goToInitiativesFilter = (filter: any) => {
    const encodedHash = Base64.encode(
      JSON.stringify({
        ...filter,
        plan_status: 'is_in_progress',
        sorting: 'plan',
      }),
    );
    history.push(
      routes.WORKSPACE_INITIATIVE_ROUTE_WITH_FILTERS.replace(':workspaceSlug', currentWorkspace.slug).replace(
        ':filter',
        encodedHash,
      ),
    );
  };

  return (
    <>
      <h3>
        {t('workspaceInsights.initiativesOverviewHeader', {
          label: translate(currentWorkspace, CustomTermKey.INITIATIVE, 2),
        })}
      </h3>
      <OverviewCard>
        <OverviewLine className="header">
          <span>
            {t('workspaceInsights.initiativesOverview.totalActiveInitiatives', {
              label: translate(currentWorkspace, CustomTermKey.INITIATIVE, 2).toLowerCase(),
            })}
          </span>
          <div>
            <span>{initiativesStats.total_initiatives_count}</span>
          </div>
        </OverviewLine>
        <OverviewLine
          role="button"
          tabIndex={0}
          className="clickable"
          onClick={() =>
            goToInitiativesFilter({
              work_state: 'backlog',
            })
          }
        >
          <div className="badge_container">
            <KoalaTextBadge isLowercase={true} variant={getWorkStateColor('backlog')} size="small">
              {getWorkState('backlog', currentWorkspace, t)}
            </KoalaTextBadge>
          </div>
          <span className="backlog">{initiativesStats.backlog_initiatives_count}</span>
          {prctInitiatives(initiativesStats.backlog_initiatives_count)}
        </OverviewLine>
        <OverviewLine
          role="button"
          tabIndex={0}
          className="clickable"
          onClick={() =>
            goToInitiativesFilter({
              work_state: 'planned',
            })
          }
        >
          <div className="badge_container">
            <KoalaTextBadge isLowercase={true} variant={getWorkStateColor('planned')} size="small">
              {getWorkState('planned', currentWorkspace, t)}
            </KoalaTextBadge>
          </div>
          <span className="planned">{initiativesStats.planned_initiatives_count}</span>
          {prctInitiatives(initiativesStats.planned_initiatives_count)}
        </OverviewLine>
        <OverviewLine
          role="button"
          tabIndex={0}
          className="clickable"
          onClick={() =>
            goToInitiativesFilter({
              work_state: 'design',
            })
          }
        >
          <div className="badge_container">
            <KoalaTextBadge isLowercase={true} variant={getWorkStateColor('design')} size="small">
              {getWorkState('design', currentWorkspace, t)}
            </KoalaTextBadge>
          </div>
          <span className="design">{initiativesStats.design_initiatives_count}</span>
          {prctInitiatives(initiativesStats.design_initiatives_count)}
        </OverviewLine>
        <OverviewLine
          role="button"
          tabIndex={0}
          className="clickable"
          onClick={() =>
            goToInitiativesFilter({
              work_state: 'in_progress',
            })
          }
        >
          <div className="badge_container">
            <KoalaTextBadge isLowercase={true} variant={getWorkStateColor('in_progress')} size="small">
              {getWorkState('in_progress', currentWorkspace, t)}
            </KoalaTextBadge>
          </div>
          <span className="in_progress">{initiativesStats.in_progress_initiatives_count}</span>
          {prctInitiatives(initiativesStats.in_progress_initiatives_count)}
        </OverviewLine>
        <OverviewLine
          role="button"
          tabIndex={0}
          className="clickable"
          onClick={() =>
            goToInitiativesFilter({
              work_state: 'in_review',
            })
          }
        >
          <div className="badge_container">
            <KoalaTextBadge isLowercase={true} variant={getWorkStateColor('in_review')} size="small">
              {getWorkState('in_review', currentWorkspace, t)}
            </KoalaTextBadge>
          </div>
          <span className="in_review">{initiativesStats.in_review_initiatives_count}</span>
          {prctInitiatives(initiativesStats.in_review_initiatives_count)}
        </OverviewLine>
        <OverviewLine
          role="button"
          tabIndex={0}
          className="clickable"
          onClick={() =>
            goToInitiativesFilter({
              work_state: 'ready',
            })
          }
        >
          <div className="badge_container">
            <KoalaTextBadge isLowercase={true} variant={getWorkStateColor('ready')} size="small">
              {getWorkState('ready', currentWorkspace, t)}
            </KoalaTextBadge>
          </div>
          <span className="ready">{initiativesStats.ready_initiatives_count}</span>
          {prctInitiatives(initiativesStats.ready_initiatives_count)}
        </OverviewLine>
        <OverviewLine
          role="button"
          tabIndex={0}
          className="clickable"
          onClick={() =>
            goToInitiativesFilter({
              work_state: 'blocked',
            })
          }
        >
          <div className="badge_container">
            <KoalaTextBadge isLowercase={true} variant={getWorkStateColor('blocked')} size="small">
              {getWorkState('blocked', currentWorkspace, t)}
            </KoalaTextBadge>
          </div>
          <span className={initiativesStats.blocked_initiatives_count > 0 ? 'red' : ''}>
            {initiativesStats.blocked_initiatives_count}
          </span>
          {prctInitiatives(initiativesStats.blocked_initiatives_count)}
        </OverviewLine>
        <OverviewLine
          role="button"
          tabIndex={0}
          className="clickable"
          onClick={() =>
            goToInitiativesFilter({
              work_state: 'done',
            })
          }
        >
          <div className="badge_container">
            <KoalaTextBadge isLowercase={true} variant={getWorkStateColor('done')} size="small">
              {getWorkState('done', currentWorkspace, t)}
            </KoalaTextBadge>
          </div>
          <span className="done">{initiativesStats.done_initiatives_count}</span>
          {prctInitiatives(initiativesStats.done_initiatives_count)}
        </OverviewLine>
        <OverviewLine
          role="button"
          tabIndex={0}
          className="clickable"
          onClick={() =>
            goToInitiativesFilter({
              work_state: 'abandoned',
            })
          }
        >
          <div className="badge_container">
            <KoalaTextBadge isLowercase={true} variant={getWorkStateColor('abandoned')} size="small">
              {getWorkState('abandoned', currentWorkspace, t)}
            </KoalaTextBadge>
          </div>
          <span className="abandoned">{initiativesStats.abandoned_initiatives_count}</span>
          {prctInitiatives(initiativesStats.abandoned_initiatives_count)}
        </OverviewLine>
        <OverviewLine className="header">
          <span>{t('workspaceInsights.warnings')}</span>
          <span>{totalInitiativesWarning}</span>
        </OverviewLine>
        <OverviewLine
          role="button"
          tabIndex={0}
          className="clickable"
          onClick={() =>
            goToInitiativesFilter({
              insights: 'is_overdue',
            })
          }
        >
          <span>{t('workspaceInsights.initiativesOverview.overdue')}</span>
          <span className="overdue">{initiativesStats.overdue_initiatives_count}</span>
          {prctInitiatives(initiativesStats.overdue_initiatives_count)}
        </OverviewLine>
      </OverviewCard>
    </>
  );
}

export default ActivityGraphs;
