import React, { Fragment, useState, useEffect } from 'react';
import styled from 'styled-components';
import theme from 'theme';
import * as TabilityTypes from 'types';
import { useLocation, useHistory, Link } from 'react-router-dom';
import yaml from 'js-yaml';
import * as amplitudeUtils from 'utils/amplitude';

import { parseISO, formatDistanceToNow, differenceInCalendarDays } from 'date-fns';

import TemplateHeader from 'components/TemplateHeader';
import OutcomeBlock from './OutcomeBlock';
import OutcomePanel from './OutcomePanel';
import MarkdownContent from 'components/MarkdownContent';
import { CustomTermKey, translate } from 'utils/customTermUtils';

import TwitterSvg from './twitter.svg';
import KoalaIconButton from 'koala/components/IconButton';
import KoalaTextBadge from 'koala/components/TextBadge';

const TemplateContainer = styled.div`
  background: #fff;
  max-width: 90rem;
  width: 100%;
  padding: ${theme.spacing.x3};
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.x3};
`;

const Template = styled.div`
  background: ${theme.colors.N5};
  border: 1px solid ${theme.colors.N20};
  border-radius: 4px;
  padding: ${theme.spacing.x3};
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.x1};
`;

const ObjectiveBlock = styled.div`
  display: grid;
  grid-template-columns: 10rem 1fr;
  grid-template-rows: auto;
  gap: ${theme.spacing.x2};

  > div {
    display: flex;
    align-items: center;
  }

  .label {
    justify-content: flex-end;
  }
  .text {
    font-family: monospace, 'Courier New';
    font-size: 1.3rem;
  }
`;

const PanelWrapper = styled.div<{ showPanel: boolean }>`
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  width: 70rem;
  z-index: 80;
  transform: translateX(${(props) => (props.showPanel ? '0%' : '100%')});
  transition: 0.2s ease-out;

  &.narrow-global-panel {
    width: 60rem;
  }
`;

const PanelContent = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  overflow: auto;
  box-shadow: rgba(21, 21, 21, 0.15) 0 0 10px 0;

  background: #fff;
`;

const PanelMask = styled.div<{ showPanel: boolean }>`
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.2);
  z-index: ${(props) => (props.showPanel ? '70' : '-1')};
  opacity: ${(props) => (props.showPanel ? '1' : '0')};
  transition: 0.1s ease-out;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const EmptyObjective = styled.div`
  padding: ${theme.spacing.x2} ${theme.spacing.x3};
`;

const MetaColumns = styled.div`
  display: flex;
  width: 100%;
  gap: ${theme.spacing.x3};
  @media ${theme.devices.laptop} {
    flex-direction: column;
  }
`;

const NotesContent = styled.div`
  width: 60rem;
`;

const MetaContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.x2};
`;

const InfoBlock = styled.div`
  background: ${theme.colors.N0};
  border: 1px solid ${theme.colors.blockBorder};
  box-sizing: border-box;
  padding: ${theme.spacing.x1} ${theme.spacing.x2};
  border-radius: 4px;
  width: 100%;

  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.x1};

  a.signup {
    color: ${theme.colors.B50};
  }
`;

const ShareButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: ${theme.spacing.x1};
`;

const TwitterImg = styled.img`
  width: 1.8rem;
`;

const TagContainer = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  flex: 1;
  gap: ${theme.spacing.x1};
  margin-bottom: ${theme.spacing.x1};
`;

const HeaderLink = styled.div`
  span {
    display: inline-block;
    transform: rotate(180deg);
  }
  a {
    &:hover {
      text-decoration: underline;
    }
  }
`;

interface Props {
  plan: TabilityTypes.Plan;
}

function PublicPlanPresentationOverview(props: Props) {
  const location = useLocation();
  const history = useHistory();
  const { plan } = props;

  useEffect(() => {
    amplitudeUtils.sendAmplitudeData('app.templates.details.visit', {
      'Tability Platform': 'web',
      'Tability Version': 2,
      Template: plan.title,
    });
  }, [plan.title]);

  const { workspace } = plan;
  const currentWorkspace = workspace;

  const [isCopied, setIsCopied] = useState(false);

  const planFinishAt = plan && plan.finish_at ? parseISO(plan.finish_at) : null;
  let differenceInDays = planFinishAt ? differenceInCalendarDays(planFinishAt, new Date()) : 0;
  if (differenceInDays < 0) {
    differenceInDays = 0;
  }
  const isBlockSelected = (blockId: string) => {
    return location.hash.includes(blockId);
  };

  const handleClosePanel = (e: React.MouseEvent) => {
    e.preventDefault();
    history.push(location.pathname);
  };

  // Show the panel if we have a outcome block id in the URL
  const showPanel = location.hash.includes('#outcome:');

  // Strip HTML from text to see if we have an empty field.
  // @ts-ignore
  let doc = new DOMParser().parseFromString(plan.notes, 'text/html');
  const notesAreEmpty = !plan.notes || !doc.body.textContent || doc.body.textContent === '';

  const handleCopyUrl = () => {
    navigator.clipboard.writeText(window.location.href);
    setIsCopied(true);
  };

  const handleTweet = () => {
    const text = `Check out the plan "${plan.title}" — a free resource on @tabilityio`;
    const tweetUrl = `https://twitter.com/intent/tweet?url=${window.location.href}&text=${text}`;
    if (window) {
      window.open(tweetUrl, '_blank');
    }
  };

  let objectives = [];
  try {
    // @ts-ignore
    const content = yaml.load(plan.yaml_content);
    // @ts-ignore
    objectives = content.objectives;
  } catch (e) {}

  const tagList = plan.cached_tag_list || '';
  const tags = tagList.length > 0 ? tagList.split(',') : [];

  return (
    <>
      <TemplateHeader />
      <PanelMask onClick={handleClosePanel} showPanel={showPanel} />
      <PanelWrapper showPanel={showPanel}>
        <PanelContent>{showPanel && <OutcomePanel />}</PanelContent>
      </PanelWrapper>
      <TemplateContainer>
        <HeaderLink>
          <Link to="/templates">
            <span>→</span> back to all templates
          </Link>
        </HeaderLink>
        <h1>{plan.title}</h1>
        <p>
          {workspace.name} &middot; Published {formatDistanceToNow(parseISO(plan.created_at), { addSuffix: true })}
        </p>
        <Template>
          {objectives.map((objective: any, index: number) => {
            const outcomesToDisplay: TabilityTypes.Outcome[] = objective.outcomes || [];
            return (
              <Fragment key={`objective-${index}`}>
                <ObjectiveBlock>
                  <div className="label">
                    <KoalaTextBadge variant="teal" edge="circle">
                      Objective
                    </KoalaTextBadge>
                  </div>
                  <div className="text">{objective.title}</div>
                </ObjectiveBlock>
                {outcomesToDisplay.length === 0 && (
                  <EmptyObjective className="subtle">
                    This {translate(currentWorkspace, CustomTermKey.OBJECTIVE, 1).toLowerCase()} does not have any{' '}
                    {translate(currentWorkspace, CustomTermKey.OUTCOME, 2).toLowerCase()}.
                  </EmptyObjective>
                )}
                {outcomesToDisplay.map((outcome, index: number) => {
                  //const outcome = outcomes.find((o) => o.id === outcomeId);
                  if (outcome) {
                    const blockId = `outcome:${outcome.nano_slug}`;
                    const isSelected = isBlockSelected(blockId);
                    return <OutcomeBlock outcome={outcome} key={`outcome-${index}`} isSelected={isSelected} />;
                  } else {
                    return null;
                  }
                })}
              </Fragment>
            );
          })}
        </Template>
        <MetaColumns>
          <NotesContent>{!notesAreEmpty && <MarkdownContent source={plan.notes} />}</NotesContent>
          <MetaContent>
            <InfoBlock>
              <b>
                Made for{' '}
                <a href="https://www.tability.io" target="_blank" rel="noopener noreferrer">
                  Tability
                </a>
              </b>
              <span>Use Tability to track all your goals and initiatives in one place.</span>
              <Link
                to={`/signup?utm_source=tability&utm_medium=templates&utm_campaign=${plan.nano_slug}`}
                className="signup"
              >
                Get started →
              </Link>
            </InfoBlock>
            {tags.length > 0 && (
              <TagContainer>
                {tags.map((tag: string) => {
                  return (
                    <KoalaTextBadge
                      isLowercase={true}
                      key={tag}
                      linkTo={`/templates/${tag}`}
                      variant="neutral-outlined"
                      edge="circle"
                      size="small"
                    >
                      {tag.toLowerCase()}
                    </KoalaTextBadge>
                  );
                })}
              </TagContainer>
            )}
            <b>Share</b>
            <ShareButtonsContainer>
              <KoalaIconButton iconName="link" onClick={handleCopyUrl} />
              <KoalaIconButton onClick={handleTweet}>
                <TwitterImg src={TwitterSvg} alt="Twitter" />
              </KoalaIconButton>
            </ShareButtonsContainer>
            {isCopied && <span>Link copied</span>}
          </MetaContent>
        </MetaColumns>
      </TemplateContainer>
    </>
  );
}

export default PublicPlanPresentationOverview;
