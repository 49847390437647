/**
  This is the playground editor. It loads the state from local storage and saves there too
 */

import React, { MouseEvent, useState } from 'react';
import styled from 'styled-components';
import theme from 'theme';
import { useParams } from 'react-router-dom';
import * as workspaceUtils from 'utils/workspaceUtils';
import * as membershipUtils from 'utils/membershipUtils';
import { darken } from 'polished';

// Routes
import * as routes from 'routes';

// Components
import ReactTooltip from 'react-tooltip';
import UpgradeIcon from 'components/_assets/UpgradeIcon';
import KoalaProgressBar from 'koala/components/ProgressBar';

import { useDispatch, useSelector, shallowEqual } from 'react-redux';

import { setGlobalModalContent } from 'state/actions/globalUIActions';
import devices from 'theme/devices';
import KoalaIcon from 'koala/components/Icons';
import KoalaTextBadge from 'koala/components/TextBadge';
import { Membership, Workspace } from 'types';
import { getWorkspaceDefaultTheme, WorkspaceThemeKey } from 'utils/workspaceThemeUtils';
// import { useMutation } from 'react-query';
// import * as remoteApi from 'api/remote';
// import { setCurrentMembership } from 'state/actions/sessionActions';
import { useTranslation } from 'react-i18next';
import SidebarNavLink from './SidebarNavLink';
import KoalaIconButton from 'koala/components/IconButton';

// import GettingStartedButton from './GettingStartedButton';

const Container = styled.div<{ activeColor: string; textColor: string; iconColor: string }>`
  display: flex;
  height: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 1rem;

  .nav-link {
    width: 100%;
    height: 4.4rem;
    display: flex;
    align-items: center;
    color: ${(props) => props.textColor};
    font-size: 1.4rem;
    font-weight: 400;
    padding: 0 ${theme.spacing.x2};
    position: relative;
    .icon--custom {
      color: ${(props) => props.iconColor};
    }

    &.enabled:hover {
      background: ${(props) => props.activeColor};
      text-decoration: none;
    }
    &.active {
      background: ${(props) => props.activeColor};
    }
    &.quickstart {
      svg {
        height: 2rem;
        circle,
        path {
          stroke: ${(props) => props.iconColor};
        }
      }
    }

    span {
      &.inbox-label {
        margin-left: ${theme.spacing.x2};
        flex: 1;
        display: flex;
        gap: ${theme.spacing.x1};
        align-items: center;
      }
      &.inbox-notification {
        font-size: 1rem;
        font-weight: 800;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        width: 2.4rem;
        height: 2.4rem;
        background: #0d383b;

        &.pending_checkins {
          background: ${theme.colors.red};
        }
      }
    }

    @media ${devices.minMobile} and (${theme.devices.desktop}) {
      height: 4rem;
      width: 4rem;
      padding: 0;
      justify-content: center;

      span {
        display: none;
        &.inbox-label {
          display: none;
        }
      }
      .new-icon {
        display: none;
      }
      .upgrade-icon {
        display: none;
      }

      .inbox-notification-badge {
        position: absolute;
        top: -6px;
        right: -6px;
      }
    }

    // manage settings display
    &.settings {
      display: none;
      border: none;
      @media ${devices.desktop} {
        display: flex;
        text-align: left;
        border-radius: 0px;
      }
    }
  }

  .accelerator {
    border-top: 1px dotted ${(props) => props.iconColor};
    border-bottom: 1px dotted ${(props) => props.iconColor};
  }

  &.collapsed {
    .tooltipContainer {
      display: initial;
    }
    .section-header {
      display: none;
    }
    .nav-link {
      height: 4rem;
      width: 4rem;
      padding: 0;
      justify-content: center;

      span {
        display: none;
      }
      .upgrade-icon {
        display: none;
      }
      .new-icon {
        display: none;
      }

      &.settings {
        display: flex;
        text-align: left;
      }
    }
  }

  .span-icon {
    color: ${(props: any) => props.color || '#000'};
    font-size: 2.2rem;
    font-weight: 600;
  }
  @media ${devices.minMobile} and (${theme.devices.desktop}) {
    .tooltipContainer {
      display: initial;
    }
  }
`;

const SidebarDivider = styled.div<{ bgColor: string }>`
  border-top: 1px solid ${(props) => darken(0.15, props.bgColor)};
  filter: brightness(0.8);
`;
const SidebarSection = styled.div<{ color: string }>`
  width: 100%;
  padding: 1.2rem ${theme.spacing.x2};
  text-transform: uppercase;
  font-size: 10px;
  font-weight: 800;
  color: ${(props) => props.color};
  letter-spacing: 0.5px;

  @media ${devices.minMobile} and (${theme.devices.desktop}) {
    display: none;
  }
`;

const ScrollableContainer = styled.div`
  height: 100%;
  overflow: auto;
  overflow-x: hidden;
  width: 100%;
  min-height: 2px;
  position: relative;
`;

const CheckinsLimitContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: ${theme.spacing.x1};
  align-items: flex-start;
  .checkins-quota-text {
    white-space: nowrap;
  }
`;

const StaticBottomContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`;

const SidebarButton = styled.button<{ activeColor: string; textColor: string; iconColor: string }>`
  background: transparent;
  border: 0px solid ${(props) => props.iconColor};
  width: 100%;
  display: flex;
  align-items: center;
  font-size: 1.4rem;
  color: ${(props) => props.textColor};
  font-weight: 600;
  border-radius: 0;
  padding-left: ${theme.spacing.x2};
  padding-right: ${theme.spacing.x2};
  gap: ${theme.spacing.x1};

  &:hover {
    background: ${(props) => props.activeColor};
    filter: brightness(0.9);
  }

  &.collapsed {
    height: 4rem;
    width: 4rem;
    padding: 0;
    justify-content: center;
    span {
      display: none;
    }
    svg {
      margin-right: 0;
    }
  }

  @media ${theme.devices.minMobile} and (${theme.devices.desktop}) {
    height: 4rem;
    width: 4rem;
    padding: 0;
    justify-content: center;
    span {
      display: none;
    }
    .invite-button-icon {
      margin-right: 0;
    }
  }

  @media ${theme.devices.desktop} {
    margin-top: 0rem;
  }
`;

const SidebarButtonBlock = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #fff;
  p {
    text-align: center;
  }
  p + p {
    margin-top: ${theme.spacing.x1};
  }
  img {
    width: 13rem;
  }

  @media ${theme.devices.desktop} {
    .hide-mobile {
      display: none;
    }
  }

  &.collapsed {
    .hide-mobile {
      display: none;
    }
  }
`;

const QuotaButton = styled.button<{ activeColor: string; textColor: string; iconColor: string }>`
  background: transparent;
  color: ${(props) => props.textColor};
  width: 100%;
  display: flex;
  align-items: center;
  font-size: 1.4rem;
  border: 0;
  font-weight: 600;
  border-radius: 8px;
  padding: ${theme.spacing.x2};

  &:hover {
    background: ${(props) => props.activeColor};
  }

  .invite-button-icon {
    margin-right: ${theme.spacing.x1};
  }

  &.collapsed {
    display: none;
  }

  @media ${theme.devices.minMobile} and (${theme.devices.desktop}) {
    display: none;
  }

  @media ${theme.devices.desktop} {
    margin-top: 0rem;
  }
`;

const HelpPanel = styled.div`
  position: absolute;
  bottom: 5rem;
  height: 11rem;
  left: ${theme.spacing.x2};
  width: 25rem;
  z-index: 60;
  box-shadow: 0px 0px 8px rgba(21, 21, 21, 0.25);
  border-radius: 8px;
  background: #fff;
  transition: 0.2s ease-out;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  padding: ${theme.spacing.x2};

  .close-button {
    position: absolute;
    top: ${theme.spacing.half};
    right: ${theme.spacing.half};
  }

  a,
  .secondary {
    line-height: 1.4;
    height: auto;
    display: inline-block;
    text-align: left;

    text-decoration: none;
    font-weight: 600;
    color: #151515;
    font-size: 1.4rem;
    margin: 0;
    &:hover {
      text-decoration: underline;
      color: #151515;
    }

    margin-bottom: ${theme.spacing.x1};
  }
`;

interface Props {
  workspace: Workspace;
  showSettings: (e: MouseEvent) => void;
  handleCloseMobileSidebar: (e: MouseEvent) => void;
  isCollapsed: boolean;
}

function InboxNav(props: Props) {
  const dispatch = useDispatch();
  const { workspace, showSettings, handleCloseMobileSidebar, isCollapsed } = props;
  const { workspaceSlug } = useParams<{ workspaceSlug: string }>();
  const currentMembership: Membership = useSelector((state: any) => state.session.currentMembership, shallowEqual);
  const currentWorkspace: Workspace = useSelector((state: any) => state.session.currentWorkspace, shallowEqual);
  const isReadOnly = membershipUtils.isReadOnly(currentMembership);
  const isEmptyWorkspace = useSelector((state: any) => state.session.isEmptyWorkspace);
  const notEnoughUsers = currentWorkspace.memberships_count < 3;
  const currentPing = useSelector((state: any) => state.session.currentPing, shallowEqual);
  const defaultTheme = getWorkspaceDefaultTheme(workspace);
  const { t } = useTranslation();

  const [showHelpPanel, setShowHelpPanel] = useState(false);

  const handleInvite = (e: MouseEvent) => {
    e.preventDefault();
    const action = `workspace:${workspaceSlug}:invite`;
    dispatch(setGlobalModalContent(action));
  };

  const handleShowCheckinsQuota = (e: MouseEvent) => {
    e.preventDefault();
    const action = 'all:_:checkins_quota';
    dispatch(setGlobalModalContent(action));
  };

  let inboxNotificationsCount = 0;
  let hasPendingCheckingsClass = '';

  if (currentPing && currentPing.inbox) {
    const inboxStats = currentPing.inbox;
    inboxNotificationsCount = inboxStats.pending_checkins + inboxStats.now_active_initiatives;
    hasPendingCheckingsClass = inboxStats.pending_checkins > 0 ? 'pending_checkins' : '';
  }

  const hasEssentialsSubscription =
    workspaceUtils.hasEssentialsSubscription(workspace) || workspace.pricing_version >= 2;

  let showInvites = true;
  const isAdmin = ['admin', 'owner'].includes(currentMembership.role);

  const isV4FreePlan = workspace.billing_subscription_plan === 'tability-v4-free';
  const isV4BasicPlan = workspace.billing_subscription_plan === 'tability-v4-basic';
  const hasV4PremiumSubscription = workspaceUtils.hasV4PremiumSubscription(workspace);
  const isV4Trial = workspaceUtils.hasV4Trial(workspace);

  let teamsTooltip = t('sidebar.teams');
  let standupsTooltip = t('sidebar.standups');
  if (notEnoughUsers) {
    teamsTooltip = t('sidebar.disabledNotEnoughUsers', { userCount: 3, section: t('sidebar.teams') });
    standupsTooltip = t('sidebar.disabledNotEnoughUsers', { userCount: 3, section: t('sidebar.standups') });
  }

  if (isEmptyWorkspace) {
    teamsTooltip = t('sidebar.disabledEmpty');
    standupsTooltip = t('sidebar.disabledEmpty');
  }

  if (workspace.invites_restricted && !isAdmin) {
    showInvites = false;
  }

  // Collapse the nav if we're in the map view
  const collapsedClass = isCollapsed ? 'collapsed' : '';

  // Calculate the check-ins usage prct
  let checkinsQuotaPrct = 100;
  if (workspace.billing_checkins_limit) {
    checkinsQuotaPrct = Math.min(100, (workspace.checkins_count / workspace.billing_checkins_limit) * 100);
  }

  return (
    <Container
      className={collapsedClass}
      activeColor={workspace.theme_link_active_bg ?? defaultTheme[WorkspaceThemeKey.sidebarActiveBg]}
      textColor={workspace.theme_link_text ?? defaultTheme[WorkspaceThemeKey.sidebarText]}
      iconColor={workspace.theme_link_icon ?? defaultTheme[WorkspaceThemeKey.sidebarIcon]}
    >
      <ScrollableContainer>
        {isV4Trial && false && (
          <SidebarNavLink
            to={`/${workspaceSlug}/accelerator`}
            dataFor="workspaceAcceleratorLink"
            onClick={handleCloseMobileSidebar}
            className="nav-link accelerator"
          >
            <KoalaIcon iconName="compass" />
            <span className="inbox-label">{t('dashboardWorkspaces.title')}</span>
          </SidebarNavLink>
        )}
        <SidebarNavLink
          className="dashboard-link nav-link"
          to={routes.WORKSPACE_INBOX_ROUTE.replace(':workspaceSlug', workspaceSlug)}
          dataFor="workspaceInboxLink"
          onClick={handleCloseMobileSidebar}
        >
          <KoalaIcon iconName="home" />
          <span className="inbox-label">{t('sidebar.dashboard')}</span>
          {inboxNotificationsCount > 0 && (
            <KoalaTextBadge
              size="small"
              edge="circle"
              textColor={
                hasPendingCheckingsClass
                  ? theme.colors.N0
                  : workspace.theme_badge_text ?? defaultTheme[WorkspaceThemeKey.sidebarBadgeText]
              }
              backgroundColor={
                hasPendingCheckingsClass
                  ? theme.colors.red
                  : workspace.theme_badge_bg ?? defaultTheme[WorkspaceThemeKey.sidebarBadgeBg]
              }
              className="inbox-notification-badge"
            >
              {inboxNotificationsCount}
            </KoalaTextBadge>
          )}
        </SidebarNavLink>

        <SidebarDivider bgColor={workspace.theme_alt_bg ?? defaultTheme[WorkspaceThemeKey.sidebarAltBg]} />
        <SidebarSection
          color={workspace.theme_link_text ?? defaultTheme[WorkspaceThemeKey.sidebarText]}
          className="section-header"
        >
          <span className="inbox-label">{t('workspaceInbox.work')}</span>
        </SidebarSection>
        <SidebarNavLink
          to={routes.WORKSPACE_PLANS_ROUTE.replace(':workspaceSlug', workspaceSlug)}
          dataFor="workspacePlansLink"
          onClick={handleCloseMobileSidebar}
          className="nav-link"
        >
          <KoalaIcon iconName="plan" />
          <span className="inbox-label">{t('sidebar.plans')}</span>
        </SidebarNavLink>
        <SidebarNavLink
          to={routes.WORKSPACE_MAP_ROUTE.replace(':workspaceSlug', workspaceSlug)}
          dataFor="workspaceMapLink"
          onClick={handleCloseMobileSidebar}
          className="nav-link"
          isDisabled={isEmptyWorkspace}
        >
          <KoalaIcon iconName="map" />
          <span className="inbox-label">{t('sidebar.map')}</span>
          {!hasEssentialsSubscription && <UpgradeIcon className="upgrade-icon" />}
          {workspace.pricing_version === 4 && isV4FreePlan && !workspace.billing_has_done_marketing && (
            <UpgradeIcon className="upgrade-icon" />
          )}
        </SidebarNavLink>
        {!isV4FreePlan && !isReadOnly && (
          <SidebarNavLink
            to={routes.WORKSPACE_STANDUPS_ROUTE.replace(':workspaceSlug', workspaceSlug)}
            dataFor="workspaceStandupsLink"
            onClick={handleCloseMobileSidebar}
            className="nav-link"
            isDisabled={isEmptyWorkspace || notEnoughUsers}
          >
            <KoalaIcon iconName="standup" />
            <span className="inbox-label">{t('sidebar.standups')}</span>
          </SidebarNavLink>
        )}
        <SidebarDivider bgColor={workspace.theme_alt_bg ?? defaultTheme[WorkspaceThemeKey.sidebarAltBg]} />
        <SidebarSection
          color={workspace.theme_link_text ?? defaultTheme[WorkspaceThemeKey.sidebarText]}
          className="section-header"
        >
          {t('workspaceInbox.reports')}
        </SidebarSection>
        <SidebarNavLink
          to={routes.WORKSPACE_INSIGHTS_ROUTE.replace(':workspaceSlug', workspaceSlug)}
          dataFor="workspaceInsightsLink"
          onClick={handleCloseMobileSidebar}
          className="nav-link"
          isDisabled={isEmptyWorkspace}
        >
          <KoalaIcon iconName="insights" />
          <span className="inbox-label">{t('sidebar.insights')}</span>
          {!hasV4PremiumSubscription && <UpgradeIcon className="upgrade-icon" />}
        </SidebarNavLink>
        {!isV4FreePlan && (
          <SidebarNavLink
            to={routes.WORKSPACE_DASHBOARDS_ROUTE.replace(':workspaceSlug', workspaceSlug)}
            dataFor="workspaceDashboardLink"
            onClick={handleCloseMobileSidebar}
            className="nav-link"
            isDisabled={isEmptyWorkspace}
          >
            <KoalaIcon iconName="cards" />
            <span className="inbox-label">{t('sidebar.dashboards')}</span>
          </SidebarNavLink>
        )}

        {!isV4FreePlan && (
          <SidebarNavLink
            to={routes.WORKSPACE_FILTERS_ROUTE.replace(':workspaceSlug', workspaceSlug)}
            dataFor="workspaceFilters"
            onClick={handleCloseMobileSidebar}
            className="filters-link nav-link"
            isDisabled={isEmptyWorkspace}
          >
            <KoalaIcon iconName="goals" />
            <span className="inbox-label">{t('sidebar.filters')}</span>
            {!hasEssentialsSubscription && <UpgradeIcon className="upgrade-icon" />}
          </SidebarNavLink>
        )}

        {!isReadOnly && !isV4FreePlan && (
          <>
            <SidebarDivider bgColor={workspace.theme_alt_bg ?? defaultTheme[WorkspaceThemeKey.sidebarAltBg]} />
            <SidebarSection
              color={workspace.theme_link_text ?? defaultTheme[WorkspaceThemeKey.sidebarText]}
              className="section-header"
            >
              {t('workspaceInbox.org')}
            </SidebarSection>
            <SidebarNavLink
              to={routes.WORKSPACE_PEOPLE_ROUTE.replace(':workspaceSlug', workspaceSlug)}
              dataFor="workspacePeopleLink"
              onClick={handleCloseMobileSidebar}
              className="nav-link"
              isDisabled={isEmptyWorkspace}
            >
              <KoalaIcon iconName="person" />
              <span className="inbox-label">{t('sidebar.people')}</span>
            </SidebarNavLink>

            {!isV4BasicPlan && (
              <SidebarNavLink
                to={routes.WORKSPACE_TEAMS_ROUTE.replace(':workspaceSlug', workspaceSlug)}
                dataFor="workspaceTeamsLink"
                onClick={handleCloseMobileSidebar}
                className="nav-link"
                isDisabled={isEmptyWorkspace || notEnoughUsers}
              >
                <KoalaIcon iconName="team" />
                <span className="inbox-label">{t('sidebar.teams')}</span>
              </SidebarNavLink>
            )}
          </>
        )}
        {currentMembership && ['admin', 'owner'].includes(currentMembership.role) && (
          <button className="settings icon nav-link" onClick={showSettings}>
            <KoalaIcon iconName="settings" />
            <span className="inbox-label">{t('sidebar.settings')}</span>
          </button>
        )}
      </ScrollableContainer>
      <StaticBottomContainer>
        <SidebarDivider bgColor={workspace.theme_alt_bg ?? defaultTheme[WorkspaceThemeKey.sidebarAltBg]} />
        {workspace.billing_checkins_limit > 0 && workspace.pricing_version >= 3 && (
          <QuotaButton
            className={collapsedClass}
            onClick={handleShowCheckinsQuota}
            textColor={workspace.theme_link_text ?? defaultTheme[WorkspaceThemeKey.sidebarText]}
            iconColor={workspace.theme_link_icon ?? defaultTheme[WorkspaceThemeKey.sidebarIcon]}
            activeColor={workspace.theme_link_active_bg ?? defaultTheme[WorkspaceThemeKey.sidebarActiveBg]}
          >
            <CheckinsLimitContainer>
              <KoalaProgressBar
                value={checkinsQuotaPrct}
                appearance="custom"
                bgColor={workspace.theme_alt_bg ?? defaultTheme[WorkspaceThemeKey.sidebarAltBg]}
                barColor={workspace.theme_link_icon ?? defaultTheme[WorkspaceThemeKey.sidebarIcon]}
              />
              <div className="checkins-quota-text">
                {t('sidebar.checkinsUsed', {
                  count: workspace.checkins_count,
                  limit: workspace.billing_checkins_limit,
                })}
              </div>
            </CheckinsLimitContainer>
          </QuotaButton>
        )}
        <SidebarButtonBlock className={collapsedClass}>
          <SidebarButton
            textColor={workspace.theme_link_text ?? defaultTheme[WorkspaceThemeKey.sidebarText]}
            iconColor={workspace.theme_link_icon ?? defaultTheme[WorkspaceThemeKey.sidebarIcon]}
            activeColor={workspace.theme_link_active_bg ?? defaultTheme[WorkspaceThemeKey.sidebarActiveBg]}
            onClick={() => setShowHelpPanel(!showHelpPanel)}
            className={`help ${collapsedClass}`}
          >
            <KoalaIcon
              iconName="help"
              className="invite-button-icon"
              iconAppearance={workspace.theme_link_icon ?? defaultTheme[WorkspaceThemeKey.sidebarIcon]}
            />
            <span className="inbox-label">{t('sidebar.guides')}</span>
          </SidebarButton>
          {showHelpPanel && (
            <HelpPanel>
              <div className="close-button">
                <KoalaIconButton onClick={() => setShowHelpPanel(false)} iconName="close" size="small" />
              </div>
              <a href="https://guides.tability.app" target="_blank" rel="noopener noreferrer">
                {t('components.workspaceHeader.helpDocs')}
              </a>
              <a href="https://feedback.tability.io/feature-requests" target="_blank" rel="noopener noreferrer">
                {t('components.workspaceHeader.helpFeeback')}
              </a>
              <a href="https://feedback.tability.io" target="_blank" rel="noopener noreferrer">
                {t('components.workspaceHeader.helpRoadmap')}
              </a>
            </HelpPanel>
          )}
        </SidebarButtonBlock>
        {showInvites && (
          <SidebarButtonBlock className={collapsedClass}>
            <SidebarButton
              textColor={workspace.theme_link_text ?? defaultTheme[WorkspaceThemeKey.sidebarText]}
              iconColor={workspace.theme_link_icon ?? defaultTheme[WorkspaceThemeKey.sidebarIcon]}
              activeColor={workspace.theme_link_active_bg ?? defaultTheme[WorkspaceThemeKey.sidebarActiveBg]}
              onClick={handleInvite}
              className={`invite ${collapsedClass}`}
            >
              <KoalaIcon
                iconName="personPlus"
                className="invite-button-icon"
                iconAppearance={workspace.theme_link_icon ?? defaultTheme[WorkspaceThemeKey.sidebarIcon]}
              />
              <span className="inbox-label">{t('sidebar.inviteTeam')}</span>
            </SidebarButton>
          </SidebarButtonBlock>
        )}
      </StaticBottomContainer>
      <div className="tooltipContainer">
        <ReactTooltip place="bottom" type="dark" id="workspaceInboxLink" className="tooltip" effect="solid">
          {t('sidebar.dashboard')}
        </ReactTooltip>
        <ReactTooltip place="bottom" type="dark" id="workspaceDashboardLink" className="tooltip" effect="solid">
          {isEmptyWorkspace ? t('sidebar.disabledEmpty') : t('sidebar.dashboards')}
        </ReactTooltip>
        <ReactTooltip place="bottom" type="dark" id="workspaceStandupsLink" className="tooltip" effect="solid">
          {standupsTooltip}
        </ReactTooltip>
        <ReactTooltip place="bottom" type="dark" id="workspacePlansLink" className="tooltip" effect="solid">
          {t('sidebar.plans')}
        </ReactTooltip>
        <ReactTooltip
          place="bottom"
          type="dark"
          id="workspaceInsightsLink"
          className="tooltip"
          effect="solid"
          delayShow={200}
        >
          {isEmptyWorkspace ? t('sidebar.disabledEmpty') : t('sidebar.insights')}
        </ReactTooltip>
        <ReactTooltip
          place="bottom"
          type="dark"
          id="workspaceMapLink"
          className="tooltip"
          effect="solid"
          delayShow={200}
        >
          {isEmptyWorkspace ? t('sidebar.disabledEmpty') : t('sidebar.map')}
        </ReactTooltip>
        <ReactTooltip place="bottom" type="dark" id="workspaceFilters" className="tooltip" effect="solid">
          {isEmptyWorkspace ? t('sidebar.disabledEmpty') : t('sidebar.filters')}
        </ReactTooltip>
        <ReactTooltip place="bottom" type="dark" id="workspacePlansGettingStarted" className="tooltip" effect="solid">
          {t('sidebar.guides')}
        </ReactTooltip>
        <ReactTooltip place="bottom" type="dark" id="workspaceTeamsLink" className="tooltip" effect="solid">
          {teamsTooltip}
        </ReactTooltip>
        <ReactTooltip place="bottom" type="dark" id="workspacePeopleLink" className="tooltip" effect="solid">
          {isEmptyWorkspace ? t('sidebar.disabledEmpty') : t('sidebar.people')}
        </ReactTooltip>
      </div>
    </Container>
  );
}

export default InboxNav;
