import React from 'react';
import styled from 'styled-components';
import theme from 'theme';
import * as TabilityTypes from 'types';
import { Link, useParams } from 'react-router-dom';
import * as routes from 'routes';
import * as checkinUtils from 'utils/checkinUtils';

// Routes
import KoalaColorBlock from 'koala/components/ColorBlock';
import PlanIconLabel from 'components/PlanIconLabel';

export const BlockGutter = styled.div`
  position: relative;
  grid-area: gutter;
  display: flex;
  align-items: flex-start;
  height: 100%;

  > div {
    position: relative;
    top: 5px;
  }
`;
export const BlockContent = styled.div`
  grid-area: content;
  margin: 0;
  height: 100%;
  min-width: 1rem;

  display: flex;
  align-items: flex-start;
  flex: 1;
  justify-content: space-between;
`;

const BlockGrid = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  grid-template-rows: auto;
  grid-template-areas:
    'gutter content'
    'plan plan';
  align-items: center;
  gap: ${theme.spacing.half};
  margin-left: ${theme.spacing.x2};
  width: 100%;

  @media ${theme.devices.mobile} {
    grid-template-areas: 'gutter content';
    grid-template-columns: auto 1fr;
  }
`;

const BlockGridLink = styled(Link)`
  display: grid;
  cursor: pointer;
  grid-template-columns: auto 1fr;
  grid-template-rows: auto;
  grid-template-areas:
    'gutter content'
    'plan plan';
  align-items: center;
  gap: ${theme.spacing.half};
  margin-left: ${theme.spacing.x2};
  width: 100%;

  &:hover {
    cursor: pointer;
  }
  a {
    &:hover {
      text-decoration: underline;
    }
  }
  &:focus,
  &.selected,
  &:active {
    background: ${theme.colors.B5};
  }

  @media ${theme.devices.mobile} {
    grid-template-areas: 'gutter content';
    grid-template-columns: auto 1fr;
  }
`;

const BlockPlan = styled.div`
  grid-area: plan;
  display: flex;
  align-items: center;
  font-size: 1.2rem;
  a {
    color: ${theme.colors.subtleText};
    &:hover {
      text-decoration: underline;
    }
  }
  color: ${theme.colors.subtleText};
  @media ${theme.devices.mobile} {
    display: none;
  }
`;

interface Props {
  outcome: TabilityTypes.Outcome;
  aligned?: boolean;
  unlinked?: boolean;
}

function OutcomeBlock(props: Props) {
  const { workspaceSlug } = useParams<{ workspaceSlug: string }>();

  const { outcome, unlinked } = props;
  const { plan } = outcome;
  const planUrl = routes.WORKSPACE_PLAN_TRACK_ROUTE.replace(':workspaceSlug', workspaceSlug).replace(
    ':planId',
    plan.id,
  );

  const blockId = `outcome:${outcome.nano_slug}`;
  const hashPath = `#${blockId}:show`;

  const currentCheckin = outcome.current_checkin;

  // Calculate the progress prct

  // Use the checkin date, or the plan start date if we don't have the checkin date
  // Fall back to today if start date is not set on the plan. This could happen if for some reason a user has removed
  // the start date of a plan after tracking is enabled.

  const currentConfidence = currentCheckin ? currentCheckin.confidence : 'grey';
  const confidenceColor = checkinUtils.confidenceToColor(currentConfidence);

  if (unlinked) {
    return (
      <BlockGrid className={`item`}>
        <BlockGutter>
          <KoalaColorBlock
            color={confidenceColor}
            size="xsmall"
            isPending={outcome.is_pending_checkin}
            isCompleted={outcome.completed}
          />
        </BlockGutter>
        <BlockContent>{outcome.title}</BlockContent>
        <BlockPlan>
          <PlanIconLabel plan={plan} size="xsmall" crop={50} />
        </BlockPlan>
      </BlockGrid>
    );
  }

  return (
    <BlockGridLink to={hashPath} className={`item`}>
      <BlockGutter>
        <KoalaColorBlock
          color={confidenceColor}
          size="xsmall"
          isPending={outcome.is_pending_checkin}
          isCompleted={outcome.completed}
        />
      </BlockGutter>
      <BlockContent>
        <Link to={hashPath} title={outcome.title}>
          {outcome.title}
        </Link>
      </BlockContent>
      <BlockPlan>
        <Link to={planUrl} onClick={(e) => e.stopPropagation()}>
          <PlanIconLabel plan={plan} size="xsmall" crop={50} />
        </Link>
      </BlockPlan>
    </BlockGridLink>
  );
}

export default React.memo(OutcomeBlock);
