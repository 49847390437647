import InitiativeRow from 'components/InitiativeRow';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { shallowEqual, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import theme from 'theme';
import { Initiative, Objective, Outcome } from 'types';
import { CustomTermKey, translate } from 'utils/customTermUtils';
import NewInitiativeForm from './NewInitiativeForm';
import KoalaIcon from 'koala/components/Icons';

const ObjectiveTitle = styled.div`
  display: flex;
  padding: ${theme.spacing.x1} ${theme.spacing.x2};
  font-weight: bold;
  left: 0px;
  z-index: 1;
  width: 100%;
  border-bottom: 1px solid ${theme.colors.N20};
`;

const OutcomeBlock = styled.div`
  display: flex;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const Block = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${theme.spacing.x1} ${theme.spacing.x2};
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: ${theme.colors.N60};
`;
const OutcomeTitleBlock = styled(Block)`
  border-bottom: 1px solid ${theme.colors.N20};
  border-right: 1px solid ${theme.colors.N20};
  color: ${theme.colors.N60};
  background-color: ${theme.colors.N0};
  left: 0px;
  z-index: 1;
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.x2};
  max-width: 200px;
  width: 200px;
  overflow: hidden;
  overflow-wrap: break-word;
  :hover {
    cursor: pointer;
    text-decoration: underline;
  }
`;

const EmptyBlock = styled.div`
  color: ${theme.colors.N60};
  padding: ${theme.spacing.x1} ${theme.spacing.x2};
  margin-bottom: ${theme.spacing.x1};
`;

const Initiatives = styled.div`
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid ${theme.colors.N20};
  width: 100%;

  .item {
    border-bottom: 1px solid ${theme.colors.N20};
    padding: ${theme.spacing.x1} ${theme.spacing.x2};
  }
`;

const NoInitiatives = styled.div`
  padding: ${theme.spacing.x1} ${theme.spacing.x2};
  color: ${theme.colors.N60};
  border-bottom: 1px solid ${theme.colors.N20};
  display: flex;
  align-items: center;
`;

const CreateSection = styled.button`
  display: flex;
  align-items: center;
  gap: ${theme.spacing.half};
  color: ${theme.colors.N60};
  padding: ${theme.spacing.x1};
  border-radius: 4px;
  border: none;
  width: 100%;

  :hover {
    cursor: pointer;
    background: ${theme.colors.N3};
  }
  :active,
  :focus {
    background: ${theme.colors.B5};
    filter: none;
  }
`;

interface Props {
  objective: Objective;
  outcomes: Outcome[];
  initiatives: Initiative[];
  hideClosedInitiatives: boolean;
}

function ObjectiveSection(props: Props) {
  const { objective, outcomes, initiatives, hideClosedInitiatives } = props;
  const history = useHistory();
  const initiativesByOutcome: { [outcomeId: string]: Initiative[] } = {};
  const currentWorkspace = useSelector((state: any) => state.session.currentWorkspace, shallowEqual);
  const { t } = useTranslation();
  const [showNewInitiative, setShowNewInitiative] = useState('');

  if (initiatives) {
    initiatives.forEach((initiative) => {
      if (initiativesByOutcome[initiative.outcome_id]) {
        initiativesByOutcome[initiative.outcome_id].push(initiative);
      } else {
        initiativesByOutcome[initiative.outcome_id] = [initiative];
      }
    });
  }

  const handleOutcomeClick = (outcome: Outcome) => {
    history.push(`#outcome:${outcome.nano_slug}:show`);
  };

  return (
    <>
      <ObjectiveTitle>{objective.title}</ObjectiveTitle>
      <Container>
        {!outcomes && (
          <OutcomeBlock>
            <EmptyBlock>
              {t('panels.noOutcomes', {
                objective: translate(currentWorkspace, CustomTermKey.OBJECTIVE, 1).toLowerCase(),
                outcomes: translate(currentWorkspace, CustomTermKey.OUTCOME, 2).toLowerCase(),
              })}
            </EmptyBlock>
          </OutcomeBlock>
        )}
        {outcomes &&
          outcomes.map((outcome) => {
            const initiatives = initiativesByOutcome[outcome.id];
            if (!initiatives) {
              return (
                <OutcomeBlock>
                  <OutcomeTitleBlock onClick={() => handleOutcomeClick(outcome)}>{outcome.title}</OutcomeTitleBlock>
                  <Initiatives>
                    <NoInitiatives>
                      {t('panels.noInitiatives', {
                        outcome: translate(currentWorkspace, CustomTermKey.OUTCOME, 1).toLowerCase(),
                        initiatives: translate(currentWorkspace, CustomTermKey.INITIATIVE, 2).toLowerCase(),
                      })}
                    </NoInitiatives>

                    {showNewInitiative !== outcome.id && (
                      <CreateSection className="add-task" tabIndex={0} onClick={() => setShowNewInitiative(outcome.id)}>
                        <KoalaIcon iconName="plus" iconSize={1.4} />
                        {t('workspacePlan.initiatives.create', {
                          initiative: translate(currentWorkspace, CustomTermKey.INITIATIVE, 1),
                        })}
                      </CreateSection>
                    )}
                    {showNewInitiative === outcome.id && <NewInitiativeForm outcomeId={outcome.id} />}
                  </Initiatives>
                </OutcomeBlock>
              );
            }
            const lastInitiative = initiatives[initiatives.length - 1];
            return (
              <OutcomeBlock>
                <OutcomeTitleBlock onClick={() => handleOutcomeClick(outcome)}>{outcome.title}</OutcomeTitleBlock>
                <Initiatives>
                  {initiatives &&
                    initiatives.map((i) => {
                      if (i.closed_at && hideClosedInitiatives) {
                        return null;
                      }
                      return <InitiativeRow initiative={i} showMeta={true} />;
                    })}

                  {showNewInitiative !== outcome.id && (
                    <CreateSection className="add-task" tabIndex={0} onClick={() => setShowNewInitiative(outcome.id)}>
                      <KoalaIcon iconName="plus" iconSize={1.4} />
                      {t('workspacePlan.initiatives.create', {
                        initiative: translate(currentWorkspace, CustomTermKey.INITIATIVE, 1),
                      })}
                    </CreateSection>
                  )}
                  {showNewInitiative === outcome.id && (
                    <NewInitiativeForm outcomeId={outcome.id} lastInitiative={lastInitiative} />
                  )}
                </Initiatives>
              </OutcomeBlock>
            );
          })}
      </Container>
    </>
  );
}

export default ObjectiveSection;
