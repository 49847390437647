import { TFunction } from 'i18next';

export const dataSourceOriginToLabel = (dataSourceOrigin: string, t: TFunction) => {
  switch (dataSourceOrigin) {
    case 'link':
      return t(`panels.editOutcome.link`);
    case 'big_query':
      return 'BigQuery';
    case 'chartmogul':
      return 'ChartMogul';
    case 'jira_cloud':
      return 'Jira';
    case 'hubspot':
      return 'HubSpot';
    case 'zendesk_sales':
      return 'Zendesk Sales';
    case 'zendesk_support':
      return 'Zendesk Support';
    case 'google_sheets':
      return 'Google Sheets';
    case 'google_tasks':
      return 'Google Tasks';
    case 'salesforce':
      return 'Salesforce';
    case '':
      return t(`panels.editOutcome.checkins`);
    case 'power_bi':
      return 'Power BI';
    case 'tableau':
      return 'Tableau';
    default:
      return dataSourceOrigin;
  }
};
