/**
  This is the playground editor. It loads the state from local storage and saves there too
 */

import React, { Fragment } from 'react';
import styled from 'styled-components';
import theme from 'theme';
import { useParams } from 'react-router-dom';
import { useSelector, shallowEqual } from 'react-redux';
import * as workspaceUtils from 'utils/workspaceUtils';
import UpgradeIcon from 'components/_assets/UpgradeIcon';

// Routes
import * as routes from 'routes';

// Components
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Workspace } from 'types';

const Container = styled.div<{ bgColor: string; textColor: string }>`
  display: flex;
  flex-direction: column;
  padding: ${theme.spacing.x4};
  align-items: flex-start;
  width: 25rem;
  justify-content: flex-start;
  background: ${(props) => (props.bgColor ? props.bgColor : '#0d383b')};
  color: ${(props) => (props.textColor ? props.textColor : theme.colors.N0)};

  box-shadow: 2px 0px 4px rgba(0, 0, 0, 0.25);
  height: 100%;
  overflow: scroll;

  a {
    width: 100%;
    color: ${theme.colors.N0};
    margin: 0;
    margin-bottom: ${theme.spacing.x2};
    border-bottom: 2px solid transparent;
    padding-bottom: 0.4rem;
    font-weight: 400;

    &:hover {
      border-bottom: 2px solid ${theme.colors.N0};
      text-decoration: none;
      font-weight: 400;
    }
    &.active {
      border-bottom: 2px solid ${theme.colors.N0};
      text-decoration: none;
      font-weight: 400;
    }
  }

  @media ${theme.devices.tablet} {
    padding: ${theme.spacing.x8} ${theme.spacing.x4};
    width: 17rem;
  }
`;

const Header = styled.div<{ textColor: string }>`
  margin-bottom: ${theme.spacing.x2};
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 1.1rem;
  font-weight: 800;
  color: ${(props) => (props.textColor ? props.textColor : theme.colors.T50)};
`;

const UpgradeOption = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

function InboxNav() {
  const { workspaceSlug } = useParams<{ workspaceSlug: string }>();
  const currentMembership = useSelector((state: any) => state.session.currentMembership, shallowEqual);
  const currentWorkspace: Workspace = useSelector((state: any) => state.session.currentWorkspace, shallowEqual);
  const hasAutomationSubscription = workspaceUtils.hasAutomationSubscription(currentWorkspace);
  const hasPremiumPerUserSubscription = workspaceUtils.hasPremiumPerUserSubscription(currentWorkspace);
  const hasV4Plus = workspaceUtils.hasV4PlusSubscription(currentWorkspace);
  const hasV4Trial = workspaceUtils.hasV4Trial(currentWorkspace);
  const { t } = useTranslation();
  const canUseSSO =
    hasAutomationSubscription ||
    hasPremiumPerUserSubscription ||
    currentWorkspace.pricing_version === 3 ||
    hasV4Plus ||
    hasV4Trial;

  return (
    <Container bgColor={currentWorkspace.theme_link_active_bg ?? ''} textColor={currentWorkspace.theme_link_text ?? ''}>
      <Header textColor={currentWorkspace.theme_link_icon ?? ''}>{t('workspaceSettingsNav.workspace')}</Header>
      <NavLink to={routes.WORKSPACE_SETTINGS_ROUTE.replace(':workspaceSlug', workspaceSlug)} exact={true}>
        {t('workspaceSettingsDetails.title')}
      </NavLink>
      <NavLink to={routes.WORKSPACE_SETTINGS_USAGE_ROUTE.replace(':workspaceSlug', workspaceSlug)} exact={true}>
        {t('workspaceSettingsUsage.title')}
      </NavLink>
      <NavLink to={routes.WORKSPACE_SETTINGS_WORKFLOW_ROUTE.replace(':workspaceSlug', workspaceSlug)} exact={true}>
        {t('workspaceSettingsWorkflow.title')}
      </NavLink>
      <NavLink to={routes.WORKSPACE_SETTINGS_INTEGRATIONS_ROUTE.replace(':workspaceSlug', workspaceSlug)}>
        {t('workspaceSettingsIntegrations.title')}
      </NavLink>
      <NavLink to={routes.WORKSPACE_SETTINGS_THEME_ROUTE.replace(':workspaceSlug', workspaceSlug)}>
        {t('workspaceSettingsTheme.title')}
      </NavLink>
      {currentMembership && ['owner', 'admin'].includes(currentMembership.role) && (
        <NavLink to={routes.WORKSPACE_SETTINGS_TERMS_ROUTE.replace(':workspaceSlug', workspaceSlug)} exact={true}>
          {t('workspaceSettingsTerms.title')}
        </NavLink>
      )}
      {currentMembership && ['owner', 'admin'].includes(currentMembership.role) && (
        <NavLink to={routes.WORKSPACE_SETTINGS_TAGS_ROUTE.replace(':workspaceSlug', workspaceSlug)} exact={true}>
          {t('workspaceSettingsTags.title')}
        </NavLink>
      )}
      {currentWorkspace.pricing_version === 3 && (
        <NavLink to={routes.WORKSPACE_SETTINGS_AUDIT_ROUTE.replace(':workspaceSlug', workspaceSlug)} exact={true}>
          {t('workspaceSettingsAudit.title')}
        </NavLink>
      )}
      {workspaceUtils.hasV4PremiumSubscription(currentWorkspace) && (
        <NavLink to={routes.WORKSPACE_SETTINGS_AUDIT_ROUTE.replace(':workspaceSlug', workspaceSlug)} exact={true}>
          {t('workspaceSettingsAudit.title')}
        </NavLink>
      )}
      {currentMembership && ['owner'].includes(currentMembership.role) && (
        <NavLink to={routes.WORKSPACE_SETTINGS_DELETE_ROUTE.replace(':workspaceSlug', workspaceSlug)} exact={true}>
          {t('workspaceSettingsDelete.title')}
        </NavLink>
      )}
      <Header textColor={currentWorkspace.theme_link_icon ?? ''}>{t('workspaceSettingsNav.security')}</Header>
      <NavLink to={routes.WORKSPACE_SETTINGS_SECURITY_ROUTE.replace(':workspaceSlug', workspaceSlug)} exact={true}>
        {t('workspaceSettingsSecurity.title')}
      </NavLink>
      {currentWorkspace.pricing_version === 3 && (
        <NavLink
          to={routes.WORKSPACE_SETTINGS_WHITELISTING_ROUTE.replace(':workspaceSlug', workspaceSlug)}
          exact={true}
        >
          {t('workspaceSettingsWhitelisting.title')}
        </NavLink>
      )}
      {workspaceUtils.hasV4PremiumSubscription(currentWorkspace) && (
        <NavLink
          to={routes.WORKSPACE_SETTINGS_WHITELISTING_ROUTE.replace(':workspaceSlug', workspaceSlug)}
          exact={true}
        >
          {t('workspaceSettingsWhitelisting.title')}
        </NavLink>
      )}
      <NavLink to={routes.WORKSPACE_SETTINGS_SSO_ROUTE.replace(':workspaceSlug', workspaceSlug)} exact={true}>
        <UpgradeOption>
          <span>{t('workspaceSettingsSSO.title')}</span>
          {!canUseSSO && <UpgradeIcon />}
        </UpgradeOption>
      </NavLink>
      <NavLink to={routes.WORKSPACE_SETTINGS_PRIVACY_ROUTE.replace(':workspaceSlug', workspaceSlug)} exact={true}>
        {t('workspaceSettingsPrivacy.title')}
      </NavLink>
      <Header textColor={currentWorkspace.theme_link_icon ?? ''}>{t('workspaceSettingsNav.userTeam')}</Header>
      <NavLink to={routes.WORKSPACE_SETTINGS_MEMBERSHIPS_ROUTE.replace(':workspaceSlug', workspaceSlug)}>
        {t('workspaceSettingsMemberships.title')}
      </NavLink>
      {currentMembership && ['owner'].includes(currentMembership.role) && (
        <Fragment>
          <Header textColor={currentWorkspace.theme_link_icon ?? ''}>{t('workspaceSettingsNav.billing')}</Header>
          <NavLink to={routes.WORKSPACE_SETTINGS_BILLING_ROUTE.replace(':workspaceSlug', workspaceSlug)}>
            {t('workspaceSettingsBilling.title')}
          </NavLink>
        </Fragment>
      )}
    </Container>
  );
}

export default InboxNav;
