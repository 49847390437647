import queryKeys from 'config/queryKeys';
import KoalaCircularProgress from 'koala/components/CircularProgress';
import React, { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { shallowEqual, useSelector } from 'react-redux';
import styled from 'styled-components';
import theme from 'theme';
import { CustomTermKey, translate } from 'utils/customTermUtils';
import * as planUtils from 'utils/planUtils';
import * as remoteApi from 'api/remote';
import { useQuery } from 'react-query';
import KoalaIcon from 'koala/components/Icons';
import KoalaNCSPie from 'koala/components/NCSPie';
import ReactTooltip from 'react-tooltip';
import { DragHandle, SourceTitle, WidgetContainer, WidgetContent, WidgetHeader } from '.';
import KoalaLoader from 'koala/components/Loader';
import { AxiosError } from 'axios';
import PlanIconLabel from 'components/PlanIconLabel';
import { WORKSPACE_PLAN_TEMPLATE_ROUTE, WORKSPACE_PLAN_TRACK_ROUTE, WORKSPACE_PLAN_WRITE_ROUTE } from 'routes';
import { Link } from 'react-router-dom';

const OverivewSectionContent = styled.div`
  flex: 1;
  display: flex;
  flex-wrap: wrap;
  column-gap: ${theme.spacing.x1};
  row-gap: ${theme.spacing.x2};
  align-items: center;
  justify-content: space-around;
`;
const DataContainer = styled.div`
  background: ${theme.colors.N0};
  box-sizing: border-box;
  display: flex;
  align-items: center;
  gap: ${theme.spacing.x2};
  position: relative;
  width: 19rem;

  &:last-of-type {
    border-right: 0;
  }
  h4 {
    font-weight: 600;
  }
  small {
    display: flex;
    gap: ${theme.spacing.half};
    align-items: center;
  }
`;

interface Props {
  planId: string;
}

function PlanSummaryWidget(props: Props) {
  const { planId } = props;
  const { t } = useTranslation();
  const currentWorkspace = useSelector((state: any) => state.session.currentWorkspace, shallowEqual);
  const [error, setError] = useState('');
  const queryKey = [queryKeys.currentPlan, planId];
  const staleTime = queryKeys.staleTime;
  // Get the plan details
  const { data: planResponse, isLoading } = useQuery(queryKey, remoteApi.fetchPlanDetails, {
    staleTime,
    onError: (error: AxiosError) => {
      const errorText = error.response?.data.error;
      if (errorText.includes('not allowed') && error.response?.status === 401) {
        setError(
          t('workspaceDashboards.permissionError') ?? 'You do not have permission to view the content in this widget',
        );
      } else {
        setError(t('workspaceDashboards.widgetError') ?? 'Unable to fetch widget');
      }
    },
  });

  if (isLoading) {
    return (
      <WidgetContainer>
        <KoalaLoader />
      </WidgetContainer>
    );
  }

  const plan = planResponse?.data ?? null;

  if (!plan) {
    return (
      <WidgetContainer>
        <WidgetHeader>
          <DragHandle className="drag-handle">
            <KoalaIcon iconName="grab" iconSize="small" />
          </DragHandle>
          <p className="widget-type">{t('workspaceDashboards.planSummary')}</p>
        </WidgetHeader>
        <WidgetContent>
          <p>{error ?? t('workspaceDashboards.widgetError')}</p>
        </WidgetContent>
      </WidgetContainer>
    );
  }

  const confidenceData = planUtils.getConfidenceData(plan);
  const outcomeProgressData = planUtils.getOutcomeProgressData(plan);
  const initiativeProgressData = planUtils.getInitiativeProgressData(plan);
  const { timelineProgressData, daysLeft } = planUtils.getTimelineProgressData(plan, t);

  let ncsColor = theme.colors.G50;
  if (plan.ncs <= 30) {
    ncsColor = theme.colors.Y90;
  }
  if (plan.ncs < 0) {
    ncsColor = theme.colors.R50;
  }

  const stateToRouteMapping: { [key: string]: string } = {
    draft: WORKSPACE_PLAN_WRITE_ROUTE,
    published: WORKSPACE_PLAN_TRACK_ROUTE,
    template: WORKSPACE_PLAN_TEMPLATE_ROUTE,
  };
  let routeMappingState = plan.state || 'draft';
  const routeTarget = stateToRouteMapping[routeMappingState];
  const planRoute = routeTarget.replace(':workspaceSlug', currentWorkspace.slug).replace(':planId', plan.nano_slug);

  return (
    <WidgetContainer>
      <WidgetHeader>
        <DragHandle className="drag-handle">
          <KoalaIcon iconName="grab" iconSize="small" />
        </DragHandle>
        <p className="widget-type">{t('workspaceDashboards.planSummary')}</p>
      </WidgetHeader>
      <WidgetContent>
        <SourceTitle>
          <Link to={planRoute}>
            <PlanIconLabel plan={plan} size="small" />
          </Link>
        </SourceTitle>
        <OverivewSectionContent>
          <DataContainer>
            <KoalaCircularProgress data={timelineProgressData} size="small" useIcon />
            <div>
              <h4>{t('shared.time.numberDaysLeft', { count: daysLeft })}</h4>
              <small className="subtle">{t('workspacePlan.timeline')}</small>
            </div>
          </DataContainer>
          <DataContainer>
            <KoalaCircularProgress data={outcomeProgressData} size="small" useIcon />
            <div>
              <h4>{outcomeProgressData.centerLabel}</h4>
              <small className="subtle">{t('workspacePlan.overallProgress')}</small>
            </div>
          </DataContainer>
          <DataContainer>
            <KoalaCircularProgress data={initiativeProgressData} size="small" useIcon />
            <div>
              <h4>{initiativeProgressData.centerLabel}</h4>
              <small className="subtle">
                {t(`workspacePlan.tasksCompleted`, {
                  initiatives: translate(currentWorkspace, CustomTermKey.INITIATIVE, 2),
                })}
              </small>
            </div>
          </DataContainer>
          <DataContainer>
            <KoalaNCSPie centerLabel={[]} data={confidenceData} size="small" />
            <div>
              <h4 style={{ color: ncsColor }}>
                {Math.round(plan.ncs)} {t('shared.ncs.acronym')}
              </h4>
              <small className="subtle">
                {t('shared.ncs.full')}
                <KoalaIcon iconName="tooltip" iconSize="small" data-tip data-for="explain-ncs" />
              </small>
            </div>
            <ReactTooltip
              place="bottom"
              type="dark"
              className="tooltip"
              effect="solid"
              id="explain-ncs"
              delayHide={500}
              clickable={true}
            >
              <Trans i18nKey="shared.ncs.tooltip" components={{ br: <br /> }} />{' '}
              <a
                href="https://www.tability.io/odt-articles/nps-but-for-your-okrs-introducing-the-net-confidence-score-ncs"
                target="_blank"
                rel="noopener noreferrer"
              >
                {t('shared.learnMore')}
              </a>
            </ReactTooltip>
          </DataContainer>
        </OverivewSectionContent>
      </WidgetContent>
    </WidgetContainer>
  );
}

export default PlanSummaryWidget;
