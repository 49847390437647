import React from 'react';
import styled from 'styled-components';
import * as TabilityTypes from 'types';
import { useInfiniteQuery } from 'react-query';
import queryKeys from 'config/queryKeys';
import { useHistory, useLocation } from 'react-router-dom';
import theme from 'theme';
import parse from 'parse-link-header';

// API
import * as remoteApi from 'api/remote';

// Components
import { CustomTermKey, translate } from 'utils/customTermUtils';
import { shallowEqual, useSelector } from 'react-redux';
import InitiativeRow from 'components/InitiativeRow';
import { useTranslation } from 'react-i18next';
import { AxiosResponse } from 'axios';
import KoalaButton from 'koala/components/Button';
import KoalaLoader from 'koala/components/Loader';

export const BlockContent = styled.div`
  font-weight: 700;
  grid-area: content;
  background: transparent;
  margin: 0;
  height: 100%;

  display: flex;
  align-items: center;
`;

export const BlockMeta = styled.div`
  font-weight: 700;
  grid-area: meta;
  display: flex;
  align-items: center;
  height: 100%;
`;
const Container = styled.div`
  margin-top: ${theme.spacing.x1};
`;

const HeaderRow = styled.div`
  display: flex;
  align-items: center;
  gap: ${theme.spacing.x1};
  border-bottom: 0;
  font-weight: 800;
  font-size: 1rem;
  height: 3.7rem;
  text-transform: uppercase;
  letter-spacing: 1px;
  padding: ${theme.spacing.x1} ${theme.spacing.x2};
`;

const InitiativeList = styled.div`
  .item {
    border-top: 1px solid ${theme.colors.N20};
  }

  .initiative-owners {
    display: none;
  }
  .initiative-due-date {
    display: flex;
  }
`;

const LoadContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1.2rem ${theme.spacing.x2};
`;

const getNextPage = (response: any) => {
  if (response && response.headers && response.headers.link) {
    const links = response.headers.link;
    const parsed = parse(links);
    if (parsed && parsed.next) {
      return parsed.next.page;
    }
  }
  return null;
};

interface Props {
  membership: TabilityTypes.Membership;
  showContributingInitiatives: boolean;
}

function ActiveInitiativesList(props: Props) {
  // Query that fetches all the initiatives with pending checkins
  const location = useLocation();
  const history = useHistory();
  const workspace: TabilityTypes.Workspace = useSelector((state: any) => state.session.currentWorkspace, shallowEqual);
  const { membership, showContributingInitiatives } = props;
  const activeInitiativesQueryKey = [queryKeys.initiatives, membership.id, showContributingInitiatives];
  const { t } = useTranslation();

  const {
    isLoading,
    data: initiativeResponse,
    canFetchMore,
    fetchMore,
  } = useInfiniteQuery(activeInitiativesQueryKey, remoteApi.fetchMembershipActiveInitiatives, {
    getFetchMore: (lastGroup, allGroups) => {
      return getNextPage(lastGroup);
    },
  });

  const isBlockSelected = (blockId: string) => {
    return location.hash.includes(blockId);
  };

  if (isLoading) {
    return (
      <InitiativeList>
        <p className="subtle">
          <KoalaLoader />
        </p>
      </InitiativeList>
    );
  }

  const intiativesLabel = translate(workspace, CustomTermKey.INITIATIVE, 2).toLowerCase();

  const hasInitiatives =
    !initiativeResponse || initiativeResponse.length === 0 || initiativeResponse[0].data.length === 0;

  if (hasInitiatives && showContributingInitiatives) {
    return <></>;
  } else if (hasInitiatives) {
    return (
      <InitiativeList>
        <p className="subtle">{t('workspacePeople.emptyOwner', { intiatives: intiativesLabel })}</p>
      </InitiativeList>
    );
  }

  return (
    <Container>
      {showContributingInitiatives && <HeaderRow>{t('workspaceInbox.contributing')}</HeaderRow>}

      <InitiativeList className="item-list">
        {initiativeResponse.map((group: AxiosResponse<TabilityTypes.Initiative[]>) =>
          group.data.map((initiative) => {
            const blockId = `initiative:${initiative.nano_slug}`;
            const isSelected = isBlockSelected(blockId);

            const showHashRoute = `#${blockId}:show`;
            const handleShowInitiative = (e: any) => {
              e.preventDefault();
              e.stopPropagation();
              history.push(showHashRoute);
            };
            return (
              <InitiativeRow
                key={initiative.id}
                initiative={initiative}
                isSelected={isSelected}
                handleClick={handleShowInitiative}
                showMeta
              />
            );
          }),
        )}
        {canFetchMore && (
          <LoadContainer>
            <KoalaButton appearance="secondary" onClick={() => fetchMore()}>
              {t(`shared.loadMore`)}
            </KoalaButton>
          </LoadContainer>
        )}
      </InitiativeList>
    </Container>
  );
}

export default ActiveInitiativesList;
