import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import theme from 'theme';
import * as TabilityTypes from 'types';
import * as amplitudeUtils from 'utils/amplitude';
import * as membershipUtils from 'utils/membershipUtils';
import { useSelector, shallowEqual } from 'react-redux';
import { CustomTermKey, translate } from 'utils/customTermUtils';
import * as planUtils from 'utils/planUtils';
import {
  getConfidenceData,
  getOutcomeProgressData,
  getInitiativeProgressData,
  getTimelineProgressData,
} from 'utils/planUtils';

import { format, parseISO } from 'date-fns';
import ReactTooltip from 'react-tooltip';

import KoalaCircularProgress from 'koala/components/CircularProgress';
import KoalaNCSPie from 'koala/components/NCSPie';

import KoalaIcon from 'koala/components/Icons';
import PlanInfo from './PlanInfo';
import PlanObjective from './PlanObjective';
import SubPlansOverview from './SubPlansOverview';
import KoalaButton from 'koala/components/Button';
import KoalaToggleButton from 'koala/components/ToggleButton';
import { DEFAULT_PLAN_EXPAND, DEFAULT_PLAN_HIDE_COMPLETED } from 'config/constants';
import { Trans, useTranslation } from 'react-i18next';
import Insights from './Insights';
import MetricInsights from './MetricInsights';
import PlanPublished from './PlanPublished';

const PlanWrapper = styled.div`
  max-width: 140rem;
  margin: 0 auto;
  padding: 0 ${theme.spacing.x2};
  display: grid;
  grid-template-columns: 1fr 25rem;
  min-height: 100%;

  @media ${theme.devices.laptop} {
    display: flex;
    flex-direction: column;
  }
`;

const PublishedContainer = styled.div`
  grid-column: 1 / span 2;
  max-height: 25rem;
`;

const MainContent = styled.div`
  padding-top: ${theme.spacing.x2};
  padding-bottom: ${theme.spacing.x2};
  padding-right: ${theme.spacing.x2};
  min-height: 100%;

  @media ${theme.devices.laptop} {
    padding-right: 0;
    margin-right: 0;
    height: initial;
    border: 0;
    padding-bottom: 0;
  }
`;

const WarningBlock = styled.div`
  padding: 1.2rem ${theme.spacing.x2};
  margin-bottom: ${theme.spacing.x2};
  background: ${theme.colors.warningBg};
  border-radius: 4px;
`;

const PlanContent = styled.div``;

const PlanOverview = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;

  @media ${theme.devices.tablet} {
    grid-template-columns: 1fr 1fr;
  }
`;
const DataContainer = styled.div`
  background: ${theme.colors.N0};
  border-right: 1px solid ${theme.colors.blockBorder};
  box-sizing: border-box;
  padding: 0 ${theme.spacing.x2};
  display: flex;
  align-items: center;
  gap: ${theme.spacing.x2};
  position: relative;

  &:last-of-type {
    border-right: 0;
  }
  h4 {
    font-weight: 600;
  }
  small {
    display: flex;
    gap: ${theme.spacing.half};
    align-items: center;
  }
`;

const EmptyState = styled.div`
  background: ${theme.colors.N3};
  border-radius: 4px;
  padding: ${theme.spacing.x3};
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.x2};
  align-items: center;
`;

const CollapseActions = styled.div`
  display: flex;
  align-items: center;
  gap: ${theme.spacing.half};
  justify-content: flex-end;
`;

const ToggleButton = styled.div`
  display: flex;
  align-items: center;
  font-size: 1.1rem;
  font-weight: 500;
  height: 2.4rem;
  line-height: 1.6rem;
  color: ${theme.colors.N70};
  margin-right: ${theme.spacing.x1};
`;

export const PlanSection = styled.div`
  border: 1px solid ${theme.colors.blockBorder};
  border-radius: 4px;
  background: #fff;
  margin-bottom: ${theme.spacing.x3};
  position: relative;

  @media ${theme.devices.tablet} {
    &.hide-mobile {
      display: none;
    }
  }
`;

export const PlanSectionHeader = styled.div`
  padding: ${theme.spacing.x1} ${theme.spacing.x2};
  border-bottom: 1px solid ${theme.colors.blockBorder};
  display: flex;
  justify-content: space-between;
  align-items: center;
  .title {
    font-size: 1rem;
    font-weight: 900;
    text-transform: uppercase;
    letter-spacing: 1px;
  }

  &.collapsed {
    border-bottom: 0;
  }
  gap: ${theme.spacing.x2};
`;

export const PlanSectionContent = styled.div`
  padding: ${theme.spacing.x2};
  &.no-padding {
    padding: 0;
  }
`;

const OverivewSectionContent = styled.div`
  padding: ${theme.spacing.x2} 0px;
`;

interface Props {
  plan: TabilityTypes.Plan;
  objectives: TabilityTypes.Objective[];
  outcomes: TabilityTypes.Outcome[];
  initiatives: TabilityTypes.Initiative[];
  outcomesByObjective: { [objectiveId: string]: TabilityTypes.Outcome[] };
  outcomesLoading: boolean;
}

function Tracker(props: Props) {
  const { t } = useTranslation();
  const { plan, objectives, outcomesByObjective, outcomesLoading, outcomes, initiatives } = props;
  useEffect(() => {
    if (plan.is_dummy) {
      amplitudeUtils.sendAmplitudeData('app.workspace.plan.dummy.visit', {
        'Tability Platform': 'web',
        'Tability Version': 2,
      });
    }
  }, [plan.is_dummy]);
  const currentWorkspace = useSelector((state: any) => state.session.currentWorkspace, shallowEqual);
  const currentMembership = useSelector((state: any) => state.session.currentMembership, shallowEqual);
  const isReadOnly = membershipUtils.isReadOnly(currentMembership);

  const hasEditPermission = planUtils.hasEditPermission(plan, currentMembership);

  const storedDefaultExpandedState: boolean = localStorage.getItem(DEFAULT_PLAN_EXPAND)
    ? localStorage.getItem(DEFAULT_PLAN_EXPAND) === 'true'
    : false;
  const storedDefaultHideClosedInitiatives: boolean = localStorage.getItem(DEFAULT_PLAN_HIDE_COMPLETED)
    ? localStorage.getItem(DEFAULT_PLAN_HIDE_COMPLETED) === 'true'
    : true;

  const [defaultExpandedState, setDefaultExpandedState] = useState(storedDefaultExpandedState);
  const [defaultChanged, setDefaultChanged] = useState(false);
  const [hideClosedInitiatives, setHideClosedInitiatives] = useState(storedDefaultHideClosedInitiatives);

  useEffect(() => {
    localStorage.setItem(DEFAULT_PLAN_EXPAND, JSON.stringify(defaultExpandedState));
  }, [defaultExpandedState]);

  useEffect(() => {
    localStorage.setItem(DEFAULT_PLAN_HIDE_COMPLETED, JSON.stringify(hideClosedInitiatives));
  }, [hideClosedInitiatives]);

  const planFinishAtWithYear = plan && plan.finish_at ? format(parseISO(plan.finish_at), 'MMM do, yyyy') : null;

  const ExpiredBlock = () => {
    if (!plan.expired) {
      return null;
    }

    return (
      <WarningBlock>
        <Trans
          i18nKey="workspacePlan.expired"
          values={{
            planFinishAtWithYear,
          }}
          components={{ b: <b /> }}
        />
      </WarningBlock>
    );
  };

  const toggleHideInitiatives = () => {
    setHideClosedInitiatives((prev) => !prev);
  };

  const toggleExpandOutcomes = (isExpanding: boolean) => {
    setDefaultChanged(false);
    setDefaultExpandedState(isExpanding);
  };

  const confidenceData = getConfidenceData(plan);
  const outcomeProgressData = getOutcomeProgressData(plan);
  const initiativeProgressData = getInitiativeProgressData(plan);
  const { timelineProgressData, daysLeft } = getTimelineProgressData(plan, t);

  let ncsColor = theme.colors.G50;
  if (plan.ncs <= 30) {
    ncsColor = theme.colors.Y90;
  }
  if (plan.ncs < 0) {
    ncsColor = theme.colors.R50;
  }

  return (
    <>
      {!plan.checked_in && plan.state === 'published' && (
        <PublishedContainer>
          <PlanPublished />
        </PublishedContainer>
      )}
      <PlanWrapper>
        <MainContent className="grey-bg">
          <ExpiredBlock />
          {plan.checked_in && (
            <PlanSection className="hide-mobile">
              <PlanSectionHeader>
                <span className="title">{t('workspacePlan.planOverviewTitle')}</span>
              </PlanSectionHeader>
              <OverivewSectionContent>
                <PlanOverview>
                  <DataContainer>
                    <KoalaCircularProgress data={timelineProgressData} size="small" useIcon />
                    <div>
                      <h4>{t('shared.time.numberDaysLeft', { count: daysLeft })}</h4>
                      <small className="subtle">{t('workspacePlan.timeline')}</small>
                    </div>
                  </DataContainer>
                  <DataContainer>
                    <KoalaCircularProgress data={outcomeProgressData} size="small" useIcon />
                    <div>
                      <h4>{outcomeProgressData.centerLabel}</h4>
                      <small className="subtle">{t('workspacePlan.overallProgress')}</small>
                    </div>
                  </DataContainer>
                  <DataContainer>
                    <KoalaCircularProgress data={initiativeProgressData} size="small" useIcon />
                    <div>
                      <h4>{initiativeProgressData.centerLabel}</h4>
                      <small className="subtle">
                        {t(`workspacePlan.tasksCompleted`, {
                          initiatives: translate(currentWorkspace, CustomTermKey.INITIATIVE, 2),
                        })}
                      </small>
                    </div>
                  </DataContainer>
                  <DataContainer>
                    <KoalaNCSPie centerLabel={[]} data={confidenceData} size="small" />
                    <div>
                      <h4 style={{ color: ncsColor }}>
                        {Math.round(plan.ncs)} {t('shared.ncs.acronym')}
                      </h4>
                      <small className="subtle">
                        {t('shared.ncs.full')}
                        <KoalaIcon iconName="tooltip" iconSize="small" data-tip data-for="explain-ncs" />
                      </small>
                    </div>
                    <ReactTooltip
                      place="bottom"
                      type="dark"
                      className="tooltip"
                      effect="solid"
                      id="explain-ncs"
                      delayHide={500}
                      clickable={true}
                    >
                      <Trans i18nKey="shared.ncs.tooltip" components={{ br: <br /> }} />{' '}
                      <a
                        href="https://www.tability.io/odt-articles/nps-but-for-your-okrs-introducing-the-net-confidence-score-ncs"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {t('shared.learnMore')}
                      </a>
                    </ReactTooltip>
                  </DataContainer>
                </PlanOverview>
              </OverivewSectionContent>
            </PlanSection>
          )}
          <PlanSection className="plan-outcomes">
            <PlanSectionHeader>
              <span className="title">{t('workspacePlan.planStatusTitle')}</span>
              {objectives.length > 0 && (
                <CollapseActions>
                  <ToggleButton>
                    <KoalaToggleButton
                      handleChange={toggleHideInitiatives}
                      size="small"
                      defaultChecked={hideClosedInitiatives}
                      id="mask-completed-plan-initiatives"
                    />
                    {t('workspacePlan.hideCompleted', {
                      initiatives: translate(currentWorkspace, CustomTermKey.INITIATIVE, 2).toLowerCase(),
                    })}
                  </ToggleButton>
                  <KoalaButton appearance="secondary" size="small" onClick={() => toggleExpandOutcomes(true)}>
                    {t('shared.expandAll')}
                  </KoalaButton>
                  <KoalaButton appearance="secondary" size="small" onClick={() => toggleExpandOutcomes(false)}>
                    {t('shared.collapseAll')}
                  </KoalaButton>
                </CollapseActions>
              )}
            </PlanSectionHeader>

            <PlanSectionContent className="no-padding">
              <PlanContent className="plan-outcomes">
                {objectives.length === 0 && (
                  <EmptyState>
                    <h3>{t('workspacePlan.empty')}</h3>
                    <p>{t('workspacePlan.addGoals')}</p>
                  </EmptyState>
                )}
                {objectives.map((objective, index) => (
                  <PlanObjective
                    planType={plan.plan_type ?? 'classic'}
                    objective={objective}
                    outcomes={outcomesByObjective[objective.id] || []}
                    isLoading={outcomesLoading}
                    key={index}
                    hasEditPermission={hasEditPermission}
                    objectiveIndex={index}
                    defaultExpandedState={defaultExpandedState}
                    setDefaultChanged={setDefaultChanged}
                    defaultChanged={defaultChanged}
                    hideClosedInitiatives={hideClosedInitiatives}
                  />
                ))}
              </PlanContent>
            </PlanSectionContent>
          </PlanSection>
          {plan.checked_in && (
            <>
              {plan.plan_type === 'classic' && (
                <Insights plan={plan} objectives={objectives} outcomes={outcomes} initiatives={initiatives} />
              )}
              {plan.plan_type === 'simplified' && (
                <MetricInsights plan={plan} objectives={objectives} outcomes={outcomes} initiatives={initiatives} />
              )}
            </>
          )}
          {plan.plan_type === 'classic' && <>{!isReadOnly && <SubPlansOverview plan={plan} />}</>}
        </MainContent>
        <PlanInfo plan={plan} outcomes={outcomes} objectives={objectives} />
      </PlanWrapper>
    </>
  );
}

export default React.memo(Tracker);
