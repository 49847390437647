import React, { Fragment, useState } from 'react';
import styled from 'styled-components';
import * as TabilityTypes from 'types';
import { useQuery, useMutation } from 'react-query';
import queryKeys from 'config/queryKeys';
import theme from 'theme';
import { Prompt } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import * as workspaceUtils from 'utils/workspaceUtils';
// API
import * as remoteApi from 'api/remote';

// Components
import { ChromeContent, ChromeSideNav } from 'components/Chrome';
import SettingsNarrowWrapper from 'components/SettingsNarrowWrapper';
import ContentTitle from 'components/ContentTitle';
import FormField from 'components/FormField';
import Loader from 'components/Loader';
import WorkspaceSettingsNav from 'components/WorkspaceSettingsNav';
import UpgradePanel from 'components/UpgradePanel';

// API
import { fetchWorkspaceAdminDetails } from 'api/remote';
import KoalaButton from 'koala/components/Button';
import { Trans, useTranslation } from 'react-i18next';

const Form = styled.form`
  display: flex;
  flex-direction: column;

  textarea {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif,
      'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol' !important;
    font-size: 1.4rem;
    line-height: 1.4;
    padding: ${theme.spacing.x1};
    min-height: 15rem;
    width: 100%;
    border-radius: 4px;
    border: 1px solid #949494;
    &:placeholder {
      color: #949494;
      font-size: 1.4rem;
    }
  }

  h3 {
    margin-bottom: ${theme.spacing.x2};
  }
`;

const ErrorList = styled.ul`
  margin-bottom: ${theme.spacing.x2};
  color: #d0402e;
`;

const Actions = styled.div`
  display: flex;
  align-items: center;
  button {
    margin-right: ${theme.spacing.x2};
  }
`;

const RadioBlock = styled.div`
  display: flex;
  align-items: center;
  label {
    color: #151515;
    text-transform: none;
    font-weight: normal;
    font-size: 1.6rem;

    input {
      margin-right: ${theme.spacing.x1};
    }
  }
`;

const SuccessBanner = styled.div`
  padding: ${theme.spacing.x2};
  background: ${theme.colors.successBg};
  border-radius: 8px;
  margin-bottom: ${theme.spacing.x3};
`;

interface Props {
  workspace: TabilityTypes.Workspace;
}

const staleTime = 0;

function WorkspaceSettingsSSO(props: Props) {
  const { workspace } = props;
  const { t } = useTranslation();
  const hasAutomationSubscription = workspaceUtils.hasAutomationSubscription(workspace);
  const hasPremiumPerUserSubscription = workspaceUtils.hasPremiumPerUserSubscription(workspace);
  const hasV4Plus = workspaceUtils.hasV4PlusSubscription(workspace);
  const hasV4Trial = workspaceUtils.hasV4Trial(workspace);
  const canUseSSO =
    hasAutomationSubscription ||
    hasPremiumPerUserSubscription ||
    workspace.pricing_version === 3 ||
    hasV4Trial ||
    hasV4Plus;

  const queryKey = [queryKeys.currentWorkspace, workspace.slug, 'admin'];

  const [isFormSaved, setIsFormSaved] = useState(false);
  const [isFormDirty, setIsFormDirty] = useState(false);

  const [workspaceAuthType, setWorkspaceAuthType] = useState('');
  const [workspaceIdpSSOTargetUrl, setWorkspaceIdpSSOTargetUrl] = useState('');
  const [auth_type, setAuthType] = useState('');
  const [idp_cert, setIdpCert] = useState('');
  const [idp_sso_target_url, setIdpSSOTargetUrl] = useState('');

  const { isFetching: isInitialFetch }: any = useQuery(queryKey, fetchWorkspaceAdminDetails, {
    staleTime,
    onSuccess: (response) => {
      const workspace = response.data;
      setWorkspaceAuthType(workspace.auth_type);
      setWorkspaceIdpSSOTargetUrl(workspace.idp_sso_target_url);
      setAuthType(workspace.auth_type);
      setIdpCert(workspace.idp_cert);
      setIdpSSOTargetUrl(workspace.idp_sso_target_url);
    },
  });

  // Workspace mutations
  const [updateWorkspaceMutation, { isError, isLoading, error }]: [any, any] = useMutation(
    remoteApi.updateWorkspaceSSO,
    {
      onMutate: () => {
        setIsFormSaved(false);
      },
      onSuccess: (response) => {
        setIsFormSaved(true);
        setIsFormDirty(false);
        setWorkspaceIdpSSOTargetUrl(idp_sso_target_url);
      },
    },
  );

  let errors: any = {}; // UI Errors

  // Errors for the workspace creation are returned as a hash
  if (isError) {
    if (error.response.status === 500) {
      errors = {
        server: [t('errors.serverError')],
      };
    } else if (error.response.status === 402) {
      errors = {
        subscription: [t('errors.planError')],
      };
    } else {
      errors = error.response ? error.response.data : {};
    }
  }

  const handleChange = (e: any) => {
    const inputTarget = e.target;
    const value = inputTarget.type === 'checkbox' ? inputTarget.checked : inputTarget.value;
    const name = inputTarget.name;
    //setIsFormDirty(true);
    //setIsFormSaved(false);

    switch (name) {
      case 'auth_type':
        setAuthType(value);
        break;
      case 'idp_cert':
        setIdpCert(value);
        break;
      case 'idp_sso_target_url':
        setIdpSSOTargetUrl(value);
        break;
    }
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();

    // We only allow changing the auth_type if it's not shared
    let _auth_type = workspaceAuthType === 'shared' ? workspaceAuthType : auth_type;

    const params = {
      idp_sso_target_url,
      idp_cert,
      auth_type: _auth_type,
    };
    updateWorkspaceMutation({
      workspaceId: workspace.id,
      workspace: params,
    });
  };

  if (!canUseSSO) {
    return (
      <Fragment>
        <Helmet>
          <title>
            {workspace.name} | {t('workspaceSettingsSSO.title')} | Tability
          </title>
        </Helmet>
        <ChromeSideNav>
          <WorkspaceSettingsNav />
        </ChromeSideNav>
        <ChromeContent>
          <SettingsNarrowWrapper>
            <ContentTitle>
              <h1>
                {t('workspaceSettingsSSO.title')}
                {isInitialFetch && <Loader size="small" />}
              </h1>
            </ContentTitle>
            <UpgradePanel
              title={t('workspaceSettingsSSO.upgrade')}
              description={t('workspaceSettingsSSO.upgradeInfo')}
            />
          </SettingsNarrowWrapper>
        </ChromeContent>
      </Fragment>
    );
  }

  // This function is used to turn the error hash keys into proper strings.
  const errorKeyToString = (errorKey: string): string => {
    switch (errorKey) {
      case 'accepted_domains':
        return t('errors.acceptedDomains');
      case 'invite_link_token_enabled':
        return t('errors.invitationLink');
      default:
        return '';
    }
  };

  const authAPI = `https://${process.env.REACT_APP_TABILITY_API_DOMAIN}/auth/saml`;
  const saml_consumer_url = `${authAPI}/consume/${workspace.slug}`;
  const saml_metadata_url = `${authAPI}/metadata/${workspace.slug}`;
  const saml_init_url = `${authAPI}/init/${workspace.slug}`;

  // SAML SSO configuration component
  const SAMLSSOConf = () => (
    <Fragment>
      <SuccessBanner>{t('workspaceSettingsSSO.enabled')}</SuccessBanner>
      <h3>{t('workspaceSettingsSSO.configuration')}</h3>
      <FormField>
        <label htmlFor="accepted_domains">{t('workspaceSettingsSSO.entityID')}</label>
        <span>{saml_metadata_url}</span>
      </FormField>
      <FormField>
        <label htmlFor="accepted_domains">{t('workspaceSettingsSSO.consumerUrl')}</label>
        <span>{saml_consumer_url}</span>
      </FormField>
      <h3>{t('workspaceSettingsSSO.identitySettings')}</h3>
      <FormField>
        <label>{t('workspaceSettingsSSO.ssoUrl')}</label>
        <input type="text" disabled value={idp_sso_target_url} />
        <small className="subtle">{t('workspaceSettingsSSO.ssoUrlInfo')}</small>
      </FormField>
      <h3>{t('workspaceSettingsSSO.ssoURLHeader')}</h3>
      <FormField>
        <label>{t('workspaceSettingsSSO.ssoURLInfo')}</label>
        <p>
          <KoalaButton href={saml_init_url} target="_blank" rel="noreferrer noopener">
            {t('workspaceSettingsSSO.login')}
          </KoalaButton>
        </p>
      </FormField>
    </Fragment>
  );

  return (
    <Fragment>
      <Helmet>
        <title>
          {workspace.name} | {t('workspaceSettingsSSO.title')} | Tability
        </title>
      </Helmet>
      <Prompt
        message={t('shared.confirmLeave') ?? 'Your changes are not saved. Are you sure you want to leave?'}
        when={isFormDirty}
      />
      <ChromeSideNav>
        <WorkspaceSettingsNav />
      </ChromeSideNav>
      <ChromeContent>
        <SettingsNarrowWrapper>
          <ContentTitle>
            <h1>
              {t('workspaceSettingsSSO.title')}
              {isInitialFetch && <Loader size="small" />}
            </h1>
          </ContentTitle>
          <Form onSubmit={handleSubmit}>
            <FormField>
              <label>{t('workspaceSettingsSSO.info')}</label>
              <RadioBlock>
                <label>
                  <input
                    type="radio"
                    name="auth_type"
                    autoComplete="off"
                    value="shared"
                    checked={auth_type === 'shared'}
                    onChange={handleChange}
                  />
                  <Trans i18nKey="workspaceSettingsSSO.default" components={{ b: <b /> }} />
                </label>
              </RadioBlock>
              <RadioBlock>
                <label>
                  <input
                    type="radio"
                    name="auth_type"
                    autoComplete="off"
                    value="restricted.sso"
                    checked={auth_type === 'restricted.sso'}
                    onChange={handleChange}
                  />
                  <Trans i18nKey="workspaceSettingsSSO.sso" components={{ b: <b /> }} />
                </label>
              </RadioBlock>
            </FormField>
            {auth_type === 'restricted.sso' && (
              <div>
                {workspaceAuthType === 'restricted.sso' && <SAMLSSOConf />}
                {workspaceAuthType === 'shared' && (
                  <Fragment>
                    <h3>{t('workspaceSettingsSSO.step1')}</h3>
                    <FormField>
                      <label htmlFor="accepted_domains">{t('workspaceSettingsSSO.entityID')}</label>
                      <span>{saml_metadata_url}</span>
                    </FormField>
                    <FormField>
                      <label htmlFor="accepted_domains">{t('workspaceSettingsSSO.consumerUrl')}</label>
                      <span>{saml_consumer_url}</span>
                    </FormField>
                    <h3>{t('workspaceSettingsSSO.step2')}</h3>
                    <FormField>
                      <label>{t('workspaceSettingsSSO.ssoUrl')}</label>
                      <input
                        type="url"
                        name="idp_sso_target_url"
                        value={idp_sso_target_url}
                        placeholder={t('workspaceSettingsSSO.ssoUrl') ?? 'SSO URL'}
                        onChange={handleChange}
                      />
                      <small className="subtle">{t('workspaceSettingsSSO.ssoUrlInfo')}</small>
                    </FormField>
                    <FormField>
                      <label>{t('workspaceSettingsSSO.certificate')}</label>
                      <textarea
                        name="idp_cert"
                        value={idp_cert}
                        placeholder={t('workspaceSettingsSSO.certificate') ?? 'IDP Certificate'}
                        onChange={handleChange}
                      />
                      <small className="subtle">{t('workspaceSettingsSSO.certificateInfo')}</small>
                    </FormField>
                    <FormField>
                      {!workspaceIdpSSOTargetUrl && (
                        <KoalaButton submit disabled={isLoading} loading={isLoading}>
                          {t('shared.save')}
                        </KoalaButton>
                      )}
                      {workspaceIdpSSOTargetUrl && (
                        <KoalaButton submit disabled={isLoading} loading={isLoading} appearance="subtle">
                          {t('shared.save')}
                        </KoalaButton>
                      )}
                    </FormField>
                    {workspaceIdpSSOTargetUrl && workspaceAuthType === 'shared' && (
                      <FormField>
                        <h3>{t('workspaceSettingsSSO.step3')}</h3>
                        <FormField>
                          <KoalaButton href={saml_init_url} target="_blank" rel="noreferrer noopener">
                            {t('workspaceSettingsSSO.login')}
                          </KoalaButton>
                        </FormField>
                        <span className="subtle">
                          <small>{t('workspaceSettingsSSO.loginInfo')}</small>
                        </span>
                      </FormField>
                    )}
                  </Fragment>
                )}
              </div>
            )}
            {isError && (
              <ErrorList>
                {Object.keys(errors).map((errorKey: string) => {
                  // Get the first error for the key
                  // NOTE: there might be more! I'm just keeping the UI simpler right now.
                  const error: string = errors[errorKey][0];
                  return (
                    <li>
                      {errorKeyToString(errorKey)} {error.toLowerCase()}
                    </li>
                  );
                })}
              </ErrorList>
            )}
            {auth_type === 'shared' && (
              <Actions>
                <KoalaButton submit disabled={isLoading} loading={isLoading}>
                  {t('shared.save')}
                </KoalaButton>
                {isFormSaved && <div>{t('shared.saved')}</div>}
              </Actions>
            )}
          </Form>
        </SettingsNarrowWrapper>
      </ChromeContent>
    </Fragment>
  );
}

export default WorkspaceSettingsSSO;
