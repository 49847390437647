import queryKeys from 'config/queryKeys';
import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { Initiative, OutcomeRelationship } from 'types';
import * as remoteApi from 'api/remote';
import styled from 'styled-components';
import theme from 'theme';
import { AxiosResponse } from 'axios';
import KoalaLoader from 'koala/components/Loader';

import Initiatives from './Initiatives';
import OutcomeChild from './OutcomeChild';

const Container = styled.div`
  margin-left: ${theme.spacing.x7};
  height: fit-content;

  .no-tree {
    list-style-type: none;
    margin: 0;
    position: relative;
    margin-bottom: ${theme.spacing.x1};
  }

  .tree-item {
    list-style-type: none;
    margin: 0;
    position: relative;
  }

  .tree-item::before,
  .tree-item::after {
    content: '';
    left: -${theme.spacing.x1};
    position: absolute;
    right: auto;
  }

  .tree-item::before {
    border-left: 1px solid ${theme.colors.N20};
    bottom: 50px;
    height: 100%;
    top: 0;
  }

  .tree-item::after {
    box-sizing: border-box;
    border-left: 1px solid ${theme.colors.N20};
    border-bottom: 1px solid ${theme.colors.N20};
    border-bottom-left-radius: 10px;
    top: 0px;
    width: 12px;
    height: 20px;
  }

  .tree li.outcome::after {
    box-sizing: border-box;
    border-left: 1px solid ${theme.colors.N20};
    border-bottom: 1px solid ${theme.colors.N20};
    border-bottom-left-radius: 10px;
    top: 0px;
    width: 12px;
    height: 20px;
  }
  .tree li.initiative::after {
    box-sizing: border-box;
    border-left: 1px solid ${theme.colors.N20};
    border-bottom: 1px solid ${theme.colors.N20};
    border-bottom-left-radius: 10px;
    top: 0px;
    width: 12px;
    height: 20px;
  }

  .tree > ul > li::before,
  .tree > ul > li::after {
    border: 0;
  }

  .tree-item:last-child::before {
    height: 14px;
  }

  .tree-item:not(.parent_li) span {
    position: relative;
  }

  .tree-item span:before {
    content: '';
    position: absolute;
    left: -4px;
    bottom: calc(50% - 4px);
    border-color: transparent;
    border-left-color: ${theme.colors.N20};
    border-style: inherit;
    border-width: 4px;
  }
`;

const LoaderContainer = styled.div`
  padding: 1.2rem ${theme.spacing.x2};
`;

interface Props {
  outcomeId: string;
  hideClosedInitiatives: boolean;
}

function OutcomeChildren(props: Props) {
  const { outcomeId, hideClosedInitiatives } = props;
  const [initiatives, setInitiatives] = useState<Initiative[]>([]);
  const [parents, setParent] = useState<OutcomeRelationship[]>([]);
  const [contributing, setContributing] = useState<OutcomeRelationship[]>([]);

  const staleTime = 0;

  const queryKeyRelationship = [queryKeys.outcomeRelationships, outcomeId];

  // Get the outcomes
  const { isLoading: isOutcomesLoading } = useQuery(queryKeyRelationship, remoteApi.fetchOutcomeRelationships, {
    staleTime,
    onSuccess: (response: any) => {
      const outcomeRelationships: OutcomeRelationship[] = response.data;
      const parents: OutcomeRelationship[] = [];
      const contributing: OutcomeRelationship[] = [];
      outcomeRelationships.forEach((outcomeRelationship) => {
        if (
          outcomeRelationship.outcome_source_id === outcomeId &&
          outcomeRelationship.relationship_type === 'contributes_to'
        ) {
          parents.push(outcomeRelationship);
        }
        if (
          outcomeRelationship.outcome_target_id === outcomeId &&
          outcomeRelationship.relationship_type === 'contributes_to'
        ) {
          contributing.push(outcomeRelationship);
        }
      });
      setParent(parents);
      setContributing(contributing);
    },
  });

  // get the initiatives
  const queryKeyInitiatives = [queryKeys.initiatives, outcomeId];
  const { isLoading: isInitiativeLoading }: any = useQuery(queryKeyInitiatives, remoteApi.fetchInitiatives, {
    staleTime,
    onSuccess: (response: AxiosResponse) => {
      setInitiatives(response.data);
    },
  });

  if (isOutcomesLoading || isInitiativeLoading) {
    return (
      <LoaderContainer>
        <KoalaLoader />
      </LoaderContainer>
    );
  }

  return (
    <Container>
      <div className="tree">
        <ul>
          <li>
            <ul>
              {contributing.map((outcomeRelationship, i) => (
                <OutcomeChild
                  key={i}
                  outcomeRelationship={outcomeRelationship}
                  hideClosedInitiatives={hideClosedInitiatives}
                />
              ))}
              <Initiatives
                outcomeId={outcomeId}
                initiatives={initiatives}
                hideClosedInitiatives={hideClosedInitiatives}
                parents={parents}
              />
            </ul>
          </li>
        </ul>
      </div>
    </Container>
  );
}

export default OutcomeChildren;
