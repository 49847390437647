import React, { useState } from 'react';
import styled from 'styled-components';
import theme from 'theme';
import { useMutation, useQuery, useQueryCache } from 'react-query';
import queryKeys from 'config/queryKeys';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';

import * as workspaceUtils from 'utils/workspaceUtils';
import { setGlobalModalContent } from 'state/actions/globalUIActions';

// API
import * as remoteApi from 'api/remote';
import { useTranslation } from 'react-i18next';

// Components
import PlanPermissionSync from 'components/PlanPermissionSync';
import { PanelGrid, PanelHeader, PanelContent, PanelActions } from 'components/GlobalPanel';
import Loader from 'components/Loader';
import UpgradePanel from 'components/UpgradePanel';
import KoalaIconButton from 'koala/components/IconButton';
import { CustomTermKey, translate } from 'utils/customTermUtils';
import { Outcome, OutcomeRelationship, Plan, Workspace } from 'types';
import KoalaButton from 'koala/components/Button';
import KoalaTextButton from 'koala/components/TextButton';
import KoalaSelect, { KoalaSelectOption } from 'koala/components/Select';
import PlanIconLabel from 'components/PlanIconLabel';
import OutcomeSelection from './OutcomeSelection';
import KoalaTextBadge from 'koala/components/TextBadge';
import { AxiosResponse } from 'axios';
import KoalaColorBlock from 'koala/components/ColorBlock';
import { confidenceToColor } from 'utils/checkinUtils';
import KoalaLoader from 'koala/components/Loader';

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  .icon {
    transform: rotate(180deg);
  }
`;

const LoadingContainer = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;
`;

const OptionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.x3};

  h4 {
    font-size: 1.2rem;
    font-weight: 700;
    text-transform: uppercase;
  }
`;

const OptionSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.x1};
`;

const OptionsBlock = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.x1};
`;

const SelectHeader = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: ${theme.spacing.x1};
  margin-bottom: ${theme.spacing.x2};
`;

const OutcomeRow = styled.div`
  display: grid;
  gap: ${theme.spacing.x1};
  padding: ${theme.spacing.half} ${theme.spacing.x1};
  grid-template-columns: auto 1fr auto;
`;

const PlanWithParent = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.half};
  .parent {
    color: ${theme.colors.subtleText};
    font-size: 1.2rem;
  }
`;

interface Props {
  outcomeId: string;
}

function EditOutcomePanel(props: Props) {
  const { t } = useTranslation(undefined, { useSuspense: false });
  const translationKey = 'modals.addDependency';
  const dispatch = useDispatch();
  const [selectedPlan, setSelectedPlan] = useState<Plan | null>();
  const [selectedOutcome, setSelectedOutcome] = useState<KoalaSelectOption | null>(null);
  const queryCache = useQueryCache();
  const { outcomeId } = props;
  const [relationship, setRelationship] = useState<string | null>(null);
  const [parents, setParent] = useState<OutcomeRelationship[]>([]);
  const [related, setRelated] = useState<OutcomeRelationship[]>([]);
  const [contributing, setContributing] = useState<OutcomeRelationship[]>([]);

  const currentWorkspace: Workspace = useSelector((state: any) => state.session.currentWorkspace, shallowEqual);
  const hasStarterSubscription =
    workspaceUtils.hasStarterSubscription(currentWorkspace) || currentWorkspace.pricing_version >= 2;
  const [hasEditPermission, setHasEditPermission] = useState(false);

  // Query keys and query params
  const queryKey = [queryKeys.currentOutcome, outcomeId];
  const staleTime = 0;

  // Functions to update the outcome
  const [createOutcomeRelationshipMutation, { isLoading }]: any = useMutation(remoteApi.createOutcomeRelationship, {
    onSuccess: () => {
      queryCache.invalidateQueries(queryKeys.outcomeRelationships);
      queryCache.invalidateQueries([queryKeys.outcomes, 'search']); // Trick to force refrech of insights alignment
      dispatch(setGlobalModalContent(''));
    },
  });

  // Get the outcome details
  const { data: outcomeResponse } = useQuery(queryKey, remoteApi.fetchOutcomeDetails, {
    staleTime,
  });

  const outcome: Outcome = outcomeResponse ? outcomeResponse.data : null;

  // Get the outcomes
  const relationshipQueryKey = [queryKeys.outcomeRelationships, outcomeId];

  const { isLoading: isLoadingRelationships } = useQuery(relationshipQueryKey, remoteApi.fetchOutcomeRelationships, {
    staleTime,
    onSuccess: (response: AxiosResponse<OutcomeRelationship[]>) => {
      const outcomeRelationships: OutcomeRelationship[] = response.data;
      const parents: OutcomeRelationship[] = [];
      const related: OutcomeRelationship[] = [];
      const contributing: OutcomeRelationship[] = [];
      outcomeRelationships.forEach((outcomeRelationship) => {
        if (
          outcomeRelationship.outcome_source_id === outcome.id &&
          outcomeRelationship.relationship_type === 'contributes_to'
        ) {
          parents.push(outcomeRelationship);
        }
        if (outcomeRelationship.relationship_type === 'relates_to') {
          related.push(outcomeRelationship);
        }
        if (
          outcomeRelationship.outcome_target_id === outcome.id &&
          outcomeRelationship.relationship_type === 'contributes_to'
        ) {
          contributing.push(outcomeRelationship);
        }
      });
      setParent(parents);
      setRelated(related);
      setContributing(contributing);
    },
  });

  // Functions to destroy the relationship
  const [deleteOutcomeRelationshipMutation] = useMutation(remoteApi.deleteOutcomeRelationship, {
    onSuccess: () => {
      queryCache.invalidateQueries(queryKeys.outcomeRelationships);
    },
  });

  const handleSave = () => {
    if (!selectedOutcome) {
      return;
    }

    let relationship_type, outcome_source_id, outcome_target_id;
    if (relationship === 'add_parent') {
      outcome_source_id = outcome.id;
      outcome_target_id = selectedOutcome.meta.id;
      relationship_type = 'contributes_to';
    }

    if (relationship === 'add_related') {
      outcome_source_id = outcome.id;
      outcome_target_id = selectedOutcome.meta.id;
      relationship_type = 'relates_to';
    }

    if (relationship === 'add_dependency') {
      outcome_source_id = selectedOutcome.meta.id;
      outcome_target_id = outcome.id;
      relationship_type = 'contributes_to';
    }
    const outcome_relationship = {
      outcome_target_id,
      relationship_type,
    };

    createOutcomeRelationshipMutation({
      outcomeId: outcome_source_id,
      outcome_relationship,
    });
  };

  const handleCancel = (e: Event) => {
    e.preventDefault();
    dispatch(setGlobalModalContent(''));
  };
  // Now we can display the app with the Chrome
  if (!outcome) {
    return (
      <LoadingContainer>
        <Loader size="big" />
      </LoadingContainer>
    );
  }

  const handleRemove = (related: OutcomeRelationship) => {
    deleteOutcomeRelationshipMutation(related.id);
  };

  if (!hasStarterSubscription) {
    return (
      <PanelGrid>
        <PanelHeader>
          <Header>
            <h2>{t(`${translationKey}.title`)}</h2>
            <KoalaIconButton onClick={handleCancel} iconName="close" />
          </Header>
        </PanelHeader>
        <PanelContent>
          <UpgradePanel
            title={t(`${translationKey}.upgradePanelTitle`, {
              outcomes: translate(currentWorkspace, CustomTermKey.OUTCOME, 2),
            })}
            description={t(`${translationKey}.upgradePanelDescription`, {
              outcomes: translate(currentWorkspace, CustomTermKey.OUTCOME, 2),
            })}
          />
        </PanelContent>
      </PanelGrid>
    );
  }

  const { plan } = outcome;

  if (!hasEditPermission) {
    return (
      <PanelGrid>
        <PlanPermissionSync planId={plan.id} setHasEditPermission={setHasEditPermission} />
        <PanelHeader>
          <Header>
            <h2>{t(`${translationKey}.title`)}</h2>
            <KoalaIconButton onClick={handleCancel} iconName="close" />
          </Header>
        </PanelHeader>
        <PanelContent>
          <p>{t(`${translationKey}.noEditPermission`)}</p>
        </PanelContent>
      </PanelGrid>
    );
  }

  const handlePlanChanged = (option: any) => {
    setSelectedPlan(option);
    setSelectedOutcome(null);
  };

  const fetchData = (input: string) => {
    let planParams;
    if (input) {
      planParams = {
        filter: {
          title: input,
        },
        order_direction: 'asc',
        order_attribute: 'title',
        limit: 10,
      };
    } else {
      planParams = {
        filter: {
          status: 'active',
        },
        order_direction: 'asc',
        order_attribute: 'title',
        limit: 10,
      };
    }
    return remoteApi.fetchPlans(queryKeys.plans, currentWorkspace.slug, planParams).then((response) => {
      return response.data;
    });
  };

  const handleLoadPlanOptions = (input: string) => {
    return fetchData(input);
  };

  const handlePreselectParent = () => {
    if (plan && plan.parent) {
      setSelectedPlan(plan.parent);
      setSelectedOutcome(null);
    }
    setRelationship('add_parent');
  };

  const label = translate(currentWorkspace, CustomTermKey.OUTCOME, 1).toLowerCase();
  const relationshipToInstruction: any = {
    add_parent: t(`${translationKey}.addParent`),
    add_dependency: t(`${translationKey}.addDependency`),
    add_related: t(`${translationKey}.addRelated`),
  };

  return (
    <PanelGrid>
      <PanelHeader>
        <Header>
          <h2>{t(`${translationKey}.title`)}</h2>
          <KoalaIconButton onClick={handleCancel} iconName="close" />
        </Header>
      </PanelHeader>
      <PanelContent>
        {isLoadingRelationships && <KoalaLoader />}
        {!isLoadingRelationships && !relationship && (
          <OptionsContainer>
            <OptionsBlock>
              <h4>{t(`${translationKey}.optionsAddParentTitle`, { label })}</h4>
              {parents &&
                parents.map((parent) => (
                  <OutcomeRow>
                    <KoalaColorBlock color={confidenceToColor(parent.outcome_target.current_checkin?.confidence)} />
                    {parent.outcome_target.title}
                    <KoalaIconButton iconName="close" size="small" onClick={() => handleRemove(parent)} />
                  </OutcomeRow>
                ))}
              <div>
                <KoalaButton appearance="subtle" onClick={() => handlePreselectParent()}>
                  {t(`${translationKey}.addParent`)}
                </KoalaButton>
              </div>
            </OptionsBlock>

            <OptionsBlock>
              <h4>{t(`${translationKey}.optionsAddDependencyTitle`, { label })}</h4>
              {contributing &&
                contributing.map((contributing) => (
                  <OutcomeRow>
                    <KoalaColorBlock
                      color={confidenceToColor(contributing.outcome_source.current_checkin?.confidence)}
                    />
                    {contributing.outcome_source.title}
                    <KoalaIconButton iconName="close" size="small" onClick={() => handleRemove(contributing)} />
                  </OutcomeRow>
                ))}
              <div>
                <KoalaButton appearance="subtle" onClick={() => setRelationship('add_dependency')}>
                  {t(`${translationKey}.addDependency`)}
                </KoalaButton>
              </div>
            </OptionsBlock>

            <OptionsBlock>
              <h4>{t(`${translationKey}.optionsAddRelatedTitle`, { label })}</h4>
              {related &&
                related.map((related) => (
                  <OutcomeRow>
                    <KoalaColorBlock color={confidenceToColor(related.outcome_target.current_checkin?.confidence)} />
                    {related.outcome_target.title}
                    <KoalaIconButton iconName="close" size="small" onClick={() => handleRemove(related)} />
                  </OutcomeRow>
                ))}
              <div>
                <KoalaButton appearance="subtle" onClick={() => setRelationship('add_related')}>
                  {t(`${translationKey}.addRelated`)}
                </KoalaButton>
              </div>
            </OptionsBlock>
          </OptionsContainer>
        )}
        {relationship !== null && (
          <>
            <SelectHeader>
              <KoalaTextBadge variant="neutral-outlined">{relationshipToInstruction[relationship]}</KoalaTextBadge>
              <KoalaTextButton onClick={() => setRelationship(null)}>
                {t(`${translationKey}.changeRelationship`)}
              </KoalaTextButton>
            </SelectHeader>
            <OptionsContainer>
              <OptionSection>
                <h4>{t(`${translationKey}.plan`)}</h4>
                <KoalaSelect
                  isMultiSelect={false}
                  height={52}
                  isClearable={true}
                  handleChange={(option) => handlePlanChanged(option)}
                  placeholder={t(`${translationKey}.selectPlan`)}
                  loadOptions={handleLoadPlanOptions}
                  labelFormat={(option) => {
                    if (option.parent) {
                      return (
                        <PlanWithParent>
                          <span className="parent">{option.parent.title}</span>
                          <PlanIconLabel plan={option} size="small" />
                        </PlanWithParent>
                      );
                    }
                    return (
                      <PlanWithParent>
                        <span className="parent">{currentWorkspace.name}</span>
                        <PlanIconLabel plan={option} size="small" />
                      </PlanWithParent>
                    );
                  }}
                  selectedOption={selectedPlan}
                />
              </OptionSection>
              <OptionSection>
                <h4>{label}</h4>
                {selectedPlan && (
                  <OutcomeSelection
                    plan={selectedPlan}
                    setSelectedOutcome={setSelectedOutcome}
                    selectedOutcome={selectedOutcome}
                    currentOutcome={outcome}
                    workspace={currentWorkspace}
                  />
                )}
                {!selectedPlan && <p className="subtle">{t(`${translationKey}.selectPlan`)}</p>}
              </OptionSection>
            </OptionsContainer>
          </>
        )}
      </PanelContent>
      {relationship !== null && (
        <PanelActions>
          <KoalaButton submit onClick={handleSave} disabled={isLoading || !selectedOutcome} loading={isLoading}>
            {t('shared.save')}
          </KoalaButton>
          <KoalaTextButton onClick={handleCancel}>{t('shared.cancel')}</KoalaTextButton>
        </PanelActions>
      )}
    </PanelGrid>
  );
}

export default React.memo(EditOutcomePanel);
