import queryKeys from 'config/queryKeys';
import React, { Fragment, useState } from 'react';
import { useInfiniteQuery, useQuery } from 'react-query';
import { shallowEqual, useSelector } from 'react-redux';
import styled from 'styled-components';
import * as remoteApi from 'api/remote';
import { Membership, Segment, Workspace } from 'types';
import theme from 'theme';
import { CustomTermKey, translate } from 'utils/customTermUtils';
import { useTranslation } from 'react-i18next';
import { DragHandle, ListContainer, SourceTitle, WidgetContainer, WidgetContent, WidgetHeader } from '.';
import { Link, useHistory, useLocation, useParams } from 'react-router-dom';
import * as filterUtils from 'utils/filterUtils';
import * as workspaceUtils from 'utils/workspaceUtils';
import { Base64 } from 'js-base64';
import parse from 'parse-link-header';
import PlanIconLabel from 'components/PlanIconLabel';
import KoalaButton from 'koala/components/Button';
import UpgradeIcon from 'components/_assets/UpgradeIcon';
import KoalaTextButton from 'koala/components/TextButton';
import KoalaLoader from 'koala/components/Loader';
import InitiativeRow from 'components/InitiativeRow';
import KoalaIcon from 'koala/components/Icons';
import { WORKSPACE_INITIATIVE_SEGMENT_ROUTE_WITH_FILTERS } from 'routes';

const NoRows = styled.div`
  padding: ${theme.spacing.x1};
  text-align: center;
  color: ${theme.colors.subtleText};
`;

const UpgradeBanner = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: ${theme.spacing.x3};
  background: rgba(197, 165, 239, 0.25);
  max-width: 60rem;
  margin: ${theme.spacing.x1} auto;

  p {
    display: flex;
    align-items: center;

    svg {
      margin-right: ${theme.spacing.x1};
    }
  }
`;

const LoadMore = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: ${theme.spacing.x1} 0;
`;

const GroupSeparator = styled.div`
  padding: ${theme.spacing.x1} ${theme.spacing.x2};
  background: ${theme.colors.N5};
  font-weight: 900;
  font-size: 1rem;
  text-transform: uppercase;
  border-bottom: 1px solid ${theme.colors.blockBorder};
  display: flex;
  align-items: center;
  gap: ${theme.spacing.x1};
`;

const getNextPage = (response: any) => {
  if (response && response.headers && response.headers.link) {
    const links = response.headers.link;
    const parsed = parse(links);
    if (parsed && parsed.next) {
      return parsed.next.page;
    }
  }
  return null;
};

interface Props {
  segmentId: string;
}

function InitiativeListWidget(props: Props) {
  const { segmentId } = props;
  const workspace: Workspace = useSelector((state: any) => state.session.currentWorkspace, shallowEqual);
  const currentMembership: Membership = useSelector((state: any) => state.session.currentMembership, shallowEqual);
  const { t } = useTranslation();
  // Query keys and query params
  const [initiativeGroups, setInitiativeGroups]: any = useState([]);
  const [resultsCount, setResultsCount]: any = useState(0);
  const history = useHistory();
  const location = useLocation();

  const { workspaceSlug } = useParams<{
    workspaceSlug: string;
  }>();

  const { data: segmentResponse } = useQuery([queryKeys.segments, segmentId], remoteApi.fetchSegmentDetails);

  const segment: Segment = segmentResponse?.data;

  let filterParams = {};
  const customFilterHash = segment?.filter_hash ? JSON.parse(Base64.decode(segment.filter_hash)) : {};
  if (segment) {
    filterParams = filterUtils.getFilterHash(customFilterHash, segment.membership);
  }

  // Get the initiatives
  const queryKey = [
    queryKeys.initiatives,
    'search',
    {
      workspaceSlug,
      filter: filterParams,
    },
  ];
  const {
    isLoading: initiativesLoading,
    isFetching,
    isFetchingMore,
    fetchMore,
    canFetchMore,
  } = useInfiniteQuery(queryKey, remoteApi.searchInitiatives, {
    getFetchMore: (lastGroup, allGroups) => {
      return getNextPage(lastGroup);
    },
    onSuccess: (data: any) => {
      const resultsCount = parseInt(data[0].headers['x-total']);
      setResultsCount(resultsCount);
      setInitiativeGroups(data);
    },
  });

  const hasItems = initiativeGroups && initiativeGroups.length > 0 ? initiativeGroups[0].data.length > 0 : false;

  let indexCount = 0;
  const sortToGroupMapping: any = {
    plan: 'plan',
    owner: 'membership',
    state: 'state',
  };
  let currentGroup: any = null;
  let limitResultsForUpgrade = false;
  let freeLimit = 2;
  const hasEssentialsSubscription =
    workspaceUtils.hasEssentialsSubscription(workspace) || workspace.pricing_version >= 2;

  if (workspace.pricing_version === 4) {
    limitResultsForUpgrade = false;
  } else {
    limitResultsForUpgrade = !hasEssentialsSubscription;
  }
  const unseenResults = resultsCount - freeLimit;

  if (!segment) {
    return <WidgetContainer>{t('workspaceDashboards.widgetError')}</WidgetContainer>;
  }

  const segmentRoute = WORKSPACE_INITIATIVE_SEGMENT_ROUTE_WITH_FILTERS.replace(':workspaceSlug', workspace.slug)
    .replace(':segmentId', segment.id)
    .replace(':filter', segment.filter_hash);
  const isLoading = initiativesLoading || isFetching;

  return (
    <WidgetContainer>
      <WidgetHeader>
        <DragHandle className="drag-handle">
          <KoalaIcon iconName="grab" iconSize="small" />
        </DragHandle>
        <p className="widget-type">
          {t('workspaceDashboards.initiativeSummary', {
            initiative: translate(workspace, CustomTermKey.INITIATIVE, 1),
          })}
        </p>
      </WidgetHeader>

      <WidgetContent>
        <SourceTitle>
          <Link to={segmentRoute} className="widget-source">
            {segment.title}
          </Link>
        </SourceTitle>
        {isLoading && <KoalaLoader />}
        {!isLoading && !hasItems && (
          <NoRows>
            {t('workspaceFilters.emptyState', {
              type: translate(workspace, CustomTermKey.INITIATIVE, 2).toLowerCase(),
            })}
          </NoRows>
        )}
        {hasItems && (
          <ListContainer>
            {initiativeGroups.map((group: any, i: number) => {
              return (
                <Fragment key={i}>
                  {group.data.map((initiative: any, index: number) => {
                    indexCount++;

                    let displayCurrentGroup = false;
                    let comparedGroup = null;
                    // Get the grouping key
                    const groupKey = sortToGroupMapping[customFilterHash.sorting];
                    if (groupKey) {
                      comparedGroup = initiative[groupKey];
                      const currentGroupId = currentGroup ? currentGroup.id : null;
                      const comparedGroupId = comparedGroup ? comparedGroup.id : null;

                      if (currentGroupId !== comparedGroupId) {
                        currentGroup = comparedGroup;
                        displayCurrentGroup = true;
                      }
                    }

                    if (limitResultsForUpgrade && indexCount > freeLimit) {
                      return null;
                    }
                    const blockId = `initiative:${initiative.nano_slug}`;
                    const hashPath = `#${blockId}:show`;
                    const isSelected = location.hash.includes(hashPath);

                    const handleClick = (e: any) => {
                      e.preventDefault();
                      e.stopPropagation();
                      history.push(hashPath);
                    };

                    return (
                      <Fragment key={initiative.id}>
                        {displayCurrentGroup && customFilterHash.sorting === 'plan' && (
                          <GroupSeparator className="item">
                            <PlanIconLabel size="xsmall" plan={initiative.plan} />
                          </GroupSeparator>
                        )}
                        <InitiativeRow
                          initiative={initiative}
                          handleClick={handleClick}
                          isSelected={isSelected}
                          showHeirarchy={true}
                          showMeta={true}
                        />
                      </Fragment>
                    );
                  })}
                </Fragment>
              );
            })}
            {canFetchMore && !limitResultsForUpgrade && (
              <LoadMore>
                <KoalaButton loading={!!isFetchingMore} onClick={() => fetchMore()} appearance="secondary">
                  {t('shared.loadMore')}
                </KoalaButton>
              </LoadMore>
            )}
            {limitResultsForUpgrade && resultsCount > freeLimit && (
              <>
                {workspace.pricing_version < 4 && (
                  <UpgradeBanner>
                    <p>
                      <UpgradeIcon /> <b> {t('workspaceFilters.upgrade.moreResults', { count: unseenResults })}</b>
                    </p>
                    <p>
                      {t('workspaceFilters.upgrade.information', {
                        count: unseenResults,
                        type: translate(workspace, CustomTermKey.INITIATIVE, unseenResults).toLowerCase(),
                      })}
                    </p>
                    {currentMembership && ['owner'].includes(currentMembership.role) && (
                      <p>
                        <KoalaButton isLink to={`/${workspaceSlug}/settings/billing`}>
                          {t('shared.upgrade.startTrial')}
                        </KoalaButton>
                      </p>
                    )}
                    {currentMembership && !['owner'].includes(currentMembership.role) && (
                      <p>
                        <KoalaTextButton href="https://tability.io/pricing" target="_blank" rel="noreferrer">
                          {t('shared.upgrade.learnMore')}
                        </KoalaTextButton>
                      </p>
                    )}
                  </UpgradeBanner>
                )}
                {workspace.pricing_version === 4 && (
                  <UpgradeBanner>
                    <p>
                      <UpgradeIcon /> <b> {t('workspaceFilters.upgradeV4.moreResults', { count: unseenResults })}</b>
                    </p>
                    <p>
                      {t('workspaceFilters.upgradeV4.information', {
                        count: unseenResults,
                        type: translate(workspace, CustomTermKey.INITIATIVE, unseenResults).toLowerCase(),
                      })}
                    </p>
                    {currentMembership && ['owner'].includes(currentMembership.role) && (
                      <p>
                        <KoalaButton isLink to={`/${workspaceSlug}/settings/billing`}>
                          {t('workspaceFilters.upgradeV4.upgrade')}
                        </KoalaButton>
                      </p>
                    )}
                    {currentMembership && !['owner'].includes(currentMembership.role) && (
                      <p>
                        <KoalaTextButton href="https://tability.io/pricing" target="_blank" rel="noreferrer">
                          {t('shared.upgradeV4.learnMore')}
                        </KoalaTextButton>
                      </p>
                    )}
                  </UpgradeBanner>
                )}
              </>
            )}
          </ListContainer>
        )}
      </WidgetContent>
    </WidgetContainer>
  );
}

export default InitiativeListWidget;
