import React, { Fragment } from 'react';
import styled from 'styled-components';
import * as TabilityTypes from 'types';
import { useMutation } from 'react-query';
import theme from 'theme';
import { Helmet } from 'react-helmet';
import * as workspaceUtils from 'utils/workspaceUtils';

// API
import * as remoteApi from 'api/remote';

// Components
import { ChromeContent, ChromeSideNav } from 'components/Chrome';
import SettingsNarrowWrapper from 'components/SettingsNarrowWrapper';
import ContentTitle from 'components/ContentTitle';
import WorkspaceSettingsNav from 'components/WorkspaceSettingsNav';
import KoalaButton from 'koala/components/Button';
import KoalaIcon from 'koala/components/Icons';
import { Trans, useTranslation } from 'react-i18next';

const Block = styled.div`
  margin-bottom: ${theme.spacing.x4};

  h3 {
    margin-bottom: ${theme.spacing.x2};
  }
`;

const PricingTable = styled.div`
  margin: ${theme.spacing.x4} 0;
`;

const PricingGridLine = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  width: 100%;
  > div {
    padding: ${theme.spacing.x1} ${theme.spacing.x2};
    display: flex;
    align-items: center;
    gap: ${theme.spacing.half};
  }
  .grey-bg {
    background: ${theme.colors.N5};
  }
  &.pricing-header {
    > div {
      border-radius: 4px 4px 0 0;
      display: initial;
    }
    .pricing-plan-name {
      font-size: 2rem;
      font-weight: 600;
    }
    .pricing-plan-price {
      big {
        font-size: 2.4rem;
        font-weight: 600;
      }
      small {
        display: inline-block;
        margin-left: ${theme.spacing.half};
      }
    }
    .pricing-plan-upgrade {
      padding: ${theme.spacing.x2} 0;
      display: flex;
      justify-content: center;
      button {
        flex: 1;
        justify-content: center;
      }
    }
  }

  &.pricing-grid-header {
    font-weight: 800;
    font-size: 1.1rem;
    text-transform: uppercase;
    letter-spacing: 1px;
    border-top: 1px solid ${theme.colors.N20};
  }
`;

declare global {
  interface Window {
    Stripe: any;
    Rewardful: any;
  }
}

interface Props {
  workspace: TabilityTypes.Workspace;
}

function PricingVersion(props: Props) {
  const { workspace } = props;
  const { t } = useTranslation();

  // Workspace mutations
  const [createWorkspaceCheckoutSessionMutation, { isLoading: isLoadingCheckout }]: [any, any] = useMutation(
    remoteApi.createWorkspaceCheckoutSession,
    {
      onSuccess: (response) => {
        const { data } = response;
        const { session_id } = data;

        const stripe = window.Stripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);
        stripe
          .redirectToCheckout({
            sessionId: session_id,
          })
          .then((result: any) => {
            if (result.error) {
              alert(result.error.message);
            }
          });
      },
    },
  );

  // Workspace mutations
  const [createWorkspacePortalSessionMutation, { isLoading: isLoadingPortal }]: [any, any] = useMutation(
    remoteApi.createWorkspacePortalSession,
    {
      onSuccess: (response) => {
        const { data } = response;
        const { session_url } = data;

        window.location.href = session_url;
      },
    },
  );

  const handleSubscribe = (price_id: string) => {
    const params = {
      workspaceSlug: workspace.slug,
      body: {
        price_id,
      },
    };
    createWorkspaceCheckoutSessionMutation(params);
  };

  const handleShowPortal = () => {
    const params = {
      workspaceSlug: workspace.slug,
    };
    createWorkspacePortalSessionMutation(params);
  };

  const hasPlan = workspaceUtils.hasSubscription(workspace);

  return (
    <Fragment>
      <Helmet>
        <script src="https://js.stripe.com/v3/" />
        <title>
          {workspace.name} | {t('workspaceSettingsBillingPlans.title')} | Tability
        </title>
      </Helmet>
      <ChromeSideNav>
        <WorkspaceSettingsNav />
      </ChromeSideNav>
      <ChromeContent>
        <SettingsNarrowWrapper>
          <ContentTitle>
            <h1>{t('workspaceSettingsBilling.plans.title')}</h1>
          </ContentTitle>
          <p>
            <Trans
              i18nKey="workspaceSettingsBilling.currentPlan"
              values={{ plan: workspaceUtils.billingSubscriptionToString(workspace) }}
              components={{ b: <b /> }}
            />{' '}
            {t('workspaceSettingsBilling.plans.contactEnterprise')}
          </p>
          {hasPlan && (
            <p>
              <KoalaButton
                onClick={() => handleShowPortal()}
                disabled={isLoadingPortal}
                loading={isLoadingPortal}
                appearance="subtle"
              >
                {t('workspaceSettingsBilling.manageSubscription')}
              </KoalaButton>
            </p>
          )}
          <PricingTable>
            <PricingGridLine className="pricing-header">
              <div></div>
              <div>
                <div className="pricing-plan-name">{t('workspaceSettingsBilling.v2.free')}</div>
                <div className="pricing-plan-price">
                  <big>{t('workspaceSettingsBilling.plans.price', { price: '0' })}</big>
                  <small>{t('workspaceSettingsBilling.v2.currency')}</small>
                </div>
              </div>
              <div className="grey-bg">
                <div className="pricing-plan-name">{t('workspaceSettingsBilling.v2.essentials')}</div>
                <div className="pricing-plan-price">
                  <big>{t('workspaceSettingsBilling.plans.price', { price: '6' })}</big>
                  <small>{t('workspaceSettingsBilling.v2.currency')}</small>
                </div>
                {!hasPlan && (
                  <div className="pricing-plan-upgrade">
                    <KoalaButton
                      onClick={() => handleSubscribe('tability2-essentials-peruser-monthly')}
                      disabled={isLoadingCheckout}
                      loading={isLoadingCheckout}
                    >
                      {t('workspaceSettingsBilling.upgradeButton')}
                    </KoalaButton>
                  </div>
                )}
              </div>
              <div>
                <div className="pricing-plan-name">{t('workspaceSettingsBilling.v2.premium')}</div>
                <div className="pricing-plan-price">
                  <big>{t('workspaceSettingsBilling.plans.price', { price: '9' })}</big>
                  <small>{t('workspaceSettingsBilling.v2.currency')}</small>
                </div>
                {!hasPlan && (
                  <div className="pricing-plan-upgrade">
                    <KoalaButton
                      onClick={() => handleSubscribe('tability2-premium-peruser-monthly')}
                      disabled={isLoadingCheckout}
                      loading={isLoadingCheckout}
                    >
                      {t('workspaceSettingsBilling.upgradeButton')}
                    </KoalaButton>
                  </div>
                )}
              </div>
            </PricingGridLine>
            <PricingGridLine className="pricing-grid-header">
              <div>{t('workspaceSettingsBilling.usage')}</div>
              <div />
              <div className="grey-bg" />
              <div />
            </PricingGridLine>
            <PricingGridLine>
              <div>{t('workspaceSettingsBilling.usersLabel')}</div>
              <div>5</div>
              <div className="grey-bg">
                <KoalaIcon iconName="taskV2" iconSize={1.6} iconAppearance={theme.colors.G50} />
                {t('workspaceSettingsBilling.unlimited')}
              </div>
              <div>
                <KoalaIcon iconName="taskV2" iconSize={1.6} iconAppearance={theme.colors.G50} />
                {t('workspaceSettingsBilling.unlimited')}
              </div>
            </PricingGridLine>
            <PricingGridLine>
              <div>{t('workspaceSettingsBilling.checkins')}</div>
              <div>100</div>
              <div className="grey-bg">
                <KoalaIcon iconName="taskV2" iconSize={1.6} iconAppearance={theme.colors.G50} />
                {t('workspaceSettingsBilling.unlimited')}
              </div>
              <div>
                <KoalaIcon iconName="taskV2" iconSize={1.6} iconAppearance={theme.colors.G50} />
                {t('workspaceSettingsBilling.unlimited')}
              </div>
            </PricingGridLine>
            <PricingGridLine>
              <div>{t('workspaceSettingsBilling.integrations')}</div>
              <div>
                <KoalaIcon iconName="taskV2" iconSize={1.6} iconAppearance={theme.colors.G50} />
                {t('workspaceSettingsBilling.unlimited')}
              </div>
              <div className="grey-bg">
                <KoalaIcon iconName="taskV2" iconSize={1.6} iconAppearance={theme.colors.G50} />
                {t('workspaceSettingsBilling.unlimited')}
              </div>
              <div>
                <KoalaIcon iconName="taskV2" iconSize={1.6} iconAppearance={theme.colors.G50} />
                {t('workspaceSettingsBilling.unlimited')}
              </div>
            </PricingGridLine>
            <PricingGridLine className="pricing-grid-header">
              <div>{t('workspaceSettingsBilling.v2.goalSetting')}</div>
              <div />
              <div className="grey-bg" />
              <div />
            </PricingGridLine>
            <PricingGridLine>
              <div>{t('workspaceSettingsBilling.v2.planEditor')}</div>
              <div>
                <KoalaIcon iconName="taskV2" iconSize={1.6} iconAppearance={theme.colors.G50} />
              </div>
              <div className="grey-bg">
                <KoalaIcon iconName="taskV2" iconSize={1.6} iconAppearance={theme.colors.G50} />
              </div>
              <div>
                <KoalaIcon iconName="taskV2" iconSize={1.6} iconAppearance={theme.colors.G50} />
              </div>
            </PricingGridLine>
            <PricingGridLine>
              <div>{t('workspaceSettingsBilling.v2.OKRsTemplates')}</div>
              <div>
                <KoalaIcon iconName="taskV2" iconSize={1.6} iconAppearance={theme.colors.G50} />
              </div>
              <div className="grey-bg">
                <KoalaIcon iconName="taskV2" iconSize={1.6} iconAppearance={theme.colors.G50} />
              </div>
              <div>
                <KoalaIcon iconName="taskV2" iconSize={1.6} iconAppearance={theme.colors.G50} />
              </div>
            </PricingGridLine>
            <PricingGridLine>
              <div>{t('workspaceSettingsBilling.v2.alignment')}</div>
              <div>
                <KoalaIcon iconName="taskV2" iconSize={1.6} iconAppearance={theme.colors.G50} />
              </div>
              <div className="grey-bg">
                <KoalaIcon iconName="taskV2" iconSize={1.6} iconAppearance={theme.colors.G50} />
              </div>
              <div>
                <KoalaIcon iconName="taskV2" iconSize={1.6} iconAppearance={theme.colors.G50} />
              </div>
            </PricingGridLine>
            <PricingGridLine className="pricing-grid-header">
              <div>{t('workspaceSettingsBilling.v2.goalTracking')}</div>
              <div />
              <div className="grey-bg" />
              <div />
            </PricingGridLine>
            <PricingGridLine>
              <div>{t('workspaceSettingsBilling.v2.progressCheckins')}</div>
              <div>
                <KoalaIcon iconName="taskV2" iconSize={1.6} iconAppearance={theme.colors.G50} />
              </div>
              <div className="grey-bg">
                <KoalaIcon iconName="taskV2" iconSize={1.6} iconAppearance={theme.colors.G50} />
              </div>
              <div>
                <KoalaIcon iconName="taskV2" iconSize={1.6} iconAppearance={theme.colors.G50} />
              </div>
            </PricingGridLine>
            <PricingGridLine>
              <div>{t('workspaceSettingsBilling.v2.reminders')}</div>
              <div>
                <KoalaIcon iconName="taskV2" iconSize={1.6} iconAppearance={theme.colors.G50} />
              </div>
              <div className="grey-bg">
                <KoalaIcon iconName="taskV2" iconSize={1.6} iconAppearance={theme.colors.G50} />
              </div>
              <div>
                <KoalaIcon iconName="taskV2" iconSize={1.6} iconAppearance={theme.colors.G50} />
              </div>
            </PricingGridLine>
            <PricingGridLine>
              <div>{t('workspaceSettingsBilling.v2.trends')}</div>
              <div>
                <KoalaIcon iconName="taskV2" iconSize={1.6} iconAppearance={theme.colors.G50} />
              </div>
              <div className="grey-bg">
                <KoalaIcon iconName="taskV2" iconSize={1.6} iconAppearance={theme.colors.G50} />
              </div>
              <div>
                <KoalaIcon iconName="taskV2" iconSize={1.6} iconAppearance={theme.colors.G50} />
              </div>
            </PricingGridLine>
            <PricingGridLine>
              <div>{t('workspaceSettingsBilling.v2.strategyMap')}</div>
              <div>
                <KoalaIcon iconName="taskV2" iconSize={1.6} iconAppearance={theme.colors.G50} />
              </div>
              <div className="grey-bg">
                <KoalaIcon iconName="taskV2" iconSize={1.6} iconAppearance={theme.colors.G50} />
              </div>
              <div>
                <KoalaIcon iconName="taskV2" iconSize={1.6} iconAppearance={theme.colors.G50} />
              </div>
            </PricingGridLine>
            <PricingGridLine className="pricing-grid-header">
              <div>{t('workspaceSettingsBilling.v2.initiatives')}</div>
              <div />
              <div className="grey-bg" />
              <div />
            </PricingGridLine>
            <PricingGridLine>
              <div>{t('workspaceSettingsBilling.v2.initiativesMapping')}</div>
              <div>
                <KoalaIcon iconName="taskV2" iconSize={1.6} iconAppearance={theme.colors.G50} />
              </div>
              <div className="grey-bg">
                <KoalaIcon iconName="taskV2" iconSize={1.6} iconAppearance={theme.colors.G50} />
              </div>
              <div>
                <KoalaIcon iconName="taskV2" iconSize={1.6} iconAppearance={theme.colors.G50} />
              </div>
            </PricingGridLine>
            <PricingGridLine>
              <div>{t('workspaceSettingsBilling.v2.kanban')}</div>
              <div>
                <KoalaIcon iconName="taskV2" iconSize={1.6} iconAppearance={theme.colors.G50} />
              </div>
              <div className="grey-bg">
                <KoalaIcon iconName="taskV2" iconSize={1.6} iconAppearance={theme.colors.G50} />
              </div>
              <div>
                <KoalaIcon iconName="taskV2" iconSize={1.6} iconAppearance={theme.colors.G50} />
              </div>
            </PricingGridLine>
            <PricingGridLine>
              <div>{t('workspaceSettingsBilling.v2.dueDates')}</div>
              <div>
                <KoalaIcon iconName="taskV2" iconSize={1.6} iconAppearance={theme.colors.G50} />
              </div>
              <div className="grey-bg">
                <KoalaIcon iconName="taskV2" iconSize={1.6} iconAppearance={theme.colors.G50} />
              </div>
              <div>
                <KoalaIcon iconName="taskV2" iconSize={1.6} iconAppearance={theme.colors.G50} />
              </div>
            </PricingGridLine>
            <PricingGridLine className="pricing-grid-header">
              <div>{t('workspaceSettingsBilling.v2.reporting')}</div>
              <div />
              <div className="grey-bg" />
              <div />
            </PricingGridLine>
            <PricingGridLine>
              <div>{t('workspaceSettingsBilling.v2.slideshow')}</div>
              <div>
                <KoalaIcon iconName="taskV2" iconSize={1.6} iconAppearance={theme.colors.G50} />
              </div>
              <div className="grey-bg">
                <KoalaIcon iconName="taskV2" iconSize={1.6} iconAppearance={theme.colors.G50} />
              </div>
              <div>
                <KoalaIcon iconName="taskV2" iconSize={1.6} iconAppearance={theme.colors.G50} />
              </div>
            </PricingGridLine>
            <PricingGridLine>
              <div>{t('workspaceSettingsBilling.v2.digests')}</div>
              <div>
                <KoalaIcon iconName="taskV2" iconSize={1.6} iconAppearance={theme.colors.G50} />
              </div>
              <div className="grey-bg">
                <KoalaIcon iconName="taskV2" iconSize={1.6} iconAppearance={theme.colors.G50} />
              </div>
              <div>
                <KoalaIcon iconName="taskV2" iconSize={1.6} iconAppearance={theme.colors.G50} />
              </div>
            </PricingGridLine>
            <PricingGridLine>
              <div>{t('workspaceSettingsBilling.v2.filter')}</div>
              <div>
                <KoalaIcon iconName="taskV2" iconSize={1.6} iconAppearance={theme.colors.G50} />
              </div>
              <div className="grey-bg">
                <KoalaIcon iconName="taskV2" iconSize={1.6} iconAppearance={theme.colors.G50} />
              </div>
              <div>
                <KoalaIcon iconName="taskV2" iconSize={1.6} iconAppearance={theme.colors.G50} />
              </div>
            </PricingGridLine>
            <PricingGridLine>
              <div>{t('workspaceSettingsBilling.v2.exports')}</div>
              <div>
                <KoalaIcon iconName="taskV2" iconSize={1.6} iconAppearance={theme.colors.G50} />
              </div>
              <div className="grey-bg">
                <KoalaIcon iconName="taskV2" iconSize={1.6} iconAppearance={theme.colors.G50} />
              </div>
              <div>
                <KoalaIcon iconName="taskV2" iconSize={1.6} iconAppearance={theme.colors.G50} />
              </div>
            </PricingGridLine>
            <PricingGridLine>
              <div>{t('workspaceSettingsBilling.v2.publicSharing')}</div>
              <div>
                <KoalaIcon iconName="close" iconAppearance={theme.colors.R50} />
              </div>
              <div className="grey-bg">
                <KoalaIcon iconName="taskV2" iconSize={1.6} iconAppearance={theme.colors.G50} />
              </div>
              <div>
                <KoalaIcon iconName="taskV2" iconSize={1.6} iconAppearance={theme.colors.G50} />
              </div>
            </PricingGridLine>
            <PricingGridLine className="pricing-grid-header">
              <div>{t('workspaceSettingsBilling.v2.security')}</div>
              <div />
              <div className="grey-bg" />
              <div />
            </PricingGridLine>
            <PricingGridLine>
              <div>{t('workspaceSettingsBilling.v2.google')}</div>
              <div>
                <KoalaIcon iconName="taskV2" iconSize={1.6} iconAppearance={theme.colors.G50} />
              </div>
              <div className="grey-bg">
                <KoalaIcon iconName="taskV2" iconSize={1.6} iconAppearance={theme.colors.G50} />
              </div>
              <div>
                <KoalaIcon iconName="taskV2" iconSize={1.6} iconAppearance={theme.colors.G50} />
              </div>
            </PricingGridLine>
            <PricingGridLine>
              <div>{t('workspaceSettingsBilling.v2.encrypted')}</div>
              <div>
                <KoalaIcon iconName="taskV2" iconSize={1.6} iconAppearance={theme.colors.G50} />
              </div>
              <div className="grey-bg">
                <KoalaIcon iconName="taskV2" iconSize={1.6} iconAppearance={theme.colors.G50} />
              </div>
              <div>
                <KoalaIcon iconName="taskV2" iconSize={1.6} iconAppearance={theme.colors.G50} />
              </div>
            </PricingGridLine>
            <PricingGridLine>
              <div>{t('workspaceSettingsBilling.v2.2FA')}</div>
              <div>
                <KoalaIcon iconName="taskV2" iconSize={1.6} iconAppearance={theme.colors.G50} />
              </div>
              <div className="grey-bg">
                <KoalaIcon iconName="taskV2" iconSize={1.6} iconAppearance={theme.colors.G50} />
              </div>
              <div>
                <KoalaIcon iconName="taskV2" iconSize={1.6} iconAppearance={theme.colors.G50} />
              </div>
            </PricingGridLine>
            <PricingGridLine>
              <div>{t('workspaceSettingsBilling.v2.saml')}</div>
              <div>
                <KoalaIcon iconName="close" iconAppearance={theme.colors.R50} />
              </div>
              <div className="grey-bg">
                <KoalaIcon iconName="close" iconAppearance={theme.colors.R50} />
              </div>
              <div>
                <KoalaIcon iconName="taskV2" iconSize={1.6} iconAppearance={theme.colors.G50} />
              </div>
            </PricingGridLine>
            <PricingGridLine>
              <div>{t('workspaceSettingsBilling.v2.restrictedIPs')}</div>
              <div>
                <KoalaIcon iconName="close" iconAppearance={theme.colors.R50} />
              </div>
              <div className="grey-bg">
                <KoalaIcon iconName="close" iconAppearance={theme.colors.R50} />
              </div>
              <div>
                <KoalaIcon iconName="taskV2" iconSize={1.6} iconAppearance={theme.colors.G50} />
              </div>
            </PricingGridLine>
            <PricingGridLine>
              <div>{t('workspaceSettingsBilling.v2.restrictedEmails')}</div>
              <div>
                <KoalaIcon iconName="close" iconAppearance={theme.colors.R50} />
              </div>
              <div className="grey-bg">
                <KoalaIcon iconName="close" iconAppearance={theme.colors.R50} />
              </div>
              <div>
                <KoalaIcon iconName="taskV2" iconSize={1.6} iconAppearance={theme.colors.G50} />
              </div>
            </PricingGridLine>
          </PricingTable>
          <Block>
            <KoalaButton
              href="https://tability.io/pricing"
              target="_blank"
              rel="noopened noreferrer"
              appearance="subtle"
            >
              {t('workspaceSettingsBilling.compareButton')}
            </KoalaButton>
          </Block>
        </SettingsNarrowWrapper>
      </ChromeContent>
    </Fragment>
  );
}

export default PricingVersion;
