import { ModalContent, ModalGrid, ModalHeader } from 'components/GlobalModal';
import queryKeys from 'config/queryKeys';
import KoalaIconButton from 'koala/components/IconButton';
import React, { useState } from 'react';
import { useMutation, useQueryCache } from 'react-query';
import * as remoteApi from 'api/remote';
import KoalaButton from 'koala/components/Button';
import { useDispatch } from 'react-redux';
import { setGlobalModalContent } from 'state/actions/globalUIActions';
import styled from 'styled-components';
import theme from 'theme';
import { useTranslation } from 'react-i18next';

const PlanInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.x1};
  a {
    color: ${theme.colors.blue};
  }
`;

const ErrorContainer = styled.p`
  color: ${theme.colors.red};
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.x2};

  textarea {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif,
      'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol' !important;
    font-size: 1.4rem;
    line-height: 1.4;
    padding: ${theme.spacing.x1};
    min-height: 5rem;
    width: 100%;
    border-radius: 4px;
    border: 1px solid #949494;
    &:placeholder {
      color: #949494;
      font-size: 1.4rem;
    }
  }

  .inline {
    display: flex;
    gap: ${theme.spacing.x1};
  }
`;

const Block = styled.div`
  border: 1px solid ${theme.colors.blockBorder};
  padding: ${theme.spacing.x2};
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.x2};
`;

const ErrorWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.x1};
`;

interface Props {
  planId: string;
}

function ImportPlanContent(props: Props) {
  const { planId } = props;
  const dispatch = useDispatch();
  const queryCache = useQueryCache();
  const { t } = useTranslation();
  const translationKey = 'modals.importPlanContent';
  const [cvsFile, setCSVFile] = useState<File | null>(null);
  const [textContent, setTextContent] = useState('');

  // Mutation that will update the plan in the backend
  const [fillFromCSVMutation, { isLoading, isError }]: [any, any] = useMutation(remoteApi.fillFromCSV, {
    onSuccess: () => {
      queryCache.invalidateQueries(queryKeys.currentPlan);
      queryCache.invalidateQueries(queryKeys.objectives);
      queryCache.invalidateQueries(queryKeys.outcomes);
      queryCache.invalidateQueries(queryKeys.initiatives);
      queryCache.invalidateQueries(queryKeys.currentPing);

      dispatch(setGlobalModalContent(''));
    },
  });

  // Mutation that will update the plan in the backend
  const [fillFromTextMutation, { isLoading: isLoadingPlansStructure, isError: isErrorPlansStructure }]: [any, any] =
    useMutation(remoteApi.fillFromText, {
      onSuccess: () => {
        queryCache.invalidateQueries(queryKeys.currentPlan);
        queryCache.invalidateQueries(queryKeys.objectives);
        queryCache.invalidateQueries(queryKeys.outcomes);
        queryCache.invalidateQueries(queryKeys.initiatives);
        queryCache.invalidateQueries(queryKeys.currentPing);
        dispatch(setGlobalModalContent(''));
      },
    });

  const handleCancel = (e: any) => {
    e.preventDefault();
    dispatch(setGlobalModalContent(''));
  };

  const handleFillFromCSV = (e: any) => {
    e.preventDefault();
    const params = {
      planId,
      file: cvsFile,
    };
    fillFromCSVMutation(params);
  };

  const handleFillFromText = (e: any) => {
    e.preventDefault();
    const params = {
      planId,
      text: textContent,
    };
    fillFromTextMutation(params);
  };

  const handleCSVFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    const inputTarget = e.target;
    setCSVFile(inputTarget.files ? inputTarget.files[0] : null);
  };

  return (
    <ModalGrid>
      <ModalHeader>
        <h2>{t(`${translationKey}.title`)}</h2>
        <KoalaIconButton onClick={handleCancel} iconName="close" />
      </ModalHeader>
      <ModalContent>
        <Wrapper>
          <form onSubmit={handleFillFromCSV}>
            <Block>
              <PlanInfoContainer>
                <b>{t(`${translationKey}.option1Title`)}</b>
                <small className="subtle">{t(`${translationKey}.option1Info`)}</small>
              </PlanInfoContainer>
              <input type="file" accept=".csv" onChange={handleCSVFileChange} required />
              <div>
                <KoalaButton type="submit" loading={isLoading} disabled={isLoading}>
                  {t(`${translationKey}.option1Button`)}
                </KoalaButton>
              </div>
              {isError && (
                <ErrorWrapper>
                  <ErrorContainer className="error">{t(`${translationKey}.option1Error`)}</ErrorContainer>
                  <div>
                    <KoalaButton appearance="subtle" size="small" onClick={() => window.Intercom('show')}>
                      {t('shared.getHelp')}
                    </KoalaButton>
                  </div>
                </ErrorWrapper>
              )}
            </Block>
          </form>
          <div>OR</div>
          <form onSubmit={handleFillFromText}>
            <Block>
              <PlanInfoContainer>
                <b>{t(`${translationKey}.option2Title`)}</b>
                <small className="subtle">{t(`${translationKey}.option2Info`)}</small>
              </PlanInfoContainer>
              <div>
                <textarea
                  placeholder={t(`${translationKey}.option2Placeholder`) || ''}
                  value={textContent}
                  onChange={(e) => setTextContent(e.target.value)}
                  rows={10}
                  required
                />
              </div>
              <div>
                <KoalaButton type="submit" loading={isLoadingPlansStructure} disabled={isLoadingPlansStructure}>
                  {t(`${translationKey}.option2Button`)}
                </KoalaButton>
              </div>
              {isErrorPlansStructure && (
                <ErrorWrapper>
                  <ErrorContainer className="error">{t(`${translationKey}.option2Error`)}</ErrorContainer>
                  <div>
                    <KoalaButton appearance="subtle" size="small" onClick={() => window.Intercom('show')}>
                      {t('shared.getHelp')}
                    </KoalaButton>
                  </div>
                </ErrorWrapper>
              )}
            </Block>
          </form>
        </Wrapper>
      </ModalContent>
    </ModalGrid>
  );
}

export default ImportPlanContent;
