import React, { useEffect, useRef, Fragment } from 'react';
import styled from 'styled-components';
import theme from 'theme';
import * as TabilityTypes from 'types';
import { useSelector, shallowEqual } from 'react-redux';
import { useMutation, useQueryCache } from 'react-query';
import queryKeys from 'config/queryKeys';
import * as workspaceUtils from 'utils/workspaceUtils';

// API
import * as remoteApi from 'api/remote';

// Components
import FormField from 'components/FormField';
import Loader from 'components/Loader';
import Toggle from 'components/Toggle';
import CopyTextInput from 'components/CopyTextInput';
import UpgradeIcon from 'components/_assets/UpgradeIcon';

import ZapierIcon from './IconZapier.png';
import KoalaTextButton from 'koala/components/TextButton';
import KoalaButton from 'koala/components/Button';
import { Trans, useTranslation } from 'react-i18next';

const Help = styled.div`
  a {
    color: ${theme.colors.blue};
  }
  margin-bottom: ${theme.spacing.x3};

  p {
    display: flex;
    align-items: center;
    &.body-text {
      display: inherit;
    }
    svg {
      margin-right: ${theme.spacing.x1};
    }
  }

  background: ${theme.colors.upgradeBg};

  padding: ${theme.spacing.x3};
  border-radius: 8px;
`;

const Info = styled.div`
  a {
    color: ${theme.colors.blue};
  }
  margin-top: ${theme.spacing.x2};

  p {
    display: flex;
    align-items: center;
    svg {
      margin-right: ${theme.spacing.x1};
    }
  }

  background: ${theme.colors.infoBg};

  padding: ${theme.spacing.x2};
  border-radius: 8px;
`;

const LoadingContainer = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;
`;

const ToggleContainer = styled.div`
  display: flex;
  margin: ${theme.spacing.x2} 0;

  > div {
    margin-right: ${theme.spacing.x1};
  }

  &.disabled {
    opacity: 0.7;
  }
`;

const Inline = styled.div`
  display: flex;
  align-items: center;
  > div:first-of-type {
    flex: 1;
  }
  a {
    margin-left: ${theme.spacing.x1};
  }
`;

const ZapierBlock = styled.div`
  h3 {
    display: flex;
    align-items: center;
    img {
      width: 1.6rem;
      margin-right: ${theme.spacing.x1};
    }
    margin-bottom: ${theme.spacing.x1};
  }

  > div {
    margin-bottom: ${theme.spacing.x2};
  }
`;

interface Props {
  outcome: TabilityTypes.Outcome;
}

function EditOutcomePanel(props: Props) {
  const queryCache = useQueryCache();
  const inputEl: any = useRef(null);
  const { t } = useTranslation();
  const translationKey = 'panels.editOutcome';

  const { outcome } = props;

  const currentMembership: TabilityTypes.Membership = useSelector(
    (state: any) => state.session.currentMembership,
    shallowEqual,
  );
  const currentUser: TabilityTypes.User = useSelector((state: any) => state.session.currentUser, shallowEqual);

  const currentWorkspace = useSelector((state: any) => state.session.currentWorkspace, shallowEqual);
  const workspaceSlug = currentWorkspace.slug;
  const hasEssentialsSubscription =
    workspaceUtils.hasEssentialsSubscription(currentWorkspace) || currentWorkspace.pricing_version >= 2;

  const v4Disabled = currentWorkspace.pricing_version === 4 && !workspaceUtils.hasV4BasicSubscription(currentWorkspace);

  // Adds the current user to the membership to be able to display the user in the panel
  currentMembership.user = currentUser;

  useEffect(() => {
    if (inputEl && inputEl.current) {
      inputEl.current.focus();
    }
  }, [inputEl]);

  // Functions to update the outcome
  const [manageOutcomeMutation, { isLoading: isUpdating }]: [any, any] = useMutation(remoteApi.manageOutcome, {
    onSuccess: (response) => {
      queryCache.invalidateQueries(queryKeys.outcomes);
      queryCache.invalidateQueries(queryKeys.currentOutcome);
      //queryCache.setQueryData(queryKey, response);
      //history.goBack();
    },
  });

  const handleToggleRemoteUpdates = (e: any) => {
    const remote_checkins_enabled = e.target.checked;
    let outcomeParams = {
      remote_checkins_enabled,
    };

    const mutationParams = {
      outcomeId: outcome.nano_slug,
      outcome: outcomeParams,
    };
    manageOutcomeMutation(mutationParams);
  };

  // Now we can display the app with the Chrome
  if (!outcome) {
    return (
      <LoadingContainer>
        <Loader size="big" />
      </LoadingContainer>
    );
  }

  const remoteUpdateClass = hasEssentialsSubscription ? '' : 'disabled';
  const remoteUpdateLink = outcome.remote_checkins_url;

  return (
    <>
      <FormField>
        <p>{t(`${translationKey}.remoteCheckinInfo`)}</p>
      </FormField>
      <form>
        <FormField>
          <label>{t(`${translationKey}.remoteCheckin`)}</label>
          <ToggleContainer className={remoteUpdateClass}>
            <Toggle>
              <input
                className="tgl tgl-light"
                id="remote_checkins_enabled"
                type="checkbox"
                name="remote_checkins_enabled"
                defaultChecked={outcome.remote_checkins_enabled}
                onChange={handleToggleRemoteUpdates}
                disabled={!hasEssentialsSubscription || v4Disabled}
              />
              <label className="tgl-btn" htmlFor="remote_checkins_enabled" data-tip data-for="toggleContainer" />
              <div>{t(`${translationKey}.enableRemote`)}</div>
              {isUpdating && <Loader />}
            </Toggle>
          </ToggleContainer>
        </FormField>
        {outcome.remote_checkins_enabled && (
          <Fragment>
            <FormField>
              <label>{t(`${translationKey}.checkinApiUrl`)}</label>
              <Inline>
                <CopyTextInput text={remoteUpdateLink} />
              </Inline>
            </FormField>
            <FormField>
              <label>{t(`${translationKey}.checkinApiToken`)}</label>
              <span>{outcome.remote_checkins_token}</span>
            </FormField>
          </Fragment>
        )}
        {v4Disabled && (
          <Help>
            <Fragment>
              <p>
                <UpgradeIcon />
                <b>{t(`${translationKey}.upgrade`)}</b>
              </p>
              {currentMembership && ['owner'].includes(currentMembership.role) && (
                <p className="body-text">
                  <Trans
                    i18nKey={`${translationKey}.upgradeLink`}
                    values={{ plan: 'Plus subscription' }}
                    components={{
                      plan: (
                        <KoalaTextButton
                          isInlineText
                          isLink
                          to={`/${workspaceSlug}/settings/billing`}
                          style={{ color: theme.colors.blue }}
                        />
                      ),
                    }}
                  />
                </p>
              )}
              {currentMembership && !['owner'].includes(currentMembership.role) && (
                <p>
                  <Trans
                    i18nKey={`${translationKey}.upgradeLink`}
                    components={{
                      plan: <b />,
                    }}
                  />
                </p>
              )}
            </Fragment>
          </Help>
        )}
        {!hasEssentialsSubscription && (
          <Help>
            <Fragment>
              <p>
                <UpgradeIcon />
                <b>{t(`${translationKey}.upgrade`)}</b>
              </p>
              {currentMembership && ['owner'].includes(currentMembership.role) && (
                <p className="body-text">
                  <Trans
                    i18nKey={`${translationKey}.upgradeLink`}
                    values={{ plan: 'Essentials plan' }}
                    components={{
                      plan: (
                        <KoalaTextButton
                          isInlineText
                          isLink
                          to={`/${workspaceSlug}/settings/billing`}
                          style={{ color: theme.colors.blue }}
                        />
                      ),
                    }}
                  />
                </p>
              )}
              {currentMembership && !['owner'].includes(currentMembership.role) && (
                <p>
                  <Trans
                    i18nKey={`${translationKey}.upgradeLink`}
                    values={{ plan: 'Essentials plan' }}
                    components={{
                      plan: <b />,
                    }}
                  />
                </p>
              )}
            </Fragment>
          </Help>
        )}
      </form>
      <FormField>
        <p>
          <KoalaTextButton
            href="https://guides.tability.app/docs/tutorials/track-progress-with-check-ins/automate-check-ins"
            target="_blank"
            rel="noopener noreferrer"
            appearance="secondary"
          >
            {t(`${translationKey}.apiTutorial`)}
          </KoalaTextButton>
        </p>
      </FormField>
      <ZapierBlock>
        <h3>
          <img src={ZapierIcon} alt="Zapier" /> {t(`${translationKey}.zapier`)}
        </h3>
        <FormField>
          <p>{t(`${translationKey}.zapierInfo`)}</p>
        </FormField>
        {!outcome.remote_checkins_enabled && <Info>{t(`${translationKey}.remoteZapier`)}</Info>}
        {outcome.remote_checkins_enabled && (
          <div>
            <KoalaButton
              href="https://zapier.com/apps/tability/integrations"
              target="_blank"
              rel="noopener noreferrer"
              appearance="secondary"
            >
              {t(`${translationKey}.setupZapier`)}
            </KoalaButton>
          </div>
        )}
        <div>
          <KoalaTextButton
            href="https://guides.tability.app/docs/integrations/zapier"
            target="_blank"
            rel="noopener noreferrer"
            appearance="secondary"
          >
            {t(`${translationKey}.zapierTutorial`)}
          </KoalaTextButton>
        </div>
        <h3>{t(`${translationKey}.help`)}</h3>
        <KoalaButton appearance="secondary" onClick={() => window.Intercom('show')}>
          {t(`${translationKey}.contactSupport`)}
        </KoalaButton>
      </ZapierBlock>
    </>
  );
}

export default React.memo(EditOutcomePanel);
