import KoalaIconButton from 'koala/components/IconButton';
import KoalaSelect from 'koala/components/Select';
import React, { Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import theme from 'theme';
import FormField from 'components/FormField';
import PlanSummaryWidgetDetails from './WidgetDetails/PlanSummaryWidgetDetails';
import ObjectiveSummaryWidgetDetails from './WidgetDetails/ObjectiveSummaryWidgetDetails';
import OutcomeSummaryWidgetDetails from './WidgetDetails/OutcomeSummaryWidgetDetails';
import UserProfileWidgetDetails from './WidgetDetails/UserProfileDetails';
import { CustomTermKey, translate } from 'utils/customTermUtils';
import { shallowEqual, useSelector } from 'react-redux';
import InitiativeSummaryWidgetDetails from './WidgetDetails/InitiativeSummaryWidgetDetails';
import ObjectiveListWidgetDetails from './WidgetDetails/ObjectiveListWidgetDetails';
import OutcomeListWidgetDetails from './WidgetDetails/OutcomeListWidgetDetails';
import InitiativeListWidgetDetails from './WidgetDetails/InitiativeListWidgetDetails';
import OutcomeTaskGraphWidgetDetails from './WidgetDetails/OutcomeTaskGraphWidgetDetails';
import PlanConfidenceGraphWidgetDetails from './WidgetDetails/PlanConfidenceGraphWidgetDetails';
import PlanNCSGraphWidgetDetails from './WidgetDetails/PlanNCSGraphWidgetDetails';
import OutcomeGraphWidgetDetails from './WidgetDetails/OutcomeGraphWidgetDetails';

const Mask = styled.div<{ showSearchPanel: boolean }>`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.4);
  z-index: ${(props) => (props.showSearchPanel ? '50' : '-1')};
  opacity: ${(props) => (props.showSearchPanel ? '1' : '0')};
  transition: 0.2s ease-out;
`;

const ContentWrapper = styled.div<{ showSearchPanel: boolean }>`
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  width: 40rem;
  z-index: 60;
  transform: translateX(${(props) => (props.showSearchPanel ? '0%' : '100%')});
  transition: 0.2s ease-out;

  @media ${theme.devices.mobile} {
    max-width: 40rem;
    width: 100%;
  }
`;

const Content = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  overflow: auto;
  padding: 3.2rem;

  background: #fff;

  input {
    margin-bottom: ${theme.spacing.x2};
  }
`;

const SearchHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #151515;
  font-weight: 700;
  font-size: 2rem;
  margin-bottom: ${theme.spacing.x3};
`;

interface Props {
  showPanel: boolean;
  setShowPanel: (newValue: boolean) => void;
  handleAddWidget: (type: string, id: string, source: string) => void;
  isAddingWidget: boolean;
}

function WidgetPanel(props: Props) {
  const { setShowPanel, showPanel, handleAddWidget, isAddingWidget } = props;
  const { t } = useTranslation();
  const currentWorkspace = useSelector((state: any) => state.session.currentWorkspace, shallowEqual);

  const [widgetType, setWidgetType] = useState('');

  const handleClosePanel = () => {
    setShowPanel(false);
  };

  const handleSingleOptionSelect = (option: any) => {
    const value = option ? option.value : '';
    setWidgetType(value);
  };

  const insightOptions = [
    { label: t('workspaceDashboards.planSummary'), value: 'plan_summary' },
    {
      label: t('workspaceDashboards.objectiveSummary', {
        objective: translate(currentWorkspace, CustomTermKey.OBJECTIVE, 1),
      }),
      value: 'objective_summary',
    },
    {
      label: t('workspaceDashboards.outcomeSummary', {
        outcome: translate(currentWorkspace, CustomTermKey.OUTCOME, 1),
      }),
      value: 'outcome_summary',
    },
    {
      label: t('workspaceDashboards.initiativeSummary', {
        initiative: translate(currentWorkspace, CustomTermKey.INITIATIVE, 1),
      }),
      value: 'initiative_summary',
    },
    { label: t('workspaceDashboards.userProfile'), value: 'user_profile' },
    {
      label: t('workspaceDashboards.objectiveList', {
        objective: translate(currentWorkspace, CustomTermKey.OBJECTIVE, 1),
      }),
      value: 'objective_list',
    },
    {
      label: t('workspaceDashboards.outcomeList', {
        outcome: translate(currentWorkspace, CustomTermKey.OUTCOME, 1),
      }),
      value: 'outcome_list',
    },
    {
      label: t('workspaceDashboards.initiativeList', {
        initiative: translate(currentWorkspace, CustomTermKey.INITIATIVE, 1),
      }),
      value: 'initiative_list',
    },
    {
      label: t('workspaceDashboards.OutcomeTaskGraph', {
        outcomes: translate(currentWorkspace, CustomTermKey.OUTCOME, 2),
        tasks: translate(currentWorkspace, CustomTermKey.INITIATIVE, 2),
      }),
      value: 'outcome-task-graph',
    },
    {
      label: t('workspaceDashboards.PlanConfidenceGraph'),
      value: 'plan-confidence-graph',
    },
    {
      label: t('workspaceDashboards.PlanNCSGraph'),
      value: 'plan-ncs-graph',
    },
    {
      label: t('workspaceDashboards.OutcomeGraph', {
        outcome: translate(currentWorkspace, CustomTermKey.OUTCOME, 1),
      }),
      value: 'outcome-graph',
    },
  ];

  const getSelectedInsightOptionsFromFilter = () => {
    return insightOptions.filter((o: any) => {
      return o.value === widgetType;
    });
  };

  const widgetDetails = () => {
    switch (widgetType) {
      case 'plan_summary':
        return <PlanSummaryWidgetDetails handleAddWidget={handleAddWidget} isLoading={isAddingWidget} />;
      case 'objective_summary':
        return <ObjectiveSummaryWidgetDetails handleAddWidget={handleAddWidget} isLoading={isAddingWidget} />;
      case 'outcome_summary':
        return <OutcomeSummaryWidgetDetails handleAddWidget={handleAddWidget} isLoading={isAddingWidget} />;
      case 'initiative_summary':
        return <InitiativeSummaryWidgetDetails handleAddWidget={handleAddWidget} isLoading={isAddingWidget} />;
      case 'user_profile':
        return <UserProfileWidgetDetails handleAddWidget={handleAddWidget} isLoading={isAddingWidget} />;
      case 'objective_list':
        return <ObjectiveListWidgetDetails handleAddWidget={handleAddWidget} isLoading={isAddingWidget} />;
      case 'outcome_list':
        return <OutcomeListWidgetDetails handleAddWidget={handleAddWidget} isLoading={isAddingWidget} />;
      case 'initiative_list':
        return <InitiativeListWidgetDetails handleAddWidget={handleAddWidget} isLoading={isAddingWidget} />;
      case 'outcome-task-graph':
        return <OutcomeTaskGraphWidgetDetails handleAddWidget={handleAddWidget} isLoading={isAddingWidget} />;
      case 'plan-confidence-graph':
        return <PlanConfidenceGraphWidgetDetails handleAddWidget={handleAddWidget} isLoading={isAddingWidget} />;
      case 'plan-ncs-graph':
        return <PlanNCSGraphWidgetDetails handleAddWidget={handleAddWidget} isLoading={isAddingWidget} />;
      case 'outcome-graph':
        return <OutcomeGraphWidgetDetails handleAddWidget={handleAddWidget} isLoading={isAddingWidget} />;
      default:
        break;
    }
  };

  return (
    <Fragment>
      <Mask onClick={handleClosePanel} showSearchPanel={showPanel} />
      <ContentWrapper showSearchPanel={showPanel}>
        <Content>
          <SearchHeader>
            <p>{t('workspaceDashboards.add')}</p>
            <KoalaIconButton onClick={handleClosePanel} iconName="close" />
          </SearchHeader>

          <FormField>
            <label>{t('workspaceDashboards.widgetType')}</label>
            <KoalaSelect
              placeholder={t('workspaceDashboards.widgetTypePlaceholder')}
              options={insightOptions}
              handleChange={handleSingleOptionSelect}
              selectedOption={getSelectedInsightOptionsFromFilter()}
            />
          </FormField>
          {widgetDetails()}
        </Content>
      </ContentWrapper>
    </Fragment>
  );
}

export default WidgetPanel;
