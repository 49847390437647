import React from 'react';
import * as TabilityTypes from 'types';

import PricingVersion1 from './PricingVersion1';
import PricingVersion2 from './PricingVersion2';
import PricingVersion3 from './PricingVersion3';
import PricingVersion4 from './PricingVersion4';

interface Props {
  workspace: TabilityTypes.Workspace;
}

function WorkspaceSettingBilling(props: Props) {
  const { workspace } = props;

  // pricing_version determines which type of pricing we need to apply to the workspace
  const { pricing_version } = workspace;

  // Return the pricing version 2 (per user - $6 Essentials/$9 Premium)
  if (pricing_version === 2) {
    return <PricingVersion2 workspace={workspace} />;
  }

  if (pricing_version === 3) {
    return <PricingVersion3 workspace={workspace} />;
  }

  if (pricing_version === 4) {
    return <PricingVersion4 workspace={workspace} />;
  }

  // By default we return the OG pricing (packaged users)
  return <PricingVersion1 workspace={workspace} />;
}

export default WorkspaceSettingBilling;
