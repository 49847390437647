import styled from 'styled-components';
import theme from 'theme';
import spacing from 'theme/spacing';

export const MobileReadyChromeWrapperWithSidebar = styled.div`
  display: grid;
  grid-template-columns: auto auto 1fr;
  grid-template-rows: auto auto auto auto auto 1fr;
  grid-template-areas:
    'mobile-nav mobile-nav mobile-nav'
    'quickstart quickstart quickstart'
    'teaser teaser teaser'
    'sidebar side-nav header'
    'sidebar side-nav nav'
    'sidebar side-nav content';
  height: 100%;
  min-height: 100%;

  @media ${theme.devices.desktop} {
    grid-template-columns: auto auto 1fr auto;
  }

  &.collapsed {
    grid-template-columns: auto auto 1fr auto;
  }

  transition: all ease 0.8s;
`;

export const MobileReadyChromeHeader = styled.div`
  grid-area: header;
  border-bottom: 1px solid ${theme.colors.N20};
  display: flex;
  flex-direction: column;

  background: #fff;

  &.no_border {
    border-bottom: 0;
  }

  @media ${theme.devices.mobile} {
    margin-left: ${theme.spacing.x2};
  }
`;

export const MobileReadyChromeTeaser = styled.div`
  grid-area: teaser;
`;

export const MobileReadyChromeQuickstart = styled.div`
  grid-area: quickstart;

  @media ${theme.devices.mobile} {
    display: none;
  }
`;

export const MobileReadyChromeMobileNav = styled.div`
  grid-area: mobile-nav;

  display: none;

  @media ${theme.devices.mobile} {
    display: initial;
    position: absolute;
    padding-top: ${spacing.x2};
    padding-left: 1.2rem;
  }
`;

export const MobileReadyChromeSidebar = styled.div<{ color: string }>`
  position: relative;
  grid-area: sidebar;
  background: ${(props) => (props.color ? props.color : '#0a5055')};
  z-index: 10;

  @media ${theme.devices.mobile} {
    display: none;

    &.mobile-sidebar {
      display: initial;
      position: absolute;
      height: inherit;
    }
  }
`;
