import React from 'react';
import styled from 'styled-components';
import theme from 'theme';
import { differenceInDays, parseISO, format } from 'date-fns';
import KoalaIcon from 'koala/components/Icons';
import DatePicker from 'react-datepicker';
import KoalaIconButton from 'koala/components/IconButton';

const ClockContainer = styled.div`
  padding: 0.2rem 0.4rem;
  background-color: #f3f3f3;
  border-radius: 4px;
  height: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  color: ${theme.colors.N100};
  border: 1px solid ${theme.colors.N20};
  min-width: 7rem;
  &.danger {
    background-color: #fcf0ef;
    border: 1px solid ${theme.colors.R10};
  }
  &.warning {
    background-color: #fdf6dd;
    border: 1px solid ${theme.colors.Y20};
  }
  .no-date {
    padding: 0 ${theme.spacing.x1};
  }
`;

const DueDateSection = styled.div`
  display: flex;
  align-items: center;
  gap: ${theme.spacing.half};

  .clickable {
    border: 2px solid transparent;
    :hover {
      cursor: pointer;
      border-radius: 6px;
      border: 2px solid ${theme.colors.B50};
    }
  }
`;

interface Props {
  dueAt: string | undefined;
  state?: string;
  dataFor?: string;
  handleDateChange?: (date: Date) => void;
  handleDateClear?: () => void;
}

function InitiativeDueDate(props: Props) {
  const { dueAt, state, handleDateChange, dataFor, handleDateClear } = props;
  let clockClassName = '';
  let daysLeft = null;

  if (state === 'open' && dueAt) {
    daysLeft = differenceInDays(parseISO(dueAt), new Date());
    if (daysLeft <= 7) {
      clockClassName = 'warning';
    }

    if (daysLeft <= 1) {
      clockClassName = 'danger';
    }
  }
  clockClassName += handleDateChange ? ' clickable' : '';

  const date = dueAt ? new Date(dueAt) : undefined;
  const label = (
    <ClockContainer className={clockClassName} data-tip={dataFor ? true : false} data-for={dataFor}>
      <KoalaIcon iconName="calendarV2" iconAppearance={theme.colors.N60} iconSize="small" />
      {date && <span title={format(date, 'dd MMM yyyy')}>{format(date, 'dd MMM')}</span>}
      {!date && <span className="no-date">&mdash;</span>}
    </ClockContainer>
  );

  if (handleDateChange) {
    return (
      <DueDateSection onClick={(e) => e.stopPropagation()}>
        <DatePicker portalId="root" onChange={handleDateChange} dateFormat="d MMM yyyy" customInput={label} />
        {handleDateClear && date && <KoalaIconButton iconName="close" size="xsmall" onClick={handleDateClear} />}
      </DueDateSection>
    );
  }

  return label;
}

export default React.memo(InitiativeDueDate);
