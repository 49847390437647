import React, { useState } from 'react';
import styled from 'styled-components';
import theme from 'theme';

import { useParams } from 'react-router-dom';
import KoalaButton from 'koala/components/Button';
import { useTranslation } from 'react-i18next';
import PlanSelect from './PlanSelect';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: ${theme.spacing.x2} 0;
`;

interface Props {
  handleAddWidget: (type: string, id: string, source: string) => void;
  isLoading: boolean;
}

function PlanConfidenceGraphWidgetDetails(props: Props) {
  const { handleAddWidget, isLoading } = props;
  const { t } = useTranslation();
  const { workspaceSlug } = useParams<{
    workspaceSlug: string;
  }>();
  const [planId, setPlanId] = useState<string>('');

  const handleSave = (e: React.FormEvent) => {
    e.preventDefault();
    if (!planId) {
      return;
    }
    handleAddWidget('plan-confidence-graph', planId, 'plan');
  };

  return (
    <Container>
      <PlanSelect workspaceSlug={workspaceSlug} setPlanId={setPlanId} />
      <div>
        <KoalaButton onClick={handleSave} loading={isLoading} disabled={!planId}>
          {t('shared.add')}
        </KoalaButton>
      </div>
    </Container>
  );
}

export default PlanConfidenceGraphWidgetDetails;
