import queryKeys from 'config/queryKeys';
import React, { useEffect, useRef, useState } from 'react';
import { useQuery } from 'react-query';
import { shallowEqual, useSelector } from 'react-redux';
import styled from 'styled-components';
import * as remoteApi from 'api/remote';
import theme from 'theme';
import { DragHandle, SourceTitle, WidgetContainer, WidgetContent, WidgetHeader } from '.';
import { useHistory } from 'react-router-dom';
import KoalaLoader from 'koala/components/Loader';
import { Checkin, Outcome } from 'types';
import CheckinsChart from 'components/Chart';
import KoalaIcon from 'koala/components/Icons';
import { CustomTermKey, translate } from 'utils/customTermUtils';
import { useTranslation } from 'react-i18next';

const Container = styled.div`
  display: flex;
  width: 100%;
  height: 90%;
  min-height: 200px;

  .checkin-chart {
    width: 100%;
    margin: 0px;
  }

  @media ${theme.devices.mobile} {
    max-height: 25rem;
  }
`;

interface Props {
  outcomeId: string;
}

function OutcomeGraphWidget(props: Props) {
  const { outcomeId } = props;
  const { t } = useTranslation();
  const currentWorkspace = useSelector((state: any) => state.session.currentWorkspace, shallowEqual);
  const history = useHistory();
  const chartRef = useRef<HTMLDivElement>(null);
  const staleTime = queryKeys.staleTime;
  const [dimensions, setDimensions] = useState({ width: 200, height: 200 });

  useEffect(() => {
    const handleResize = (entries: any) => {
      for (let entry of entries) {
        if (entry.target === chartRef.current) {
          setDimensions({
            width: entry.contentRect.width,
            height: entry.contentRect.height,
          });
        }
      }
    };

    const resizeObserver = new ResizeObserver(handleResize);
    if (chartRef.current) {
      resizeObserver.observe(chartRef.current);
    }

    // Cleanup observer on unmount
    return () => {
      if (chartRef.current) {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        resizeObserver.unobserve(chartRef.current);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chartRef.current]);

  // Get the checkin details
  const queryKeyCheckins = [queryKeys.checkins, outcomeId];
  const { data: checkinsResponse, isLoading: checkinsLoading } = useQuery(queryKeyCheckins, remoteApi.fetchCheckins, {
    staleTime,
  });

  // Get the outcome details
  const queryKeyOutcomes = [queryKeys.currentOutcome, outcomeId];
  const { data: outcomeResponse, isLoading: outcomesLoading } = useQuery(
    queryKeyOutcomes,
    remoteApi.fetchOutcomeDetails,
    {
      staleTime,
    },
  );
  const outcome: Outcome = outcomeResponse ? outcomeResponse.data : null;

  // Construct the query key using the plan Id
  const queryKeyInitiatives = [queryKeys.initiatives, outcomeId];
  const { data: initiativesResponse, isLoading: initiativesLoading } = useQuery(
    queryKeyInitiatives,
    remoteApi.fetchInitiatives,
    {
      staleTime,
    },
  );

  let initiatives = [];
  if (initiativesResponse) {
    initiatives = initiativesResponse.data;
  }

  if (outcomesLoading || checkinsLoading || initiativesLoading) {
    return (
      <WidgetContainer>
        <KoalaLoader />
        <Container className="panel-checkins-chart" ref={chartRef}></Container>
      </WidgetContainer>
    );
  }

  if (!outcome) {
    return (
      <WidgetContainer>
        <WidgetHeader>
          <DragHandle className="drag-handle">
            <KoalaIcon iconName="grab" iconSize="small" />
          </DragHandle>
          <p className="widget-type">
            {t('workspaceDashboards.OutcomeGraph', { outcome: translate(currentWorkspace, CustomTermKey.OUTCOME, 1) })}
          </p>
        </WidgetHeader>
        <WidgetContent>
          <p>{t('workspaceDashboards.widgetError')}</p>
        </WidgetContent>
      </WidgetContainer>
    );
  }

  let checkins: Checkin[] = [];
  if (checkinsResponse) {
    checkins = checkinsResponse.data;
  }

  const handleClick = () => {
    history.push(`#outcome:${outcome.nano_slug}:show`);
  };

  return (
    <WidgetContainer>
      <WidgetHeader>
        <DragHandle className="drag-handle">
          <KoalaIcon iconName="grab" iconSize="small" />
        </DragHandle>
        <p className="widget-type">
          {t('workspaceDashboards.OutcomeGraph', { outcome: translate(currentWorkspace, CustomTermKey.OUTCOME, 1) })}
        </p>
      </WidgetHeader>
      <WidgetContent>
        <SourceTitle>
          <span className="widget-source" onClick={handleClick}>
            {outcome.title}
          </span>
        </SourceTitle>
        <Container className="panel-checkins-chart" ref={chartRef}>
          <CheckinsChart
            outcome={outcome}
            checkins={checkins}
            initiatives={initiatives}
            downloadDetails={{
              showTooltip: true,
              showMetrics: true,
              showToday: true,
              height: dimensions.height,
              width: dimensions.width,
            }}
          />
        </Container>
      </WidgetContent>
    </WidgetContainer>
  );
}

export default OutcomeGraphWidget;
