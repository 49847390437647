import React, { Fragment, useState } from 'react';
import styled from 'styled-components';
import theme from 'theme';
import * as TabilityTypes from 'types';
import ReactTooltip from 'react-tooltip';
import { CustomTermKey, translate } from 'utils/customTermUtils';
import { shallowEqual, useSelector } from 'react-redux';
import { useQuery } from 'react-query';
import queryKeys from 'config/queryKeys';

// API
import * as remoteApi from 'api/remote';

import { parseISO, differenceInCalendarDays, isBefore } from 'date-fns';
import { useHistory, useLocation } from 'react-router-dom';

import KoalaIcon from 'koala/components/Icons';

import ProgressChart from './ProgressChart';
import ConfidenceChart from './ConfidenceChart';
import NCSChart from './NCSChart';
import InitiativeRow from 'components/InitiativeRow';
import { Trans, useTranslation } from 'react-i18next';

import { PlanSection, PlanSectionContent, PlanSectionHeader } from '..';

// Routes

const DataContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;

  &.with-border-bottom {
    border-bottom: 1px solid ${theme.colors.blockBorder};
    margin-bottom: ${theme.spacing.x1};
  }
  &.with-border-right {
    border-right: 1px solid ${theme.colors.blockBorder};
    padding-right: ${theme.spacing.x1};
  }
  &.with-border-left {
    margin-left: ${theme.spacing.x1};
  }
  h4 {
    display: flex;
    gap: ${theme.spacing.half};
    align-items: center;
    min-height: 2.4rem;
    margin-bottom: ${theme.spacing.x1};
  }
`;

const InsightsLine = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;

  @media ${theme.devices.laptop} {
    grid-template-columns: 1fr;
  }
`;

const EmptyState = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 1.2rem ${theme.spacing.x2};
`;

const InitiativeList = styled.div`
  .item {
    padding: 1.2rem ${theme.spacing.x2};
    display: grid;
    grid-template-columns: auto 14.5rem;
  }

  .initiative-state {
    display: none;
  }
  .initiative-details {
    a {
      overflow: auto;
      white-space: normal;
    }
    max-width: none;
  }
  .block-actions {
    top: 1.2rem;
    right: 1.6rem;
  }
`;

const ChartsWrapper = styled.div`
  display: flex;
  margin-bottom: ${theme.spacing.x1};
  > div {
    flex: 1;
  }
  @media ${theme.devices.tablet} {
    flex-direction: column;
  }
`;

const InsightsMask = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background: rgba(255, 255, 255, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
`;

const NoCheckinsBlock = styled.div`
  padding: ${theme.spacing.x2};
  margin-bottom: ${theme.spacing.x2};
  border-radius: 8px;
  display: flex;
  align-items: center;
  background: ${theme.colors.N5};
  border: 1px solid ${theme.colors.N20};
  max-width: 50rem;

  > div {
    &:first-child {
      img {
        width: 4rem;
      }
      margin-right: ${theme.spacing.x3};
    }
  }
`;

interface Props {
  plan: TabilityTypes.Plan;
  objectives: TabilityTypes.Objective[];
  outcomes: TabilityTypes.Outcome[];
  initiatives: TabilityTypes.Initiative[];
}

const getInitiativesStats = (initiatives: any) => {
  let closedTotal = 0;
  let overdue: any = [];
  let upcoming: any = [];
  let recentlyCompleted: any = [];
  let memberships: any = {};
  let membershipIds: any = [];

  const today = new Date();
  initiatives.forEach((initiative: any) => {
    if (initiative.membership) {
      const { membership } = initiative;
      if (!memberships[membership.id]) {
        memberships[membership.id] = {
          data: membership,
          initiativesCount: 0,
          initiativesCompletedCount: 0,
        };
        membershipIds.push(membership.id);
      }
      memberships[membership.id].initiativesCount++;
      if (initiative.state === 'closed') {
        memberships[membership.id].initiativesCompletedCount++;
      }
    }

    if (initiative.state === 'closed') {
      closedTotal++;
      const diffClosed = differenceInCalendarDays(today, parseISO(initiative.closed_at));
      if (diffClosed < 7) {
        recentlyCompleted.push(initiative);
      }
    } else {
      // Check if upcoming
      if (['now', 'later'].includes(initiative.roadmap_state)) {
        upcoming.push(initiative);
      }

      // Check if overdue
      if (initiative.due_at) {
        const dueDate = parseISO(initiative.due_at);
        if (isBefore(dueDate, new Date())) {
          overdue.push(initiative);
        }
      }
    }
  });

  return {
    closedTotal,
    overdue,
    upcoming,
    recentlyCompleted,
    memberships,
    membershipIds,
  };
};

function Tracker(props: Props) {
  const { plan, initiatives } = props;
  const history = useHistory();
  const [trends, setTrends] = useState([]);
  // const [checkinsTrends, setCheckinsTrends] = useState([]);
  const { t } = useTranslation();
  // Fetch trends data
  useQuery([queryKeys.currentPlan, plan.id, 'trends'], remoteApi.fetchPlanTrends, {
    staleTime: 0,
    onSuccess: (response) => {
      setTrends(response.data);
    },
  });

  // Fetch trends data
  // useQuery([queryKeys.currentPlan, plan.id, 'checkin_trends'], remoteApi.fetchPlanCheckinsTrends, {
  //   staleTime: 0,
  //   onSuccess: (response) => {
  //     setCheckinsTrends(response.data);
  //   },
  // });

  const planFinishAt = plan && plan.finish_at ? parseISO(plan.finish_at) : null;
  const location = useLocation();
  let differenceInDays = planFinishAt ? differenceInCalendarDays(planFinishAt, new Date()) : 0;
  const currentWorkspace = useSelector((state: any) => state.session.currentWorkspace, shallowEqual);
  if (differenceInDays < 0) {
    differenceInDays = 0;
  }

  const isBlockSelected = (blockId: string) => {
    return location.hash.includes(blockId);
  };

  const initiativesStats = getInitiativesStats(initiatives);

  return (
    <>
      <PlanSection className="plan-insights">
        {!plan.checked_in && (
          <InsightsMask>
            <NoCheckinsBlock>
              <div>
                <img
                  src="https://res.cloudinary.com/tability/image/upload/v1600083943/static_assets/tabby_surprised_tranev.png"
                  alt="Surprised Tabby"
                />
              </div>
              <div>
                <p>{t('shared.checkin.noCheckinsInPlan')}</p>
              </div>
            </NoCheckinsBlock>
          </InsightsMask>
        )}
        <PlanSectionHeader>
          <span className="title">{t('workspacePlan.planTrendsTitle')}</span>
        </PlanSectionHeader>
        <PlanSectionContent>
          <ChartsWrapper>
            <DataContainer className="with-border-bottom">
              <h4>
                {t('workspacePlan.insights.compareProgress', {
                  outcomes: translate(currentWorkspace, CustomTermKey.OUTCOME, 2),
                  initiatives: translate(currentWorkspace, CustomTermKey.INITIATIVE, 2),
                })}
              </h4>
              <ProgressChart trends={trends} plan={plan} />
            </DataContainer>
          </ChartsWrapper>
          {plan.checked_in && (
            <ChartsWrapper>
              <DataContainer className="with-border-right">
                <h4>{t('workspacePlan.insights.confidence')}</h4>
                <ConfidenceChart trends={trends} plan={plan} />
              </DataContainer>
              <DataContainer className="with-border-left">
                <h4>
                  {t('shared.ncs.full')}
                  <KoalaIcon iconName="tooltip" iconSize="small" data-tip data-for="explain-ncs2" />
                </h4>
                <ReactTooltip
                  place="bottom"
                  type="dark"
                  className="tooltip"
                  effect="solid"
                  id="explain-ncs2"
                  delayHide={500}
                  clickable={true}
                >
                  <Trans i18nKey="shared.ncs.tooltip" components={{ br: <br /> }} />{' '}
                  <a
                    href="https://www.tability.io/odt-articles/nps-but-for-your-okrs-introducing-the-net-confidence-score-ncs"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {t('shared.learnMore')}
                  </a>
                </ReactTooltip>
                <NCSChart trends={trends} plan={plan} />
              </DataContainer>
            </ChartsWrapper>
          )}
        </PlanSectionContent>
      </PlanSection>
      {plan.checked_in && (
        <PlanSection>
          <PlanSectionHeader>
            <span className="title">{t('workspacePlan.planInsightsTitle')}</span>
          </PlanSectionHeader>
          <PlanSectionContent>
            <InsightsLine>
              <DataContainer className="with-border-right">
                <h4>
                  {t('workspacePlan.insights.overdue', {
                    initiatives: translate(currentWorkspace, CustomTermKey.INITIATIVE, 2),
                  })}
                </h4>
                {initiativesStats.overdue.length > 0 && (
                  <InitiativeList>
                    {initiativesStats.overdue.map((initiative: TabilityTypes.Initiative, i: number) => {
                      const isSelected = isBlockSelected(`#initiative:${initiative.nano_slug}`);
                      const blockId = `initiative:${initiative.nano_slug}`;
                      const showHashRoute = `#${blockId}:show`;
                      const handleTaskClick = () => {
                        history.push(showHashRoute);
                      };
                      return (
                        <InitiativeRow
                          key={initiative.id}
                          initiative={initiative}
                          isSelected={isSelected}
                          showMeta={true}
                          handleClick={handleTaskClick}
                        />
                      );
                    })}
                  </InitiativeList>
                )}
                {initiativesStats.overdue.length === 0 && (
                  <EmptyState>
                    <p>
                      {t('workspacePlan.insights.overdueEmpty')}
                      <span role="img" aria-label="all good!">
                        👍
                      </span>
                    </p>
                    <p className="subtle">
                      {t('workspacePlan.insights.overdueEmptyInfo', {
                        initiatives: translate(currentWorkspace, CustomTermKey.INITIATIVE, 2).toLowerCase(),
                      })}
                    </p>
                  </EmptyState>
                )}
              </DataContainer>
              <DataContainer className="with-border-left">
                <h4>
                  {t('workspacePlan.insights.completed', {
                    initiatives: translate(currentWorkspace, CustomTermKey.INITIATIVE, 2),
                  })}{' '}
                  <span role="img" aria-label="celebration">
                    🎉
                  </span>
                </h4>
                {initiativesStats.recentlyCompleted.length > 0 && (
                  <InitiativeList>
                    {initiativesStats.recentlyCompleted.map((initiative: any, i: number) => {
                      const isSelected = isBlockSelected(`#initiative:${initiative.nano_slug}`);
                      const blockId = `initiative:${initiative.nano_slug}`;
                      const showHashRoute = `#${blockId}:show`;
                      const handleTaskClick = () => {
                        history.push(showHashRoute);
                      };
                      return (
                        <InitiativeRow
                          initiative={initiative}
                          key={initiative.id}
                          handleClick={handleTaskClick}
                          isSelected={isSelected}
                          showMeta={true}
                        />
                      );
                    })}
                  </InitiativeList>
                )}
                {initiativesStats.recentlyCompleted.length === 0 && (
                  <EmptyState>
                    <p>{t('workspacePlan.insights.completedEmpty')}</p>
                    <p className="subtle">{t('workspacePlan.insights.completedEmptyInfo')}</p>
                  </EmptyState>
                )}
              </DataContainer>
            </InsightsLine>
          </PlanSectionContent>
        </PlanSection>
      )}
    </>
  );
}

export default React.memo(Tracker);
