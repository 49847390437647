import React from 'react';
import styled from 'styled-components';
import theme from 'theme';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import KoalaIcon from 'koala/components/Icons';

import { AuditEvent } from 'types';
import * as dateUtils from 'utils/dateUtils';
import * as workspaceUtils from 'utils/workspaceUtils';
import PlanDeleted from './PlanDeleted';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import PlanUpdated from './PlanUpdated';
import PlanArchived from './PlanArchived';
import PlanCreated from './PlanCreated';
import ObjectiveCreated from './ObjectiveCreated';
import ObjectiveDeleted from './ObjectiveDeleted';
import ObjectiveUpdated from './ObjectiveUpdated';
import WorkspaceUpdated from './WorkspaceUpdated';
import OutcomeContributorChanged from './OutcomeContributorChanged';
import { setGlobalModalContent } from 'state/actions/globalUIActions';
import OutcomeUpdated from './OutcomeUpdated';
import InitiativeCreated from './InitiativeCreated';
import InitiativeUpdated from './InitiativeUpdated';
import InitiativeDeleted from './InitiativeDeleted';
import InitiativeContributorChanged from './InitiativeContributorChanged';

export const BlurDiv = styled.span<{ isBlurred: boolean }>`
  li {
    margin-left: ${theme.spacing.x3};
  }
  filter: ${({ isBlurred }) => (isBlurred ? 'blur(3px)' : 'none')};
`;

const NewsblockLayout = styled.div`
  display: grid;
  grid-template-columns: auto auto 1fr;
  grid-template-rows: auto;
  gap: ${theme.spacing.x1};
  cursor: pointer;
  padding: ${theme.spacing.x1};
  border-radius: 4px;

  &:hover {
    background: ${theme.colors.N10};
    cursor: pointer;
  }
  &:focus,
  &:active {
    background: ${theme.colors.B5};
  }
  position: relative;
`;

const Line = styled.div`
  position: absolute;
  width: 2px;
  background: ${theme.colors.N10};
  z-index: 1;
  top: ${theme.spacing.x1};
  bottom: -${theme.spacing.x1};
  left: 2.1rem;
`;

const IconContainer = styled.div`
  background: #fff;
  border-radius: 50%;
  width: 2.8rem;
  min-height: 2.8rem;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  position: relative;
  border: 2px solid ${theme.colors.N20};
`;

export const Gutter = styled.div``;

export const Date = styled.div`
  min-height: 2.8rem;
  width: 12rem;
  position: relative;
  top: 4px;
  color: ${theme.colors.N70};
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.x1};
  position: relative;
  min-height: 2.8rem;
  top: 4px;
`;

export const ContentAction = styled.div`
  line-height: 1.6;
  span {
    display: inline-block;
    vertical-align: bottom;
  }
  .badge {
    margin: 0 ${theme.spacing.half};
    position: relative;
    top: 0px;
  }

  .closed {
    text-decoration: line-through;
  }
`;

export const ContentComment = styled.div`
  background: ${theme.colors.N0};
  border: 1px solid ${theme.colors.N10};
  width: 100%;
  .ql-container {
    color: ${theme.colors.N70};
  }
  padding: 0 ${theme.spacing.x1};
  margin-top: ${theme.spacing.x1};
  border-radius: 4px;
  flex: 1;
`;
interface Props {
  event: AuditEvent;
}

function AuditEventItem(props: Props) {
  const history = useHistory();
  const { t, i18n } = useTranslation();
  const { event } = props;
  const { workspaceSlug } = useParams<{ workspaceSlug: string }>();
  const dispatch = useDispatch();

  const currentWorkspace = useSelector((state: any) => state.session.currentWorkspace, shallowEqual);
  const hasV4PremiumSubscription = workspaceUtils.hasV4PremiumSubscription(currentWorkspace);
  const canViewAudit = hasV4PremiumSubscription || currentWorkspace.pricing_version === 3;
  const { source_cached_nano_slug, plan } = event;

  const getSourceLink = (event: any) => {
    // Test if the current event membership is the same as previous
    switch (event.event_type) {
      case 'plan.published':
        return `/${workspaceSlug}/plans/${source_cached_nano_slug}/outcomes`;
      case 'plan.deleted':
        return `/${workspaceSlug}/plans`;
      case 'plan.updated':
        return `/${workspaceSlug}/plans/${source_cached_nano_slug}/outcomes`;
      case 'plan.archived':
        return `/${workspaceSlug}/plans/${source_cached_nano_slug}/outcomes`;
      case 'plan.created':
        return `/${workspaceSlug}/plans/${source_cached_nano_slug}/outcomes`;
      case 'objective.created':
        return `/${workspaceSlug}/plans/${plan?.nano_slug}/outcomes#objective:${source_cached_nano_slug}:show`;
      case 'objective.deleted':
        return `/${workspaceSlug}/plans/${plan?.nano_slug}/outcomes`;
      case 'objective.updated':
        return `/${workspaceSlug}/plans/${plan?.nano_slug}/outcomes#objective:${source_cached_nano_slug}:show`;
      case 'outcome.created':
        return `/${workspaceSlug}/plans/${plan?.nano_slug}/outcomes#outcome:${source_cached_nano_slug}:show`;
      case 'outcome.deleted':
        return `/${workspaceSlug}/plans/${plan?.nano_slug}/outcomes`;
      case 'outcome.updated':
        return `/${workspaceSlug}/plans/${plan?.nano_slug}/outcomes#outcome:${source_cached_nano_slug}:show`;
      case 'outcome.contributor_created':
        return `/${workspaceSlug}/plans/${plan?.nano_slug}/outcomes#outcome:${source_cached_nano_slug}:show`;
      case 'outcome.contributor_deleted':
        return `/${workspaceSlug}/plans/${plan?.nano_slug}/outcomes#outcome:${source_cached_nano_slug}:show`;
      case 'initiative.created':
        return `/${workspaceSlug}/plans/${plan?.nano_slug}/tasks#initiative:${source_cached_nano_slug}:show`;
      case 'initiative.deleted':
        return `/${workspaceSlug}/plans/${plan?.nano_slug}/outcomes`;
      case 'initiative.updated':
        return `/${workspaceSlug}/plans/${plan?.nano_slug}/tasks#initiative:${source_cached_nano_slug}:show`;
      case 'initiative.contributor_created':
        return `/${workspaceSlug}/plans/${plan?.nano_slug}/tasks#initiative:${source_cached_nano_slug}:show`;
      case 'initiative.contributor_deleted':
        return `/${workspaceSlug}/plans/${plan?.nano_slug}/tasks#initiative:${source_cached_nano_slug}:show`;
      default:
        return '';
    }
  };

  const handleNav = (e: React.SyntheticEvent) => {
    e.preventDefault();
    const sourceLink = getSourceLink(event);
    dispatch(setGlobalModalContent(''));

    history.push(sourceLink);
  };

  const renderEventContent = (event: AuditEvent) => {
    // Test if the current event membership is the same as previous
    switch (event.event_type) {
      case 'plan.deleted':
        return <PlanDeleted key={event.id} event={event} canViewAudit={canViewAudit} />;
      case 'plan.updated':
        return <PlanUpdated key={event.id} event={event} canViewAudit={canViewAudit} />;
      case 'plan.archived':
        return <PlanArchived key={event.id} event={event} canViewAudit={canViewAudit} />;
      case 'plan.created':
        return <PlanCreated key={event.id} event={event} canViewAudit={canViewAudit} />;
      case 'objective.created':
        return <ObjectiveCreated key={event.id} event={event} canViewAudit={canViewAudit} />;
      case 'objective.deleted':
        return <ObjectiveDeleted key={event.id} event={event} canViewAudit={canViewAudit} />;
      case 'objective.updated':
        return <ObjectiveUpdated key={event.id} event={event} canViewAudit={canViewAudit} />;
      case 'outcome.created':
        return <ObjectiveCreated key={event.id} event={event} canViewAudit={canViewAudit} />;
      case 'outcome.deleted':
        return <ObjectiveDeleted key={event.id} event={event} canViewAudit={canViewAudit} />;
      case 'outcome.updated':
        return <OutcomeUpdated key={event.id} event={event} canViewAudit={canViewAudit} />;
      case 'outcome.contributor_created':
        return <OutcomeContributorChanged key={event.id} event={event} canViewAudit={canViewAudit} />;
      case 'outcome.contributor_deleted':
        return <OutcomeContributorChanged key={event.id} event={event} canViewAudit={canViewAudit} />;
      case 'initiative.created':
        return <InitiativeCreated key={event.id} event={event} canViewAudit={canViewAudit} />;
      case 'initiative.updated':
        return <InitiativeUpdated key={event.id} event={event} canViewAudit={canViewAudit} />;
      case 'initiative.deleted':
        return <InitiativeDeleted key={event.id} event={event} canViewAudit={canViewAudit} />;
      case 'initiative.contributor_created':
        return <InitiativeContributorChanged key={event.id} event={event} canViewAudit={canViewAudit} />;
      case 'initiative.contributor_deleted':
        return <InitiativeContributorChanged key={event.id} event={event} canViewAudit={canViewAudit} />;

      default:
        return null;
    }
  };

  const iconName = (event: AuditEvent) => {
    // Test if the current event membership is the same as previous
    switch (event.source_type) {
      case 'plan':
        return 'plan';
      case 'objective':
        return 'flag';
      case 'outcome':
        return 'metric';
      case 'initiative':
        return 'task';
      default:
        return 'metric';
    }
  };

  if (event.source_type === 'workspace') {
    return (
      <NewsblockLayout className="unlinked">
        <Line />
        <Gutter>
          <IconContainer>
            <KoalaIcon iconName="edit" iconSize="small" />
          </IconContainer>
        </Gutter>
        <Date>{dateUtils.formatDistanceToNowLocale(event.updated_at, i18n, true)}</Date>
        <Content>
          <WorkspaceUpdated key={event.id} event={event} canViewAudit={canViewAudit} />
        </Content>
      </NewsblockLayout>
    );
  }

  return (
    <NewsblockLayout onClick={handleNav}>
      <Line />
      <Gutter>
        <IconContainer>
          <KoalaIcon iconName={iconName(event)} iconSize="small" />
        </IconContainer>
      </Gutter>
      <Date>{t('shared.time.ago', { time: dateUtils.formatDistanceToNowLocale(event.updated_at, i18n) })}</Date>
      <Content>{renderEventContent(event)}</Content>
    </NewsblockLayout>
  );
}

export default React.memo(AuditEventItem);
