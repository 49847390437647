import React, { useState } from 'react';
import styled from 'styled-components';
import * as TabilityTypes from 'types';
import { useQuery } from 'react-query';
import queryKeys from 'config/queryKeys';
import theme from 'theme';
import { Helmet } from 'react-helmet';
import { useSelector, shallowEqual } from 'react-redux';

// API
import * as remoteApi from 'api/remote';

// Header component
import WorkspaceHeader from 'components/WorkspaceHeader';
import { MobileReadyChromeHeader } from 'components/MobileReadyChrome';

// Components
import { ChromeContent } from 'components/Chrome';
import Loader from 'components/Loader';
import StandupColumn from './StandupColumn';
import NewFollower from './NewFollower';
import KoalaButton from 'koala/components/Button';
import KoalaIcon from 'koala/components/Icons';
import { useTranslation } from 'react-i18next';

const Wrapper = styled.div`
  overflow: auto;
  padding: 1.2rem ${theme.spacing.x2};

  display: flex;
  gap: ${theme.spacing.x2};
  background: ${theme.colors.N0};
  height: 100%;
`;

const AddColumn = styled.div`
  min-width: 20rem;
  width: 20rem;
  display: flex;
  flex-direction: column;
  background: ${theme.colors.N0};
  height: 100%;
  padding-right: ${theme.spacing.x3};
`;

const UpgradeBanner = styled.div`
  display: flex;
  gap: ${theme.spacing.x1};
  background: ${theme.colors.V50};
  color: #fff;
  padding: ${theme.spacing.x1};
  font-weight: 600;
  border-radius: 4px;
  justify-content: center;
  margin: ${theme.spacing.x2};

  button {
    color: #fff;
  }

  .upgrade-icon {
    svg {
      path {
        fill: #fff;
      }
    }
  }
`;

interface Props {
  workspace: TabilityTypes.Workspace;
}

function WorkspaceSettingsDetails(props: Props) {
  const { workspace } = props;
  const { t } = useTranslation();

  const currentMembership = useSelector((state: any) => state.session.currentMembership, shallowEqual);
  const isOwner = currentMembership.role === 'owner';

  const [following, setFollowing]: any = useState([]);
  // Get the list of followed members
  const followingQueryKey = [queryKeys.following, currentMembership.id, {}];

  const { isLoading } = useQuery(followingQueryKey, remoteApi.fetchFollowing, {
    staleTime: 0,
    onSuccess: (response) => {
      const following = response.data;
      setFollowing(following);
    },
  });

  const membershipsToIgnore = following.map((follower: TabilityTypes.Follower) => follower.membership_target_id);
  membershipsToIgnore.push(currentMembership.id);

  const standupsQuota = workspace.billing_standups_limit;
  const standupsUsed = workspace.standups_count;
  const standupsLeft = standupsQuota - standupsUsed;

  return (
    <>
      <MobileReadyChromeHeader>
        <WorkspaceHeader
          workspace={workspace}
          useGreyBackground={true}
          title={<h1>{t('workspaceStandups.title')}</h1>}
        />
      </MobileReadyChromeHeader>
      <ChromeContent isGreyBackground>
        <Helmet>
          <title>
            {workspace.name} | {t('workspaceStandups.title')} | Tability
          </title>
        </Helmet>
        {workspace.pricing_version === 4 && (
          <>
            {workspace.billing_standups_limit > 0 && (
              <UpgradeBanner>
                <KoalaIcon iconName="upgrade" className="upgrade-icon" />
                {t('workspaceStandups.upgrade.standupsLeft', { count: standupsLeft })}
                {isOwner && (
                  <KoalaButton size="small" appearance="secondary" isLink to={`/${workspace.slug}/settings/billing`}>
                    {t('workspaceStandups.upgrade.unlock')}
                  </KoalaButton>
                )}
              </UpgradeBanner>
            )}
          </>
        )}
        {workspace.pricing_version < 4 && (
          <>
            {workspace.billing_standups_limit > 0 && (
              <UpgradeBanner>
                <KoalaIcon iconName="upgrade" className="upgrade-icon" />
                {t('workspaceStandups.upgrade.paidAddon')}{' '}
                {t('workspaceStandups.upgrade.standupsLeft', { count: standupsLeft })}
                {isOwner && (
                  <KoalaButton size="small" appearance="secondary" isLink to={`/${workspace.slug}/settings/billing`}>
                    {t('workspaceStandups.upgrade.unlock')}
                  </KoalaButton>
                )}
              </UpgradeBanner>
            )}
          </>
        )}
        <Wrapper>
          <StandupColumn workspace={workspace} membership={currentMembership} />
          <StandupColumn workspace={workspace} />

          {isLoading && (
            <AddColumn>
              <Loader />
            </AddColumn>
          )}

          {following.map((follower: TabilityTypes.Follower) => {
            const { membership_target } = follower;
            return <StandupColumn membership={membership_target} workspace={workspace} follower={follower} />;
          })}
          <AddColumn>
            <p className="subtle">{t('workspaceStandups.followTeammates')}</p>
            <p>
              <NewFollower membershipsToIgnore={membershipsToIgnore} />
            </p>
          </AddColumn>
        </Wrapper>
      </ChromeContent>
    </>
  );
}

export default WorkspaceSettingsDetails;
