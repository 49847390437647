import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { useQuery, useMutation, useQueryCache } from 'react-query';
import queryKeys from 'config/queryKeys';
import styled from 'styled-components';
import theme from 'theme';
import DatePicker from 'react-datepicker';
import * as outcomeUtils from 'utils/outcomeUtils';
import * as TabilityTypes from 'types';
import { hasExceededCheckinsLimit, hasExceededUserLimit } from 'utils/workspaceUtils';
import { useHistory } from 'react-router-dom';
import { getTime, isSameDay } from 'date-fns';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';

import 'theme/DatePicker.css';

// API
import * as remoteApi from 'api/remote';

import { setGlobalModalContent } from 'state/actions/globalUIActions';

// Components
import Loader from 'components/Loader';
import MentionBox from 'components/MentionBox';
import { ModalGrid, ModalHeader, ModalContent, ModalFooter } from 'components/GlobalModal';
import FormField from 'components/FormField';
import RadioField from 'components/RadioField';
import KoalaButton from 'koala/components/Button';
import KoalaTextButton from 'koala/components/TextButton';
import KoalaIconButton from 'koala/components/IconButton';
import { CustomTermKey, translate } from 'utils/customTermUtils';
import InitiativesTab from 'panels/OutcomePanel/InitiativesTab';
import ProgressBars from './CheckinComponents/ProgressBars';
import CheckinsChart from 'components/Chart';
import { confidenceToColor } from 'utils/checkinUtils';

import { CONTEXT_STORAGE_KEY } from 'config/constants';
import DataSourceLabel from './CheckinComponents/DataSourceLabel';
import ReactTooltip from 'react-tooltip';

const LoadingContainer = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;
`;

const Columns = styled.div`
  display: flex;
  margin-top: 0;

  h4 {
    margin-bottom: ${theme.spacing.x1};
    font-weight: 800;
    font-size: 1.2rem;
    text-transform: uppercase;
    color: ${theme.colors.subtleText};
  }

  @media ${theme.devices.mobile} {
    flex-direction: column-reverse;
  }
`;

const UpdateColumn = styled.div`
  flex: 1;
  padding-right: ${theme.spacing.x2};

  @media ${theme.devices.mobile} {
    width: 100%;
    padding: 0px;

    input.small {
      min-width: 15rem;
    }

    .radio-group > label {
      padding: 0 ${theme.spacing.x1};
      white-space: normal;
      min-width: 7rem;
      text-align: center;
    }
  }
`;

const ChartColumn = styled.div`
  flex: 1;
  padding-left: ${theme.spacing.x2};

  @media ${theme.devices.mobile} {
    width: 100%;
    padding: 0;
    margin-bottom: ${theme.spacing.x4};
  }
`;

const CumulInput = styled.div`
  display: flex;

  input.cumul {
    border-radius: 4px 0 0 4px;
  }

  .cumul-result {
    height: 3.2rem;
    display: flex;
    align-items: center;
    border: 1px solid ${theme.colors.inputBorder};
    border-left: 0;
    padding: 0 ${theme.spacing.x2};
    color: ${theme.colors.subtleText};
    font-size: 1.4rem;
    border-radius: 0 4px 4px 0;
  }

  @media ${theme.devices.tablet} {
    width: 55%;
    min-width: 10rem;
    .cumul-result {
      width: inherit;
    }
  }
`;

const ScoreField = styled.div`
  display: flex;
  align-items: center;
  input.tiny {
    min-width: 10rem;
    width: auto;
    margin-right: 0;
  }
  .prefix,
  .suffix {
    color: ${theme.colors.subtleText};
    display: flex;
    align-items: center;
    border: 0;
    background: transparent;
    outline: none;
    transition: border 0.5s ease;
    box-sizing: border-box;
    font-size: 1.4rem;
    line-height: 1;
    height: 4rem;
    padding: 0;
    margin: 0;
  }
  .prefix {
    padding-right: ${theme.spacing.x1};
  }
  .suffix {
    padding-left: ${theme.spacing.x1};
  }
`;

const Warning = styled.div`
  border-radius: 8px;
  margin-top: ${theme.spacing.x3};
  background-color: ${theme.colors.warningBg};
  padding: ${theme.spacing.x2};
  h3 {
    margin-bottom: ${theme.spacing.x2};
  }
`;

const InvisibleButton = styled.button`
  display: none;
`;

const TasksContainer = styled(FormField)`
  background: ${theme.colors.N0};
  padding: ${theme.spacing.x2};
  margin: ${theme.spacing.x2} 0;
  border-radius: 4px;
  border: 1px solid ${theme.colors.blockBorder};
  margin-bottom: 0px;
`;

const WarningBlock = styled.div`
  padding: ${theme.spacing.x2};
  margin: ${theme.spacing.x2} 0;
  border-radius: 8px;
  display: flex;
  background: ${theme.colors.warningBg};

  > div {
    &:first-child {
      img {
        width: 4rem;
      }
      margin-right: ${theme.spacing.x3};
    }
  }
`;

interface Props {
  outcomeId: string;
}

function NewCheckin(props: Props) {
  const dispatch = useDispatch();
  const { outcomeId } = props;
  const queryCache = useQueryCache();
  const history = useHistory();
  const { t } = useTranslation();
  const translationKey = 'modals.checkin';
  const storageKey = `${CONTEXT_STORAGE_KEY}_checkin_${outcomeId}`;

  const currentWorkspace: TabilityTypes.Workspace = useSelector(
    (state: any) => state.session.currentWorkspace,
    shallowEqual,
  );
  const currentMembership = useSelector((state: any) => state.session.currentMembership, shallowEqual);
  const isOwner = currentMembership.role === 'owner';

  // Checkin mutations
  const [createCheckinMutation, { isLoading }]: [any, any] = useMutation(remoteApi.createCheckin, {
    onSuccess: () => {
      sessionStorage.removeItem(storageKey);
      dispatch(setGlobalModalContent(''));
      queryCache.invalidateQueries(queryKeys.outcomes);
      queryCache.invalidateQueries(queryKeys.checkins);
      queryCache.invalidateQueries(queryKeys.currentPlan);
      queryCache.invalidateQueries([queryKeys.checkins, 'pending']);
      queryCache.invalidateQueries([queryKeys.currentOutcome, outcomeId]);
      queryCache.invalidateQueries([queryKeys.currentPing]);
    },
    onError: (error: any) => {
      const errorText = error.response.data.error;
      if (errorText.includes('check-ins limit') && error.response.status === 401) {
        setErrorCheckinsLimitExceeded(true);
      }
    },
  });

  // Error management
  const [errorCheckinsLimitExceeded, setErrorCheckinsLimitExceeded] = useState(false);

  // Form values
  const [checkin_date, setCheckinDate] = useState(new Date());
  const [score, setScore] = useState('');
  const [previousScore, setPreviousScore] = useState(0);
  const [confidence, setConfidence] = useState<string>('green');
  const [body, setBody] = useState('');
  const [outcome, setOutcome] = useState<TabilityTypes.Outcome | null>(null);
  const [checkins, setCheckins] = useState<TabilityTypes.Checkin[]>([]);
  const [isCumul, setIsCumul] = useState(false);
  const [cumulValue, setCumulValue] = useState<number | null>(null);
  const [placeholder, setPlaceholder] = useState<number | null>(null);

  // Construct the query key using the plan Id
  const queryKey = [queryKeys.currentOutcome, outcomeId];
  const staleTime = 0;

  // Get the plan details
  useQuery(queryKey, remoteApi.fetchOutcomeDetails, {
    staleTime,
    onSuccess: (response) => {
      const outcome: TabilityTypes.Outcome = response.data;
      setOutcome(outcome);
      if (outcome.current_checkin) {
        const lastCheckin = outcome.current_checkin;
        setPreviousScore(lastCheckin.score);
        const defaultConfidence = lastCheckin.confidence === 'yellow' ? 'green' : lastCheckin.confidence;
        setConfidence(outcome.outcome_type !== 'kpi' ? defaultConfidence : 'blue');
        setPlaceholder(lastCheckin.score);
        setCumulValue(lastCheckin.score);
      } else {
        setConfidence(outcome.outcome_type !== 'kpi' ? 'yellow' : 'blue');
        setCumulValue(outcome.from !== null ? outcome.from : outcome.to);
        setPlaceholder(outcome.from !== null ? outcome.from : outcome.to);
      }
    },
  });

  // Construct the query key using the plan Id
  const queryKeyCheckins = [queryKeys.checkins, outcomeId, { sort: 'checkin_date', order: 'asc' }];

  // Get the plan details
  useQuery(queryKeyCheckins, remoteApi.fetchCheckins, {
    staleTime,
    onSuccess: (response) => {
      const checkins = response.data;
      setCheckins(checkins);
    },
  });

  useEffect(() => {
    debounceStoreText.current(body);
  }, [body]);

  useEffect(() => {
    const savedContext = sessionStorage.getItem(storageKey) || '';
    setBody(savedContext);
  }, [storageKey]);

  const storeText = (newText: string) => {
    if (newText) {
      sessionStorage.setItem(storageKey, newText);
    }
  };

  const debounceStoreText = useRef(
    _.debounce((newText: string) => storeText(newText), 1000, {
      maxWait: 5000,
    }),
  );

  // Now we can display the app with the Chrome
  if (!outcome) {
    return (
      <LoadingContainer>
        <Loader size="medium" />
      </LoadingContainer>
    );
  }

  const handleCancel = (e: any) => {
    e.preventDefault();
    dispatch(setGlobalModalContent(''));
  };

  const handleChange = (e: any) => {
    const inputTarget = e.target;
    const value = inputTarget.type === 'checkbox' ? inputTarget.checked : inputTarget.value;
    const name = inputTarget.name;

    switch (name) {
      case 'checkin_date':
        setCheckinDate(value);
        break;
      case 'score':
        setScore(value);
        break;
      case 'confidence':
        setConfidence(value);
        break;
      case 'body':
        setBody(value);
        break;
    }
  };

  const handleScoreChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    let value = e.target.value;
    value = value.replace(/[^-0-9.+]+/g, '');
    setScoreChanges(value);
  };

  const setScoreChanges = (value: string) => {
    setScore(value);

    let cumulValue = 0;

    // Detect if it's a cumulative update. If that's the case we set the state to true.
    // We can only do the cumulative update on new status
    if (value && value[0] === '+') {
      let valueToAdd = parseFloat(value.replace('+', ''));
      valueToAdd = isNaN(valueToAdd) ? 0 : valueToAdd;
      cumulValue = previousScore + valueToAdd;
      setIsCumul(true);
      setCumulValue(cumulValue);
    } else {
      let valueToAdd = parseFloat(value.replace('+', ''));
      valueToAdd = isNaN(valueToAdd) ? 0 : valueToAdd;
      cumulValue = valueToAdd;
      setIsCumul(false);
      setCumulValue(cumulValue);
    }
  };

  const handleSubmit = () => {
    // Set score to cumul value if necessary
    const checkin = {
      checkin_date,
      score: cumulValue || 0,
      confidence,
      body,
    };
    createCheckinMutation({
      outcomeId,
      checkin,
    });
  };

  const draftCheckin: TabilityTypes.checkinType = {
    x: checkin_date,
    y: cumulValue,
    timestamp: getTime(checkin_date),
    color: confidenceToColor(confidence),
    checkin: { confidence },
  };

  let format: string[] = [];
  if (outcomeUtils.hasTarget(outcome) && outcome.score_format) {
    format = outcome.score_format.split('_number_');
  }

  const cumulClass = isCumul ? 'cumul' : '';

  const submitText = isLoading ? <Loader /> : t('shared.create');

  const handleStopPropagation = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
  };

  const analysisPlaceholder = t(`${translationKey}.placeholder`);

  let progressValue;
  if (score !== '') {
    progressValue = isCumul ? cumulValue : score;
  } else {
    progressValue = placeholder;
  }

  // Handle check-ins quota exceeded
  const handleUpgradeV2 = (e: any) => {
    e.preventDefault();
    const route = `/${currentWorkspace.slug}/settings/billing/plans`;
    history.push(route);
    dispatch(setGlobalModalContent(''));
  };

  const isCheckinToday = isSameDay(checkin_date, new Date());
  console.log(isCheckinToday);
  const atRiskLimit = outcome.plan.limit_at_risk_enabled ? outcome.plan.limit_at_risk_value : 3;
  const atRiskStreakLimitReached = isCheckinToday
    ? outcomeUtils.atRiskStreakLimitReached(checkins, atRiskLimit)
    : false;
  const isDisabledAtRisk = outcome.plan.limit_at_risk_enabled && atRiskStreakLimitReached;

  if (
    currentWorkspace.pricing_version >= 2 &&
    (hasExceededCheckinsLimit(currentWorkspace) || errorCheckinsLimitExceeded)
  ) {
    return (
      <ModalGrid onClick={handleStopPropagation}>
        <ModalHeader>
          <h2>{outcome.title}</h2>
          <KoalaIconButton iconName="close" onClick={handleCancel} />
        </ModalHeader>
        <ModalContent>
          <Warning>
            <h3>
              <span role="img" aria-label="yikes!">
                😬
              </span>{' '}
              {t(`${translationKey}.quota`)}
            </h3>
            <p>{t(`${translationKey}.quotaLine1`)}</p>
            <p>{t(`${translationKey}.quotaLine2`)}</p>
            {isOwner && (
              <p>
                <KoalaButton onClick={handleUpgradeV2} appearance="subtle">
                  {t('shared.upgrade.upgradeWorkspace')}
                </KoalaButton>
              </p>
            )}
          </Warning>
        </ModalContent>
        <ModalFooter>
          <KoalaTextButton onClick={handleCancel}>{t('shared.cancel')}</KoalaTextButton>
        </ModalFooter>
      </ModalGrid>
    );
  }

  if (hasExceededUserLimit(currentWorkspace)) {
    return (
      <ModalGrid onClick={handleStopPropagation}>
        <ModalHeader>
          <h2>{outcome.title}</h2>
          <KoalaIconButton iconName="close" onClick={handleCancel} />
        </ModalHeader>
        <ModalContent>
          <Warning>
            <h3>
              <span role="img" aria-label="yikes!">
                😬
              </span>{' '}
              {t(`${translationKey}.userQuotaReached`)}
            </h3>
            <p>
              {t(`${translationKey}.userQuotaReachedLine1`, {
                users_count: currentWorkspace.memberships_count,
                user_limit: currentWorkspace.billing_user_limit,
              })}
            </p>
            <p>{t(`${translationKey}.userQuotaReachedLine2`)}</p>
            {isOwner && (
              <p>
                <KoalaButton onClick={handleUpgradeV2} appearance="subtle">
                  {t('shared.upgrade.upgradeWorkspace')}
                </KoalaButton>
              </p>
            )}
          </Warning>
        </ModalContent>
        <ModalFooter>
          <KoalaTextButton onClick={handleCancel}>{t('shared.cancel')}</KoalaTextButton>
        </ModalFooter>
      </ModalGrid>
    );
  }

  return (
    <ModalGrid onClick={handleStopPropagation}>
      <ModalHeader>
        <h2>{outcome.title}</h2>
        <KoalaIconButton iconName="close" onClick={handleCancel} />
      </ModalHeader>
      <ModalContent>
        <Columns>
          <UpdateColumn>
            <form onSubmit={handleSubmit}>
              <FormField>
                <label>{t(`${translationKey}.date`)}</label>
                <DatePicker
                  className="small"
                  selected={checkin_date}
                  dateFormat="d MMM yyyy"
                  onChange={(date: Date) => setCheckinDate(date)}
                />
              </FormField>
              <DataSourceLabel outcome={outcome} setScoreChanges={setScoreChanges} />
              {
                // Only display the score field if the outcome has a target
                outcome.outcome_type !== 'no_metric' && (
                  <FormField>
                    <label>{t(`${translationKey}.progress`)}</label>
                    <ScoreField>
                      {format[0] && (
                        <div className="prefix">
                          <span>{format[0]}</span>
                        </div>
                      )}
                      <CumulInput>
                        <input
                          className={`tiny ${cumulClass}`}
                          name="score"
                          autoComplete="off"
                          value={score}
                          placeholder={`${placeholder}`}
                          onChange={handleScoreChange}
                          required={true}
                        />
                        {isCumul === true && <div className="cumul-result">= {cumulValue}</div>}
                      </CumulInput>
                      {format[1] && (
                        <div className="suffix">
                          <span>{format[1]}</span>
                        </div>
                      )}
                    </ScoreField>
                    <p>
                      <small className="subtle">{t(`${translationKey}.progressInfo`)}</small>
                    </p>
                  </FormField>
                )
              }
              {outcome.outcome_type !== 'kpi' && (
                <RadioField>
                  <label>{t(`${translationKey}.confidence`)}</label>
                  <div className="radio-group">
                    <input
                      type="radio"
                      id="green"
                      checked={confidence === 'green'}
                      name="confidence"
                      value={'green'}
                      onChange={handleChange}
                    />
                    <label htmlFor="green" className="green">
                      {t('shared.confidence.onTrack')}
                    </label>
                    <input
                      type="radio"
                      id="yellow"
                      checked={confidence === 'yellow'}
                      name="confidence"
                      value={'yellow'}
                      onChange={handleChange}
                      disabled={isDisabledAtRisk}
                    />
                    {isDisabledAtRisk && (
                      <ReactTooltip type="dark" id="yellow-label" className="tooltip" effect="solid" delayShow={50}>
                        {t(`${translationKey}.confidenceWarningTooltip`, { limit: atRiskLimit })}
                      </ReactTooltip>
                    )}
                    <label
                      htmlFor="yellow"
                      className={`yellow ${isDisabledAtRisk ? 'disabled' : ''}`}
                      data-tip
                      data-for="yellow-label"
                    >
                      {t('shared.confidence.atRisk')}
                    </label>
                    <input
                      type="radio"
                      id="red"
                      checked={confidence === 'red'}
                      name="confidence"
                      value={'red'}
                      onChange={handleChange}
                    />
                    <label htmlFor="red" className="red">
                      {t('shared.confidence.offTrack')}
                    </label>
                  </div>
                </RadioField>
              )}

              {confidence === 'yellow' && atRiskStreakLimitReached && isCheckinToday && (
                <WarningBlock>
                  <div>
                    <img
                      src="https://res.cloudinary.com/tability/image/upload/v1600083943/static_assets/tabby_surprised_tranev.png"
                      alt="Seems completed"
                    />
                  </div>
                  <div>{t(`${translationKey}.confidenceWarning`)}</div>
                </WarningBlock>
              )}

              <FormField>
                <label>{t(`${translationKey}.analysis`)}</label>
                <MentionBox
                  value={body}
                  setValue={setBody}
                  placeholder={analysisPlaceholder}
                  cmdEnterCallback={handleSubmit}
                />
              </FormField>
              <InvisibleButton type="submit" />
            </form>
          </UpdateColumn>
          <ChartColumn>
            <CheckinsChart checkins={checkins} outcome={outcome} draftCheckin={draftCheckin} />
            {checkin_date && outcome.outcome_type === 'improve_metric' && (
              <ProgressBars
                outcome={outcome}
                checkinDate={checkin_date}
                currentValue={progressValue ?? ''}
                confidence={confidence}
              />
            )}
            <TasksContainer>
              <label>
                {t(`${translationKey}.related`, { label: translate(currentWorkspace, CustomTermKey.INITIATIVE, 2) })}
              </label>
              <InitiativesTab outcome={outcome} hideMeta openTaskInNewTab />
            </TasksContainer>
          </ChartColumn>
        </Columns>
      </ModalContent>
      <ModalFooter>
        <KoalaButton onClick={handleSubmit} disabled={isLoading} dataFor="submit-checkin-btn">
          {submitText}
        </KoalaButton>
        <KoalaTextButton onClick={handleCancel} type="button">
          {t('shared.cancel')}
        </KoalaTextButton>
      </ModalFooter>
    </ModalGrid>
  );
}

export default NewCheckin;
