import { ModalContent, ModalFooter, ModalGrid, ModalHeader } from 'components/GlobalModal';
import { SELECTED_MEMBERSHIPS_KEY } from 'config/constants';
import KoalaButton from 'koala/components/Button';
import KoalaIconButton from 'koala/components/IconButton';
import KoalaTextButton from 'koala/components/TextButton';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryCache } from 'react-query';
import { useDispatch } from 'react-redux';
import { setGlobalModalContent } from 'state/actions/globalUIActions';
import * as remoteApi from 'api/remote';
import styled from 'styled-components';
import theme from 'theme';
import queryKeys from 'config/queryKeys';

const Form = styled.form``;

const FormField = styled.div`
  display: flex;
  flex-direction: column;
  .form-label {
    text-transform: uppercase;
    font-size: 1.4rem;
    font-weight: 600;
    color: ${theme.colors.subtleText};
    margin-bottom: ${theme.spacing.x1};
  }

  margin: ${theme.spacing.x3} 0;
`;

const MembershipSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.x1};
  padding: ${theme.spacing.x2};
`;

interface Props {}

function DeleteMemberships(props: Props) {
  const { t } = useTranslation();
  const translationKey = 'modals.deleteMemberships';
  const dispatch = useDispatch();
  const queryCache = useQueryCache();
  const storedSelectedMembershipsValue = localStorage.getItem(SELECTED_MEMBERSHIPS_KEY);
  const [confirmText, setConfirmText] = useState('');
  const selectedMemberships: { id: string; title: string }[] = storedSelectedMembershipsValue
    ? JSON.parse(storedSelectedMembershipsValue)
    : [];

  //api
  const deleteMembershipAsync = async (membership: { id: string; title: string }) => {
    try {
      const response = await remoteApi.deleteMembership(membership.id);
      queryCache.invalidateQueries([queryKeys.currentMembership, membership.id]);
      return response.data;
    } catch (error: any) {
      // If an error occurs during deletion, return an object with the error information
      return {
        error: true,
        statusText: error.response?.statusText || 'Failed to delete',
        ...membership,
      };
    }
  };

  const [deleteMembershipsMutation, { isLoading }] = useMutation(
    async (memberships: any[]) => {
      const deletePromises = memberships.map((membership) => deleteMembershipAsync(membership));
      return Promise.all(deletePromises);
    },
    {
      onSuccess: (results) => {
        // Filter out the memberships that failed to delete
        const failedMemberships = results.filter((result) => result.error);

        if (failedMemberships.length > 0) {
          // Some memberships failed to delete
          const failedMembershipsMessage = failedMemberships
            .map((failedMembership) => `${failedMembership.title} (${failedMembership.statusText})`)
            .join('\n');
          const failedMessage = `${t(`${translationKey}.failed`, {
            count: failedMemberships.length,
            memberships: failedMembershipsMessage,
          })}`;
          window.alert(failedMessage);
        }

        if (results.length !== failedMemberships.length) {
          // if there's at least one success, clear cache
          queryCache.invalidateQueries(queryKeys.plans);
          queryCache.invalidateQueries([queryKeys.currentWorkspace]);
        }

        localStorage.removeItem(SELECTED_MEMBERSHIPS_KEY);
        dispatch(setGlobalModalContent(''));
      },
    },
  );

  const handleDeleteMemberships = (e: React.FormEvent) => {
    e.preventDefault();
    deleteMembershipsMutation(selectedMemberships);
  };

  const handleCancel = () => {
    dispatch(setGlobalModalContent(''));
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setConfirmText(e.target.value);
  };

  const isDisabled = confirmText !== 'BULK REMOVE' || isLoading;
  const membershipCount = selectedMemberships.length;

  return (
    <ModalGrid>
      <ModalHeader>
        <h2>{t(`${translationKey}.title`)}</h2>
        <KoalaIconButton onClick={handleCancel} iconName="close" />
      </ModalHeader>
      <ModalContent>
        <Form onSubmit={handleDeleteMemberships}>
          <p>{t(`${translationKey}.label`, { count: membershipCount })}</p>
          <MembershipSection>
            {selectedMemberships.map((membership, i) => (
              <b key={i}>{membership.title}</b>
            ))}
          </MembershipSection>
          <p>{t(`${translationKey}.label2`)}</p>
          <FormField>
            <div className="form-label">{t(`${translationKey}.confirm`)}</div>
            <input
              onChange={handleChange}
              value={confirmText}
              className="small"
              placeholder={t(`${translationKey}.typeDelete`) ?? 'type BULK DELETE to confirm'}
            />
          </FormField>
        </Form>
      </ModalContent>
      <ModalFooter>
        <KoalaButton disabled={isDisabled} loading={isLoading} onClick={handleDeleteMemberships}>
          {t('shared.delete')}
        </KoalaButton>
        <KoalaTextButton onClick={handleCancel}>{t('shared.cancel')}</KoalaTextButton>
      </ModalFooter>
    </ModalGrid>
  );
}

export default DeleteMemberships;
