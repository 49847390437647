import queryKeys from 'config/queryKeys';
import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { shallowEqual, useSelector } from 'react-redux';
import styled from 'styled-components';
import * as remoteApi from 'api/remote';
import { Objective } from 'types';
import theme from 'theme';
import { CustomTermKey, translate } from 'utils/customTermUtils';
import KoalaCircularProgress from 'koala/components/CircularProgress';
import { Trans, useTranslation } from 'react-i18next';
import KoalaIcon from 'koala/components/Icons';
import ReactTooltip from 'react-tooltip';
import KoalaNCSPie from 'koala/components/NCSPie';
import * as objectiveUtils from 'utils/objectiveUtils';
import { DragHandle, PathSection, SourceTitle, WidgetContainer, WidgetContent, WidgetHeader } from '.';
import KoalaLoader from 'koala/components/Loader';
import { AxiosError } from 'axios';
import PlanIconLabel from 'components/PlanIconLabel';
import { WORKSPACE_PLAN_TEMPLATE_ROUTE, WORKSPACE_PLAN_TRACK_ROUTE, WORKSPACE_PLAN_WRITE_ROUTE } from 'routes';
import { Link, useHistory } from 'react-router-dom';

const ObjectiveMetrics = styled.div`
  display: flex;
  flex: 1;
  flex-wrap: wrap;
  column-gap: ${theme.spacing.x2};
  align-items: center;
  justify-content: space-around;
`;

const DataContainer = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  min-width: 13rem;

  &:last-of-type {
    border-right: 0;
  }
  h4 {
    font-weight: 600;
  }
  small {
    display: flex;
    gap: ${theme.spacing.half};
    align-items: center;
  }
`;

interface Props {
  objectiveId: string;
}

function ObjectiveSummaryWidget(props: Props) {
  const { objectiveId } = props;
  const history = useHistory();
  const currentWorkspace = useSelector((state: any) => state.session.currentWorkspace, shallowEqual);
  const { t } = useTranslation();
  const [error, setError] = useState('');
  // Query keys and query params
  const queryKey = [queryKeys.currentObjective, objectiveId];
  const staleTime = 0;
  const [objective, setObjective] = useState<Objective | null>(null);

  // Get the outcome details
  const { isLoading } = useQuery(queryKey, remoteApi.fetchObjectiveDetails, {
    staleTime,
    onSuccess: (response) => {
      setObjective(response.data);
    },
    onError: (error: AxiosError) => {
      const errorText = error.response?.data.error;
      if (errorText.includes('not allowed') && error.response?.status === 401) {
        setError(
          t('workspaceDashboards.permissionError') ?? 'You do not have permission to view the content in this widget',
        );
      } else {
        setError(t('workspaceDashboards.widgetError') ?? 'Unable to fetch widget');
      }
    },
  });

  if (isLoading) {
    return (
      <WidgetContainer>
        <KoalaLoader />
      </WidgetContainer>
    );
  }

  if (!objective) {
    return (
      <WidgetContainer>
        <WidgetHeader>
          <DragHandle className="drag-handle">
            <KoalaIcon iconName="grab" iconSize="small" />
          </DragHandle>
          <p className="widget-type">
            {t('workspaceDashboards.objectiveSummary', {
              objective: translate(currentWorkspace, CustomTermKey.OBJECTIVE, 1),
            })}
          </p>
        </WidgetHeader>
        <WidgetContent>
          <p>{error ?? t('workspaceDashboards.widgetError')}</p>
        </WidgetContent>
      </WidgetContainer>
    );
  }

  const confidenceData = objectiveUtils.getConfidenceData(objective);
  const outcomeProgressData = objectiveUtils.getOutcomeProgressData(objective);
  const initiativeProgressData = objectiveUtils.getInitiativeProgressData(objective);

  const stateToRouteMapping: { [key: string]: string } = {
    draft: WORKSPACE_PLAN_WRITE_ROUTE,
    published: WORKSPACE_PLAN_TRACK_ROUTE,
    template: WORKSPACE_PLAN_TEMPLATE_ROUTE,
  };
  let routeMappingState = objective.plan.state || 'draft';
  const routeTarget = stateToRouteMapping[routeMappingState];
  const planRoute = routeTarget
    .replace(':workspaceSlug', currentWorkspace.slug)
    .replace(':planId', objective.plan.nano_slug);

  const handleClickObjective = () => {
    history.push(`#objective:${objective.nano_slug}:show`);
  };

  return (
    <WidgetContainer>
      <WidgetHeader>
        <DragHandle className="drag-handle">
          <KoalaIcon iconName="grab" iconSize="small" />
        </DragHandle>
        <p className="widget-type">
          {t('workspaceDashboards.objectiveSummary', {
            objective: translate(currentWorkspace, CustomTermKey.OBJECTIVE, 1),
          })}
        </p>
      </WidgetHeader>
      <WidgetContent>
        <SourceTitle>
          <KoalaIcon iconName="flag" iconSize="small" className="header-icon" />
          <span onClick={handleClickObjective} className="widget-source">
            {objective.title}
          </span>
        </SourceTitle>
        <ObjectiveMetrics>
          <DataContainer>
            <h4>{translate(currentWorkspace, CustomTermKey.OUTCOME, 2)}</h4>
            <KoalaCircularProgress data={outcomeProgressData} size="large" />
          </DataContainer>
          <DataContainer>
            <h4>{translate(currentWorkspace, CustomTermKey.INITIATIVE, 2)}</h4>
            <KoalaCircularProgress data={initiativeProgressData} size="large" />
          </DataContainer>
          <DataContainer>
            <h4>
              {t('shared.ncs.acronym')}
              <KoalaIcon iconName="tooltip" iconSize="small" data-tip data-for="explain-ncs" />
            </h4>
            <ReactTooltip
              place="bottom"
              type="dark"
              className="tooltip"
              effect="solid"
              id="explain-ncs"
              delayHide={500}
              clickable={true}
            >
              <Trans i18nKey={'shared.ncs.tooltip'} components={{ br: <br /> }} />{' '}
              <a
                href="https://www.tability.io/odt-articles/nps-but-for-your-okrs-introducing-the-net-confidence-score-ncs"
                target="_blank"
                rel="noopener noreferrer"
              >
                {t('shared.learnMore')}
              </a>
            </ReactTooltip>
            <KoalaNCSPie
              centerLabel={[Math.round(objective.ncs).toString(), t('shared.ncs.acronym')]}
              data={confidenceData}
              size="large"
            />
          </DataContainer>
        </ObjectiveMetrics>
        <PathSection>
          <Link to={planRoute}>
            <PlanIconLabel plan={objective.plan} />
          </Link>
        </PathSection>
      </WidgetContent>
    </WidgetContainer>
  );
}

export default ObjectiveSummaryWidget;
